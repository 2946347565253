import { PermissionNames, PermissionType } from 'src/app/models/auth/authorization.models';
import { ProfileLink } from 'src/app/models/profile/profile-link.model';

export const HcpLinks: ProfileLink[] = [
    {
        title: 'Demographics',
        url: 'demographics',
        active: false,
    },
    {
        title: 'Contacts',
        url: 'contacts',
        active: false,
    },
    {
        title: 'LOB Specific Table',
        url: 'lob-specific-table',
        active: false,
    },
    {
        title: 'Notes',
        url: 'notes',
        active: false,
        children: [
            {
                title: 'Progress Notes',
                url: 'notes/progress',
                active: false,
            },
            {
                title: 'Conversation',
                url: 'notes/conversation',
                active: false,
                permission: {
                    name: PermissionNames.ConversationNotes,
                    type: PermissionType.Search,
                },
            },
        ],
    },
    {
        title: 'Licensure',
        url: 'licensure',
        active: false,
    },
];
