import { Injectable } from '@angular/core';
import { environment } from 'src/environments/config';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class NarrativeApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiNarrative.protocol}://${environment.apiNarrative.host}:${environment.apiNarrative.port}/${environment.apiNarrative.path}`;
}
