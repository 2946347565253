<section>
    <h2>Select an Authorized Representative</h2>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Authorized Representative</mat-label>
        <mat-select (selectionChange)="onAuthorizedRepSelected($event)">
            <mat-option
                *ngFor="let authorizedRep of authorizedRepresentatives$ | async"
                [value]="authorizedRep">
                {{ authorizedRep.firstName }} {{ authorizedRep.lastName }}
                <ng-container *ngIf="authorizedRep.title">({{ authorizedRep.title }})</ng-container>
            </mat-option>
        </mat-select>
    </mat-form-field>
</section>

<div class="button-container">
    <a
        class="alt-link"
        (click)="onAddAuthorizedRepClicked()">
        Add an Authorized Representative
    </a>
</div>
