import { Injectable } from '@angular/core';
import { User } from 'src/app/models/auth/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserAdapter {
    adapt(item: any): User {
        let user = new User(
            item.id,
            item.displayName,
            item.phone,
            item.username,
            item.nickname,
            item.givenName,
            item.lastLoginDate,
            item.pnNameId
        );
        if (item.businessLines) {
            user.businessLines = item.businessLines;
        }
        if (item.activeDirectoryGroups) {
            user.groups = item.activeDirectoryGroups;
        }
        return user;
    }
}
