<nav
    [class.hidden]="hideDetails"
    *ngIf="phoneNumbers.length > 1">
    <button
        id="HipaaPhoneNumbers_Previous"
        [class.hidden]="currentIndex === 0"
        (click)="selectNumber(false)">
        <mat-icon>navigate_next</mat-icon>
    </button>
    <button
        id="HipaaPhoneNumbers_Next"
        [class.hidden]="currentIndex === phoneNumbers.length - 1"
        (click)="selectNumber(true)">
        <mat-icon>navigate_next</mat-icon>
    </button>
</nav>
<mat-card
    [class.hide-details]="hideDetails"
    [class.single-number]="phoneNumbers.length <= 1">
    <mat-icon (click)="showAdditionalDetails()">info</mat-icon>
    <mat-card-content>
        <aside>
            <label for="contact_name">Name</label>
            <output name="contact_name">
                {{ currentContact?.firstName ? currentContact?.firstName + ' ' + currentContact?.lastName : '--' }}
            </output>
            <label for="contact_relationship">Relationship</label>
            <output name="contact_relationship">{{ currentContact?.title || '--' }}</output>
            <label for="contact_number">Number</label>
            <output name="contact_number">
                {{ activePhoneNumber ? activePhoneNumber.number : '--' }}
                <small *ngIf="activePhoneNumber">{{ activePhoneNumber.numberType }}</small>
            </output>
        </aside>
    </mat-card-content>
</mat-card>
