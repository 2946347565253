import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ProfileLink, ProfileLinkPermission } from 'src/app/models/profile/profile-link.model';
import { ProfileMode } from 'src/app/models/profile/profile-mode.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { ProfileAcknowledgeComponent } from './profile-acknowledge/profile-acknowledge.component';

@Component({
    selector: 'swft-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnDestroy, OnInit {
    private onDestroy: Subject<void> = new Subject<void>();

    @ViewChild(ProfileAcknowledgeComponent) patientAcknowledge?: ProfileAcknowledgeComponent;
    @Input() profileLinks: ProfileLink[] = [];
    @Input() profileType!: ProfileMode;
    @Input() id: number = 0;
    @Input() showHcp = false;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private snackbar: SWFTSnackBarService
    ) {}

    ngOnInit(): void {
        this.profileLinks.forEach(l => {
            if (this.router.url.includes(l.url)) {
                l.active = true;
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    showContent(link: any): void {
        const content: HTMLElement | null = document.getElementById('PatientProfileContent');

        const hideSpinnersTimeout = setTimeout(() => {
            this.hideSpinners(true);
        }, 10);

        if (link.active) return;

        this.profileLinks.forEach(l => {
            l.active = false;
        });

        link.active = true;

        if (content) {
            content.classList.remove('show');
            setTimeout(() => {
                this.router.navigate([link.url], { relativeTo: this.route });
            }, 400);
            setTimeout(() => {
                content.classList.add('show');
            }, 500);
            setTimeout(() => {
                clearTimeout(hideSpinnersTimeout);
                this.hideSpinners(false);
            }, 650);
        }
    }

    showChild(count: number = 0): void {
        if (count > 5) {
            this.snackbar.error('Error loading profile content');
            return;
        }

        const content: HTMLElement | null = document.getElementById('PatientProfileContent');

        if (content) {
            content.classList.add('hide');
            window.setTimeout(() => {
                content.classList.remove('hide');
                const contentCheck: HTMLElement | null = document.getElementById('PatientProfileContent');
                if (!contentCheck || contentCheck.classList.contains('hide')) {
                    this.showChild(count + 1);
                }
            }, 400);
        }
    }

    canViewLink(permission?: ProfileLinkPermission) {
        if (!permission) return true;
        return this.auth.hasValidPermission(permission.name, permission.type);
    }

    private hideSpinners(state: boolean): void {
        document.querySelectorAll('.swft-spinner').forEach(spinnerElement => {
            if (state) {
                spinnerElement.classList.add('hide');
                return;
            }
            spinnerElement.classList.remove('hide');
        });
    }
}
