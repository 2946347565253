import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HipaaModule } from './components/hipaa/hipaa.module';
import { HomeComponent } from './components/home/home.component';
import { LogicModule } from './components/logic/logic.module';
import { CallbackComponent } from './components/login/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileGridComponent } from './components/profile-grid/profile-grid.component';
import { ProgressNotesEditorComponent } from './components/progress-notes/progress-notes-editor/progress-notes-editor.component';
import { IdleComponent } from './components/swft/idle/idle.component';
import { RefreshTokenComponent } from './components/swft/refresh-token/refresh-token.component';
import { ConflictDialogComponent } from './components/swft/swft-conflict-dialog/conflict-dialog.component';
import { SwftModule } from './components/swft/swft.module';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { StripHtmlTagsPipe } from './pipes/strip-html-tags/strip-html-tags.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';
import { navReducer } from './state/nav/nav.reducer';
import { MaterialModule } from './swft/modules/material.module';
import { SharedModule } from './swft/modules/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CallbackComponent,
        HomeComponent,
        ProfileGridComponent,
        ConflictDialogComponent,
        IdleComponent,
        RefreshTokenComponent,
        ProgressNotesEditorComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        HipaaModule,
        HttpClientModule,
        MaterialModule,
        NgIdleModule.forRoot(),
        SharedModule,
        StoreModule.forRoot({ nav: navReducer }),
        SwftModule,
        LogicModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        DatePipe,
        DecimalPipe,
        YesNoPipe,
        StripHtmlTagsPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
