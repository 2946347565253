import { HipaaVerificationType } from '../enums/hipaa.enum';
import { CallType, OutboundCall } from './call.models';
import { Contact, CoveredEntityContact, HcpContact, PatientContact, PayorContact } from './profile/contact.model';
import { PatientDemographic } from './profile/demographic.model';
import { PatientPhysician } from './profile/physician.model';

export type HipaaContact = PatientContact | PayorContact | Contact | HcpContact | CoveredEntityContact | null;

export interface SWFTHipaaAttestations {
    documentation: boolean;
    ignoredNumbers: boolean;
    userInitials: string;
}

export interface HipaaValidateContact {
    contact?: HipaaContact;
    valid: boolean;
}
export class HipaaData {
    patient: PatientDemographic = {
        mrn: '',
        firstName: '',
        lastName: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        phone: '',
        workPhone: '',
        cellPhone: '',
        language: '',
        email: '',
        company: '',
        siteName: '',
        patientStatus: '',
        dob: '',
        sex: '',
        id: 0,
        ssn: '',
        deidentifiedId: '',
        specialId: '',
        hubId: '',
    };
    callType?: CallType;
    callLog: OutboundCall[] = [];
    contact?: HipaaContact;
    verificationType: HipaaVerificationType = HipaaVerificationType.Empty;
    attestations: SWFTHipaaAttestations = {
        documentation: false,
        ignoredNumbers: false,
        userInitials: '',
    };
    hcpContact?: PatientPhysician;
}
