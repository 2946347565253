import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoginError } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';

@Component({
    selector: 'swft-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly defaultBtnText = 'Log in';
    private submitTimer: number = 0;
    pageLoaded = false;
    isLoggingIn = false;
    btnText: string = this.defaultBtnText;
    form: UntypedFormGroup = new UntypedFormGroup({
        username: new UntypedFormControl(''),
    });
    private readonly usernamesessionStorageKey = 'authenticationUsername';
    private onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private loginService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private snackbar: SWFTSnackBarService,
        private dialogRef: MatDialog,
        private sessionStorageService: SessionStorageService
    ) {}

    ngOnInit() {
        this.dialogRef.closeAll();
        // If user logs out or login fails, clean url and display logout message
        this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            const loginError: LoginError = params['loginError'];
            let message: string | null = null;

            if (loginError === LoginError.LOGOUT) {
                message = 'You have been logged out.';
            }
            if (loginError === LoginError.INSUFFICIENT_PRIVILEGES) {
                message = 'Login failed, insufficient privileges!';
            }
            if (loginError === LoginError.FAILED) {
                message = 'Login failed';
            }

            if (message) {
                this.snackbar.error(message, false);
                this.reset();
                this.router.navigate(['/login']);
            }
        });

        document.querySelector('body')?.classList.add('login');
    }

    ngOnDestroy(): void {
        document.querySelector('body')?.classList.remove('login');
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.pageLoaded = true;
        }, 250);
    }

    onSubmit() {
        let username = this.form.controls['username'].value;

        let storedUsername = this.sessionStorageService.getItem(this.usernamesessionStorageKey);
        if (!username && storedUsername) {
            username = storedUsername;
        }

        this.loginService.login(username);
        this.isLoggingIn = true;
        this.submitTimer = window.setTimeout(() => {
            this.reset();
        }, 6000);
    }

    updateUserName(userName: string): void {
        this.sessionStorageService.setItem(this.usernamesessionStorageKey, userName);
    }

    private reset(): void {
        if (this.submitTimer) clearTimeout(this.submitTimer);
        this.btnText = this.defaultBtnText;
        this.isLoggingIn = false;
    }
}
