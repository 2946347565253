<ng-container *ngIf="attestations | async; let attestations">
    <div class="content-container">
        <label>Attestation</label>
        <article
            *ngIf="attestations.documentation"
            id="DocumentationAttestation">
            <h3>Documentation</h3>
            <p>
                THIS IS NOT PRODUCTION TEXT. However, this IS, potentially, where some legal jargon could exist
                explaining to the user that they are agreeing to access HIPAA protected information without first
                communicating with the patient, why this might be a bad idea and how they may reconsider thier choice.
                Perhaps a link to T&C? If the user is aware of the implecations and sure of their need to access then...
            </p>
        </article>
        <article
            *ngIf="attestations.ignoredNumbers"
            id="IgnoredNumbersAttestation">
            <h3>Ignored Phone Numbers</h3>
            <p>
                THIS IS NOT PRODUCTION TEXT. However, this IS, potentially, where some legal jargon could exist
                explaining to the user that they are agreeing to access HIPAA protected information without first
                communicating with the patient, why this might be a bad idea and how they may reconsider thier choice.
                Perhaps a link to T&C? If the user is aware of the implecations and sure of their need to access then...
            </p>
        </article>
    </div>
    <div
        class="button-container"
        [class.documentation-only]="!attestations.ignoredNumbers">
        <strong>I understand the conditions above and can lawfully verify my right to access.</strong>
        <form [formGroup]="formGroup">
            <mat-form-field
                *ngIf="attestations.ignoredNumbers"
                appearance="fill"
                [class.success]="formGroup.controls['initials'].valid"
                class="full">
                <mat-label>Initals</mat-label>
                <input
                    id="AttestationUserInitials"
                    matInput
                    type="text"
                    autocomplete="off"
                    formControlName="initials" />
            </mat-form-field>
            <button
                id="AttestationContinueBtn"
                color="primary"
                class="center"
                [ngClass]="attestations.ignoredNumbers ? 'two-thirds' : 'full'"
                [disabled]="attestations.ignoredNumbers && formGroup.controls['initials'].invalid"
                mat-raised-button
                (click)="onAgree()">
                Agree and Continue
            </button>
        </form>
    </div>
</ng-container>
