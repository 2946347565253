import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogDirective } from 'src/app/directives/confirmation-dialog.directive';
import { SwftDynamicTagDirective } from 'src/app/directives/dynamic-tag/dynamic-tag.directive';
import { SwftSearchGridDirective } from 'src/app/directives/grid-search-modal.directive';
import { HyphentatePhoneNumberDirective } from 'src/app/directives/hyphenate-phone-number/hyphenate-phone-number.directive';
import { SwftSelectSearchDirective } from 'src/app/directives/select-search-modal.directive';
import { DatePickerDirective } from 'src/app/directives/swft-datepicker.directive';
import { SWFTTooltipDirective } from 'src/app/directives/swft-tooltip.directive';
import { TextareaAutoResizeDirective } from 'src/app/directives/text-area-auto-resize.directive';
import { MaterialModule } from 'src/app/swft/modules/material.module';
import { GenericFormPageComponent } from '../forms/pages/generic-form-page/generic-form-page.component';
import { SwftLeftNavComponent } from './left-nav/swft-left-nav.component';
import { SwftActionsColumnComponent } from './swft-actions-column/swft-actions-column.component';
import { SwftActionsHeaderComponent } from './swft-actions-header/swft-actions-header.component';
import { SwftConfirmComponent } from './swft-confirm/swft-confirm.component';
import { ConfirmationDialogComponent } from './swft-confirmation-dialog/confirmation-dialog.component';
import { SwftDatepickerComponent } from './swft-datepicker/swft-datepicker.component';
import { SwftFormErrorsComponent } from './swft-form-errors/swft-form-errors.component';
import { SwftGridSearchComponent } from './swft-grid-search/swft-grid-search.component';
import { SwftGridComponent } from './swft-grid/swft-grid.component';
import { SwftPopUpNoteComponent } from './swft-popup-note/swft-popup-note.component';
import { SwftSearchbarComponent } from './swft-searchbar/swft-searchbar.component';
import { SwftSelectSearchComponent } from './swft-select-search/swft-select-search.component';
import { SwftSpinnerComponent } from './swft-spinner/swft-spinner.component';
import { SwftToolbarComponent } from './swft-toolbar/swft-toolbar.component';
import { WarmTransferComponent } from './warm-transfer/warm-transfer.component';
import { LeftNavSectionComponent } from './left-nav-section/left-nav-section.component';

@NgModule({
    declarations: [
        SwftSpinnerComponent,
        SwftConfirmComponent,
        SwftFormErrorsComponent,
        SwftSearchGridDirective,
        SwftSelectSearchDirective,
        SwftDynamicTagDirective,
        SwftGridSearchComponent,
        SwftToolbarComponent,
        ConfirmationDialogComponent,
        ConfirmationDialogDirective,
        SwftActionsColumnComponent,
        SwftSearchbarComponent,
        SWFTTooltipDirective,
        SwftLeftNavComponent,
        SwftDatepickerComponent,
        DatePickerDirective,
        SwftActionsHeaderComponent,
        SwftPopUpNoteComponent,
        GenericFormPageComponent,
        TextareaAutoResizeDirective,
        SwftGridComponent,
        SwftSelectSearchComponent,
        WarmTransferComponent,
        HyphentatePhoneNumberDirective,
        LeftNavSectionComponent,
    ],
    imports: [MaterialModule, CommonModule, ReactiveFormsModule, MatInputModule, MatPaginatorModule, RouterModule],
    exports: [
        SwftSpinnerComponent,
        SwftConfirmComponent,
        SwftFormErrorsComponent,
        SwftSearchGridDirective,
        SwftSelectSearchDirective,
        SwftDynamicTagDirective,
        SwftToolbarComponent,
        SwftActionsHeaderComponent,
        ConfirmationDialogComponent,
        ConfirmationDialogDirective,
        SwftActionsColumnComponent,
        SwftSearchbarComponent,
        SWFTTooltipDirective,
        SwftLeftNavComponent,
        SwftDatepickerComponent,
        DatePickerDirective,
        SwftPopUpNoteComponent,
        GenericFormPageComponent,
        TextareaAutoResizeDirective,
        SwftGridComponent,
        SwftSelectSearchComponent,
        HyphentatePhoneNumberDirective,
    ],
})
export class SwftModule {}
