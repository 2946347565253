import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class CprApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiCpr.protocol}://${environment.apiCpr.host}:${environment.apiCpr.port}/${environment.apiCpr.path}`;
}
