<mat-calendar
    (selectedChange)="selectedDate.next($event)"
    [selected]="selectedDate.value"></mat-calendar>
<div class="form">
    <mat-form-field>
        <input
            matInput
            type="number"
            placeholder="Add Days"
            [value]="days | async"
            (change)="setDays.bind(this)($event)" />
    </mat-form-field>
</div>
<p>Calculated Date: {{ calcDate | async | date: DateFormat }}</p>
<swft-confirm
    (deny)="cancel()"
    (confirm)="save()"
    confirmText="Save"
    [disabled]="false"></swft-confirm>
