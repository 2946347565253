import { Endpoint, IEndpoint } from './endpoint';

export class IdentityEndpoints {
    groups: GroupEndpoints = new GroupEndpoints();
    users: UserEndpoints = new UserEndpoints();
    login: LoginEndpoints = new LoginEndpoints();
    permissions: PermissionsEndpoints = new PermissionsEndpoints();
}

export class GroupEndpoints {
    fetchAll: IEndpoint = new Endpoint('get', 'v1/group');
    update: IEndpoint = new Endpoint('put', 'v1/group');
}
export class UserEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/user');
    searchWithGroups: IEndpoint = new Endpoint('post', 'v1/user/withGroups');
    searchUserModel: IEndpoint = new Endpoint('post', 'v1/user/searchUserModel');
    createOrUpdate: IEndpoint = new Endpoint('put', 'v1/user');
    getById: IEndpoint = new Endpoint('get', 'v1/user/{id}');
    pnNameLists: IEndpoint = new Endpoint('get', 'v1/user/pnNameLists');
}

export class LoginEndpoints {
    authenticate: IEndpoint = new Endpoint('post', 'v1/login/authenticate');
    getCredentials: IEndpoint = new Endpoint(
        'get',
        'v1/login/oidc-callback?state={state}&code={code}&username={username}&redirectUri={redirectUri}'
    );
}

export class PermissionsEndpoints {
    fetchAll: IEndpoint = new Endpoint('get', 'v1/permission');
}
