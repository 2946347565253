import { of } from 'rxjs';
import { Action, ActionCombination } from '../models/action.models';
import { SortOrder } from '../models/api/api-search.models';
import { FormSearch } from '../models/form.models';
import { HealthCareProvider } from '../models/hcp.model';
import { NarrativeSearch } from '../models/narrative.model';
import { Patient } from '../models/patient.model';
import { PatientDemographic } from '../models/profile/demographic.model';
import { PatientOrder } from '../models/profile/order.model';
import { ResponseOption } from '../models/response-options.models';
import { SingleSearchTermQuery, SwftSort } from '../models/table.models';

export const DateFormat: string = 'MM/dd/yyyy';
export const DateTimeMin: Date = new Date('0001-01-01T00:00:00Z');
export const DateTimeFormat: string = 'MM/dd/yyyy hh:mm:ss a'; // 22 -> mm/dd/yy hh:mm:ss AM/PM
export const DecimalRegex = /^[0-9]*.[0-9]+$/i;
export const ZipCodeRegex = /^\d{5}(?:\d{2})?$/;
export const PhoneNumberRegex = '^\\d{3}-\\d{3}-\\d{4}$';
export const InitalSortHcp: SwftSort<HealthCareProvider> = { property: 'id', order: SortOrder.Ascending };
export const InitalSortPatients: SwftSort<Patient> = { property: 'mrn', order: SortOrder.Ascending };
export const InitalSortPatientDemographics: SwftSort<PatientDemographic> = {
    property: 'mrn',
    order: SortOrder.Ascending,
};
export const InitalSortOrders: SwftSort<PatientOrder> = { property: 'rxNumber', order: SortOrder.Ascending };
export const InitalSortForms: SwftSort<FormSearch> = { property: 'id', order: SortOrder.Ascending };
export const InitalSortNarrative: SwftSort<NarrativeSearch> = { property: 'id', order: SortOrder.Ascending };
export const InitalSortAction: SwftSort<Action> = { property: 'name', order: SortOrder.Ascending };
export const InitalSortActionCombination: SwftSort<ActionCombination> = { property: 'id', order: SortOrder.Ascending };
export const InitalSortResponseOption: SwftSort<ResponseOption> = { property: 'name', order: SortOrder.Ascending };
export const EmptyQuery: SingleSearchTermQuery = { search: '' };

export const PageSizeTen: number = 10;
export const PageSizeHundred: number = 100;

export const EmptyPaginatorResults = of({
    content: [],
    totalElements: -1,
    size: -1,
    number: -1,
});

export enum EmptySearchGridText {
    Patient = 'Enter search criteria to view patients',
    NoResult = 'No results',
}

export enum SwftBasis {
    Empty = '',
    HCP = 'HCP Based',
    Patient = 'Patient Based',
    Order = 'Order Based',
}

export enum BsaFormulaType {
    DuBois = 'Du Bois',
    Mosteller = 'Mosteller',
}

export enum MedicalHeightUnit {
    Centimeters = 'cm',
    Inches = 'in',
}

export enum MedicalWeightUnit {
    Kilogram = 'kg',
    Pounds = 'lbs',
}

export interface KeyValuePair {
    [key: string | number]: any;
}
export const EmptyTableCell = '---';
