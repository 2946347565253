export interface Action {
    id: number;
    name: string;
    type: ActionType;
    modifiedDate?: Date;
    createdDate?: Date;
    deleted: boolean;
    actionCombination?: ActionCombination[];
}
export interface ActionCombination {
    id: number;
    actions?: Action[];
    allowableNextActions?: AllowableNextAction[];
    actionCombinationMap?: ActionCombinationMap[];
    formId: number;
    defaultDaysOut: number;
    daysOutType: DayOutType;
    daysOutEditable: boolean;
    sequence: number;
    modifiedDate?: Date;
    createdDate?: Date;
    deleted: boolean;
}

export interface ActionCombinationNote {
    lastActionName: string;
    nextActionName: string;
    nextActionDate: string;
    narrativeId: number | undefined;
}

export const defaultActionCombinationNote: ActionCombinationNote = {
    lastActionName: 'undefined',
    nextActionName: 'undefined',
    nextActionDate: 'undefined',
    narrativeId: 0,
};

export interface AllowableNextAction {
    id: number;
    actionId: number;
    actionCombinationId: number;
    name?: string;
    defaultDaysOut: number;
    daysOutType: DayOutType;
    daysOutEditable: boolean;
    sequence: number;
    modifiedDate?: Date;
    createdDate?: Date;
    deleted: boolean;
    action?: Action;
}

export interface ActionCombinationMap {
    actionId: number;
    actionCombinationId: number;
}

export enum ActionType {
    LastAction = 'LastAction',
    NextAction = 'NextAction',
    Empty = '',
}

export enum DayOutType {
    Calendar = 'Calendar',
    Business = 'Business',
    Empty = '',
}
