<div
    [class.maximize]="maximized"
    cdkDrag
    (cdkDragEnded)="dragEnded($event)"
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary="body">
    <header cdkDragHandle>
        <mat-icon
            id="SWFTPopupNoteClose"
            (click)="close()">
            close
        </mat-icon>
        <mat-icon
            *ngIf="!maximized"
            (click)="maximize(true)">
            web_asset
        </mat-icon>
        <mat-icon
            *ngIf="maximized"
            class="minimize"
            (click)="maximize(false)">
            minimize
        </mat-icon>
    </header>

    <ul>
        <li>
            <strong>Subject</strong>
            {{ note.subject }}
        </li>
        <li>
            <strong>Created Date</strong>
            {{ note.createdDate | date: dateTimeFormat }}
        </li>
        <li>
            <strong>Created By</strong>
            {{ note.createdBy }}
        </li>
    </ul>

    <p [innerHtml]="note.body"></p>
</div>
