<swft-hipaa-call-log></swft-hipaa-call-log>
<swft-hipaa-call-failed *ngIf="allCallsFailed"></swft-hipaa-call-failed>

<ng-container *ngIf="!allCallsFailed">
    <swft-hipaa-phone-number-selector
        #phoneNumberSelector
        (phoneNumberSelected)="phoneNumberSelected($event)"></swft-hipaa-phone-number-selector>

    <div
        [class.hidden]="processingResult"
        class="button-container">
        <a
            class="alt-link"
            (click)="addPhoneNumber()">
            I have a number for {{ selectedContact?.firstName + ' ' + selectedContact?.lastName | truncate: 18 }}
        </a>
        <button
            class="half center"
            color="primary"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.Answered)">
            Answered by {{ selectedContact?.firstName | truncate: 8 }}
        </button>
        <button
            class="half center"
            color="primary"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.AnsweredByOther)">
            Answered by Other
        </button>
        <button
            class="half center"
            color="gray"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.LeftMessage)">
            Left Message
        </button>
        <button
            class="half center"
            color="gray"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.Ignored)">
            Ignore
        </button>
        <button
            class="half center"
            color="warn"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.BadNumber)">
            Bad Number
        </button>
        <button
            class="half center"
            color="warn"
            mat-raised-button
            (click)="callResultSelected(outboundCallResult.NoAnswer)">
            No Answer
        </button>
    </div>
</ng-container>
