import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, take, takeUntil } from 'rxjs';
import { HipaaService } from '../../services/hipaa/hipaa.service';
import { ConfirmationDialogComponent } from '../swft/swft-confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'swft-hipaa',
    templateUrl: './hipaa.component.html',
    styleUrls: ['./hipaa.component.scss'],
})
export class HipaaComponent implements OnInit, OnDestroy {
    showBackButton = false;
    onDestroy = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<HipaaComponent>,
        private hipaaService: HipaaService
    ) {}

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /**
     * Listend for updates to the HIPAA data, and determine if the back button should be shown.
     */
    ngOnInit(): void {
        this.hipaaService.showBackButton$.pipe(takeUntil(this.onDestroy)).subscribe((show: boolean) => {
            this.showBackButton = show;
        });

        this.hipaaService.clearData();
    }

    onBack() {
        this.hipaaService.back();
    }

    /**
     * Opens a confirmation dialog to close the HIPAA dialog, and if confirmed,
     * closes the dialog and clears the HIPAA data.
     */
    onCancel() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                panelClass: 'small-auto',
                data: {
                    okOnly: false,
                    message: 'Are you sure you want to cancel?',
                    description: 'All progress will be lost.',
                },
            })
            ?.afterClosed()
            .pipe(take(1))
            .subscribe(response => {
                if (response) {
                    this.dialogRef.close(null);
                }
            });
    }
}
