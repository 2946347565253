import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DuoRedirectParams, DuoRedirectUrl, SessionStorageKeys } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';

@Component({
    selector: 'swft-refresh-token',
    templateUrl: './refresh-token.component.html',
    styleUrls: ['./refresh-token.component.scss'],
})
export class RefreshTokenComponent {
    @ViewChild('DuoFrame', { read: ElementRef }) duoFrame!: ElementRef<HTMLIFrameElement>;
    isLoading: boolean = false;
    askForUsername: boolean = false;
    showFrame: boolean = false;
    username: string | undefined = this.sessionStorage.getItem(SessionStorageKeys.Username);
    hasSrc: boolean = false;

    constructor(
        private auth: AuthService,
        private sessionStorage: SessionStorageService,
        private dialogRef: MatDialogRef<RefreshTokenComponent>,
        private snackbar: SWFTSnackBarService
    ) {
        this.auth.isRefreshingToken = true;
    }

    @HostListener('window:message', ['$event'])
    authCallback(event: MessageEvent) {
        const duo: DuoRedirectParams = new DuoRedirectParams(event.data.code, event.data.state);
        if (duo.valid) {
            this.auth
                .callback(duo)
                .pipe(take(1))
                .subscribe({
                    next: resp => {
                        if (resp.credentials.authResult.result === 'allow') {
                            this.dialogRef.close(true);
                            this.snackbar.success('Session extended!', 5000);
                        }
                    },
                });
        }
    }

    initAuth() {
        this.isLoading = true;
        this.askForUsername = false;
        if (this.username) {
            this.auth
                .getAuthUrl(this.username)
                .then((url: DuoRedirectUrl) => {
                    this.hasSrc = true;
                    this.duoFrame.nativeElement.src = url.redirectUri;
                })
                .catch((error: any) => {
                    this.isLoading = false;
                });
            return;
        }
        this.askForUsername = true;
    }

    submit(username: string) {
        this.username = username;
        this.initAuth();
    }

    iframeLoaded() {
        if (!this.hasSrc) return;
        this.showFrame = true;
        this.isLoading = false;
    }

    logout() {
        this.auth.logout();
    }
}
