export enum PermissionType {
    Read = 1,
    Create = 2,
    Update = 4,
    Delete = 8,
    Search = 16,
    All = Read | Create | Update | Delete | Search,
}

export enum SessionStorageKeys {
    Username = 'authenticationUsername',
    BearerToken = 'bearerToken',
    Credentials = 'credentials',
}

export type PermissionName =
    | 'formApproval'
    | 'formBuilder'
    | 'formDevelopment'
    | 'formPromotion'
    | 'formTaskBuilder'
    | 'informationItemBuilder'
    | 'isAdmin'
    | 'isDeveloper'
    | 'isSuper'
    | 'layoutBuilder'
    | 'questionBuilder'
    | 'questionModuleBuilder'
    | 'responseOptionBuilder'
    | 'taskBuilder'
    | 'userManager'
    | 'actionBuilder'
    | 'lobBuilder'
    | 'drugInfo'
    | 'conversationNotes'
    | 'dataDictionary'
    | 'patientLineOfBusiness'
    | 'patientSpecialId'
    | 'consentBuilder';

export enum PermissionNames {
    FormApproval = 'formApproval',
    FormBuilder = 'formBuilder',
    FormDevelopment = 'formDevelopment',
    FormPromotion = 'formPromotion',
    FormTaskBuilder = 'formTaskBuilder',
    InformationItemBuilder = 'informationItemBuilder',
    IsAdmin = 'isAdmin',
    IsDeveloper = 'isDeveloper',
    IsSuper = 'isSuper',
    LayoutBuilder = 'layoutBuilder',
    QuestionBuilder = 'questionBuilder',
    QuestionModuleBuilder = 'questionModuleBuilder',
    ResponseOptionBuilder = 'responseOptionBuilder',
    TaskBuilder = 'taskBuilder',
    UserManager = 'userManager',
    ActionBuilder = 'actionBuilder',
    LobBuilder = 'lobBuilder',
    DrugInfo = 'drugInfo',
    ConversationNotes = 'conversationNotes',
    DataDictionary = 'dataDictionary',
    PatientLineOfBusiness = 'patientLineOfBusiness',
    PatientSpecialIdentifier = 'patientSpecialId',
    ConsentBuilder = 'consentBuilder',
}

export interface TokenResponse {
    bearerToken: string;
    credentials: AuthenticationCredentials;
}

export interface AuthenticationCredentials {
    authContext?: AuthContext;
    authResult: AuthResult;
    authTime: number;
    username: string;
    iss: string;
    exp: string;
    iat: string;
    sub: string;
    aud: string;
    nonce: string | null;
}

export interface AuthContext {
    accessDevice: AccessDevice;
    alias: string;
    application: AuthApplication;
    authDevice: AuthDevice;
    email: string;
    eventType: string;
    factor: string;
    isoTimestamp: string;
    oodSoftware: string;
    reason: string;
    result: string;
    timestamp: number;
    trustedEndpointStatus: string;
    txid: string;
    user: AuthUser;
}

export interface AccessDevice {
    browser: string;
    browserVersion: string;
    flashVersion: string;
    hostname: string;
    ipAddress: string;
    isEncryptionEnabled: string;
    isFirewallEnabled: string;
    isPasswordSet: string;
    javaVersion: string;
    location: AccessDeviceLocation;
    operatingSystem: string;
    operatingSystemVersion: string;
    securityAgents: string;
}

export interface AccessDeviceLocation {
    city: string;
    country: string;
    state: string;
}

export interface AuthApplication {
    key: string;
    name: string;
}

export interface AuthDevice {
    ipAddress: string;
    location: {
        City: string;
        Country: string;
        State: string;
    };
    name: string;
}

export interface AuthUser {
    groups: string[];
    key: string;
    name: string;
}

export interface AuthResult {
    result: string;
    status: string;
    statusMsg: string;
}

export enum LoginError {
    FAILED = 'failed',
    LOGOUT = 'logout',
    INSUFFICIENT_PRIVILEGES = 'insufficientPrivileges',
}

export interface AuthToken {
    aud: string;
    email: string;
    exp: number;
    formApproval: string;
    formBuilder: string;
    formDevelopment: string;
    formPromotion: string;
    iat: string;
    isAdmin: string;
    isDeveloper: string;
    isSuper: string;
    iss: string;
    jti: string;
    layoutBuilder: string;
    name: string;
    nbf: number;
    questionBuilder: string;
    questionModuleBuilder: string;
    responseOptionBuilder: string;
    sub: string;
    taskBuilder: string;
    userId: string;
}

export interface DuoRedirectUrl {
    redirectUri: string;
}

export class DuoRedirectParams {
    code: string;
    state: string;
    dataType: string = 'duo_redirect';

    constructor(code: string = '', state: string = '') {
        this.code = code;
        this.state = state;
    }

    get valid(): boolean {
        return this.code !== '' && this.state !== '';
    }

    get invalid(): boolean {
        return !this.valid;
    }
}
