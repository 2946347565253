<h1 class="swft-title">{{ showHcp ? 'Health Care Providers' : 'Patients' }}</h1>
<div
    class="mat-elevation-z2"
    id="ProfileTable">
    <swft-searchbar
        (search)="dataSource.queryBy({ search: $event })"
        [placeholder]="searchString"></swft-searchbar>
    <table
        id="Profile"
        mat-table
        matSort
        [dataSource]="dataSource">
        <ng-container *ngFor="let column of displayColumns">
            <ng-container
                *ngIf="column.id !== 'actions'"
                [matColumnDef]="column.id">
                <th
                    mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef>
                    {{ column.name }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let profile">
                    {{ profile[column.id] | dynamicPipe: column.formatter }}
                </td>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="!showHcp"
            matColumnDef="actions">
            <th
                mat-header-cell
                *matHeaderCellDef>
                View
            </th>
            <td
                mat-cell
                *matCellDef="let profile">
                <swft-actions-column (view)="gotoPatientProfile(profile, true)"></swft-actions-column>
            </td>
        </ng-container>

        <ng-container
            *ngIf="showHcp"
            matColumnDef="actions">
            <th
                mat-header-cell
                *matHeaderCellDef>
                View
            </th>
            <td
                mat-cell
                *matCellDef="let profile">
                <swft-actions-column (view)="gotoHcpProfile(profile, true)"></swft-actions-column>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="viewColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: viewColumns"></tr>
        <tr *matNoDataRow>
            <td colspan="9999">
                {{ emptyDataText }}
            </td>
        </tr>
    </table>

    <mat-paginator
        *ngIf="dataSource.page$ | async as page"
        [length]="page.totalElements"
        [pageSize]="page.size"
        [pageIndex]="page.number"
        [hidePageSize]="page.totalElements === 0 || page.totalElements === -1"
        (page)="dataSource.fetch($event.pageIndex)"></mat-paginator>
</div>
<swft-spinner
    coverId="Profile"
    [show]="dataSource.loading$ | async"></swft-spinner>
