<mat-toolbar *ngIf="isLoggedIn | async">
    <section class="nav-left">
        <button
            mat-icon-button
            [class.active]="isActive('narratives')"
            [matMenuTriggerFor]="narrativesMenu"
            aria-label="Narratives">
            <mat-icon>menu_book</mat-icon>
            Narratives
        </button>

        <button
            mat-icon-button
            [class.active]="isActive('patients')"
            routerLink="/patients"
            aria-label="Patients">
            <mat-icon>personal_injury</mat-icon>
            Patients
        </button>

        <button
            mat-icon-button
            [class.active]="isActive('hcp')"
            routerLink="/hcp"
            aria-label="HCP">
            <mat-icon>local_hospital</mat-icon>
            HCP
        </button>

        <button
            mat-icon-button
            [class.active]="isActive('hipaa')"
            (click)="openHipaa()"
            aria-label="Hipaa">
            <mat-icon>published_with_changes</mat-icon>
            HIPAA
        </button>
    </section>

    <section
        class="nav-right"
        [class.non-admin]="!isAdmin && !isSuperAdmin && !hasVisibleBuilderLinks">
        <button
            *ngIf="hasVisibleBuilderLinks"
            mat-icon-button
            [matMenuTriggerFor]="buildersMenu"
            aria-label="Builder">
            <mat-icon>handyman</mat-icon>
            Builders
        </button>

        <button
            *ngIf="isSuperAdmin || canSearchUsers"
            mat-icon-button
            [matMenuTriggerFor]="usersMenu"
            aria-label="Users">
            <mat-icon>group</mat-icon>
            Users
        </button>

        <button
            class="btn-logout"
            mat-icon-button
            (click)="logout()"
            aria-label="Logout">
            <mat-icon>logout</mat-icon>
            Logout
        </button>
    </section>
</mat-toolbar>

<div
    cdkScrollable
    #RouterOutlet
    id="AppRouterOutlet"
    class="router-container"
    [class.login]="(isLoggedIn | async) === false"
    [class.scrollable]="isScrollable"
    [class.shrink]="leftNavIsExpanded$ | async"
    [class.builder-nav]="(hasBuilderNav$ | async) === true">
    <div>
        <router-outlet></router-outlet>
    </div>
    <footer
        *ngIf="isLoggedIn | async"
        id="AppFooter"
        [class.show]="isBottom"
        class="mat-elevation-z2"></footer>
</div>

<swft-left-nav *ngIf="isLoggedIn"></swft-left-nav>

<mat-menu #buildersMenu="matMenu">
    <button
        *ngIf="displayBuilderLink('actionBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'action']"
        aria-label="Action Builder">
        <span>Action Builder</span>
    </button>
    <button
        mat-menu-item
        *ngIf="displayBuilderLink('consentBuilder')"
        [routerLink]="['admin', 'builders', 'consent']"
        aria-label="Consent Builder">
        <span>Consent Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('dataDictionary')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'data-dictionary']"
        aria-label="Data Dictionary Builder">
        <span>Data Dictionary Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('drugInfo')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'drug-info']"
        aria-label="Drug Info Builder">
        <span>Drug Info Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('formBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'form']"
        aria-label="Form Builder">
        <span>Form Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('informationItemBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'information-item']"
        aria-label="Information Item Builder">
        <span>Information Item Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('layoutBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'layout']"
        aria-label="Layout Builder">
        <span>Layout Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('lobBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'line-of-business']"
        aria-label="Line of Business Builder">
        <span>Line of Business Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('questionBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'question']"
        aria-label="Question Builder">
        <span>Question Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('questionModuleBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'question-module']">
        <span>Question Module Builder</span>
    </button>
    <button
        *ngIf="displayBuilderLink('responseOptionBuilder')"
        mat-menu-item
        [routerLink]="['admin', 'builders', 'response-option']"
        aria-label="Response Builder">
        <span>Response Option Builder</span>
    </button>
</mat-menu>

<mat-menu #usersMenu="matMenu">
    <button
        *ngIf="isSuperAdmin"
        mat-menu-item
        routerLink="/admin/group-manager">
        <span>Group Manager</span>
    </button>
    <button
        mat-menu-item
        routerLink="/admin/users">
        <span>User Manager</span>
    </button>
</mat-menu>

<mat-menu #narrativesMenu="matMenu">
    <button
        mat-menu-item
        routerLink="/narratives/order">
        <span>Order Narratives</span>
    </button>
    <button
        mat-menu-item
        routerLink="/narratives/patient">
        <span>Patient Narratives</span>
    </button>
    <button
        mat-menu-item
        routerLink="/narratives/hcp">
        <span>HCP Narratives</span>
    </button>
</mat-menu>
