import { DataSource } from '@angular/cdk/collections';
import {
    BehaviorSubject,
    combineLatest,
    finalize,
    map,
    Observable,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import { Page, PaginationEndpoint, SwftSort } from '../table.models';

export interface SimpleDataSource<T> extends DataSource<T> {
    connect(): Observable<T[]>;
    disconnect(): void;
}

export class PaginationDataSource<T, Q = Partial<T>> implements SimpleDataSource<T> {
    public loading$: Observable<boolean>;
    public page$: Observable<Page<T>>;

    isEmpty = false;
    pageSize: number;
    initialPage: number;
    private readonly pageNumber = new Subject<number>();
    private readonly sort: BehaviorSubject<SwftSort<T>>;
    private readonly query: BehaviorSubject<Q>;
    private readonly loading = new BehaviorSubject<boolean>(true);

    constructor(
        private endpoint: PaginationEndpoint<T, Q>,
        initialSort: SwftSort<T>,
        initialQuery: Q,
        pageSize = 10,
        initialPage = 0
    ) {
        let firstCall = true;
        this.query = new BehaviorSubject<Q>(initialQuery);
        this.pageSize = pageSize;
        this.initialPage = initialPage;
        this.sort = new BehaviorSubject<SwftSort<T>>(initialSort);
        const param$ = combineLatest([this.query, this.sort]);
        this.loading$ = this.loading.asObservable();
        this.page$ = param$.pipe(
            switchMap(([query, sort]) =>
                this.pageNumber.pipe(
                    startWith(initialPage && firstCall ? initialPage : 0),
                    tap(() => (firstCall = false)),
                    switchMap(page => {
                        this.loading.next(true);
                        return this.endpoint({ page, sort, size: this.pageSize }, query).pipe(
                            finalize(() => this.loading.next(false))
                        );
                    })
                )
            ),
            shareReplay(1),
            tap(response => {
                this.loading.next(false);
                this.isEmpty = !response.content?.length;
            })
        );
    }

    sortBy(sort: Partial<SwftSort<T>>): void {
        const lastSort = this.sort.getValue();
        const nextSort = { ...lastSort, ...sort };
        this.sort.next(nextSort);
    }

    queryBy(query: Partial<Q>): void {
        const lastQuery = this.query.getValue();
        const nextQuery = { ...lastQuery, ...query };
        this.query.next(nextQuery);
    }

    filterBy(query: Partial<Q>): void {
        const lastQuery = this.query.getValue();
        const nextQuery = { ...lastQuery, ...query };
        this.query.next(nextQuery);
    }

    fetch(page: number, pageSize?: number): void {
        if (pageSize) {
            this.pageSize = pageSize;
        }
        this.pageNumber.next(page);
    }

    connect(): Observable<T[]> {
        return this.page$.pipe(map(page => page.content));
    }

    disconnect(): void {}
}
