<div
    class="search-container"
    [class.shrink]="shrink">
    <div class="search-box">
        <mat-icon
            *ngIf="showIcon && hasSearch()"
            matIcon>
            search
        </mat-icon>
        <input
            matInput
            *ngIf="hasSearch()"
            (input)="emitSearch($event.target)"
            [(ngModel)]="searchString"
            placeholder="{{ placeholder }}" />
    </div>

    <div
        class="date-container"
        *ngIf="dateSearch">
        <button
            *ngIf="!dateThroughString"
            (click)="setDefaultDate()"
            matIcon>
            <mat-icon>date_range</mat-icon>
        </button>

        <ng-container *ngIf="dateThroughString">
            <button (click)="clearDateString()">
                <mat-icon class="remove">event_busy</mat-icon>
            </button>

            <input
                class="hide-date-picker"
                matInput
                [formControl]="dateThroughControl"
                [matDatepicker]="picker"
                placeholder="Choose a date"
                (dateChange)="onDateChanged($event)" />
            <mat-datepicker-toggle
                class="hide-date-picker"
                matSuffix
                [for]="picker"></mat-datepicker-toggle>

            <mat-datepicker
                #picker
                startView="month"
                [startAt]="dateThroughString"></mat-datepicker>

            <button
                class="date-through-picker"
                (click)="picker.open()">
                <div>
                    <span class="text">Dates Through</span>
                    <span class="date">{{ dateThroughString | date: dateFormat }}</span>
                </div>
                <mat-icon svgIcon="event_upcoming"></mat-icon>
            </button>
        </ng-container>
    </div>
    <div *ngIf="dropdownValues.values.length > 0">
        <mat-form-field
            floatLabel="always"
            class="dropdown"
            theme="search-dropdown">
            <mat-label>{{ dropdownLabel }}</mat-label>
            <mat-select
                [placeholder]="dropdownPlaceholder"
                value=""
                (selectionChange)="dropdownSelected.emit($event.value)">
                <mat-option
                    value=""
                    selected>
                    <strong>any</strong>
                </mat-option>
                <mat-option
                    *ngFor="let value of dropdownValues.values"
                    [value]="value">
                    {{ value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div
        [class.toggles-container]="showPrimaryToggle && showSecondaryToggle"
        [class.large-toggle-container]="!showPrimaryToggle || !showSecondaryToggle"
        *ngIf="showPrimaryToggle || showSecondaryToggle">
        <mat-slide-toggle
            [class.large-toggle]="!showSecondaryToggle"
            (change)="emitPrimaryToggle($event)"
            *ngIf="showPrimaryToggle">
            {{ primaryToggleText }}
        </mat-slide-toggle>

        <mat-slide-toggle
            [class.large-toggle]="!showPrimaryToggle"
            (change)="emitSecondaryToggle($event)"
            *ngIf="showSecondaryToggle">
            {{ secondaryToggleText }}
        </mat-slide-toggle>
    </div>
    <div
        class="buttons"
        *ngIf="buttons.length > 0">
        <button
            mat-button
            #tooltip="matTooltip"
            [matTooltip]="button.tooltip"
            *ngFor="let button of buttons"
            (click)="button.click()">
            <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
            <mat-icon
                *ngIf="button.svgIcon"
                [svgIcon]="button.svgIcon"></mat-icon>
        </button>
    </div>
</div>
