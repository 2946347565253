<ng-container *ngIf="(isLoggedIn | async) === true && (navIsEmpty$ | async) === false">
    <nav
        *ngIf="navState$ | async; let links"
        id="LeftNavTab"
        (click)="toggleExpandNav(true)"
        [class.flash]="flash"
        [class.show]="!expanded">
        <mat-icon class="open">chevron_right</mat-icon>
        <mat-icon *ngIf="links.nav.patients && links.nav.patients.length > 0">personal_injury</mat-icon>
        <mat-icon *ngIf="links.nav.hcpProfiles && links.nav.hcpProfiles.length > 0">local_hospital</mat-icon>
        <mat-icon *ngIf="links.nav.conversations && links.nav.conversations.length > 0">forum</mat-icon>
        <mat-icon *ngIf="links.nav.questionModules && links.nav.questionModules.length > 0">quiz</mat-icon>
        <mat-icon *ngIf="links.nav.linesOfBusiness && links.nav.linesOfBusiness.length > 0">work</mat-icon>
        <mat-icon *ngIf="links.nav.questions && links.nav.questions.length > 0">question_mark</mat-icon>
        <mat-icon *ngIf="links.nav.formBuilder && links.nav.formBuilder.length > 0">vertical_split</mat-icon>
        <mat-icon *ngIf="links.nav.layoutBuilder && links.nav.layoutBuilder.length > 0">grid_view</mat-icon>
    </nav>

    <div
        class="expanded-menu"
        [class.show]="expanded">
        <mat-icon
            class="locked"
            [class.unpinned]="!locked"
            (click)="locked = !locked">
            {{ locked ? 'lock' : 'lock_open' }}
        </mat-icon>
        <mat-icon
            *ngIf="!locked"
            class="close"
            (click)="toggleExpandNav(false)">
            chevron_left
        </mat-icon>
    </div>

    <nav
        id="LeftNav"
        [class.show]="expanded">
        <swft-left-nav-section
            [section]="sections.patients"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.hcpProfiles"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.conversations"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.questionModules"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.linesOfBusiness"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.questions"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.formBuilder"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
        <swft-left-nav-section
            [section]="sections.layoutBuilder"
            (removed)="removeLinkByIndex($event.linkType, $event.index)"></swft-left-nav-section>
    </nav>
</ng-container>
