<div
    class="condition"
    *ngIf="condition"
    [class.first]="firstInGroup"
    [class.last]="lastInGroup"
    [class.adding]="showAddMenu">
    <aside
        (click)="toggleGroupType(condition.groupType)"
        [ngClass]="condition.groupType">
        <span *ngIf="firstInGroup">{{ condition.groupType || 'add' }}</span>
        <mat-icon
            *ngIf="condition.groupType && lastInGroup"
            [ngClass]="condition.groupType"
            [class.add]="showAddMenu"
            (click)="toggleAddMenu($event)">
            {{ showAddMenu ? 'cancel' : 'add_circle' }}
        </mat-icon>
    </aside>

    <section
        [ngClass]="condition.groupType"
        [class.first]="firstInGroup"
        [class.last]="lastInGroup">
        <mat-icon
            class="remove"
            *ngIf="!(firstInGroup && lastInGroup)"
            (click)="removeCondition.emit()">
            cancel
        </mat-icon>

        <mat-form-field
            appearance="fill"
            class="solid resource">
            <mat-label>Resource</mat-label>
            <input
                matInput
                swftSelectSearch
                searchPlaceholder="Search Resources"
                searchOrderBy="alias"
                searchDropdownLabel="Database Type"
                searchDropdownPlaceholder="Type"
                searchWidth="400px"
                searchValueFormat="{id} · {alias} · {dbType}"
                [searchDropdownValues]="databaseTypes"
                [(ngModel)]="resourceAlias"
                [fetch]="searchDataDictionaryFunction"
                (searchModifierChanged)="searchModifierChanged($event)"
                (selectionChanged)="resourceSelected($event)" />
        </mat-form-field>

        <mat-form-field
            floatLabel="always"
            appearance="fill"
            class="operator">
            <mat-label>Operator</mat-label>
            <mat-select
                [disabled]="!condition.resource"
                placeholder="is equal to"
                [(ngModel)]="condition.operator"
                (selectionChange)="operatorSelected($event)">
                <mat-option
                    *ngFor="let operator of operators"
                    [value]="operator">
                    {{ operator.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon
            class="right-arrow"
            *ngIf="requiresValue()"
            [ngClass]="condition.groupType">
            chevron_right
        </mat-icon>

        <!-- string -->
        <mat-form-field
            *ngIf="
                (!condition.resource || condition.resource?.expressionEngineType === ExpressionEngineTypes.String) &&
                requiresValue()
            "
            floatLabel="always"
            appearance="fill"
            [class.solid]="condition.operator">
            <mat-label>Text</mat-label>
            <input
                *ngIf="!isMultipleValues()"
                [disabled]="!condition.operator"
                type="text"
                matInput
                placeholder="a value"
                (input)="valueChanged($event)" />
            <!-- multiple values for string -->
            <mat-chip-list
                *ngIf="isMultipleValues()"
                #chipList
                aria-label="selection">
                <mat-chip
                    class="swft-chip"
                    *ngFor="let selectedValue of chipListValues | async"
                    (removed)="removeValueFromSelected(selectedValue)">
                    {{ selectedValue }}
                    <button matChipRemove>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip>
                <input
                    *ngIf="isMultipleValues()"
                    placeholder="+ Add Option"
                    #matChipInput
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addValueToSelected($event.value)" />
            </mat-chip-list>
        </mat-form-field>

        <mat-form-field
            floatLabel="always"
            *ngIf="isNumericType() && requiresValue()"
            appearance="fill"
            class="number"
            [class.range]="isRange()"
            [class.solid]="condition.operator">
            <mat-label>{{ isRange() ? 'Start' : 'Number' }}</mat-label>
            <input
                *ngIf="!isMultipleValues()"
                [disabled]="!condition.operator"
                type="number"
                matInput
                placeholder="0"
                (input)="updateNumber($event, true)" />

            <mat-chip-list
                *ngIf="isMultipleValues()"
                #chipList
                aria-label="selection">
                <mat-chip
                    class="swft-chip"
                    *ngFor="let selectedValue of chipListValues | async"
                    (removed)="removeValueFromSelected(selectedValue)">
                    {{ selectedValue }}
                    <button matChipRemove>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip>
                <input
                    *ngIf="isMultipleValues()"
                    placeholder="+ Add Option"
                    type="number"
                    #matChipInput
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addValueToSelected($event.value)" />
            </mat-chip-list>
        </mat-form-field>

        <ng-container *ngIf="isRange() && isNumericType()">
            <span class="range">and</span>
            <mat-form-field
                floatLabel="always"
                *ngIf="isNumericType()"
                appearance="fill"
                class="number"
                [class.solid]="condition.operator">
                <mat-label>End</mat-label>
                <input
                    [disabled]="!condition.operator"
                    type="number"
                    matInput
                    placeholder="0"
                    (input)="updateNumber($event, false)" />
            </mat-form-field>
        </ng-container>

        <swft-logic-datetime
            *ngIf="requiresValue()"
            [resource]="condition.resource"
            [operatorSelected]="!!condition.operator"
            [valueLabel]="isRange() ? 'Start' : 'Date'"
            [groupType]="condition.groupType"
            (valueChanges)="updateDateTime(dateTimeStart, true)"></swft-logic-datetime>

        <ng-container *ngIf="isRange() && !isNumericType()">
            <span class="range date">and</span>
            <swft-logic-datetime
                [resource]="condition.resource"
                [operatorSelected]="!!condition.operator"
                valueLabel="End"
                [groupType]="condition.groupType"
                (valueChanges)="updateDateTime(dateTimeEnd, false)"></swft-logic-datetime>
        </ng-container>
    </section>
    <footer
        *ngIf="showAddMenu"
        [ngClass]="condition.groupType">
        <button
            mat-button
            (click)="addConditionOrGroup(condition, null)">
            add condition
            <span></span>
        </button>
        <button
            class="group"
            mat-button
            (click)="addConditionOrGroup(null, 'Not yet Implemented')">
            add group
            <span></span>
        </button>
    </footer>
</div>
