<div class="dialog-header">
    <h1>Select {{ title }}</h1>
    <mat-slide-toggle
        *ngIf="showDiscontinuedToggle"
        (click)="showdeleted()">
        Discontinued
    </mat-slide-toggle>
</div>

<swft-searchbar
    (search)="search($event)"
    placeholder="Search..."></swft-searchbar>
<swft-grid
    id="SWFTSearchGrid"
    #grid
    [dataSource]="dataSource"
    [gridConfiguration]="gridConfiguration"
    (rowAction)="rowAction($event)"></swft-grid>

<swft-confirm
    [viewOnly]="!multiSelect"
    [disabled]="rows.length === 0"
    confirmText="save"
    (deny)="close()"
    (confirm)="save()"></swft-confirm>
