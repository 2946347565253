import { Injectable } from '@angular/core';
import { Order } from 'src/app/models/order.model';

@Injectable({
    providedIn: 'root',
})
export class OrderAdapter {
    adapt(item: any): Order {
        let order = new Order(
            item.id,
            item.mrn,
            item.rank,
            item.status,
            item.medicationName,
            item.prescriber,
            item.writtendate,
            item.startdate,
            item.stopdate,
            item.orderNumber
        );
        return order;
    }
}
