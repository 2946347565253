<h1 mat-dialog-title>Logout in {{ countDown }}s</h1>

<p>
    <strong>You are inactive. Would you like to stay signed in?</strong>
</p>

<swft-confirm
    confirmText="Stay signed in"
    denyText="Log out"
    (deny)="stayLoggedIn(false)"
    (confirm)="stayLoggedIn(true)"></swft-confirm>
