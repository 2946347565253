<div
    class="login-container"
    [ngClass]="{ show: pageLoaded === true }">
    <mat-card
        class="login-card"
        [ngClass]="{ show: pageLoaded === true }">
        <form
            class="login"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">
            <div>
                <h1 class="title">
                    <strong>SWFT</strong>
                    Web
                </h1>
                <mat-form-field class="login-form-field">
                    <mat-label>Username</mat-label>
                    <input
                        class="login-input"
                        formControlName="username"
                        matInput
                        [value]="form.controls['username'].value"
                        (input)="updateUserName(form.controls['username'].value)"
                        placeholder="you@pantherxrare.com" />
                </mat-form-field>
            </div>
            <button
                mat-flat-button
                color="accent"
                [ngClass]="{ active: isLoggingIn === true }"
                (click)="btnText = 'Logging in...'">
                {{ btnText }}
            </button>
        </form>
    </mat-card>
    <img
        class="logo"
        [ngClass]="{ show: pageLoaded === true }"
        src="../assets/rare_full_white_rgb.png"
        alt="Panther X Rare" />
</div>
