import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LogicCondition, LogicModifier } from 'src/app/models/logic.models';
import { LogicService } from 'src/app/services/logic/logic.service';

@Component({
    selector: 'swft-logic',
    templateUrl: './logic.component.html',
    styleUrls: ['./logic.component.scss'],
})
export class LogicComponent {
    conditions: BehaviorSubject<LogicCondition[]> = new BehaviorSubject<LogicCondition[]>([
        { groupType: LogicModifier.Empty },
    ]);
    constructor(private logicService: LogicService) {}

    addCondition(condition: LogicCondition) {
        const newCondition = { groupType: condition.groupType };
        this.conditions.next([...this.conditions.value, newCondition]);
    }

    removeCondition(index: number) {
        const conditions = this.conditions.value;
        conditions.splice(index, 1);
        if (conditions.length === 1) {
            conditions[0].groupType = LogicModifier.Empty;
        }
        this.conditions.next(this.conditions.value);
    }

    updateCondition(updatedCondition: LogicCondition, index: number) {
        const conditions = this.conditions.value;
        conditions.forEach(condition => {
            condition.groupType = updatedCondition.groupType;
        });
        conditions[index] = updatedCondition;
        this.conditions.next(conditions);
    }

    cancel() {
        this.conditions.next([{ groupType: LogicModifier.Empty }]);
    }

    save() {
        this.logicService.save(this.conditions.value);
    }
}
