import { NavigationEnd, Router } from '@angular/router';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { EditorMode } from 'src/app/models/editor.models';
import { LeftNavLink, LeftNavType } from 'src/app/models/left-nav.model';
import * as navActions from 'src/app/state/nav/nav.actions';
import { CruApi } from '../interfaces/crud.interface';
import { StateBase, SwftComponentStore, WithId } from './component-store';

/**
 * Base class for component stores that are expected to interact with the Nav
 * Accepts three type parameters:
 *
 * T - The type of the object the store is tracking
 * TState - The state of the store
 * TLink - the type of the link that we are querying for
 */
export abstract class SwftComponentStoreBase<
    T extends WithId,
    TState extends StateBase<T>,
    TLink extends LeftNavLink
> extends SwftComponentStore<T, TState, TLink> {
    /**
     * This selector will be used when attempting to load the state from the nav store.
     */
    navLinkSelector: MemoizedSelector<object, TLink[], DefaultProjectorFn<TLink[]>>;

    viewOnly$ = this.select(state => state.editorMode).pipe(map(mode => mode === EditorMode.View));
    editorMode$ = this.select(state => state.editorMode);
    item$ = this.select(state => state.item);
    loading$ = this.select(state => state.loading);

    abstract navType: LeftNavType;

    /**
     * This should be used when resetting the item
     */
    protected readonly defaultItem: T;

    constructor(
        protected api: CruApi<T>,
        protected router: Router,
        protected store: Store,
        selector: MemoizedSelector<object, TLink[], DefaultProjectorFn<TLink[]>>,
        defaultItem: T,
        defaultState: TState,
        private linkComparer?: (link: LeftNavLink, link2: LeftNavLink) => boolean
    ) {
        super(defaultState);

        this.defaultItem = defaultItem;

        this.navLinkSelector = selector;

        this.initializeNavigationListener();
    }

    initializeNavigationListener() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                tap(event => this.setEditorModeByUrl(this.router.url)),
                takeUntil(this.destroy$)
            )
            .subscribe();

        this.router.events
            .pipe(
                tap(event => {
                    if (!(event instanceof NavigationEnd)) this.updateCanAddLink(false);
                    else this.updateCanAddLink(true);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    /**
     * Attemps to load the item from the nav state store.
     * If a link is found, the store State is updated to use the same state as was found in the link.
     * If a link is NOT found, this method will call the `load` method of the Store
     */
    readonly loadFromStore = this.effect((id: Observable<string | number>) => {
        return id.pipe(
            tap(() => this.updateLoading(true)),
            map(id => Number(id)),
            switchMap((id: number) => {
                // If we have no ID we know we have a default item
                if (!id) {
                    this.updateItem(this.defaultItem);
                    return of();
                }

                const links = this.store.select(this.navLinkSelector).pipe(take(1));
                // Return the id and link in the event a link is not found
                return links.pipe(
                    map(links => {
                        const link = links?.find(
                            link =>
                                (Number(link.data.id) === id && link.url === this.router.url && link) ||
                                (this.linkComparer && this.linkComparer(link, { url: this.router.url } as LeftNavLink))
                        );

                        return {
                            id: id,
                            link: link,
                        };
                    })
                );
            }),
            tap((args: { id: number; link?: TLink }) => {
                // If we have a link, set the state from the link
                if (!!args.link) {
                    this.setState({ ...args.link.state });
                    this.updateLoading(false);
                }
            }),
            // Filter so we stop if we have a already set the state
            filter((args: { id: number; link?: TLink }) => !args.link),
            map((args: { id: number; link?: TLink }) => args.id),
            tap((id: number) => this.load(id))
        );
    });

    /**
     * Attempts to call the API to load the item. If the ID > 0, then the supplied {readApi} is used to fetch the item.
     * Once an item is read, we set the initial item to use the result as well as the current item in our state.
     */
    readonly load = this.effect((id: Observable<number>) => {
        return id.pipe(
            switchMap(id => {
                if (id > 0) {
                    return this.api.read(id).pipe(
                        tap(result => {
                            // We want to cache the initial LOB if the user loads it so we can refer back to it

                            this.setReadResult(result);
                        })
                    );
                } else {
                    this.updateInitialItem(undefined);
                    return of(undefined);
                }
            }),
            tap(() => {
                this.updateLoading(false);
            })
        );
    });

    /**
     * This is intended to reset the state when a new item is loaded into the store
     */
    readonly setReadResult = this.updater((state: TState, result: any): TState => {
        return { ...state, initialItem: result, item: result };
    });

    /**
     * Sets the loading state
     */
    readonly updateLoading = this.updater((state, loading: boolean): TState => {
        return {
            ...state,
            loading: loading,
        };
    });

    /**
     * Updates some or all of the item in the state.
     *
     * @params item @type {Partial<T>}
     */
    readonly updateItem = this.updater((state, item: Partial<T>): TState => {
        return {
            ...state,
            item: { ...state.item, ...item },
        };
    });

    /**
     * Sets the initial item, caching the result in case we need to refer back to it for any reason.
     */
    readonly updateInitialItem = this.updater((state, initialItem: T | undefined): TState => {
        return {
            ...state,
            initialItem: initialItem,
        };
    });

    /**
     * Updates the editor mode
     *
     * @params editorMode @type {EditorMode}
     */
    readonly updateEditorMode = this.updater((state, editorMode: EditorMode): TState => {
        return {
            ...state,
            editorMode,
        };
    });

    /**
     * Sets the boolean `canAddLink` state property
     */
    readonly updateCanAddLink = this.updater((state, canAddLink: boolean): TState => {
        return {
            ...state,
            canAddLink,
        };
    });

    /**
     * Uses the supplied URL to set the editor mode
     * @param url the current url
     */
    private setEditorModeByUrl(url: string) {
        if (url.includes(EditorMode.View.toLowerCase())) {
            this.updateEditorMode(EditorMode.View);
            return;
        } else if (url.includes(EditorMode.Edit.toLowerCase())) {
            this.updateEditorMode(EditorMode.Edit);
        } else {
            this.updateEditorMode(EditorMode.Create);
        }
    }

    /**
     * Dispatches the nav link to the NGRX store
     */
    dispatchNavLink(): void {
        this.state$
            .pipe(
                filter(state => state.canAddLink && !!state.item.id && !state.loading),
                tap(state => {
                    this.buildNavLink().subscribe(link => {
                        link.state = cloneDeep(state);
                        this.store.dispatch(navActions.addLink({ link, comparer: this.linkComparer }));
                    });
                }),
                take(1)
            )
            .subscribe();
    }

    /**
     * Calls the store and dispatches a nav action to remove the link
     */
    removeLinkFromNav() {
        this.buildNavLink().subscribe(link => {
            if (link.url) {
                this.store.dispatch(navActions.removeLinkByRoute({ linkType: this.navType, route: link.url }));
            }
        });
    }

    protected buildNavLink(): Observable<TLink> {
        return this.state$.pipe(
            take(1),
            map(state => {
                let urlTree = this.router.parseUrl(this.router.url);

                // If we are in create mode, check to see if the URL has already been added with the ID
                // If it has, we just use the current route
                // If not, we append the ID to the end of the URL tree so we can navigate back to it
                if (state.editorMode === EditorMode.Create) {
                    if (!!state.item.id && !this.router.url.endsWith(state.item.id!.toString())) {
                        urlTree = this.router.parseUrl(urlTree.toString() + `/${state.item.id}`);
                    }
                }

                return {
                    data: state.item,
                    state: state,
                    type: this.navType,
                    url: urlTree.toString(),
                } as TLink;
            })
        );
    }
    /**
     * Saves the item using the provided CRUD API
     * @returns Observable<T>
     */
    save(): Observable<T> {
        return this.state$.pipe(
            take(1),
            map(state => this.preparePayload(state.item)),
            switchMap(item => {
                if (item.id && item.id > 0) {
                    return this.api.update(item);
                } else {
                    return this.api.create({ ...item, id: undefined });
                }
            }),
            tap(() => this.removeLinkFromNav())
        );
    }

    /**
     * This function is intended to modify the payload any way that is needed before we save.
     *
     * Override this in implementations to add any transformations that are needed before submission
     * @param item
     */
    protected preparePayload(item: T): any {
        return item;
    }
}
