import { Contact } from './profile/contact.model';

export interface Call {
    contact: Contact;
    callDate: Date;
}

export interface OutboundCall extends Call {
    callResult: OutboundCallResult;
    phoneNumber: PhoneNumber;
}
export interface PhoneNumber {
    number: string;
    numberType: PhoneNumberType;
}

export enum PhoneNumberType {
    Work = 'Work',
    Cell = 'Cell',
    Home = 'Home',
    Other = 'Other',
}

export enum CallType {
    Inbound = 'Inbound',
    Outbound = 'Outbound',
    DocumentOnly = 'DocumentOnly',
}

export enum OutboundCallResult {
    Answered = 'Answered',
    AnsweredByOther = 'AnsweredByOther',
    Ignored = 'Ignored',
    LeftMessage = 'Left Message',
    BadNumber = 'Bad Number',
    NoAnswer = 'No Answer',
}
