import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationCredentials } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'swft-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    credentials: Subject<AuthenticationCredentials | null>;
    username: string;

    constructor(private authService: AuthService) {
        this.credentials = this.authService.credentials;
        this.username = this.authService.username;
    }
}
