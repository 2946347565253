import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'swft-confirm',
    templateUrl: './swft-confirm.component.html',
    styleUrls: ['./swft-confirm.component.scss'],
})
export class SwftConfirmComponent implements OnInit, OnChanges {
    @Output() confirm = new EventEmitter<boolean>();
    @Output() deny = new EventEmitter<boolean>();
    @Input() disabled = false;
    @Input() viewOnly = false;
    @Input() denyText = 'cancel';
    @Input() closeText = 'close';
    @Input() confirmText = 'OK';
    @Input() denyWarning = 'Are you sure you want to cancel?';
    @Input() denyDescription = 'This will discard any changes you have made.';
    @Input() doubleConfirm: boolean = false;
    disableSave = false;
    private changeDebouncer: any;

    constructor() {}

    ngOnInit(): void {
        this.disableSave = this.disabled;
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['disabled']) {
            this.disableSave = true;
            clearTimeout(this.changeDebouncer);
            this.changeDebouncer = setTimeout(() => {
                this.disableSave = changes['disabled']?.currentValue;
            }, 750);
        }
    }
}
