import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, filter, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { SwftGridColumn } from 'src/app/models/grid.models';
import { AppRouterParams } from 'src/app/models/router-params.models';
import { HcpService } from 'src/app/services/hcp/hcp.service';
import { NarrativeService } from '../../narratives/narrative.service';
import { NarrativesComponent } from '../../narratives/narratives.component';
import { HcpLinks } from '../hcp-links';
import { ProfileComponent } from '../profile.component';

@Component({
    selector: 'swft-hcp-profile',
    templateUrl: './hcp-profile.component.html',
    styleUrls: ['./hcp-profile.component.scss'],
})
export class HcpProfileComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();

    @ViewChild(NarrativesComponent) narrativesComponent: NarrativesComponent | undefined;
    @ViewChild(ProfileComponent) profileComponent: ProfileComponent | undefined;
    acknowledged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    profileLinks = HcpLinks;
    readonly narrativeType = NarrativeType.Hcp;
    id$ = new BehaviorSubject<number>(0);
    hcp$ = this.id$.pipe(
        filter(id => !!id),
        switchMap(id => this.hcpService.get(id))
    );

    displayColumns: SwftGridColumn[] = [
        { dataPath: 'formName', name: 'Form Name' },
        { dataPath: 'lastActionName', name: 'Last Action' },
        { dataPath: 'lastActionDate', name: 'Last Action Date', formatter: 'date' },
        { dataPath: 'nextActionName', name: 'Next Action' },
        { dataPath: 'nextActionDate', name: 'Next Action Date', formatter: 'date' },
        { dataPath: 'id', name: 'Narrative Id' },
        { dataPath: 'formBasisName', name: 'Form Basis' },
        { dataPath: 'status', name: 'Status' },
    ];

    constructor(
        private route: ActivatedRoute,
        private hcpService: HcpService,
        private narrativeService: NarrativeService
    ) {}

    ngOnInit(): void {
        this.route.paramMap
            .pipe(
                tap(params => {
                    const paramId = params.get(AppRouterParams.HcpId);

                    if (paramId) this.id$.next(Number(paramId));
                    else this.id$.next(0);
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    startNarrative() {
        this.id$
            .pipe(
                take(1),
                switchMap(id => this.narrativeService.startHcpNarrative(this.narrativeType, id)),
                tap(() => this.narrativesComponent?.search())
            )
            .subscribe();
    }

    onAcknowledge() {
        this.acknowledged$.next(true);
    }
}
