export class Order {
    id: number;
    mrn: string;
    rank: string;
    status: string;
    medicationName: string;
    prescriber: string;
    writtendate: string;
    startdate: string;
    stopdate: string;
    orderNumber: number;

    constructor(
        id: number,
        mrn: string,
        rank: string,
        status: string,
        medicationName: string,
        prescriber: string,
        writtendate: string,
        startdate: string,
        stopdate: string,
        orderNumber: number
    ) {
        this.id = id;
        this.mrn = mrn;
        this.rank = rank;
        this.status = status;
        this.medicationName = medicationName;
        this.prescriber = prescriber;
        this.writtendate = writtendate;
        this.startdate = startdate;
        this.stopdate = stopdate;
        this.orderNumber = orderNumber;
    }
}
