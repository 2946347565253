<h1 mat-dialog-title>{{ data.heading }}</h1>

<h4>{{ data.message }}</h4>
<p>{{ data.description }}</p>
<ul *ngIf="!!data.listItems">
    <li
        *ngFor="let item of data.listItems"
        [routerLink]="item.url"
        [class.disabled]="item.deleted"
        (click)="dialogRef.close()">
        {{ item.message }}
    </li>
</ul>

<div *ngIf="!data.okOnly">
    <button
        mat-button
        (click)="respond(false)">
        No
    </button>
    <button
        color="accent"
        mat-raised-button
        (click)="respond(true)">
        Yes
    </button>
</div>

<div
    *ngIf="data.okOnly"
    class="ok-only">
    <button
        color="accent"
        mat-raised-button
        (click)="respond(true)">
        OK
    </button>
</div>
