import { PhoneNumber } from '../call.models';

export interface Contact {
    title: string;
    firstName: string;
    lastName: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    email: string;
    homePhone: string;
    workPhone: string;
    cellPhone: string;
    phoneNumbers: PhoneNumber[];
}

export class DefaultContact implements Contact {
    title: '';
    firstName: '';
    lastName: '';
    address: '';
    state: '';
    city: '';
    zip: '';
    email: '';
    homePhone: '';
    workPhone: '';
    cellPhone: '';
    phoneNumbers: PhoneNumber[];

    constructor() {
        this.title = '';
        this.firstName = '';
        this.lastName = '';
        this.address = '';
        this.state = '';
        this.city = '';
        this.zip = '';
        this.email = '';
        this.homePhone = '';
        this.workPhone = '';
        this.cellPhone = '';
        this.phoneNumbers = [];
    }
}

export interface PatientContact extends Contact {
    mrn: number;
}

export interface PayorContact extends Contact {
    mrn: string;
    rank: number;
    pbm: string;
    segment: string;
    coverageType: string;
    memberID: string;
    binNumber: string;
    pcn: string;
    groupNumber: string;
    status: string;
    effectiveDate: string;
    terminationDate: string;
    billingNPI: string;
}

export interface HcpContact extends Contact {
    hcpId: number;
    rank: number;
    organization: string;
    address1: string; // needs to be remapped to 'address'
    phoneNumber: string; // need to be remapped
    faxNumber: string;
    zipCode: string; // needs to be remapped
}

export interface CoveredEntityContact extends Contact {
    organization: string;
}
