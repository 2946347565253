<form
    *ngIf="!this.patient"
    class="form-container">
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Select a Patient</mat-label>
        <input
            matInput
            swftSearchGrid
            [dataSource]="PatientDataSource"
            [columns]="patientColumns"
            [value]="displayValue"
            gridTitle="a Patient"
            (rowSelected)="updatePatient($event)"
            [readonly]="true" />
    </mat-form-field>
</form>

<ng-container *ngIf="this.patient">
    <aside>
        <label for="Patient">Patient</label>
        <output name="Patient">{{ patientDisplayValue$ | async }}</output>
    </aside>
    <router-outlet></router-outlet>
    <div class="button-container">
        <button
            color="primary"
            class="center"
            mat-raised-button
            [disabled]="!(contactValidator | async)?.valid"
            (click)="continue()">
            Continue
        </button>
    </div>
</ng-container>
