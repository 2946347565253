import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    selector: '[swftHyphentatePhoneNumber]',
})
export class HyphentatePhoneNumberDirective implements OnInit, OnDestroy {
    @Input('swftHyphentatePhoneNumber') shouldHyphenate: boolean = false;
    @Output() swftHyphentatePhoneNumberChange = new EventEmitter<string>();
    private onDestroy$ = new Subject<void>();
    private element?: HTMLInputElement;

    constructor(private elRef: ElementRef, private ngControl: NgControl) {}

    ngOnInit() {
        if (!this.shouldHyphenate) {
            this.ngOnDestroy();
            return;
        }

        const control = this.ngControl.control;
        if (control) {
            control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
                value = this.hyphenatePhoneNumber(value);
                control.setValue(value, { emitEvent: false });
                this.swftHyphentatePhoneNumberChange.emit(value);
            });
            return;
        }

        // Fall back to using the native element
        this.element = this.elRef.nativeElement as HTMLInputElement;
        this.element.addEventListener('input', () => {
            const value = this.element?.value;
            if (value && this.element) {
                this.element.value = this.hyphenatePhoneNumber(value);
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Will transform string to look like phone number
     * @param phoneNumber
     * @returns string in format of phone number EX: '111-111-1111'
     */
    private hyphenatePhoneNumber(phoneNumber: string): string {
        if (!phoneNumber) return '';
        const rawNumber = phoneNumber.replaceAll('-', '') as string;
        let hyphenatedPhoneNumber = rawNumber.slice(0, 3) ?? '';

        if (rawNumber.length > 3) {
            hyphenatedPhoneNumber += '-' + rawNumber.slice(3, 6);
        }
        if (rawNumber.length > 6) {
            hyphenatedPhoneNumber += '-' + rawNumber.slice(6, 10);
        }
        return hyphenatedPhoneNumber;
    }
}
