import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { DataDictionarySearch, ExpressionEngineTypes } from 'src/app/models/data-dictionary.model';
import { LogicCondition, LogicOperator, LogicOperators } from 'src/app/models/logic.models';
import { environment } from 'src/environments/environment';
import { LogicAppApiService } from '../api/logic-app-api.service';

@Injectable({
    providedIn: 'root',
})
export class LogicService {
    operators: LogicOperators = {
        string: [],
        numeric: [],
        boolean: [],
        date: [],
        time: [],
        datetime: [],
    };
    constructor(private api: LogicAppApiService) {
        this.fetchAllOperators();
    }

    /**
     * Since not all operators are valid for all data types, this method returns the operators that are valid for a given "resource".
     * @param resource A data dictionary search object, which provides the data type.
     * @returns an array of "LogicOperators".
     */
    getOperatorsByResource(resource: DataDictionarySearch): LogicOperator[] {
        if (resource.expressionEngineType === ExpressionEngineTypes.DateTime) {
            return this.operators.datetime;
        }
        if (
            resource.expressionEngineType === ExpressionEngineTypes.Integer ||
            resource.expressionEngineType === ExpressionEngineTypes.Number
        ) {
            return this.operators.numeric;
        }
        const key = resource.expressionEngineType.toString().toLowerCase() as keyof LogicOperators;
        return this.operators[key];
    }

    /**
     * After conditions are converted into a payload, this method sends the payload to the API to be saved.
     * @param conditions an array of "LogicCondition" objects.
     * @param name A name for the provided set of logic conditions.
     */
    save(conditions: LogicCondition[], name: string = '') {
        alert('Not yet implemented!');
    }

    /**
     * Fetches all operators from the API and stores them in the operators object, sorted alphabetically by name.
     */
    private fetchAllOperators(): void {
        this.api
            .getObservableEndpoint(environment.apiLogicApp.endpoints.operators.fetchAll)()
            .pipe(take(1))
            .subscribe((operators: LogicOperator[]) => {
                operators.forEach((operator: LogicOperator) => {
                    if (operator.stringType) this.operators.string.push(operator);
                    if (operator.numericType) this.operators.numeric.push(operator);
                    if (operator.booleanType) this.operators.boolean.push(operator);
                    if (operator.dateType) this.operators.date.push(operator);
                    if (operator.TimeType) this.operators.time.push(operator);
                    if (operator.datetimeType) this.operators.datetime.push(operator);
                });

                for (const key in this.operators) {
                    if (this.operators.hasOwnProperty(key)) {
                        this.operators[key as keyof LogicOperators].sort((a: LogicOperator, b: LogicOperator) => {
                            return a.name.localeCompare(b.name);
                        });
                    }
                }
            });
    }
}
