import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeftNavList } from 'src/app/models/left-nav.model';
import { NavState } from './nav.reducer';

export const selectNavFeature = createFeatureSelector<NavState>('nav');

export const selectNavState = createSelector(selectNavFeature, (state: NavState) => state);
export const selectNavQuestionModuleLinks = createSelector(
    selectNavFeature,
    (state: NavState) => state.nav.questionModules
);

export const selectNavLinks = createSelector(selectNavFeature, (state: NavState) => {
    return state.nav;
});

export const selectNavQuestionLinks = createSelector(selectNavFeature, (state: NavState) => state.nav.questions);
export const selectNavConversationLinks = createSelector(
    selectNavFeature,
    (state: NavState) => state.nav.conversations
);

export const selectNavLineOfBusinessLinks = createSelector(
    selectNavFeature,
    (state: NavState) => state.nav.linesOfBusiness
);

export const selectSwftFormLinks = createSelector(selectNavFeature, (state: NavState) => state.nav.formBuilder);

export const selectPatientLinks = createSelector(selectNavFeature, (state: NavState) => state.nav.patients);
export const selectHcpLinks = createSelector(selectNavFeature, (state: NavState) => state.nav.hcpProfiles);
export const selectLayoutBuilderLinks = createSelector(selectNavFeature, (state: NavState) => state.nav.layoutBuilder);

// Checks all of the nav keys that are passed in to see if they are empty
export const selectIsNavEmpty = createSelector(selectNavFeature, (state: NavState) => {
    const navKeys = Object.keys(state.nav);
    return navKeys.every((key: string) => state.nav[key as keyof LeftNavList].length === 0);
});

export const selectIsNavExpanded = createSelector(selectNavFeature, (state: NavState) => {
    return state.isExpanded;
});
