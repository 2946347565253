import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHTMLTags',
})
export class StripHtmlTagsPipe implements PipeTransform {
    transform(value: any): any {
        value = value.replace(/<[^>]*>/g, '');
        value = value.replace('&nbsp;', ' ');
        value = value.replace(/  +/g, ' ');
        return value;
    }
}
