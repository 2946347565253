import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEventPattern, Observable, Subject, takeUntil, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService implements OnDestroy {
    readonly _hasBuilderNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _appRouterOutlet?: HTMLDivElement;
    private _isScrollable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isBottom: Subject<boolean> = new Subject();
    private onDestroy: Subject<void> = new Subject();
    private renderer: Renderer2;
    previousUrl = '';
    currentUrl = '';
    constructor(private rendererFactory2: RendererFactory2) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        this.createHostEventListeners();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get hasBuilderNav(): boolean {
        return this._hasBuilderNav.value;
    }

    set hasBuilderNav(value: boolean) {
        this._hasBuilderNav.next(value);
    }

    set appRouterOutlet(value: HTMLDivElement) {
        this._appRouterOutlet = value;
    }

    get isScrollable(): Observable<boolean> {
        return this._isScrollable;
    }

    get isBottom(): Observable<boolean> {
        return this._isBottom;
    }

    /**
     * Resize the table for the duration of the left nav animation (500ms)
     */
    resizeTablesOnNavExpand(): void {
        const resizeTables = setInterval(() => {
            this.responsiveTables();
        }, 50);
        setTimeout(() => {
            clearInterval(resizeTables);
        }, 500);
    }

    updateAppView(): void {
        this.responsiveTables();
        this.checkRouterScrollability();
    }

    /**
     * Create event listeners for the application.
     */
    private createHostEventListeners() {
        this.observeEvent(window, 'resize', () => this.updateAppView());
        this.observeEvent(window, 'load', () => this.updateAppView());
    }

    /**
     * make tables somewhat-responsive by providing a 'block' class to the table
     * if table's width exceeds its parents width
     */
    private responsiveTables(): void {
        document.querySelectorAll('table').forEach(table => {
            const tableWidth = table.querySelector('tbody')?.clientWidth;
            const parentWidth = table.parentElement?.closest('*')?.getBoundingClientRect().width;

            if (!tableWidth || !parentWidth) return;

            if (tableWidth > parentWidth) {
                table.classList.add('hscroll');
                return;
            }

            table.classList.remove('hscroll');
        });
    }

    /**
     * Check to see if the router should be scrollable (i.e. its content's height exceeds
     * the router-outlet's height) if not scrollable, assume that the user is at the bottom of the page
     */
    private checkRouterScrollability(): void {
        if (!this._appRouterOutlet) return;
        const routerElement = this._appRouterOutlet;
        let contentHeight = 0;
        if (routerElement.hasChildNodes()) {
            for (let i = 0; i < routerElement.children.length; i++) {
                contentHeight += (<HTMLElement>routerElement.children[i]).offsetHeight;
            }
        }
        const styles = getComputedStyle(routerElement);
        const height = routerElement.clientHeight - parseFloat(styles.paddingTop) - parseFloat(styles.paddingBottom); // without padding
        const isScrollable: boolean = contentHeight > height;
        if (this._isScrollable.value !== isScrollable) {
            routerElement.scrollTo(0, 0);
            this.checkIfScrolledToBottom();
            this._isScrollable.next(isScrollable);
        }
    }

    /**
     * set 'this.isBottom' to 'true' when the router is scrolled to the bottom
     * otherwise, set 'this.isBotom' to false
     */
    private checkIfScrolledToBottom(): void {
        if (!this._appRouterOutlet) return;
        const routerElement = this._appRouterOutlet;
        const sh = routerElement.scrollHeight;
        const st = routerElement.scrollTop;
        const ch = routerElement.clientHeight;
        this._isBottom.next(sh - st - ch < 10);
    }

    /**
     * Observe the event of a target for the entire duration of the application's lifetime.
     * @param target the target element to observe
     * @param eventName the event to observe
     * @param callback the function to call when the event is observed
     */
    private observeEvent(target: any, eventName: string, callback: Function): void {
        fromEventPattern<Event>((handler: (e: Event) => boolean | void) => {
            this.renderer.listen(target, eventName, handler);
        })
            .pipe(
                debounceTime(100),
                takeUntil(this.onDestroy),
                tap(() => {
                    callback();
                })
            )
            .subscribe();
    }
}
