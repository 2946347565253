import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'swft-idle',
    templateUrl: './idle.component.html',
    styleUrls: ['./idle.component.scss'],
})
export class IdleComponent implements OnInit {
    @Input() countDown!: number;

    constructor(private dialogRef: MatDialogRef<IdleComponent>) {}

    ngOnInit(): void {
        setInterval(() => {
            this.countDown--;
            if (this.countDown == 0) this.dialogRef.close(false);
        }, 1000);
    }

    stayLoggedIn(stayLoggedIn: boolean) {
        this.dialogRef.close(stayLoggedIn);
    }
}
