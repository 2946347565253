import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { KeyValuePair } from '../utils/constants';

@Directive({
    selector: '[swftTooltip]',
})
export class SWFTTooltipDirective implements OnInit, OnDestroy {
    private element = this.elRef.nativeElement;
    @Input('swftTooltipInfo') info!: KeyValuePair;
    @Input() offsetX: number = 0;
    @Input() offsetY: number = 0;

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.element.addEventListener('mouseenter', () => {
            this.showTooltip();
        });
        this.element.addEventListener('mouseleave', () => {
            this.hideTooltip();
        });
    }

    ngOnDestroy() {
        this.hideTooltip();
    }

    private showTooltip() {
        if (!this.info) return;

        const bbox = this.element.getBoundingClientRect();
        const posY = bbox.top + this.offsetY;
        const posX = bbox.right + this.offsetX;

        const tooltip = this.renderer.createElement('div');
        tooltip.classList.add('swft-tooltip');
        tooltip.style.top = `${posY}px`;
        tooltip.style.left = `${posX}px`;

        for (const [key, value] of Object.entries(this.info)) {
            const p = this.renderer.createElement('p');
            const span = this.renderer.createElement('span');
            p.innerHTML = key + ':';
            span.innerHTML = this.arrayCheck(value);
            this.renderer.appendChild(p, span);
            this.renderer.appendChild(tooltip, p);
        }
        this.renderer.appendChild(document.body, tooltip);

        this.offScreenCheck(tooltip);
    }

    private hideTooltip() {
        const tooltip = document.querySelector('.swft-tooltip');
        if (tooltip) {
            this.renderer.removeChild(document.body, tooltip);
        }
    }

    private arrayCheck(value: any): string {
        if (Array.isArray(value)) {
            return '<br>' + value.join('<br>');
        }
        return value;
    }

    // ensure the tooltip is visible on the screen
    private offScreenCheck(tooltip: any) {
        const tooltipBbox = tooltip.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const buffer = 20;

        if (tooltipBbox.top < 0) {
            tooltip.style.top = `${buffer}px`;
        }
        if (tooltipBbox.bottom > windowHeight) {
            tooltip.style.top = `${windowHeight - tooltipBbox.height - buffer}px`;
        }
        if (tooltipBbox.left < 0) {
            tooltip.style.left = `${buffer}px`;
        }
        if (tooltipBbox.right > windowWidth) {
            tooltip.style.left = `${windowWidth - tooltipBbox.width - buffer}px`;
        }
    }
}
