import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwftConfirmationDialogItem } from 'src/app/models/swft-confirmation.models';

@Component({
    selector: 'swft-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: string;
            description: string;
            okOnly: boolean;
            heading?: string;
            listItems?: SwftConfirmationDialogItem[];
        }
    ) {}

    respond(answer: boolean) {
        this.dialogRef.close(answer);
    }
}
