import { SwftFormState } from '../components/admin/builders/form/form-builder.service';
import { LayoutBuilderState } from '../components/admin/builders/layout/layout-builder-store.service';
import { LineOfBusinessState } from '../components/admin/builders/line-of-business/state/line-of-business-store.service';
import { QuestionModuleState } from '../components/admin/builders/question/module/state/question-module-store.service';
import { QuestionState } from '../components/admin/builders/question/question-store.service';
import { ConversationState } from '../components/narratives/conversations/state/conversation-store.service';
import { PatientProfileState } from '../components/profile/patient-profile/patient-profile-state-manager.service';
import { BusinessLine } from './businessline.model';
import { Conversation } from './conversation.model';
import { FormBuilderForm } from './form.models';
import { LayoutBuilder } from './layout.models';
import { Question, QuestionModule } from './question.model';

export enum LeftNavType {
    Conversation = 'conversations',
    HcpProfile = 'hcpProfiles',
    LineOfBusiness = 'linesOfBusiness',
    Patient = 'patients',
    Question = 'questions',
    QuestionModule = 'questionModules',
    FormBuilder = 'formBuilder',
    LayoutBuilder = 'layoutBuilder',
}

export interface LeftNavList {
    conversations: ConversationLink[];
    hcpProfiles: LeftNavLink[];
    linesOfBusiness: LineOfBusinessLink[];
    patients: PatientProfileLink[];
    questions: QuestionLink[];
    questionModules: QuestionModuleLink[];
    formBuilder: FormBuilderLink[];
    layoutBuilder: LayoutBuilderLink[];
}

export interface LeftNavLink {
    type?: LeftNavType | keyof LeftNavList;
    url?: string;
    data?: any;
    state?: any;
}

export interface QuestionModuleLink extends LeftNavLink {
    data: QuestionModule;
    state: QuestionModuleState;
}

export interface LineOfBusinessLink extends LeftNavLink {
    data: BusinessLine;
    state: LineOfBusinessState;
}

export interface QuestionLink extends LeftNavLink {
    data: Question;
    state: QuestionState;
}

export interface ConversationLink extends LeftNavLink {
    data: Conversation;
    state: ConversationState;
}

export interface PatientProfileLink extends LeftNavLink {
    state: PatientProfileState;
}

export interface FormBuilderLink extends LeftNavLink {
    state: SwftFormState;
    data: FormBuilderForm;
}

export interface LayoutBuilderLink extends LeftNavLink {
    state: LayoutBuilderState;
    data: LayoutBuilder;
}
