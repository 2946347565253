import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    BehaviorSubject,
    debounceTime,
    filter,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap,
} from 'rxjs';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { PermissionName, PermissionNames, PermissionType } from 'src/app/models/auth/authorization.models';
import { SwftGridColumn } from 'src/app/models/grid.models';
import { Patient, PatientAlerts } from 'src/app/models/patient.model';
import { AppRouterParams } from 'src/app/models/router-params.models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NarrativeService } from '../../narratives/narrative.service';
import { NarrativesComponent } from '../../narratives/narratives.component';
import { ProgressNotesEditorComponent } from '../../progress-notes/progress-notes-editor/progress-notes-editor.component';
import { ProfileComponent } from '../profile.component';
import { SpecialIdComponent } from '../special-id/special-id.component';
import { PatientProfileStateManagerService } from './patient-profile-state-manager.service';

@Component({
    selector: 'swft-patient-profile',
    templateUrl: './patient-profile.component.html',
    styleUrls: ['./patient-profile.component.scss'],
})
export class PatientProfileComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();

    @ViewChild(NarrativesComponent) narrativesComponent: NarrativesComponent | undefined;
    @ViewChild(ProfileComponent) profileComponent: ProfileComponent | undefined;
    patientAlerts$: Observable<PatientAlerts | undefined> = this.patientProfileStateManager.patientAlerts$;
    profileLinks$ = this.patientProfileStateManager.patientLinks$;
    narrativeTypes: string = `${NarrativeType.Order}|${NarrativeType.Patient}`;

    id$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    loading$ = this.patientProfileStateManager.loading$;
    patient$: Observable<Patient> = of();
    patientAlertAcknowledged$: Observable<boolean> = of();
    PermissionNames = PermissionNames;

    /**
     * Ensures that, when the ID changes (aka a route switch occurs) that the latest patient information is pushed to the
     */
    set id(value: number) {
        this.patientProfileStateManager.loadFromStore(value);

        // Sets the observables for patient information
        this.id$.next(value);
        this.patient$ = this.patientProfileStateManager.item$;
        this.patientProfileStateManager.state$
            .pipe(
                debounceTime(300),
                tap(() => this.patientProfileStateManager.dispatchNavLink()),
                takeWhile(state => state.item.id === value),
                takeUntil(this.onDestroy$)
            )
            .subscribe();

        this.patientAlertAcknowledged$ = this.patientProfileStateManager.patientAlertsAcknowledged$;
    }

    orderNarrativeType: NarrativeType = NarrativeType.Order;
    patientNarrativeType: NarrativeType = NarrativeType.Patient;

    displayColumns: SwftGridColumn[] = [
        { dataPath: 'formName', name: 'Form Name', pixelWidth: 200 },
        { dataPath: 'lastActionName', name: 'Last Action' },
        { dataPath: 'lastActionDate', name: 'Last Action Date', formatter: 'date' },
        { dataPath: 'nextActionName', name: 'Next Action' },
        { dataPath: 'nextActionDate', name: 'Next Action Date', formatter: 'date' },
        { dataPath: 'id', name: 'Narrative Id' },
        { dataPath: 'formBasisName', name: 'Form Basis' },
        { dataPath: 'status', name: 'Status' },
    ];

    constructor(
        private auth: AuthService,
        private dialog: MatDialog,
        private narrativeService: NarrativeService,
        private patientProfileStateManager: PatientProfileStateManagerService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        // Listens for router events so that we can save the link with the new URL but keep the state
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                tap(() => this.patientProfileStateManager.dispatchNavLink()),
                takeUntil(this.onDestroy$)
            )
            .subscribe();

        this.route.paramMap
            .pipe(
                map(params => {
                    const paramId = Number(params.get(AppRouterParams.PatientId));

                    return paramId;
                }),
                tap(id => (this.id = id)),
                takeUntil(this.onDestroy$)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    createProgressNote() {
        this.dialog.open(ProgressNotesEditorComponent, {
            disableClose: true,
            panelClass: 'medium-auto',
        });
    }

    hasValidPermission(
        permissionName: PermissionName,
        permissionType: PermissionType = PermissionType.Create
    ): boolean {
        return this.auth.hasValidPermission(permissionName, permissionType);
    }

    startNarrative() {
        this.id$
            .pipe(
                take(1),
                switchMap(id => this.narrativeService.startNarrative(id)),
                tap(() => this.narrativesComponent?.search())
            )
            .subscribe();
    }

    openAssignSpecialId(): void {
        this.patient$.pipe(take(1)).subscribe(patient => {
            this.dialog
                .open(SpecialIdComponent, {
                    data: {
                        patientId: patient?.id,
                        specialId: patient?.specialId,
                        manuallyGenerated: patient?.specialIdManuallyGenerated,
                    },
                    panelClass: 'small-auto',
                })
                .afterClosed()
                .subscribe(result => {
                    if (patient && result) {
                        patient.specialId = result.specialId;
                        patient.specialIdManuallyGenerated = result.manuallyGenerated;
                        this.patientProfileStateManager.setPatient(patient);
                        this.patientProfileStateManager.dispatchNavLink();
                    }
                });
        });
    }

    onAcknowledge() {
        this.patientProfileStateManager.updatePatientAlertAcknowledged(true);
    }
}
