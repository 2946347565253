import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { BehaviorSubject, Subject, switchMap, take, takeUntil } from 'rxjs';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { PaginationDataSource } from 'src/app/models/api/datasource.models';
import { Doctor } from 'src/app/models/doctor.model';
import { FormSearch } from 'src/app/models/form.models';
import { SwftGridColumn } from 'src/app/models/grid.models';
import { HealthCareProvider } from 'src/app/models/hcp.model';
import { Narrative, NarrativeStatus } from 'src/app/models/narrative.model';
import { Patient } from 'src/app/models/patient.model';
import { PatientOrder } from 'src/app/models/profile/order.model';
import { SingleSearchTermQuery } from 'src/app/models/table.models';
import { FormService } from 'src/app/services/form/form.service.';
import { HcpService } from 'src/app/services/hcp/hcp.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import {
    EmptyQuery,
    InitalSortForms,
    InitalSortHcp,
    InitalSortOrders,
    InitalSortPatients,
    PageSizeTen,
    SwftBasis,
} from 'src/app/utils/constants';
import { NarrativeService } from '../narrative.service';

@Component({
    selector: 'swft-create-narrative',
    templateUrl: './create-narrative.component.html',
    styleUrls: ['./create-narrative.component.scss'],
})
export class CreateNarrativeComponent implements OnInit, OnDestroy {
    @Input() narrativeType!: NarrativeType;

    onDestroy$: Subject<void> = new Subject<void>();

    formGroup: UntypedFormGroup = new UntypedFormGroup({
        patient: new FormControl(undefined, [Validators.required]),
        order: new FormControl(undefined, [Validators.required]),
        form: new FormControl(undefined, [Validators.required]),
        hcp: new FormControl(undefined, [Validators.required]),
    });

    PatientDataSource = new PaginationDataSource<Patient, SingleSearchTermQuery>(
        (request, query) => this.patient.page(request, query),
        InitalSortPatients,
        EmptyQuery,
        PageSizeTen
    );

    patientColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Patient Id' },
        { dataPath: 'mrn', name: 'MRN' },
        { dataPath: 'firstName', name: 'First Name' },
        { dataPath: 'lastName', name: 'Last Name' },
        { dataPath: 'dob', name: 'Date of Birth', formatter: 'date' },
    ];

    OrderDataSource = new PaginationDataSource<PatientOrder, SingleSearchTermQuery>(
        (request, query) =>
            this.orderDiscontinuedToggled$.pipe(
                switchMap(orderDiscontinued =>
                    this.narrative.searchOrdersByPatientId(request, query, this.patientRow?.id, orderDiscontinued)
                ),
                takeUntil(this.onDestroy$)
            ),
        InitalSortOrders,
        EmptyQuery,
        PageSizeTen
    );

    orderColumns: SwftGridColumn[] = [
        {
            name: 'Rx Number',
            dataPath: 'rxNumber',
            sortable: true,
        },
        {
            name: 'Order Number',
            dataPath: 'orderNumber',
            sortable: true,
        },
        {
            name: 'Medication Name',
            dataPath: 'medicationName',
            sortable: true,
        },
        {
            name: 'Prescriber',
            dataPath: 'prescriber',
            sortable: true,
        },
        {
            name: 'Status',
            dataPath: 'orderStatus',
            sortable: true,
        },
        {
            name: 'Written Date',
            dataPath: 'writtenDate',
            formatter: 'date',
            sortable: true,
        },
        {
            name: 'List ID',
            dataPath: 'listId',
            sortable: true,
        },
    ];

    HCPDataSource = new PaginationDataSource<HealthCareProvider, SingleSearchTermQuery>(
        (request, query) => this.hcp.page(request, query),
        InitalSortHcp,
        EmptyQuery,
        PageSizeTen
    );

    hcpColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Id' },
        { dataPath: 'firstName', name: 'First Name' },
        { dataPath: 'lastName', name: 'Last Name' },
        { dataPath: 'organization', name: 'Organization' },
        { dataPath: 'npi', name: 'NPI' },
    ];

    FormDataSource = new PaginationDataSource<FormSearch, SingleSearchTermQuery>(
        (request, query) =>
            this.patientSearch
                ? this.formService.pageByFormBasis(request, query, [SwftBasis.Order, SwftBasis.Patient])
                : this.formService.pageByFormBasis(request, query, [this.setBasis(this.data.narrativeType)]),
        InitalSortForms,
        EmptyQuery,
        PageSizeTen
    );

    formColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Form Number' },
        { dataPath: 'title', name: 'Form Name' },
        { dataPath: 'formType', name: 'Form Type' },
        { dataPath: 'formBasis', name: 'Form Basis' },
    ];

    patientSearch: boolean = false;

    displayOrders$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    displayHCP$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    displayForms$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    displayPatients$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    patientRow!: Patient;
    doctorRow!: Doctor;
    orderRows!: PatientOrder[];
    formRow!: FormSearch;
    hcpRow!: HealthCareProvider;

    patientDisplayValue = '';
    orderDisplayValue = '';
    formDisplayValue = '';
    hcpDisplayValue = '';
    patientSearchEnabled: boolean = true;

    orderDiscontinuedToggled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public dialogRef: MatDialogRef<CreateNarrativeComponent>,
        private patient: PatientService,
        private narrative: NarrativeService,
        private formService: FormService,
        private hcp: HcpService,
        private snackBar: SWFTSnackBarService,

        @Inject(MAT_DIALOG_DATA)
        public data: { patientId: number | null; hcpId: number | null; narrativeType: NarrativeType | null }
    ) {
        if (this.data.narrativeType) this.narrativeType = this.data.narrativeType;
        this.patientSearch = !!this.data.patientId;
        this.displayPatients$.next(!this.data.patientId && this.data.narrativeType != NarrativeType.Hcp);
        this.displayHCP$.next(!this.data.hcpId && this.data.narrativeType == NarrativeType.Hcp);
        this.displayForms$.next(false);
        this.displayOrders$.next(false);
        this.setFormGroupRequiredFields();
    }
    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnInit(): void {
        if (!this.displayPatients$.value) this.getPatientDetails();
        if (!this.displayHCP$.value) this.getHcpDetails();
    }

    getHcpDetails() {
        if (this.data.hcpId) {
            this.hcp.get(this.data.hcpId).subscribe((doctor: Doctor) => {
                this.selectDoctor(doctor);
                this.formGroup.get('hcp')?.disable();
                this.patientSearchEnabled = false;
                this.displayForms$.next(true);
            });
        }
    }

    getPatientDetails() {
        if (this.data.patientId) {
            this.patient.read(this.data.patientId).subscribe(patient => {
                this.selectPatient(patient);
                this.formGroup.get('patient')?.disable();
                this.patientSearchEnabled = false;
            });
        }
    }
    updateDiscontinued(discontinued: boolean): void {
        this.orderDiscontinuedToggled$.next(discontinued);
    }

    save(startConversation: boolean = false): void {
        let payload: Narrative = {
            id: 0,
            formId: this.formRow.id,
            formName: this.formRow.title,
            formBasisName: this.formRow.formBasis as SwftBasis,
            formTypeName: this.formRow.formType,
            hcpId: undefined,
            otId: undefined,
            status: NarrativeStatus.Open,
            nextActionDate: new Date(),
            lastActionDate: new Date(),
            patientId: undefined,
            createdDate: new Date(),
            modifiedDate: new Date(),
        };
        const narrativeArray: Narrative[] = [];
        switch (this.data.narrativeType) {
            case NarrativeType.Order:
                this.orderRows.forEach(narr => {
                    let narrative = { ...payload };
                    narrative.otId = narr.orderNumber;
                    narrative.medicationName = narr.medicationName;
                    narrative.patientId = this.patientRow.id;
                    narrativeArray.push(narrative);
                });
                break;
            case NarrativeType.Patient:
                payload.patientId = this.patientRow.id;
                narrativeArray.push(payload);
                break;
            case NarrativeType.Hcp:
                payload.hcpId = this.hcpRow.id;
                narrativeArray.push(payload);
                break;
            default:
                break;
        }

        this.createNarratives(narrativeArray, startConversation);
    }

    private createNarratives(payload: Narrative[], startConversation: boolean): void {
        this.narrative
            .createBulk(payload)
            .pipe(take(1))
            .subscribe({
                next: result => {
                    if (startConversation) this.narrative.startMultiNarrativeConversation(result);
                    this.snackBar.success('Narratives saved successfully.');
                    this.close();
                },
                error: () => {
                    this.snackBar.error('Narratives unable to be saved, call failed.');
                    this.close();
                },
            });
    }

    close(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    selectPatient(row: Patient): void {
        if (row === undefined || row === this.patientRow) return;
        this.patientRow = row;
        this.patientDisplayValue = `${row.firstName} ${row.lastName} (${row.mrn})`;
        this.formGroup.patchValue({
            patient: this.patientDisplayValue,
        });
        this.clear();
        this.displayForms$.next(true);
    }

    getNarrativeTitle(type: string | null) {
        if (type === NarrativeType.Hcp) return type.toUpperCase();
        return type;
    }

    selectDoctor(row: Doctor): void {
        let hcpRow: HealthCareProvider = {
            id: row.id,
            firstName: row.phFirst,
            lastName: row.phLast,
        } as any;
        const currentRow = {
            id: this.hcpRow?.id,
            firstName: this.hcpRow?.firstName,
            lastName: this.hcpRow?.lastName,
        };
        if (!_.eq(hcpRow, currentRow)) {
            this.hcpRow = hcpRow;
            this.patientDisplayValue = `${row.phFirst} ${row.phLast}`;
            this.formGroup.patchValue({
                hcp: this.patientDisplayValue,
            });
            this.clear();
        }
    }

    clear() {
        switch (this.data.narrativeType) {
            case NarrativeType.Order:
                this.clearOrderRow();
                break;
            case NarrativeType.Patient:
                this.clearFormRow();
                break;
            case NarrativeType.Hcp:
                this.clearHcpRow();
                break;
        }
    }

    selectOrder(rows: PatientOrder[]): void {
        if (rows !== undefined && rows.length !== 0) {
            this.orderDisplayValue = '';
            rows.forEach((element, index, rows) => {
                this.orderDisplayValue += element.orderNumber;
                if (index !== rows.length - 1) this.orderDisplayValue += ', ';
            });
            this.formGroup.patchValue({
                order: this.orderDisplayValue,
            });
            this.orderRows = rows;
        }
    }
    selectHcp(row: HealthCareProvider): void {
        if (row !== undefined) {
            this.hcpDisplayValue = `${row.firstName} ${row.lastName} ${row.professionalDesignation}`;
            this.formGroup.patchValue({
                hcp: this.hcpDisplayValue,
            });
            this.displayForms$.next(true);
            this.hcpRow = row;
        }
    }
    selectForm(row: FormSearch): void {
        if (row !== undefined) {
            this.formDisplayValue = row.title + ' (' + row.id + ')';
            this.formGroup.patchValue({
                form: this.formDisplayValue,
            });
            this.formRow = row;
        }

        this.processFormSelection(row);
    }

    private processFormSelection(form: FormSearch): void {
        this.data.narrativeType = <NarrativeType>form.formBasis.replace(' Based', '').toLowerCase();
        this.displayOrders$.next(this.data.narrativeType === NarrativeType.Order);
        this.setFormGroupRequiredFields();
    }

    clearOrderRow(): void {
        this.displayOrders$.next(true);
        this.displayForms$.next(false);
        this.displayHCP$.next(false);

        this.orderDisplayValue = '';
        this.formDisplayValue = '';
        this.formGroup.patchValue({
            form: this.formDisplayValue,
            order: this.orderDisplayValue,
        });
    }

    clearFormRow(): void {
        this.displayHCP$.next(false);
        this.displayOrders$.next(false);
        this.displayForms$.next(false);
        this.orderDisplayValue = '';
        this.formDisplayValue = '';
        this.formGroup.patchValue({
            form: this.formDisplayValue,
            order: this.orderDisplayValue,
        });
    }

    clearHcpRow(): void {
        this.displayHCP$.next(true);
        this.displayOrders$.next(false);
        this.displayForms$.next(false);
        this.orderDisplayValue = '';
        this.formDisplayValue = '';
        this.formGroup.patchValue({
            form: this.formDisplayValue,
            order: this.orderDisplayValue,
        });
    }

    private setFormGroupRequiredFields(): void {
        if (this.data.narrativeType !== NarrativeType.Hcp)
            this.formGroup.get('patient')?.addValidators(Validators.required);
        else this.formGroup.get('patient')?.removeValidators(Validators.required);
        if (this.data.narrativeType === NarrativeType.Hcp)
            this.formGroup.get('hcp')?.addValidators(Validators.required);
        else this.formGroup.get('hcp')?.removeValidators(Validators.required);
        if (this.data.narrativeType === NarrativeType.Order)
            this.formGroup.get('order')?.addValidators(Validators.required);
        else this.formGroup.get('order')?.removeValidators(Validators.required);
    }

    //TODO: Change NarrativeType to SwftBasis and remove NarrativeType
    private setBasis(narrativeType: NarrativeType | null): SwftBasis {
        switch (this.data.narrativeType) {
            case NarrativeType.Order:
                return SwftBasis.Order;
            case NarrativeType.Patient:
                return SwftBasis.Patient;
            case NarrativeType.Hcp:
                return SwftBasis.HCP;
            default:
                return SwftBasis.Empty;
        }
    }
}
