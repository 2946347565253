import { Injectable } from '@angular/core';
import { PnName } from 'src/app/models/pn-names.model';

@Injectable({
    providedIn: 'root',
})
export class PnNameAdapter {
    // the patient as they appear in CPR+
    adapt(item: any): PnName {
        let pnName = new PnName(
            item.id,
            item.fName,
            item.lName,
            item.profDesig,
            item.initials,
            item.email,
            item.userName,
            item.touchDate,
            item.chgByHost,
            item.createdOn,
            item.createdBy,
            item.deleted
        );
        return pnName;
    }
}
