import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SWFTSnackBarService {
    constructor(private snackbar: MatSnackBar) {}

    /**
     * @description Displays a success message.
     * @param message The message to display in the snackbar.
     * @param autoDismiss Whether or not the snackbar should be dismissed automatically or
     *                    after a number of miliseconds. If 'false', force the user to dismiss manually.
     */
    success(message: string, autoDismiss: boolean | number = true): void {
        const config: MatSnackBarConfig = {};
        this.open(message, autoDismiss, config);
    }

    /**
     * @description Displays an error message.
     * @param message The message to display in the snackbar.
     * @param autoDismiss Whether or not the snackbar should be dismissed automatically or
     *                    after a number of miliseconds. If 'false', force the user to dismiss manually.
     */
    error(message: string, autoDismiss: boolean | number = true): void {
        const config: MatSnackBarConfig = { panelClass: 'snackbar-error' };
        this.open(message, autoDismiss, config);
    }

    /**
     * @description Displays an information message.
     * @param message The message to display in the snackbar.
     * @param autoDismiss Whether or not the snackbar should be dismissed automatically or
     *                    after a number of miliseconds. If 'false', force the user to dismiss manually.
     */
    info(message: string, autoDismiss: boolean | number = true): void {
        const config: MatSnackBarConfig = { panelClass: 'snackbar-info' };
        this.open(message, autoDismiss, config);
    }

    /**
     * @description Displays an warning message.
     * @param message The message to display in the snackbar.
     * @param autoDismiss Whether or not the snackbar should be dismissed automatically or
     *                    after a number of miliseconds. If 'false', force the user to dismiss manually.
     */
    warn(message: string, autoDismiss: boolean | number = true): void {
        const config: MatSnackBarConfig = { panelClass: 'snackbar-caution' };
        this.open(message, autoDismiss, config);
    }

    private open(message: string, autoDismiss: boolean | number, config: MatSnackBarConfig): void {
        let actionText: string | undefined = 'dismiss';

        if (autoDismiss) {
            actionText = undefined;
            if (typeof autoDismiss === 'number') {
                config.duration = autoDismiss;
            } else {
                config.duration = this.calculateMessageDuration(message);
            }
        }

        this.snackbar.open(message, actionText, config);
    }

    private calculateMessageDuration(message: string): number {
        const words: string[] = message.trim().split(' ');
        let duration = 500; // fade-in animation compensation

        words.forEach((word: string) => {
            duration += 350; // high-average human reaction time per word
            if (words.length <= 7) {
                // inner subitizing range
                duration += words.length * 40; // +40ms per character
                return;
            }
            duration += 160; // account for inner subitizing range 4*40
            duration += (word.length - 7) * 250; // +250ms per character
        });

        return duration;
    }
}
