export enum AuthClaim {
    Jti = 'jti',
    Iat = 'iat',
    Sub = 'sub',
    Name = 'name',
    Email = 'email',
    Exp = 'exp',
    UserID = 'userId',
    IsAdmin = 'isAdmin',
    IsDeveloper = 'isDeveloper',
    IsSuper = 'isSuper',
    Nbf = 'nbf',
    Iss = 'iss',
    Aud = 'aud',
}
