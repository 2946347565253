import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    /**
     * Truncates a string if longer than a specified length with added ellipses.
     * @param string the sting to truncate
     * @param length the length of truncated string
     * @param ellipses weather to include ellipses at the end of the truncated string
     * @returns a truncated string
     */
    transform(string: string | null | undefined, length: number, ellipses: boolean = true): string {
        const ellipsesString = ellipses ? '...' : '';
        if (!string) return ellipsesString;
        if (string.length <= length) return string;
        if (ellipses) length -= 2;
        return string.substring(0, length) + ellipsesString;
    }
}
