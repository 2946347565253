import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, switchMap } from 'rxjs';
import { TimeOfDay } from 'src/app/enums/datetime.enums';
import { SearchOptions, SelectSearchValues } from 'src/app/models/api/api-search.models';
import { DataDictionarySearch, DbTypes, ExpressionEngineTypes } from 'src/app/models/data-dictionary.model';
import {
    DateTimeConditionValue,
    LogicCommandTypes,
    LogicCondition,
    LogicModifier,
    LogicOperator,
    LogicRange,
} from 'src/app/models/logic.models';
import { SingleSearchTermQuery } from 'src/app/models/table.models';
import { LogicService } from 'src/app/services/logic/logic.service';
import { DataDictionaryService } from '../../admin/builders/data-dictionary/data-dictionary.service';
@Component({
    selector: 'swft-logic-condition',
    templateUrl: './logic-condition.component.html',
    styleUrls: ['./logic-condition.component.scss'],
})
export class LogicConditionComponent {
    @ViewChild('matChipInput') matChipInput!: ElementRef<HTMLInputElement>;

    @Input() firstInGroup = true; // determines if the groupType toggle should be displayed
    @Input() lastInGroup = true; // determines if the "add condition" and "add group" buttons should be displayed
    @Input() condition: LogicCondition = { groupType: LogicModifier.Empty };
    @Output() addCondition: EventEmitter<LogicCondition> = new EventEmitter<LogicCondition>();
    @Output() removeCondition: EventEmitter<null> = new EventEmitter<null>();
    @Output() addGroup: EventEmitter<null> = new EventEmitter<null>();
    @Output() valueChanges: EventEmitter<LogicCondition> = new EventEmitter<LogicCondition>();

    searchModifier$ = new BehaviorSubject<string>('');
    databaseTypes: SelectSearchValues = { name: 'DbType', values: Object.values(DbTypes) };
    dateTimeStart: DateTimeConditionValue = { timeOfDay: TimeOfDay.AM };
    dateTimeEnd: DateTimeConditionValue = { timeOfDay: TimeOfDay.AM };
    query: SingleSearchTermQuery = { search: '' };
    operators: LogicOperator[] = [];
    resourceAlias: string = '';
    showAddMenu = false;
    dateRangeValues: LogicRange = { start: '', end: '' };
    numberRangeValues: LogicRange = { start: 0, end: 0 };
    separatorKeysCodes: number[] = [ENTER, COMMA];
    chipListValues: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    LogicModifier = LogicModifier;
    ExpressionEngineTypes = ExpressionEngineTypes;
    LogicCommandTypes = LogicCommandTypes;
    TimeOfDay = TimeOfDay;

    searchDataDictionaryFunction = (options: SearchOptions, query: SingleSearchTermQuery) =>
        this.searchModifier$.pipe(
            switchMap(modifier => this.dataDictionary.searchDataDictionary(options, query, modifier, true))
        );

    constructor(private service: LogicService, private dataDictionary: DataDictionaryService) {}

    resourceSelected(resource: DataDictionarySearch) {
        if (!resource) return;
        this.resourceAlias = resource.alias;
        this.operators = this.service.getOperatorsByResource(resource);
        this.condition.resource = resource;
        this.condition.operator = undefined;
        this.condition.value = undefined;
        this.valueChanges.emit(this.condition);
    }

    operatorSelected(operator: MatSelectChange) {
        const selectedOperator = operator.value;
        this.condition.operator = selectedOperator;
        this.valueChanges.emit(this.condition);
    }

    valueChanged(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.condition.value = value;
        this.valueChanges.emit(this.condition);
    }

    searchModifierChanged(modifier: string) {
        this.searchModifier$.next(modifier);
    }

    addValueToSelected(response: string): void {
        if (!response || this.chipListValues.value.includes(response)) return;
        const updatedValues: string[] = [...this.chipListValues.value, response];
        this.chipListValues.next(updatedValues);
        this.clearInput();
        this.condition.value = this.chipListValues.value.join(',');
        this.valueChanges.emit(this.condition);
    }

    private clearInput(): void {
        if (!this.matChipInput) return;
        this.matChipInput.nativeElement.value = '';
    }

    removeValueFromSelected(value: string): void {
        const updatedValues: string[] = this.chipListValues.value.filter(selectedValue => selectedValue !== value);
        this.chipListValues.next(updatedValues);
        this.condition.value = this.chipListValues.value.join(',');
        this.valueChanges.emit(this.condition);
    }

    updateDateTime(dateTime: DateTimeConditionValue, isStart: boolean) {
        if (!dateTime.date) return;
        const newDateTime = new Date(dateTime.date);
        dateTime.hour = dateTime.hour || 0;
        dateTime.minute = dateTime.minute || 0;
        newDateTime.setHours(
            dateTime.timeOfDay === TimeOfDay.AM ? dateTime.hour : dateTime.hour + 12,
            dateTime.minute || 0
        );
        const dateString = `${newDateTime.toString()}`;

        if (!this.isRange()) {
            this.condition.value = dateString;
            this.valueChanges.emit(this.condition);
            return;
        }

        if (isStart) {
            this.dateRangeValues.start = dateString;
            this.condition.value = this.dateRangeValues;
            this.valueChanges.emit(this.condition);
            return;
        }

        this.dateRangeValues.end = dateString;
        this.condition.value = this.dateRangeValues;
        this.valueChanges.emit(this.condition);
    }

    updateNumber(event: Event, isStart: boolean) {
        const number = Number((event.target as HTMLInputElement).value);
        if (!this.isRange()) {
            this.condition.value = number;
            this.valueChanges.emit(this.condition);
            return;
        }

        if (isStart) {
            this.numberRangeValues.start = number;
            this.condition.value = this.numberRangeValues;
            this.valueChanges.emit(this.condition);
            return;
        }

        this.numberRangeValues.end = number;
        this.condition.value = this.numberRangeValues;
        this.valueChanges.emit(this.condition);
    }

    toggleAddMenu($event: Event) {
        $event.stopPropagation();
        this.showAddMenu = !this.showAddMenu;
    }

    toggleGroupType(type: LogicModifier | undefined) {
        const newGroupType = type === LogicModifier.And ? LogicModifier.Or : LogicModifier.And;
        this.condition.groupType = newGroupType;
        this.valueChanges.emit(this.condition);
    }

    requiresValue(): boolean {
        if (!this.condition.operator) return true;
        return !!this.condition.operator?.requireValue;
    }

    addConditionOrGroup(condition: LogicCondition | null, group: string | null) {
        this.showAddMenu = false;

        if (condition) {
            this.addCondition.emit(condition);
            return;
        }

        alert(group); /// Not yet implemented
    }

    isMultipleValues(): boolean {
        return this.condition.operator?.allowMultipleValues || false;
    }

    isRange(): boolean {
        return this.condition.operator?.command.includes(LogicCommandTypes.Between) || false;
    }

    isNumericType(): boolean {
        return (
            this.condition.resource?.expressionEngineType === ExpressionEngineTypes.Integer ||
            this.condition.resource?.expressionEngineType === ExpressionEngineTypes.Number
        );
    }
}
