import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/services/app/app.service';

@Component({
    selector: 'swft-spinner',
    templateUrl: './swft-spinner.component.html',
    styleUrls: ['./swft-spinner.component.scss'],
})
export class SwftSpinnerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() set show(state: any) {
        this.hideShow(state);
        this.service.updateAppView();
    }
    @Input() coverApp = false; //... If true, the spinner will completely cover the viewport.
    @Input() coverPage = false; //.. If true, the spinner will only cover the page. Header, footer and sidebars navs are not covered.
    @Input() coverId = ''; //.... An HTML ID attribute value for a specific element that the spinner should cover.
    @Input() inDialog = false;
    showSpinner = true;
    private spinner: HTMLElement | undefined;
    private parent: HTMLElement | undefined;
    spinnerInterval: any;

    constructor(private renderer: Renderer2, private service: AppService) {}

    ngOnInit() {
        this.createSpinner();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.setOverlaySizeAndPosition();
        }, 0);
    }

    ngOnDestroy() {
        try {
            this.renderer.removeChild(document.body, this.spinner);
        } catch (e) {
            // Swallow this error because it breaks sometimes when switching between patient profiles through navbar
            return;
        }
    }

    createSpinner() {
        const span = this.renderer.createElement('span');
        this.spinner = this.renderer.createElement('div');
        this.renderer.addClass(this.spinner, 'swft-spinner');
        if (this.showSpinner) this.renderer.addClass(this.spinner, 'show');
        if (this.coverApp) this.renderer.addClass(this.spinner, 'app');
        if (this.coverPage) this.renderer.addClass(this.spinner, 'page');
        if (this.coverId) this.renderer.setAttribute(this.spinner, 'data-coverId', this.coverId);
        this.renderer.appendChild(this.spinner, span);
        this.renderer.appendChild(document.body, this.spinner);
    }

    /**
     * Sets the size and position of the overlay element based on the size and position of the "coverId" element.
     *
     * Ensure the spinner is above the "coverId" element by applying a z-index that is 10 units higher than the
     * z-index of the "coverId" element.
     *
     * If the "coverId" element appears inside of a dialog, ensure the spinner
     * appears above the dialog by applying a z-index that is 10 units higher than the z-index of the dialog.
     * NOTE: The dialog z-index is set to 1000 by default via Angular Material.
     */
    private setOverlaySizeAndPosition() {
        this.parent = document.getElementById(this.coverId) as HTMLElement;

        if (!this.parent || !this.spinner) return;

        const parentLeft = this.parent.getBoundingClientRect().left;
        const parentTop = this.parent.getBoundingClientRect().top;
        const parentBottom = window.innerHeight - this.parent.getBoundingClientRect().bottom;
        const headerHeight: number = document.querySelector('#SWFTMainHeader')?.getBoundingClientRect().height || 0;
        const parentZIndex = parseInt(window.getComputedStyle(this.parent).zIndex) || 0;
        const inDialog = this.inDialog || document.querySelector(`cdk-overlay-container`)?.contains(this.parent);
        const zBuffer = inDialog ? 1010 : 10;

        let spinnerHeight = window.innerHeight - headerHeight;
        if (parentBottom > 0) spinnerHeight -= parentBottom;
        if (parentTop > 0) spinnerHeight -= parentTop;

        this.spinner.style.left = `${parentLeft}px`;
        this.spinner.style.top = `${Math.max(parentTop, headerHeight)}px`;
        this.spinner.style.width = `${this.parent.offsetWidth}px`;
        this.spinner.style.height = `${spinnerHeight}px`;
        this.spinner.style.zIndex = `${parentZIndex + zBuffer}`;
    }

    private hideShow(state: boolean): void {
        this.showSpinner = state;

        if (!state) {
            this.spinner?.classList.remove('show');
            setTimeout(() => {
                clearInterval(this.spinnerInterval);
            }, 1000);
            return;
        }
        this.spinnerInterval = setInterval(() => {
            this.setOverlaySizeAndPosition();
        }, 1);
        this.spinner?.classList.add('show');
    }
}
