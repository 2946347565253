import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import { PermissionNames, PermissionType } from 'src/app/models/auth/authorization.models';
import { User, UserViewModel } from 'src/app/models/auth/user.model';
import { PnName } from 'src/app/models/pn-names.model';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { IdentityApiService } from 'src/app/services/api/identity-api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { mapFromSearchResult } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { PnNameAdapter } from './pn-name.adapter';
import { UserAdapter } from './user.adapter';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private userAdapter: UserAdapter,
        private api: IdentityApiService,
        private pnNameAdaptor: PnNameAdapter,
        private authService: AuthService
    ) {}

    page(request: PageRequest<User>, query: SingleSearchTermQuery): Observable<Page<User>> {
        const params: SearchOptions = {
            value: query.search,
            ascending: request.sort?.order! == SortOrder.Ascending,
            orderBy: request.sort?.property!,
            page: request.page,
            pageLength: request.size,
        };

        const hasGroupManager = this.authService.hasValidPermission(PermissionNames.UserManager, PermissionType.Read);

        return hasGroupManager ? this.searchUserWithGroups(params) : this.searchUsers(params);
    }

    searchUserWithGroups(request: SearchOptions): Observable<Page<User>> {
        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.users.searchWithGroups)(request)
            .pipe(
                mapFromSearchResult,
                map(page => this.adaptUsersInPageResult(page))
            );
    }

    searchUsers(request: SearchOptions): Observable<Page<User>> {
        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.users.search)(request)
            .pipe(
                mapFromSearchResult,
                map(page => this.adaptUsersInPageResult(page))
            );
    }

    getUsersWithoutCurrentUser(request: SearchOptions, query: SingleSearchTermQuery): Observable<Page<UserViewModel>> {
        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.users.searchUserModel)(request)
            .pipe(
                mapFromSearchResult,
                map((results: Page<UserViewModel>) => {
                    const userId = this.authService.userId;
                    results.content = results.content.filter(user => user.id !== userId);

                    return results;
                })
            );
    }

    updateUser(user: User): Observable<User> {
        return this.api.getObservableEndpoint(environment.apiIdentity.endpoints.users.createOrUpdate)(user);
    }

    getUser(id: number): Observable<User> {
        const params = { id };

        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.users.getById)(null, params)
            .pipe(map(data => this.userAdapter.adapt(data)));
    }

    getPnNames(): Observable<PnName[]> {
        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.users.pnNameLists)()
            .pipe(
                map((data: any) => {
                    return data.map((item: any) => this.pnNameAdaptor.adapt(item));
                })
            );
    }

    adaptUsersInPageResult(pageResult: Page<any>): Page<User> {
        return { ...pageResult, content: pageResult.content.map(user => this.userAdapter.adapt(user)) };
    }
}
