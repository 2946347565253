import { Endpoint, IEndpoint } from './endpoint';

export class NarrativeEndpoints {
    getById: IEndpoint = new Endpoint('get', 'v1/narrative/{id}');
    search: IEndpoint = new Endpoint('post', 'v1/narrative/search');
    create: IEndpoint = new Endpoint('post', 'v1/narrative');
    bulkCreate: IEndpoint = new Endpoint('post', 'v1/narrative/bulkinsert');
    update: IEndpoint = new Endpoint('put', 'v1/narrative');

    searchOrdersByMrn: IEndpoint = new Endpoint('post', 'v1/order/search');
    action: ActionEndpoints = new ActionEndpoints();
    answer: AnswerEndpoints = new AnswerEndpoints();
    conversation: ConversationEndpoints = new ConversationEndpoints();
    conversationNote: ConversationNoteEndpoints = new ConversationNoteEndpoints();
    actionCombination: ActionCombinationEndpoints = new ActionCombinationEndpoints();
    allowableNextAction: AllowableNextActionEndpoints = new AllowableNextActionEndpoints();
}

class ConversationEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/conversation');
    read: IEndpoint = new Endpoint('get', 'v1/conversation/{id}');
    update: IEndpoint = new Endpoint('put', 'v1/conversation');
}

class ConversationNoteEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/conversationNote');
    update: IEndpoint = new Endpoint('put', 'v1/conversationNote');
    getExistingId: IEndpoint = new Endpoint('get', 'v1/conversationNote/{id}/get-existing-id');
    searchByPatientId: IEndpoint = new Endpoint('post', 'v1/conversationNote/patient/{patientId}');
    searchByHcpId: IEndpoint = new Endpoint('post', 'v1/conversationNote/hcp/{hcpId}');
}

class ActionEndpoints {
    validateNameAndType: IEndpoint = new Endpoint('get', 'v1/action/validate/{name}/{type}');

    create: IEndpoint = new Endpoint('post', 'v1/action');
    update: IEndpoint = new Endpoint('put', 'v1/action');

    read: IEndpoint = new Endpoint('get', 'v1/action/{id}');
    search: IEndpoint = new Endpoint('post', 'v1/action/search');
    delete: IEndpoint = new Endpoint('delete', 'v1/action/{id}');
    searchByActionType: IEndpoint = new Endpoint('post', 'v1/action/search/{actionType}');
    getForms: IEndpoint = new Endpoint('post', 'v1/action/{id}/forms');
}

class AnswerEndpoints {
    process: IEndpoint = new Endpoint('post', 'v1/answer');
    getAnswerHistory = new Endpoint('post', 'v1/answerlog/history/patient/{patientId}');
}

class ActionCombinationEndpoints {
    read: IEndpoint = new Endpoint('get', 'v1/ActionCombination/{id}');
    getActionCombinationsByFormId: IEndpoint = new Endpoint('get', 'v1/ActionCombination/form/{formId}');
    search: IEndpoint = new Endpoint('post', 'v1/ActionCombination/search');
    create: IEndpoint = new Endpoint('post', 'v1/ActionCombination');
    update: IEndpoint = new Endpoint('put', 'v1/ActionCombination');
}

class AllowableNextActionEndpoints {
    read: IEndpoint = new Endpoint('get', 'v1/AllowableNextAction/{id}');
    create: IEndpoint = new Endpoint('post', 'v1/AllowableNextAction');
    update: IEndpoint = new Endpoint('put', 'v1/AllowableNextAction');
}
