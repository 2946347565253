import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { delay, Observable, Subject, take, takeUntil } from 'rxjs';
import { SwftSelectSearchComponent } from '../components/swft/swft-select-search/swft-select-search.component';
import { SearchOptions, SelectSearchValues } from '../models/api/api-search.models';
import { Page, SingleSearchTermQuery } from '../models/table.models';
import { SwftBasis } from '../utils/constants';

export type PageRequestFunction<T> = (
    request: SearchOptions,
    query: SingleSearchTermQuery,
    basis?: SwftBasis[]
) => Observable<Page<T>>;

@Directive({
    selector: '[swftSelectSearch]',
})
export class SwftSelectSearchDirective implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject<void>();

    @Input() fetch?: PageRequestFunction<any>;

    @Input() searchPlaceholder: string = 'Search';
    @Input() searchOrderBy: string = '';
    @Input() searchPageLength: number = 25;
    @Input() searchDropdownLabel: string = 'Select Modifier';
    @Input() searchWidth: string | null = null;
    @Input() searchValueFormat: string = '';
    @Input() searchDropdownPlaceholder: string = '';
    @Input() searchDropdownValues: SelectSearchValues = { name: '', values: [] };
    @Input() basis?: SwftBasis[];

    @Output() selectionChanged = new EventEmitter<any>();
    @Output() searchModifierChanged = new EventEmitter<string>();

    position: DialogPosition = {};
    private element: HTMLElement | undefined;

    constructor(protected elementRef: ElementRef<HTMLElement>, protected dialog: MatDialog) {}

    ngOnInit(): void {
        /**
         * For a better click hitbox, attempt to select the entire form field. If a
         * parent form-field does NOT exist, select the native element.
         */
        this.element =
            (this.elementRef.nativeElement.closest('mat-form-field') as HTMLElement) || this.elementRef.nativeElement;
        this.element.onclick = () => {
            this.openSelectSearch();
        };
        this.element.classList.add('swft-select-search-input');

        /**
         * Remove auto-focus. When focused, the field's visual styles indicate to the user
         * that the field can be typed in to. This is not the case, so we need the field
         * to always be blurred.
         * */
        window.setTimeout(() => {
            this.elementRef.nativeElement.blur();
        }, 250);
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private calculateDialogPosition() {
        const appRouterOutletElement = document.getElementById('AppRouterOutlet');
        if (appRouterOutletElement && this.element) {
            const style = window.getComputedStyle(appRouterOutletElement);
            const outletPaddingTop = parseInt(style.paddingTop.split('px')[0]);
            const outletMarginLeft = parseInt(style.marginLeft.split('px')[0]);
            this.position.top = `${this.element.getBoundingClientRect().top - 5 + outletPaddingTop}px`;
            this.position.left = `${this.element.getBoundingClientRect().left - outletMarginLeft}px`;
        }
    }

    openSelectSearch() {
        this.calculateDialogPosition();
        const dialog: MatDialogRef<SwftSelectSearchComponent> = this.dialog.open(SwftSelectSearchComponent, {
            panelClass: 'select-search-modal',
            backdropClass: 'transparent-backdrop',
            position: this.position,
            width: this.searchWidth ? this.searchWidth : this.element?.clientWidth + 'px',
            data: {
                fetch: this.fetch,
                searchPlaceholder: this.searchPlaceholder,
                searchOrderBy: this.searchOrderBy,
                searchPageLength: this.searchPageLength,
                searchValueFormat: this.searchValueFormat,
                searchDropdownLabel: this.searchDropdownLabel,
                searchDropdownValues: this.searchDropdownValues,
                searchDropdownPlaceholder: this.searchDropdownPlaceholder,
                basis: this.basis,
            },
        });

        dialog.componentInstance.searchModifierChanged
            .pipe(takeUntil(dialog.afterClosed()))
            .subscribe(searchModifier => {
                this.searchModifierChanged.emit(searchModifier);
            });

        dialog
            .afterOpened()
            .pipe(delay(100), take(1))
            .subscribe(() => {
                this.checkIfDialogIsInViewport(dialog);
            });

        dialog
            .afterClosed()
            .pipe(take(1))
            .subscribe(row => {
                this.selectionChanged.emit(row);
            });
    }

    /**
     * Check if the dialog is in the viewport. If not, move it up 10px and check again. This is a recursive function.
     * @param dialog the dialog used in this directive
     */
    private checkIfDialogIsInViewport(dialog: MatDialogRef<SwftSelectSearchComponent>) {
        const dialogEle = document.querySelector('swft-select-search');
        const appRouterOutletElement = document.getElementById('AppRouterOutlet');
        if (!dialogEle || !appRouterOutletElement) return;
        const style = window.getComputedStyle(appRouterOutletElement);
        const outletPaddingTop = parseInt(style.paddingTop.split('px')[0]);
        const eleBounding = dialogEle.getBoundingClientRect();
        const appBounding = appRouterOutletElement.getBoundingClientRect();
        if (eleBounding.bottom > appBounding.bottom - outletPaddingTop + 20) {
            const top = this.position.top?.split('px')[0];
            if (!top) return;
            this.position.top = `${parseInt(top) - 10}px`;
            dialog.updatePosition(this.position);
            this.checkIfDialogIsInViewport(dialog);
        }
    }
}
