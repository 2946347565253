import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Conversation } from 'src/app/models/conversation.model';
import { environment } from 'src/environments/environment';
import { NarrativeApiService } from '../api/narrative-api.service';

@Injectable({
    providedIn: 'root',
})
export class ConversationService {
    constructor(private api: NarrativeApiService) {}

    create(conversation: Conversation): Observable<Conversation> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.conversation.create)(conversation);
    }

    update(conversation: Conversation): Observable<Conversation> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.conversation.update)(conversation);
    }

    read(id: number): Observable<Conversation> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.conversation.read)(null, { id });
    }
}
