import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'swft-profile-acknowledge',
    templateUrl: './profile-acknowledge.component.html',
    styleUrls: ['./profile-acknowledge.component.scss'],
})
export class ProfileAcknowledgeComponent {
    @Input() heading: string = '';
    @Input() userHasAcknowledged$: any;
    @Output() acknowledge: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}

    onAcknowledgeClicked() {
        this.acknowledge.emit();
    }
}
