import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class IsLoggedInGuard implements CanActivate {
    /**
     * If a user is not logged in, automatically direct them to
     * the login screen from any page they are trying to access
     */
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isNavigatingToLogin: boolean = route.url[0]?.path === 'login';

        if (this.authService.isLoggedIn.value) {
            if (isNavigatingToLogin) {
                this.router.navigate(['/']);
            }

            return true;
        }

        if (isNavigatingToLogin) {
            return true;
        }

        this.router.navigate(['login']);
        return false;
    }
}
