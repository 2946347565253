import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SelectSearchValues } from 'src/app/models/api/api-search.models';
import { DateFormat } from 'src/app/utils/constants';

export interface SWFTSearchBarButton {
    icon?: string;
    svgIcon?: string;
    tooltip: string;
    click: () => void;
}

@Component({
    selector: 'swft-searchbar',
    templateUrl: './swft-searchbar.component.html',
    styleUrls: ['./swft-searchbar.component.scss'],
})
export class SwftSearchbarComponent implements OnInit, OnDestroy {
    @Input() placeholder: string = 'Search';
    @Input() dateSearch: boolean = false;
    @Input() showIcon: boolean = true;
    @Input() showPrimaryToggle: boolean = false;
    @Input() showSecondaryToggle: boolean = false;
    @Input() dropdownValues: SelectSearchValues = { name: '', values: [] };
    @Input() dropdownLabel: string = '';
    @Input() dropdownPlaceholder: string = '';
    @Input() primaryToggleText: string = 'Show Completed';
    @Input() secondaryToggleText: string = 'Show Cancelled';
    @Input() buttons: SWFTSearchBarButton[] = [];
    @Input() clearSearch?: Subject<any>;
    @Input() shrink: boolean = false;

    @Output() search = new EventEmitter<string>();
    @Output() primaryToggle = new EventEmitter<boolean>();
    @Output() secondaryToggle = new EventEmitter<boolean>();
    @Output() dateChanged = new EventEmitter<Date>();
    @Output() dropdownSelected = new EventEmitter<string>();

    searchString = '';
    unFocused: Subject<void> = new Subject<void>();
    dateThroughString = new Date().toString();

    dateThroughControl = new UntypedFormControl(this.dateThroughString);

    dateFormat: string = DateFormat;

    private debouncer: any = undefined;
    private onDestroy: Subject<void> = new Subject<void>();
    constructor() {
        this.dateThroughControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(dateString => {
            this.dateThroughString = dateString;
        });
    }
    ngOnInit(): void {
        this.setDefaultDate();
        this.clearSearch?.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.searchString = '';
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    emitSearch(target: any) {
        target = target as HTMLInputElement;
        if (this.debouncer) clearTimeout(this.debouncer);

        this.debouncer = setTimeout(() => {
            this.search.emit(target.value);
            this.debouncer = undefined;
        }, 500);
    }
    emitPrimaryToggle(target: any) {
        this.primaryToggle.emit(target.checked);
    }
    emitSecondaryToggle(target: any) {
        this.secondaryToggle.emit(target.checked);
    }

    clearDateString() {
        this.dateThroughString = '';
        this.dateChanged.emit(undefined);
    }

    setDefaultDate() {
        const today = new Date();
        const todayPlus10Days = today.setDate(today.getDate() + 10);
        const todayPlus10DaysDate = new Date(todayPlus10Days);

        this.dateThroughControl.patchValue(todayPlus10DaysDate);
        this.dateThroughString = this.dateThroughControl.value;

        this.dateChanged.emit(todayPlus10DaysDate);
    }

    onDateChanged(event: any) {
        this.dateChanged.emit(event.target.value);
    }

    hasSearch(): boolean {
        return this.search.observed;
    }
}
