import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PermissionName, PermissionType } from 'src/app/models/auth/authorization.models';
import { GridAction } from 'src/app/models/grid.models';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'swft-actions-header',
    templateUrl: './swft-actions-header.component.html',
    styleUrls: ['./swft-actions-header.component.scss'],
})
export class SwftActionsHeaderComponent implements AfterViewInit {
    @Input() permissionName: PermissionName | undefined;
    @Input() displayedActions: GridAction[] = [];
    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;

    @Output() checkAllBoxes = new EventEmitter<boolean>();

    @Output() edit = new EventEmitter();
    @Output() view = new EventEmitter();
    @Output() duplicate = new EventEmitter();
    @Output() remove = new EventEmitter();
    @Output() conversation = new EventEmitter();
    @Output() selections = new EventEmitter();
    @Output() gridAction = new EventEmitter();

    permissionType = PermissionType;
    GridAction = GridAction;

    showActions: boolean = false;

    visibleButtons: EventEmitter<any>[] = [];
    actions: string[] = [];

    dropDownListControl: UntypedFormControl = new UntypedFormControl();

    constructor(private auth: AuthService, private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.parentElement.classList.add('swft-actions-column');
    }

    /**
     * Checks if the user has permission to perform the action associated with the action column button.
     * If the user does not have permission, return false.
     * If the user has permission, check to see if the button has an attached observer (i.e. there is
     * something listening to the button's click event) and if so, return true; otherwise, return false.
     * If any button is shown, the actions column will be shown AfterViewInit.
     * @param outputEmitter The event emitter associated with the action column button.
     * @param permission The permission associated with the action column button.
     * @returns boolean
     */
    showButton(
        outputEmitter: EventEmitter<null>,
        permission: PermissionType | null = null,
        action: GridAction
    ): boolean {
        //no permission given means it should show if needed
        const hasPermision =
            this.permissionName && permission ? this.auth.hasValidPermission(this.permissionName, permission) : true;

        if (hasPermision && (outputEmitter.observed || this.displayedActions.includes(action))) {
            this.visibleButtons.push(outputEmitter);
            this.actions.push(outputEmitter.toString());
            this.showActions = true;
            return true;
        }

        return false;
    }

    onCheckBoxClicked(event: MatCheckboxChange): void {
        const action: GridAction = event.checked ? GridAction.Select : GridAction.Deselect;
        this.checkAllBoxes.emit(event.checked);
        this.gridAction.emit(action);
    }

    onBulkActionSelected(actionName: GridAction): void {
        switch (actionName) {
            case GridAction.View:
                this.view.emit();
                break;
            case GridAction.Conversation:
                this.conversation.emit();
                break;
            case GridAction.Edit:
                this.edit.emit();
                break;
            case GridAction.Copy:
                this.duplicate.emit();
                break;
            case GridAction.Remove:
                this.remove.emit();
                break;
        }

        this.gridAction.emit(actionName);

        // reset the drop down
        this.dropDownListControl.setValue('---');
    }
}
