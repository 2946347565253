import { InjectionToken } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { EditorMode } from 'src/app/models/editor.models';

export interface WithId {
    id?: number;
}

/**
 * The base state object. The only property that is set here `item` is a generic type.
 * @type {T extends WithId}
 */
export interface StateBase<T extends WithId> {
    initialItem?: T;
    item: T;
    editorMode: EditorMode;
    canAddLink: boolean;
    loading: boolean;
}

/**
 * Intended to be used as a base state interface for any
 * services that will implement a Temporary ID for created entities.
 */
export interface TempIdBase<T extends WithId> extends StateBase<T> {
    tempId: number;
}

/**
 * Interface for SWFT related component stores
 */
export interface ISwftComponentStore {
    navLinkSelector: MemoizedSelector<object, any, any>;

    /**
     * Method to load the data via the store.
     */
    loadFromStore: (observableOrValue: string | number | Observable<string | number>) => Subscription;

    /**
     * Method for loading an item from the API.
     * @param id ID of the item we are intending to load
     */
    load(id: number): any;

    updateItem: any extends void ? () => void : (observableOrValue: any | Observable<any>) => Subscription;

    updateEditorMode?: EditorMode extends void
        ? () => void
        : (observableOrValue: EditorMode | Observable<EditorMode>) => Subscription;
}

/**
 * @abstract
 * Abstract class for implementing Component Stores in SWFT.
 *
 * Type arguments are:
 * @type {T} - Type of the `item` to be saved. Must have an ID
 * TState @type {StateBase<T>} - Type of the state, must have an `item`
 * TLink @type {LeftNavLink} - Type of left nav link
 */
export abstract class SwftComponentStore<T extends WithId, TState extends StateBase<T>, TLink>
    extends ComponentStore<TState>
    implements ISwftComponentStore
{
    abstract viewOnly$: Observable<boolean>;

    abstract navLinkSelector: MemoizedSelector<object, TLink[], DefaultProjectorFn<TLink[]>>;
    abstract loadFromStore: (observableOrValue: string | number | Observable<string | number>) => Subscription;
    abstract load: (id: Observable<number> | number) => Subscription;
    abstract updateItem: Partial<T> extends void
        ? () => void
        : (observableOrValue: Partial<T> | Observable<Partial<T>>) => Subscription;
    abstract updateEditorMode?: EditorMode extends void
        ? () => void
        : (observableOrValue: EditorMode | Observable<EditorMode>) => Subscription;

    abstract dispatchNavLink(): void;
}

/**
 * CONSTANT to be used as an InjectionToken in the Angular modules
 */
export const SWFT_COMPONENT_STORE_TOKEN = new InjectionToken<ISwftComponentStore>('ISwftComponentStore');
