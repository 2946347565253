import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeOfDay } from 'src/app/enums/datetime.enums';
import { DataDictionarySearch, ExpressionEngineTypes } from 'src/app/models/data-dictionary.model';
import { DateTimeConditionValue, LogicModifier } from 'src/app/models/logic.models';

@Component({
    selector: 'swft-logic-datetime',
    templateUrl: './logic-datetime.component.html',
    styleUrls: ['./logic-datetime.component.scss'],
})
export class LogicDatetimeComponent {
    @Input() resource: DataDictionarySearch | undefined;
    @Input() operatorSelected: boolean = false;
    @Input() valueLabel: string = 'Date';
    @Input() groupType: LogicModifier | undefined;
    @Output() valueChanges: EventEmitter<DateTimeConditionValue> = new EventEmitter<DateTimeConditionValue>();

    dateTime: DateTimeConditionValue = { timeOfDay: TimeOfDay.AM };
    TimeOfDay = TimeOfDay;
    ExpressionEngineTypes = ExpressionEngineTypes;

    valueChanged() {
        this.valueChanges.emit(this.dateTime);
    }

    updateTimeOfDay(timeOfDay: TimeOfDay) {
        this.dateTime.timeOfDay = timeOfDay;
        this.valueChanged();
    }
}
