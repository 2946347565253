<div #errorContainer>
    <section
        class="form-errors"
        [class.float]="float && floatable && hasErrors"
        [class.show]="hasErrors">
        <p
            *ngFor="let error of formGroupErrors"
            class="form-error">
            {{ error.message }}
        </p>

        <p
            *ngFor="let error of formErrors"
            (click)="goToControl($event.target, error.controlName)">
            <span [innerHTML]="error.controlName"></span>
            <span [innerHTML]="error.message"></span>
            <span>▲</span>
        </p>
    </section>
</div>
