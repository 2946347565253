import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { DefaultContact, HcpContact } from 'src/app/models/profile/contact.model';
import { PatientPhysician } from 'src/app/models/profile/physician.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { PhoneNumberRegex } from 'src/app/utils/constants';

interface HcpContactForm {
    firstName: FormControl<string | null>;
    lastNameOrInitial: FormControl<string | null>;
    organization: FormControl<string | null>;
    title: FormControl<string | null>;
    phone: FormControl<string | null>;
}

@Component({
    selector: 'swft-hipaa-hcp',
    templateUrl: './hipaa-hcp.component.html',
    styleUrls: ['./hipaa-hcp.component.scss'],
})
export class HipaaHCPComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    hcps$?: Observable<PatientPhysician[]>;
    selectedHcp?: PatientPhysician;
    otherSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    formGroup: FormGroup<HcpContactForm> = new FormGroup<HcpContactForm>({
        firstName: new FormControl(null, Validators.required),
        lastNameOrInitial: new FormControl(null, Validators.required),
        organization: new FormControl(null, Validators.required),
        title: new FormControl(null, Validators.required),
        phone: new FormControl(null, [Validators.required, Validators.pattern(PhoneNumberRegex)]),
    });
    selectionMade$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly _otherOption = 'other';

    constructor(private hipaaService: HipaaService, private patientService: PatientService) {}

    ngOnInit(): void {
        const orgControl = this.formGroup.controls['organization'];
        this.otherSelected$.pipe(takeUntil(this.onDestroy$)).subscribe(selected => {
            if (selected) orgControl.setValidators(Validators.required);
            else orgControl.removeValidators(Validators.required);
        });

        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            if (!this.hcps$ && data.patient.id) {
                this.hcps$ = this.patientService.getPatientPhysicians(data.patient.id);
            }
        });
        // Update the HIPAA data's validate contact as form values change
        this.formGroup.valueChanges
            .pipe(
                tap(() => {
                    // create a new contact
                    let contact: HcpContact = {
                        ...new DefaultContact(),
                        hcpId: 0,
                        rank: 0,
                        address1: '',
                        phoneNumber: this.formGroup.controls['phone'].value || '',
                        workPhone: this.formGroup.controls['phone'].value || '',
                        faxNumber: '',
                        zipCode: '',
                        firstName: this.formGroup.controls['firstName'].value || '',
                        lastName: this.formGroup.controls['lastNameOrInitial'].value || '',
                        title: this.formGroup.controls['title'].value || '',
                        organization: this.formGroup.controls['organization'].value || '',
                    };

                    if (!this.hipaaService.hcpContact) {
                        const physicianName = `${this.formGroup.controls['firstName'].value} ${this.formGroup.controls['lastNameOrInitial'].value} ${this.formGroup.controls['title'].value}`;
                        this.hipaaService.hcpContact = {
                            address: contact.address,
                            phone: contact.phoneNumber,
                            city: '',
                            email: '',
                            physicianName: physicianName,
                            fax: '',
                            license: '',
                            mrn: 0,
                            npi: '',
                            organization: this.formGroup.controls['organization'].value || '',
                            phoneExtension: '',
                            remsId: '',
                            specialty: '',
                            state: '',
                            supervisingPhysician: '',
                            zip: '',
                        };
                    }
                    // if an hcp is selected, update contact with additional data
                    if (this.selectedHcp && !this.otherSelected$.value) {
                        contact = {
                            ...contact,
                            ...this.selectedHcp,
                            address1: this.selectedHcp.address,
                            phoneNumber: this.selectedHcp.phone,
                            workPhone: this.selectedHcp.phone,
                            faxNumber: this.selectedHcp.fax,
                            zipCode: this.selectedHcp.zip,
                        };
                    }
                    if (this.otherSelected$.value)
                        contact.organization = this.formGroup.controls['organization'].value || '';
                    this.hipaaService.validateContact(contact, HipaaVerificationType.HCP);
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Updates the HIPAA data, setting the selected HCP as the contact
     * If the "other" option is selected, require that the the organization field be filled out
     * If a different HCP is selected after the first, clear the form
     * @param selectEvent A MatSelectChange event from the HCP selector, its value is typically an HCP
     */
    selected(selectEvent: MatSelectChange) {
        const otherSelected = selectEvent.value === this._otherOption;
        const value = selectEvent.value as PatientPhysician;

        this.formGroup.reset();
        this.otherSelected$.next(otherSelected);
        this.selectionMade$.next(true);

        if (otherSelected) {
            this.selectedHcp = undefined;
            return;
        }

        this.selectedHcp = value;
        this.hipaaService.hcpContact = value;
        this.addSelectedHcpInfoToForm();
    }

    private addSelectedHcpInfoToForm() {
        if (!this.selectedHcp) return;
        const nameParts = this.selectedHcp.physicianName.replace(/[^\w\s]/gi, '').split(' '); //regex removes all non-alphanumeric characters except spaces
        this.formGroup.setValue({
            firstName: nameParts[0] || '',
            lastNameOrInitial: nameParts[1] || '',
            organization: '',
            title: nameParts[2] || '',
            phone: this.selectedHcp.phone || '',
        });
        this.formGroup.updateValueAndValidity();
    }
}
