<swft-searchbar
    [shrink]="true"
    (search)="search($event)"
    (dropdownSelected)="updateSearchModifier($event)"
    [dropdownPlaceholder]="dropdownPlaceholder"
    [dropdownValues]="dropdownValues"
    [dropdownLabel]="dropdownLabel"
    [placeholder]="placeholder"></swft-searchbar>

<ul class="select-search">
    <li
        *ngFor="let item of formattedItems; let i = index"
        (click)="onRowClick(i)"
        [innerHTML]="item"></li>
</ul>
