import { Endpoint, IEndpoint } from './endpoint';

export class HcpEndpoints {
    getById: IEndpoint = new Endpoint('get', 'v1/healthcareprovider/{id}');
    search: IEndpoint = new Endpoint('post', 'v1/healthcareprovider/search');
    addresses: IEndpoint = new Endpoint('post', 'v1/healthcareprovider/addresses');
    profile = new HcpProfileEndpoints();
}

export class HcpProfileEndpoints {
    getDemographics = new Endpoint('get', 'v1/healthcareproviderprofile/{hcpId}/demographics');
    getLicensure = new Endpoint('get', 'v1/healthcareproviderprofile/{hcpId}/licensure');
    getContacts = new Endpoint('get', 'v1/healthcareproviderprofile/{hcpId}/contacts');
    progressNotes = new Endpoint('post', 'v1/healthcareproviderprofile/{id}/progressnotes');
}
