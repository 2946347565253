export {};

declare global {
    interface String {
        padTime: () => string;
        containsAnyLetter: () => boolean;
        startsWithNumber: () => boolean;
        splice: (idx: number, rem: number, str: string) => string;
    }
}

String.prototype.padTime = function padTime(): string {
    return ('00' + this.valueOf()).substring(this.valueOf().length, this.valueOf().length + 2);
};

String.prototype.containsAnyLetter = function containsAnyLetter(): boolean {
    return /[a-zA-Z]/.test(this.valueOf());
};

String.prototype.startsWithNumber = function startsWithNumber(): boolean {
    return /^\d/.test(this.valueOf());
};

String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};
