import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pipeDelimiter',
})
export class PipeDelimiterPipe implements PipeTransform {
    constructor() {}

    transform(values: any[] = [], propertyName: string): string {
        if (!propertyName) return values.join(' | ');

        return values.map(value => value[propertyName]).join(' | ');
    }
}
