import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[swftTextareaAutoresize]',
})
export class TextareaAutoResizeDirective implements AfterViewChecked {
    private element = this.elementRef.nativeElement;
    private elementValue = null;
    constructor(private elementRef: ElementRef) {}

    ngAfterViewChecked(): void {
        const value = this.element.value;
        if (this.elementValue !== value) this.resize();
        this.elementValue = value;
    }

    onInput() {
        this.resize();
    }

    @HostListener('input', ['$event'])
    resize() {
        if (this.element.scrollHeight > this.element.clientHeight) {
            this.element.style.height = `${this.element.scrollHeight}px`;
        }
    }
}
