import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, filter, map, Observable, take } from 'rxjs';
import 'src/app/swft/prototypes/date-prototypes';
import { DateFormat } from 'src/app/utils/constants';

@Component({
    selector: 'swft-swft-datepicker',
    templateUrl: './swft-datepicker.component.html',
    styleUrls: ['./swft-datepicker.component.scss'],
})
export class SwftDatepickerComponent {
    selectedDate: BehaviorSubject<Date>;

    days: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    calcDate: Observable<Date>;

    DateFormat: string = DateFormat;

    constructor(
        private dialogRef: MatDialogRef<SwftDatepickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { date: Date }
    ) {
        this.selectedDate = new BehaviorSubject<Date>(this.data.date || new Date());

        this.calcDate = combineLatest([this.days, this.selectedDate]).pipe(
            filter(([selectedDate]) => !!this.selectedDate),
            map(([days]) => this.addDays(days))
        );
    }

    setDays(event: any): void {
        this.days.next(Number(event.target.value));
    }

    addDays(event: any): Date {
        return new Date(this.selectedDate.value.addDays(this.days.value));
    }

    save(): void {
        this.calcDate.pipe(take(1)).subscribe(p => this.dialogRef.close(p));
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
