import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PermissionName, PermissionNames, PermissionType } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'swft-toolbar',
    templateUrl: './swft-toolbar.component.html',
    styleUrls: ['./swft-toolbar.component.scss'],
})
export class SwftToolbarComponent implements OnInit, OnDestroy {
    @Input() title: string = '';
    @Input() permissionName: PermissionName | undefined;
    @Input() showOffRamp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Output() create = new EventEmitter<null>();
    @Output() edit = new EventEmitter<null>();
    @Output() orderNarrative = new EventEmitter<null>();
    @Output() patientNarrative = new EventEmitter<null>();
    @Output() hcpNarrative = new EventEmitter<null>();
    @Output() specialIdentifier = new EventEmitter<null>();
    @Output() offRamp = new EventEmitter<null>();
    @Output() assignActions = new EventEmitter<null>();

    permissionType = PermissionType;
    specialIdPermissionName = PermissionNames.PatientSpecialIdentifier as PermissionName;

    showToolbar: boolean = false;
    offRampButton = false;
    private onDestroy = new Subject<void>();

    constructor(private auth: AuthService) {}

    ngOnInit(): void {
        this.showOffRamp.pipe(takeUntil(this.onDestroy)).subscribe(e => {
            if (e) this.offRampButton = true;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
    /**
     * Checks if the user has permission to perform the action associated with the toolbar button.
     * If the user does not have permission, return false.
     * If the user has permission, check to see if the button has an attached observer (i.e. there is
     * something listening to the button's click event) and if so, return true; otherwise, return false.
     * If any button is shown, the toolbar will be shown.
     * @param outputEmitter The event emitter associated with the toolbar button.
     * @param permission The permission associated with the toolbar button.
     * @returns boolean
     */
    showButton(
        outputEmitter: EventEmitter<null>,
        permission: PermissionType,
        buttonPermissionName?: PermissionName
    ): boolean {
        if (!permission) return false;

        const buttonPermission = buttonPermissionName ?? this.permissionName;

        const hasPermision: boolean = buttonPermission
            ? this.auth.hasValidPermission(buttonPermission, permission)
            : true;

        if (hasPermision && outputEmitter.observed) {
            this.showToolbar = true;
            return true;
        }

        return false;
    }
    showOffRampButton(outputEmitter: EventEmitter<null>, permission: PermissionType): boolean {
        if (!permission) return false;

        const hasPermision: boolean = this.permissionName
            ? this.auth.hasValidPermission(this.permissionName, permission)
            : true;

        if (this.offRampButton && hasPermision && outputEmitter.observed) {
            this.showToolbar = true;
            return true;
        }
        return false;
    }
}
