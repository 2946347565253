<section [class.show]="!askForUsername && !showFrame">
    <h1 mat-dialog-title>Your session has expired!</h1>
    <p>Authenticate with duo to stay logged in.</p>
    <swft-confirm
        confirmText="Authenticate with Duo"
        (confirm)="initAuth()"
        (deny)="logout()"
        denyText="Log out"
        denyWarning="Logging out will cause all unsaved changes to be lost. Are you sure?"></swft-confirm>
</section>

<section [class.show]="askForUsername">
    <h1 mat-dialog-title>Username required</h1>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Username</mat-label>
        <input
            matInput
            type="text"
            [(ngModel)]="username"
            placeholder="Username" />
    </mat-form-field>
    <button
        (click)="initAuth()"
        mat-raised-button
        color="accent">
        Continue
    </button>
</section>

<section [class.show]="showFrame">
    <h1 mat-dialog-title>Duo Authentication</h1>
    <iframe
        (load)="iframeLoaded()"
        #DuoFrame
        src="#"></iframe>
</section>

<swft-spinner
    coverId="RefreshTokenDialog"
    [show]="isLoading"></swft-spinner>
