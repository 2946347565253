import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { DataDictionaryService } from 'src/app/components/admin/builders/data-dictionary/data-dictionary.service';
import { ConfirmationDialogComponent } from 'src/app/components/swft/swft-confirmation-dialog/confirmation-dialog.component';
import { SearchOptions, SelectSearchValues } from 'src/app/models/api/api-search.models';
import { DataDictionarySearch, ExpressionEngineTypes } from 'src/app/models/data-dictionary.model';
import { SwftBasis } from 'src/app/utils/constants';
import { SwftSelectSearchDirective } from '../select-search-modal.directive';

@Directive({
    selector: '[swftDynamicTag]',
})
export class SwftDynamicTagDirective extends SwftSelectSearchDirective implements OnInit, OnDestroy {
    @Input() basis$: Observable<SwftBasis[]> = of([]);

    @Input() override searchPlaceholder = 'Search Dynamic Tags';
    @Input() override searchOrderBy = 'alias';
    @Input() override searchDropdownLabel = 'ExpressionEngine Type';
    @Input() override searchDropdownPlaceholder = 'Type';
    @Input() override searchWidth = '400px';
    @Input() override searchValueFormat = '{id} · {alias} · {expressionEngineType}';
    @Input() override searchDropdownValues: SelectSearchValues = {
        name: 'ExpressionEngineType',
        values: Object.values(ExpressionEngineTypes),
    };

    defaultOptions: SearchOptions = {
        ascending: true,
        orderBy: 'alias',
        page: 0,
        pageLength: 25,
        value: '',
    };

    isSelectSearchOpen = false;

    count = 0;
    endTag = '';
    warnUser = true;

    override fetch = this.dataDictionary.searchDataDictionaryDynamicTagsWithBasis.bind(this.dataDictionary);

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        override elementRef: ElementRef<HTMLElement>,
        override dialog: MatDialog,
        @Self() public ngControl: NgControl,
        private dataDictionary: DataDictionaryService
    ) {
        super(elementRef, dialog);
    }
    @HostListener('keydown.[', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        this.count++;

        if (!(this.count % 2)) {
            //warn the user to select a basis in the instance basis is used when searching

            if (this.warnUser) return this.warningDiaglog();

            this.isSelectSearchOpen = true;

            if (this.ngControl && this.ngControl.value && this.ngControl.value[this.ngControl.value.length - 1] === '>')
                this.endTag = this.findEndHtmlTag(this.ngControl.value);

            this.openSelectSearch();
        }
    }

    override ngOnInit(): void {
        this.basis$
            .pipe(
                takeUntil(this.onDestroy$),
                tap(basis => {
                    if (basis && basis.length >= 1 && basis[0] !== '') this.warnUser = false;
                    this.basis = basis;
                })
            )
            .subscribe();

        this.selectionChanged
            .pipe(
                takeUntil(this.onDestroy$),
                tap((resource: DataDictionarySearch) => {
                    this.isSelectSearchOpen = false;
                    if (!resource) return;
                    const control = this.ngControl.control;
                    let value = control?.value as String;
                    if (this.endTag) value = value.slice(0, value.length - this.endTag.length);
                    this.endTag
                        ? control?.patchValue(`${value}${resource.alias}]]${this.endTag}`)
                        : control?.patchValue(`${value}${resource.alias}]]`);
                })
            )
            .subscribe();
    }

    /*
     *
     * edge case of quill editors will insert an html tag after unfocusing
     */
    private findEndHtmlTag(value: string): string {
        let tag = '';
        for (let i = value.length - 1; i > 0; i--) {
            tag = tag.padStart(tag.length + 1, value[i]);
            if (value[i] === '<') break;
        }
        return tag;
    }

    private warningDiaglog() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                panelClass: 'small-auto',
                data: {
                    message: 'Select a Basis',
                    description: 'Before adding a dynamic tag you must select a basis',
                    okOnly: true,
                },
            })
            .afterClosed()
            .subscribe(() => {
                const value = this.ngControl.control?.value.replaceAll('[[', '');
                this.ngControl.control?.patchValue(value);
            });
    }
}
