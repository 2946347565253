import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { CoveredEntityContact, DefaultContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PhoneNumberRegex } from 'src/app/utils/constants';

interface CoveredEntityContactForm {
    firstName: FormControl<string | null>;
    lastNameOrInitial: FormControl<string | null>;
    organization: FormControl<string | null>;
    phone: FormControl<string | null>;
    title: FormControl<string | null>;
}

@Component({
    selector: 'swft-hipaa-covered-entity',
    templateUrl: './hipaa-covered-entity.component.html',
    styleUrls: ['./hipaa-covered-entity.component.scss'],
})
export class HipaaCoveredEntityComponent implements OnInit, OnDestroy {
    patient?: PatientDemographic;
    patientDisplayValue?: string;

    formGroup: FormGroup<CoveredEntityContactForm> = new FormGroup<CoveredEntityContactForm>({
        firstName: new FormControl(null, Validators.required),
        lastNameOrInitial: new FormControl(null, Validators.required),
        organization: new FormControl(null, Validators.required),
        phone: new FormControl(null, [Validators.required, Validators.pattern(PhoneNumberRegex)]),
        title: new FormControl(null, Validators.required),
    });
    private onDestroy = new Subject<void>();

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            if (!data.patient.id) return;
            this.patient = data.patient;
            this.patientDisplayValue = this.hipaaService.patientDisplayName;
        });

        this.formGroup.valueChanges?.pipe(takeUntil(this.onDestroy), debounceTime(300)).subscribe(() => {
            this.updateHipaaContact();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private updateHipaaContact() {
        let contact: CoveredEntityContact = {
            ...new DefaultContact(),
            firstName: this.formGroup.controls['firstName'].value || '',
            lastName: this.formGroup.controls['lastNameOrInitial'].value || '',
            title: this.formGroup.controls['title'].value || '',
            workPhone: this.formGroup.controls['phone'].value || '',
            organization: this.formGroup.controls['organization'].value || '',
        };
        this.hipaaService.validateContact(contact, HipaaVerificationType.CoveredEntity);
    }
}
