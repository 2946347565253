import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import { Patient, PatientAlerts, PatientNoteType, PatientUserDefined, SwftNote } from 'src/app/models/patient.model';
import { PatientAllergies } from 'src/app/models/profile/allergies.model';
import { PatientContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { PatientDiagnosis } from 'src/app/models/profile/diagnosis.model';
import { PatientDischarge } from 'src/app/models/profile/discharge.model';
import { PatientDispense, PatientDispenseLot } from 'src/app/models/profile/dispense.models';
import { PatientHtWt } from 'src/app/models/profile/htwt.model';
import { PatientMedHist } from 'src/app/models/profile/medical-history.model';
import { PatientNonPantherMediation } from 'src/app/models/profile/nonpanther-medication.model';
import { PatientOrder } from 'src/app/models/profile/order.model';
import { ConfirmedDeliveryTicket, DeliveryTicket } from 'src/app/models/profile/patient-delivery-ticket.model';
import { PatientPayor } from 'src/app/models/profile/payor.model';
import { PatientPhysician } from 'src/app/models/profile/physician.model';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { PatientApiService } from 'src/app/services/api/patient-api.service';
import { EmptyPaginatorResults } from 'src/app/utils/constants';
import { mapFromSearchResult, parseSearchOffset } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { CruApi } from '../interfaces/crud.interface';
import { PatientAdapter } from './patient.adapter';

@Injectable({
    providedIn: 'root',
})
export class PatientService implements CruApi<Patient> {
    currentPatient$: BehaviorSubject<Patient | undefined> = new BehaviorSubject<Patient | undefined>(undefined);

    constructor(private patientAdapter: PatientAdapter, private api: PatientApiService) {}

    page(request: PageRequest<Patient>, query: SingleSearchTermQuery): Observable<Page<Patient>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };
        if (query.search) {
            return this.api
                .getObservableEndpoint(environment.apiPatient.endpoints.search)(params)
                .pipe(
                    map(data => {
                        return {
                            content: data.results.map((item: any) => this.patientAdapter.adapt(item)),
                            totalElements: data.total,
                            size: data.pageLength,
                            number: data.page,
                        };
                    })
                );
        }
        return EmptyPaginatorResults as Observable<Page<Patient>>;
    }

    pageWithDemographics(
        request: PageRequest<PatientDemographic>,
        query: SingleSearchTermQuery
    ): Observable<Page<PatientDemographic>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };
        if (query.search) {
            return this.api
                .getObservableEndpoint(environment.apiPatient.endpoints.searchDemographics)(params)
                .pipe(mapFromSearchResult);
        }
        return EmptyPaginatorResults as Observable<Page<PatientDemographic>>;
    }

    read(patientId: number): Observable<Patient> {
        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.getById)(null, { id: patientId })
            .pipe(tap(patient => this.currentPatient$.next(patient)));
    }

    create(item: Patient): Observable<Patient> {
        throw Error('Patient create not implemented');
    }

    update(item: Patient): Observable<Patient> {
        throw Error('Patient update not implemented');
    }

    getPatientNotes(
        patientId: number,
        noteType: PatientNoteType,
        request: PageRequest<SwftNote>,
        query: SingleSearchTermQuery
    ): Observable<Page<SwftNote>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: parseSearchOffset(query),
        };

        var response: Observable<any>;

        switch (noteType) {
            case 'billingNotes':
                response = this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.billingNotes)(
                    params,
                    { patientId }
                );
                break;

            case 'progressNotes':
                response = this.api.getObservableEndpoint(
                    environment.apiPatient.endpoints.patientProfile.progressNotes
                )(params, { patientId });
                break;

            default:
                return {} as Observable<Page<SwftNote>>;
        }

        return response.pipe(
            map(data => {
                return {
                    content: data.results,
                    totalElements: data.total,
                    size: data.pageLength,
                    number: data.page,
                };
            })
        );
    }

    getPatientDemographic(patientId: number): Observable<PatientDemographic> {
        const params = { patientId };

        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getDemographic)(null, params)
            .pipe(map(result => this.patientAdapter.adaptDemographic(result)));
    }

    getPatientContacts(patientId: number): Observable<PatientContact[]> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getContacts)(
            null,
            params
        );
    }

    getPatientPayors(patientId: number): Observable<PatientPayor[]> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getPayors)(null, params);
    }

    getPatientHtWtHistory(patientId: number): Observable<PatientHtWt[]> {
        const params = { patientId };
        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getHtWtHistory)(null, params)
            .pipe(
                map((patientHtWts: PatientHtWt[]) =>
                    patientHtWts.map(patientHtWt => this.setFormattedHeightWeight(patientHtWt))
                )
            );
    }

    private setFormattedHeightWeight(patientHtWt: PatientHtWt): PatientHtWt {
        return <PatientHtWt>{
            ...patientHtWt,
            bmi: Number(patientHtWt.bmi).toFixed(2),
            heightCombined: `${Number(patientHtWt.height).toFixed(2)} in / ${Number(patientHtWt.heightCm).toFixed(
                2
            )} cm`,
            weightCombined: `${Number(patientHtWt.weight).toFixed(2)} lbs / ${Number(patientHtWt.weightKg).toFixed(
                2
            )} kg`,
        };
    }

    getPatientMedicalHistory(patientId: number): Observable<PatientMedHist> {
        const params = { patientId };
        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getMedicalHistory)(null, params)
            .pipe(map(result => this.calculatedBodyWeights(result)));
    }

    private calculatedBodyWeights(res: any): PatientMedHist {
        for (let [key, value] of Object.entries(res)) {
            if (typeof value !== 'string' && key !== 'mrn') {
                res[key] = Number(Number(value).toFixed(2));
            }

            res[key] = value || 'N/A';
        }
        return {
            ...res,
            bsaMosteller: this.calculateBSA(Number(res.heightCm), Number(res.weightKg), 'Mosteller'),
            bsaDuBois: this.calculateBSA(Number(res.heightCm), Number(res.weightKg), 'DuBois'),
        };
    }

    calculateBSA(heightCm: number, weightKg: number, formulaType: string): number {
        const mostellerFormula: number = Math.sqrt((heightCm * weightKg) / 3600);
        const duBoisFormula: number = 0.007184 * Math.pow(heightCm, 0.725) * Math.pow(weightKg, 0.425);
        return Number((formulaType === 'Mosteller' ? mostellerFormula : duBoisFormula).toFixed(2));
    }

    convertHeight(metric: string, height: number | null): number {
        return height !== null ? Number((metric === 'IN' ? height / 2.54 : height * 2.54).toFixed(2)) : 0;
    }

    convertWeight(metric: string, weight: number | null): number {
        return weight !== null ? Number((metric === 'LBS' ? weight / 0.45359237 : weight * 0.45359237).toFixed(2)) : 0;
    }

    getPatientAllergies(patientId: number): Observable<PatientAllergies> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getAllergies)(
            null,
            params
        );
    }

    getPatientDiagnosis(patientId: number): Observable<PatientDiagnosis[]> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getDiagnosis)(
            null,
            params
        );
    }

    getPatientNonPantherMedication(
        patientId: number,
        showDiscontinuedOrders: boolean
    ): Observable<PatientNonPantherMediation[]> {
        const params = { patientId, showDiscontinuedOrders };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getNonPantherMedication)(
            null,
            params
        );
    }

    searchPatientNonPantherMedication(
        patientId: number,
        showDiscontinuedOrders: boolean,
        request: PageRequest<PatientNonPantherMediation>,
        query: SingleSearchTermQuery
    ): Observable<Page<PatientNonPantherMediation>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };
        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.searchNonPantherMedication)(params, {
                patientId,
                showDiscontinuedOrders,
            })
            .pipe(
                map(result => ({
                    content: result.results,
                    totalElements: result.total,
                    size: result.pageLength,
                    number: result.page,
                }))
            );
    }

    getPatientPhysicians(patientId: number): Observable<PatientPhysician[]> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getPhysicians)(
            null,
            params
        );
    }

    getPatientOrders(patientId: number, showDiscontinuedOrders: boolean): Observable<PatientOrder[]> {
        const params = { patientId, showDiscontinuedOrders };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.orders)(null, params);
    }

    getPatientUserDefined(patientId: number): Observable<PatientUserDefined> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.userDefined)(
            null,
            params
        );
    }

    getPatientDischarges(
        patientId: number,
        request: PageRequest<PatientDischarge>,
        query: SingleSearchTermQuery
    ): Observable<Page<PatientDischarge>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property,
            value: query.search,
        };

        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.discharges)(params, { patientId })
            .pipe(
                map(result => ({
                    content: result.results,
                    totalElements: result.total,
                    size: result.pageLength,
                    number: result.page,
                }))
            );
    }

    getPatientDispenses(
        patientId: number,
        showVoided: boolean,
        request: PageRequest<PatientDispense>,
        query: SingleSearchTermQuery
    ): Observable<Page<PatientDispense>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };

        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.dispenses)(params, {
                patientId,
                showVoided,
            })
            .pipe(
                map(result => ({
                    content: this.combineLotRows(result.results),
                    totalElements: result.total,
                    size: result.pageLength,
                    number: result.page,
                }))
            );
    }

    combineLotRows(rows: PatientDispense[]): PatientDispense[] {
        let dispenses: PatientDispense[] = [];

        rows.forEach(dispense => {
            let existingEntry = dispenses.filter(d => d.labelLogNumber == dispense.labelLogNumber)[0];
            let entryToUpdateLot = existingEntry ?? dispense;

            let lotEntry = <PatientDispenseLot>{
                lot: dispense.lot,
                lotQuantity: dispense.lotQuantity,
                lotExpirationDate: dispense.lotExpirationDate,
                dispensingLocation: dispense.dispensingLocation,
                nextAnticipatedFillDate: dispense.nextAnticipatedFillDate,
                prescriberName: dispense.prescriberName,
                ticketConfirmedDate: dispense.ticketConfirmedDate,
                deliveryPromisedDate: dispense.deliveryPromisedDate,
            };

            if (!entryToUpdateLot.lots) {
                entryToUpdateLot.lots = [];
            }
            entryToUpdateLot.lots.push(lotEntry);

            if (!existingEntry) dispenses.push(dispense);
        });

        return dispenses;
    }

    getWorkingDeliveryTickets(patientId: number): Observable<DeliveryTicket[]> {
        const params = { patientId };
        return this.api.getObservableEndpoint(
            environment.apiPatient.endpoints.patientProfile.getWorkingDeliveryTickets
        )(null, params);
    }

    readPatientAlerts(patientId: number): Observable<PatientAlerts> {
        const params = { patientId };
        return this.api.getObservableEndpoint(environment.apiPatient.endpoints.readPatientAlerts)(null, params);
    }

    searchConfirmedDeliveryTickets(
        patientId: number,
        request: PageRequest<ConfirmedDeliveryTicket>,
        query: SingleSearchTermQuery
    ): Observable<Page<ConfirmedDeliveryTicket>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };

        return this.api
            .getObservableEndpoint(environment.apiPatient.endpoints.patientProfile.getConfirmedDeliveryTickets)(
                params,
                { patientId }
            )
            .pipe(
                map(result => ({
                    content: result.results,
                    totalElements: result.total,
                    size: result.pageLength,
                    number: result.page,
                }))
            );
    }
}
