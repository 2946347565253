import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import { Doctor } from 'src/app/models/doctor.model';
import { HealthCareProvider } from 'src/app/models/hcp.model';
import { SwftNote } from 'src/app/models/patient.model';
import { HcpContact } from 'src/app/models/profile/contact.model';
import { HcpDemographics } from 'src/app/models/profile/demographic.model';
import { HcpLicensure } from 'src/app/models/profile/licensure.model';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { mapFromSearchResult, parseSearchOffset } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { HcpApiService } from '../api/hcp-api.service';
import { HcpAdapter } from './hcp.adapter';

@Injectable({
    providedIn: 'root',
})
export class HcpService {
    constructor(private hcpAdapter: HcpAdapter, private api: HcpApiService) {}

    get(id: number): Observable<Doctor> {
        return this.api.getObservableEndpoint(environment.apiHealthCareProvider.endpoints.getById)(null, { id });
    }

    page(request: PageRequest<HealthCareProvider>, query: SingleSearchTermQuery): Observable<Page<HealthCareProvider>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
        };

        return this.api
            .getObservableEndpoint(environment.apiHealthCareProvider.endpoints.search)(params)
            .pipe(
                map(data => {
                    return {
                        content: data.results.map((item: any) => this.hcpAdapter.adapt(item)),
                        totalElements: data.total,
                        size: data.pageLength,
                        number: data.page,
                    };
                })
            );
    }

    getHcpDemographics(hcpId: number): Observable<HcpDemographics> {
        return this.api.getObservableEndpoint(environment.apiHealthCareProvider.endpoints.profile.getDemographics)(
            null,
            { hcpId }
        );
    }

    getHcpLicensure(hcpId: number): Observable<HcpLicensure> {
        return this.api.getObservableEndpoint(environment.apiHealthCareProvider.endpoints.profile.getLicensure)(null, {
            hcpId,
        });
    }

    getHcpContacts(hcpId: number): Observable<HcpContact[]> {
        return this.api.getObservableEndpoint(environment.apiHealthCareProvider.endpoints.profile.getContacts)(null, {
            hcpId,
        });
    }

    getProgressNotes(
        id: number,
        request: PageRequest<SwftNote>,
        query: SingleSearchTermQuery
    ): Observable<Page<SwftNote>> {
        const params: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: parseSearchOffset(query),
        };

        return this.api
            .getObservableEndpoint(environment.apiHealthCareProvider.endpoints.profile.progressNotes)(params, {
                hcpId: id,
            })
            .pipe(mapFromSearchResult);
    }
}
