import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import { FormBasis, FormSearch, FormType, SwftForm } from 'src/app/models/form.models';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { mapFromSearchResult } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { FormBuilderApiService } from '../api/form-builder-api.service';
import { CruApi } from '../interfaces/crud.interface';

@Injectable({
    providedIn: 'root',
})
export class FormService implements CruApi<SwftForm> {
    private formTypes$: BehaviorSubject<FormType[] | undefined> = new BehaviorSubject<FormType[] | undefined>(
        undefined
    );
    private formBases$: BehaviorSubject<FormBasis[] | undefined> = new BehaviorSubject<FormBasis[] | undefined>(
        undefined
    );

    constructor(private api: FormBuilderApiService) {}

    /**
     * Gets a page of forms
     * @param request
     * @param query
     * @returns search results - Observable<Page<FormSearch>>
     */
    page(request: PageRequest<FormSearch>, query: SingleSearchTermQuery): Observable<Page<FormSearch>> {
        const params: SearchOptions = {
            value: query.search,
            ascending: request.sort?.order! == SortOrder.Ascending,
            orderBy: request.sort?.property!,
            page: request.page,
            pageLength: request.size,
        };

        return this.api
            .getObservableEndpoint(environment.apiFormBuilder.endpoints.form.search)(params)
            .pipe(mapFromSearchResult);
    }

    /**
     * Gets a page of forms by form basis
     * @param request
     * @param query
     * @param formbasis
     * @returns search results - Observable<Page<FormSearch>>
     */
    pageByFormBasis(
        request: PageRequest<FormSearch>,
        query: SingleSearchTermQuery,
        formbasis: string[]
    ): Observable<Page<FormSearch>> {
        const params = {
            value: query.search,
            ascending: request.sort?.order! == SortOrder.Ascending,
            orderBy: request.sort?.property!,
            page: request.page,
            pageLength: request.size,
            formBasis: formbasis,
        };
        return this.api
            .getObservableEndpoint(environment.apiFormBuilder.endpoints.form.searchByFormType)(params)
            .pipe(mapFromSearchResult);
    }

    /**
     * Gets array of all form types from middle tier
     * @returns all form types - Observable<FormType[]>
     */
    getFormTypes(): Observable<FormType[]> {
        if (this.formTypes$.value && !!this.formTypes$.value.length) {
            return of(this.formTypes$.value);
        } else {
            return this.api
                .getObservableEndpoint(environment.apiFormBuilder.endpoints.formType.lookup)()
                .pipe(tap((formTypes: FormType[]) => this.formTypes$.next(formTypes)));
        }
    }

    /**
     * Gets array of all form basis middle tier
     * @returns all form basis - Observable<FormBasis[]>
     */
    getFormBasisList(): Observable<FormBasis[]> {
        if (this.formBases$.value && !!this.formBases$.value.length) {
            return of(this.formBases$.value);
        } else {
            return this.api
                .getObservableEndpoint(environment.apiFormBuilder.endpoints.formBasis.lookup)()
                .pipe(tap((formBases: FormBasis[]) => this.formBases$.next(formBases)));
        }
    }

    read(id: number): Observable<SwftForm> {
        return this.api.getObservableEndpoint(environment.apiFormBuilder.endpoints.form.read)(null, { id });
    }

    /**
     * Sends the SwftForm to the middle tier to be updated
     * @param form The SwftForm payload
     * @returns
     */
    update(form: SwftForm): Observable<SwftForm> {
        return this.api.getObservableEndpoint(environment.apiFormBuilder.endpoints.form.update)(form);
    }

    /**
     * Sends the SwftForm to the middle tier to be created
     * @param payload The SwftForm payload
     * @returns
     */
    create(payload: SwftForm): Observable<SwftForm> {
        return this.api.getObservableEndpoint(environment.apiFormBuilder.endpoints.form.create)(payload);
    }
}
