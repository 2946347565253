import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SortOrder } from 'src/app/models/api/api-search.models';
import { PaginationDataSource } from 'src/app/models/api/datasource.models';
import { HealthCareProvider } from 'src/app/models/hcp.model';
import { Patient } from 'src/app/models/patient.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { SingleSearchTermQuery, TableColumn } from 'src/app/models/table.models';
import { HcpService } from 'src/app/services/hcp/hcp.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import {
    DateFormat,
    EmptyQuery,
    EmptySearchGridText,
    InitalSortHcp,
    InitalSortPatientDemographics,
    PageSizeTen,
} from 'src/app/utils/constants';

@Component({
    selector: 'swft-profile-grid',
    templateUrl: './profile-grid.component.html',
    styleUrls: ['./profile-grid.component.scss'],
})
export class ProfileGridComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @Input() showActions = true;

    displayedColumns = ['mrn', 'deIdentifiedId', 'firstName', 'lastName', 'dob', 'actions'];
    dateFormat: string = DateFormat;
    subscriptions: Subscription[] = [];
    patientColumns: TableColumn[] = [
        { id: 'mrn', name: 'MRN' },
        { id: 'deIdentifiedId', name: 'De-Identified ID' },
        { id: 'hubId', name: 'HUB ID' },
        { id: 'firstName', name: 'First Name' },
        { id: 'lastName', name: 'Last Name' },
        { id: 'dob', name: 'Date of Birth', formatter: 'date' },
        { id: 'address', name: 'Street Address' },
        { id: 'zip', name: 'Zip Code' },
        { id: 'phone', name: 'Home Phone' },
        { id: 'cellPhone', name: 'Cell Phone' },
        { id: 'workPhone', name: 'Work Phone' },
        { id: 'email', name: 'Email' },
        { id: 'actions', name: 'Actions' },
    ];
    dataSource = new PaginationDataSource<any, SingleSearchTermQuery>(
        (request, query) => this.service.page(request, query),
        InitalSortPatientDemographics,
        EmptyQuery,
        PageSizeTen
    );
    patientDataSource = new PaginationDataSource<PatientDemographic, SingleSearchTermQuery>(
        (request, query) => this.service.pageWithDemographics(request, query),
        InitalSortPatientDemographics,
        EmptyQuery,
        PageSizeTen
    );

    hcpDataSource = new PaginationDataSource<HealthCareProvider, SingleSearchTermQuery>(
        (request, query) => this.hcpService.page(request, query),
        InitalSortHcp,
        EmptyQuery,
        PageSizeTen
    );

    hcpColumns: TableColumn[] = [
        { id: 'id', name: 'Id' },
        { id: 'firstName', name: 'First Name' },
        { id: 'lastName', name: 'Last Name' },
        { id: 'professionalDesignation', name: 'Designation' },
        { id: 'organization', name: 'Organization' },
        { id: 'npi', name: 'NPI' },
        { id: 'remsId', name: 'REMS ID' },
        { id: 'address', name: 'Address' },
        { id: 'city', name: 'City' },
        { id: 'state', name: 'State' },
        { id: 'zipCode', name: 'Zip Code' },
        { id: 'phoneNumber', name: 'Phone Number' },
        { id: 'faxNumber', name: 'Fax Number' },
        { id: 'actions', name: 'Actions' },
    ];
    displayColumns: TableColumn[] = [];
    viewColumns: string[] = [];
    showHcp = false;
    profileType = '';
    searchString = `Search ${this.profileType}...`;
    emptyDataText = EmptySearchGridText.NoResult;

    constructor(private service: PatientService, private router: Router, private hcpService: HcpService) {}

    ngAfterViewInit(): void {
        const url = this.router.url;
        if (url.includes('patients')) {
            this.showHcp = false;
            this.profileType = 'Patients';
            this.emptyDataText = EmptySearchGridText.Patient;
            this.displayColumns = this.patientColumns;
            this.dataSource = this.patientDataSource;

            this.subscriptions.push(
                this.dataSource.page$.subscribe(page => {
                    //only two cases are 0 and -1 respectively will not show otherwise
                    this.emptyDataText =
                        page.totalElements === 0 ? EmptySearchGridText.NoResult : EmptySearchGridText.Patient;
                })
            );

            this.subscriptions.push(
                this.sort.sortChange.subscribe(x => {
                    this.dataSource.sortBy({
                        property: x?.active! as keyof Patient,
                        order: x.direction === SortOrder.Ascending ? SortOrder.Ascending : SortOrder.Descending,
                    });
                })
            );
        }
        if (url.includes('hcp')) {
            this.dataSource = this.hcpDataSource;
            this.profileType = 'HCP';

            this.showHcp = true;
            this.displayColumns = this.hcpColumns;

            this.subscriptions.push(
                this.sort.sortChange.subscribe(x => {
                    this.dataSource.sortBy({
                        property: x?.active! as keyof HealthCareProvider,
                        order: x.direction === SortOrder.Ascending ? SortOrder.Ascending : SortOrder.Descending,
                    });
                })
            );
        }
        this.searchString = `Search ${this.profileType}...`;

        this.viewColumns = this.displayColumns.map(x => x.id);
    }

    refreshTable(): void {
        if (this.paginator) {
            this.paginator._changePageSize(this.paginator.pageSize);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    gotoPatientProfile(patient: PatientDemographic, viewOnly: boolean) {
        let route = `/patients/${patient.id}`;

        route += viewOnly ? '' : '?edit';

        this.router.navigate([route]);
    }

    gotoHcpProfile(patient: HealthCareProvider, viewOnly: boolean): void {
        let route = `/hcp/${patient.id}`;

        route += viewOnly ? '' : '?edit';

        this.router.navigate([route]);
    }
    empty(): void {}
}
