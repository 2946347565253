import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/swft/modules/material.module';
import { SwftModule } from '../swft/swft.module';
import { LogicConditionComponent } from './logic-condition/logic-condition.component';
import { LogicDatetimeComponent } from './logic-datetime/logic-datetime.component';
import { LogicComponent } from './logic.component';

@NgModule({
    declarations: [LogicComponent, LogicConditionComponent, LogicDatetimeComponent],
    imports: [CommonModule, MaterialModule, SwftModule],
    exports: [LogicComponent, LogicConditionComponent, LogicDatetimeComponent],
})
export class LogicModule {}
