import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { DefaultContact, PayorContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PatientService } from 'src/app/services/patient/patient.service';

interface PayorContactForm {
    firstName: FormControl<string | null>;
    lastNameOrInitial: FormControl<string | null>;
    title: FormControl<string | null>;
}

@Component({
    selector: 'swft-hipaa-payor',
    templateUrl: './hipaa-payor.component.html',
    styleUrls: ['./hipaa-payor.component.scss'],
})
export class HipaaPayorComponent implements OnInit, OnDestroy {
    patient?: PatientDemographic;
    patientDisplayValue?: string;
    payors?: Observable<PayorContact[]>;

    formGroup: FormGroup<PayorContactForm> = new FormGroup<PayorContactForm>({
        firstName: new FormControl(null, Validators.required),
        lastNameOrInitial: new FormControl(null, Validators.required),
        title: new FormControl(null, Validators.required),
    });

    payor?: PayorContact;
    private onDestroy = new Subject<void>();

    constructor(private hipaaService: HipaaService, private patientService: PatientService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            if (!data.patient.id) return;

            this.patient = data.patient;
            this.patientDisplayValue = this.hipaaService.patientDisplayName;

            if (!this.payors) {
                this.payors = this.patientService
                    .getPatientPayors(data.patient.id)
                    .pipe(map(payors => payors as PayorContact[]));
            }
        });

        // Update the HIPAA data's contact as form values change
        this.formGroup.valueChanges
            ?.pipe(
                debounceTime(300),
                tap(() => {
                    const contact: PayorContact = {
                        ...new DefaultContact(),
                        firstName: this.formGroup.controls['firstName'].value || '',
                        lastName: this.formGroup.controls['lastNameOrInitial'].value || '',
                        title: this.formGroup.controls['title'].value || '',
                        mrn: this.patient?.mrn || '',
                        rank: 0,
                        pbm: '',
                        segment: '',
                        coverageType: '',
                        memberID: '',
                        binNumber: '',
                        pcn: '',
                        groupNumber: '',
                        status: '',
                        effectiveDate: '',
                        terminationDate: '',
                        billingNPI: '',
                    };
                    this.hipaaService.validateContact(contact, HipaaVerificationType.Payor);
                }),
                takeUntil(this.onDestroy)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onAddPayorClicked() {
        alert('Functionality not yet implemented');
    }

    /**
     * Updates the HIPAA data, setting the selected Payor as the contact
     * If a different Payor is selected after the first, clear the form
     * @param selectEvent A MatSelectChange event from the Payor selector, its value is typically a Payor
     */
    onContactSelected(selectEvent: MatSelectChange): void {
        if (!!this.payor) {
            this.formGroup.reset();
        }
        this.hipaaService.validateContact(selectEvent.value, HipaaVerificationType.Payor);
        this.payor = selectEvent.value;
    }
}
