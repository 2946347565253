import { Injectable } from '@angular/core';
import { environment } from 'src/environments/config';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class PatientApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiPatient.protocol}://${environment.apiPatient.host}:${environment.apiPatient.port}/${environment.apiPatient.path}`;
}
