import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
    ConversationLink,
    FormBuilderLink,
    LayoutBuilderLink,
    LeftNavLink,
    LeftNavList,
    LeftNavType,
    LineOfBusinessLink,
    PatientProfileLink,
    QuestionLink,
    QuestionModuleLink,
} from 'src/app/models/left-nav.model';
import * as navActions from 'src/app/state/nav/nav.actions';
import { toggleNavbar } from 'src/app/state/nav/nav.actions';
import {
    selectHcpLinks,
    selectIsNavEmpty,
    selectIsNavExpanded,
    selectLayoutBuilderLinks,
    selectNavConversationLinks,
    selectNavLineOfBusinessLinks,
    selectNavLinks,
    selectNavQuestionLinks,
    selectNavQuestionModuleLinks,
    selectNavState,
    selectPatientLinks,
    selectSwftFormLinks,
} from 'src/app/state/nav/nav.selector';
import { HcpService } from '../hcp/hcp.service';
import { LeftNavSection } from './left-nav.models';

@Injectable({
    providedIn: 'root',
})
export class LeftNavService implements OnDestroy {
    private onDestroy$ = new Subject<void>();
    private readonly _links: BehaviorSubject<LeftNavList> = new BehaviorSubject<LeftNavList>({
        conversations: [],
        patients: [],
        questionModules: [],
        hcpProfiles: [],
        linesOfBusiness: [],
        questions: [],
        formBuilder: [],
        layoutBuilder: [],
    });

    // Defines the individual sections of links
    readonly sections: { [key in keyof LeftNavList]: LeftNavSection } = {
        patients: {
            displayWith(value: PatientProfileLink) {
                return `${value.data.mrn} • ${value.data.lastName}, ${value.data.firstName}`;
            },
            icon: 'personal_injury',
            links$: this.store.select(selectPatientLinks),
            linkType: LeftNavType.Patient,
            title: 'Patients',
            expanded: true,
        },
        hcpProfiles: {
            displayWith(value: LeftNavLink) {
                return `${value.data.hcpId} • ${value.data.lastName}, ${value.data.firstName}`;
            },
            icon: 'local_hospital',
            links$: this.store.select(selectHcpLinks),
            linkType: LeftNavType.HcpProfile,
            title: 'HCP Profiles',
            expanded: true,
        },
        conversations: {
            displayWith(value: ConversationLink) {
                return `${value.data.id} • ${value.data.narratives.length ? value.data.narratives[0].formName : ''}`;
            },
            icon: 'forum',
            links$: this.store.select(selectNavConversationLinks),
            linkType: LeftNavType.Conversation,
            title: 'Conversations',
            expanded: true,
            confirmMessage: 'Are you sure you want to exit this conversation? All unsaved work will be lost!',
        },
        questionModules: {
            displayWith(value: QuestionModuleLink) {
                return `${value.data.id < 0 ? 'unsaved' : value.data.id} • ${value.data.title}`;
            },
            icon: 'quiz',
            links$: this.store.select(selectNavQuestionModuleLinks),
            linkType: LeftNavType.QuestionModule,
            title: 'Question Modules',
            expanded: true,
        },
        linesOfBusiness: {
            displayWith(value: LineOfBusinessLink) {
                return `${value.data.id < 0 ? 'unsaved' : value.data.id} • ${value.data.name}`;
            },
            icon: 'work',
            links$: this.store.select(selectNavLineOfBusinessLinks),
            linkType: LeftNavType.LineOfBusiness,
            title: 'Lines of Business',
            expanded: true,
        },
        questions: {
            displayWith(value: QuestionLink) {
                return `${value.data.id < 0 ? 'unsaved' : value.data.id} • ${value.data.title}`;
            },
            icon: 'question_mark',
            links$: this.store.select(selectNavQuestionLinks),
            linkType: LeftNavType.Question,
            title: 'Question',
            expanded: true,
        },
        formBuilder: {
            displayWith(value: FormBuilderLink) {
                return `${value.state.formIsClean ? '' : '*'}${value.data.id! < 0 ? 'unsaved' : value.data.id} • ${
                    value.data.title
                }`;
            },
            icon: 'vertical_split',
            links$: this.store.select(selectSwftFormLinks),
            linkType: LeftNavType.FormBuilder,
            title: 'Form Builder',
            expanded: true,
        },
        layoutBuilder: {
            displayWith(value: LayoutBuilderLink) {
                return `${value.data.id! < 0 ? 'unsaved' : value.data.id} • ${value.data.layoutName}`;
            },
            icon: 'grid_view',
            links$: this.store.select(selectLayoutBuilderLinks),
            linkType: LeftNavType.LayoutBuilder,
            title: 'Layout Builder',
            expanded: true,
        },
    };

    readonly navState$ = this.store.select(selectNavState);
    readonly links$: Observable<LeftNavList> = this.store.select(selectNavLinks);
    readonly isNavEmpty$ = this.store.select(selectIsNavEmpty);
    readonly isNavExpanded$ = this.store.select(selectIsNavExpanded);

    conversationClosed$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private store: Store, private hcpService: HcpService) {
        // Listen to link changes from state and set the _links BehaviorSubject
        // We are tracking this like this so we can read the values imperatively
        // Any changes intended to be done to the links should occur via the state store
        this.links$.pipe(takeUntil(this.onDestroy$)).subscribe(links => this._links.next(links));
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getConversationLinkByNarrative(narrativeId: number[]): LeftNavLink | undefined {
        return this._links.value.conversations.find(conversation =>
            isEqual(conversation.data.narratives.map(narrative => narrative.id).sort(), narrativeId.sort())
        );
    }

    addHcpServiceLink(hcpProfileId: number, url: string) {
        const endpoint = this.hcpService.getHcpDemographics(hcpProfileId);
        this.addLink(endpoint, LeftNavType.HcpProfile, url);
    }

    removeLinkByIndex(linkType: LeftNavType, index: number): void {
        if (linkType === LeftNavType.Conversation) this.processRemovedLink(this._links.value.conversations[index]);
        this.store.dispatch(navActions.removeLinkByIndex({ linkType, index }));
    }

    toggleNavbar(isExpanded: boolean) {
        this.store.dispatch(toggleNavbar({ isExpanded }));
    }

    private processRemovedLink(link: LeftNavLink) {
        // if this was a conversation link, get the conversation id and fire the conversation closed event
        switch (link.type) {
            case LeftNavType.Conversation:
                const conversationId = link.data.id;
                this.conversationClosed$.next(conversationId);
                break;
        }
    }

    private addLink(endpoint: Observable<any>, type: LeftNavType, url: string) {
        endpoint.subscribe((data: any) => {
            const link: LeftNavLink = {
                type: type,
                url: url,
                state: data,
            };

            this.store.dispatch(
                navActions.addLink({
                    link: {
                        ...link,
                        data: data,
                    },
                })
            );
        });
    }
}
