<ng-container *ngIf="(loading$ | async) === false; else loading">
    <ng-container *ngIf="id$ | async; let id">
        <ng-container *ngIf="patient$ | async; let patient">
            <section>
                <swft-profile-header
                    profileType="Patient"
                    [firstName]="patient.firstName"
                    [lastName]="patient.lastName"
                    [id]="patient.mrn"
                    profileString="MRN"></swft-profile-header>
                <button
                    class="more-menu"
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu"
                    [disabled]="(patientAlertAcknowledged$ | async) === false"
                    aria-label="more">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button
                        mat-menu-item
                        aria-label="Create Narrative"
                        (click)="startNarrative()">
                        Create Narrative
                    </button>
                    <button
                        mat-menu-item
                        aria-label="Add Progress Note"
                        (click)="createProgressNote()">
                        Add Progress Note
                    </button>
                    <button
                        mat-menu-item
                        aria-label="Special Id"
                        [disabled]="!hasValidPermission(PermissionNames.PatientSpecialIdentifier)"
                        (click)="openAssignSpecialId()">
                        Special Id
                    </button>
                </mat-menu>
            </section>
            <swft-profile-acknowledge
                heading="Clinical/Billing Alert"
                [userHasAcknowledged$]="patientAlertAcknowledged$"
                *ngIf="(patientAlerts$ | async)?.hasAlert"
                (acknowledge)="onAcknowledge()">
                <ng-container *ngIf="patientAlerts$ | async">
                    <div>{{ (patientAlerts$ | async)?.clinicalAlert }}</div>
                    <div>{{ (patientAlerts$ | async)?.billingAlert }}</div>
                </ng-container>
            </swft-profile-acknowledge>
            <ng-container *ngIf="patientAlertAcknowledged$ | async">
                <swft-narratives
                    [displayColumns]="displayColumns"
                    [narrativeType]="narrativeTypes"
                    [resultPageSize]="10"
                    [searchId]="id"></swft-narratives>
                <swft-profile
                    [profileLinks]="(profileLinks$ | async)!"
                    [id]="id"
                    [showHcp]="false"></swft-profile>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loading><mat-spinner></mat-spinner></ng-template>
