import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OutboundCall } from 'src/app/models/call.models';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';

@Component({
    selector: 'swft-hipaa-call-log',
    templateUrl: './hipaa-call-log.component.html',
    styleUrls: ['./hipaa-call-log.component.scss'],
})
export class HipaaCallLogComponent {
    callLog$: Observable<OutboundCall[]> = this.hipaaService.dataChanges.pipe(map(hippaData => hippaData.callLog));

    constructor(private hipaaService: HipaaService) {}

    /**
     * Adds a call to the call log
     * @param outboundCall The outbound call information to be added
     */
    addOutboundCall(outboundCall: OutboundCall): void {
        this.hipaaService.addCallToLog(outboundCall);
    }
}
