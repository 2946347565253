// the patient as they appear in CPR+
export class PnName {
    id: number;
    fName: string;
    lName: string;
    profDesig: string;
    initials: string;
    email: string;
    userName: string;
    touchDate: Date | null;
    chgByHost: string;
    createdOn: Date | null;
    createdBy: string;
    deleted: boolean;

    constructor(
        id: number,
        fName: string,
        lName: string,
        profDesig: string,
        initials: string,
        email: string,
        userName: string,
        touchDate: Date | null,
        chgByHost: string,
        createdOn: Date | null,
        createdBy: string,
        deleted: boolean
    ) {
        this.id = id;
        this.fName = fName;
        this.lName = lName;
        this.profDesig = profDesig;
        this.initials = initials;
        this.email = email;
        this.userName = userName;
        this.touchDate = touchDate;
        this.chgByHost = chgByHost;
        this.createdOn = createdOn;
        this.createdBy = createdBy;
        this.deleted = deleted;
    }
}
