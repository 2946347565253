import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { AuthClaim } from 'src/app/models/auth/AuthClaim';
import {
    AuthenticationCredentials,
    AuthToken,
    DuoRedirectParams,
    DuoRedirectUrl,
    LoginError,
    PermissionName,
    PermissionType,
    SessionStorageKeys,
} from 'src/app/models/auth/authorization.models';
import { AuthTokenRefreshService } from 'src/app/workers/auth-token-refresh.service';
import { environment } from 'src/environments/environment';
import { IdentityApiService } from '../api/identity-api.service';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private loggedInStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isRefreshingToken: boolean = false;
    private _ngUnsubscribe: Subject<void> = new Subject<void>();

    credentials: BehaviorSubject<AuthenticationCredentials | null> =
        new BehaviorSubject<AuthenticationCredentials | null>(null);
    bearerToken: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private sessionStorageService: SessionStorageService,
        private router: Router,
        private api: IdentityApiService,
        private dialogRef: MatDialog,
        private authTokenRefreshService: AuthTokenRefreshService
    ) {
        this.peekCache();

        this.authTokenRefreshService.logout.pipe(takeUntil(this._ngUnsubscribe)).subscribe(() => this.logout());

        this.initTokenTimer();
    }

    ngOnDestroy(): void {
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Checks for the existence of credentials and bearer token in session storage.
     */
    peekCache() {
        const cachedCredentials = this.getCredentials(true);

        if (cachedCredentials) {
            this.credentials.next(cachedCredentials);
            const cachedToken = this.getBearerToken();
            if (cachedToken) {
                this.bearerToken.next(cachedToken);
            }
        }
    }

    login(username: string) {
        // Store the username so we have it for the next request after we are redirected back
        this.getAuthUrl(username).then((resp: DuoRedirectUrl) => {
            window.location.href = resp.redirectUri;
        });
    }

    getAuthUrl(username: string): Promise<DuoRedirectUrl> {
        // Store the username so we have it for the next request after we are redirected back
        this.storeUsername(username);
        return this.api
            .getPromiseEndpoint(environment.apiIdentity.endpoints.login.authenticate)({
                username: username,
            })
            .catch(() => {
                this.router.navigate(['/login'], { queryParams: { loginError: LoginError.FAILED } });
            });
    }

    callback(duo: DuoRedirectParams): Observable<any> {
        const username = this.getUsername();
        const redirectUri = window.location.href;

        const params = {
            state: duo.state,
            code: duo.code,
            username,
            redirectUri,
        };

        return this.api
            .getObservableEndpoint(environment.apiIdentity.endpoints.login.getCredentials)(null, params)
            .pipe(
                tap(resp => this.credentials.next(resp.credentials)),
                tap(resp => this.storeCredentials(resp.credentials)),
                tap(resp => this.bearerToken.next(resp.bearerToken)),
                tap(resp => this.storeBearerToken(resp.bearerToken)),
                tap(() => {
                    // Redirect to the original page via browser instead of Angular
                    // router. This causes the app component to be re-instantiated
                    // with the new token.
                    if (!this._isRefreshingToken) window.location.href = '/';
                    this._isRefreshingToken = false;
                    this.initTokenTimer();
                })
            );
    }

    logout() {
        this.loggedInStatus.next(false);
        this.loggedInStatus.complete();
        this.credentials.next(null);
        this.credentials.complete();
        this.bearerToken.next('');
        this.bearerToken.complete();
        this.sessionStorageService.clear();
        this.dialogRef.closeAll();
        this.authTokenRefreshService.clearTimer();
        this.router.navigate(['/login'], { queryParams: { loginError: LoginError.LOGOUT } });
    }

    get isLoggedIn(): BehaviorSubject<boolean> {
        if (!this.getBearerToken()) {
            this.loggedInStatus.next(false);
            return this.loggedInStatus;
        }

        const convertedExp: string = this.getClaimValueByKey(AuthClaim.Exp) + '000'; // convert to 13-digit DateTime
        const exp: Date = new Date(parseInt(convertedExp));
        const now: Date = new Date();
        let status = false;

        if (exp) {
            status = exp > now;
        }

        if (!status && !this._isRefreshingToken) {
            this.logout();
        }

        this.loggedInStatus.next(status);
        return this.loggedInStatus;
    }

    get isAdmin(): boolean {
        let isAdmin = false;
        const adminRoles: AuthClaim[] = [AuthClaim.IsAdmin, AuthClaim.IsDeveloper, AuthClaim.IsSuper];

        for (let i = 0; i < adminRoles.length; i++) {
            const value = this.getClaimValueByKey(adminRoles[i]).toLowerCase();
            if (value === 'true') {
                isAdmin = true;
                break;
            }
        }

        return isAdmin;
    }

    get isSuperAdmin(): boolean {
        return this.getClaimValueByKey(AuthClaim.IsSuper).toLowerCase() === 'true';
    }

    get username(): string {
        return this.getClaimValueByKey(AuthClaim.Name).toLowerCase().split('@')[0] || '';
    }

    get userId(): number {
        return parseInt(this.getClaimValueByKey(AuthClaim.UserID).toLowerCase().split('@')[0]) || 0;
    }

    set isRefreshingToken(value: boolean) {
        this._isRefreshingToken = value;
    }

    /**
     * Determines whether the user has a valid permission for the expected Permission requirement
     * @param name the `PermissionName` of the requesting permission
     * @param requiredPermission the `PermissionType` required for the current resource
     * @returns boolean
     */
    hasValidPermission(name: PermissionName, requiredPermission: PermissionType): boolean {
        const claim = this.getClaimValueByKey(name);

        if (claim) {
            const claimAsNum = Number(claim);

            // Bitwise math
            return (claimAsNum & requiredPermission) === requiredPermission;
        }

        return false;
    }

    private initTokenTimer() {
        const exp = parseInt(this.getClaimValueByKey(AuthClaim.Exp));
        if (isNaN(exp)) return;
        this.authTokenRefreshService.initTokenTimer(exp);
    }

    private storeUsername(username: string) {
        this.sessionStorageService.setItem(SessionStorageKeys.Username, username);
    }

    private getUsername(): string {
        return this.sessionStorageService.getItem(SessionStorageKeys.Username) || '';
    }

    private storeBearerToken(bearerToken: string) {
        this.sessionStorageService.setItem(SessionStorageKeys.BearerToken, bearerToken);
        this.loggedInStatus.next(true);
    }

    private getBearerToken(expCheck: boolean = false): string | undefined {
        let bearerToken = this.sessionStorageService.getItem(SessionStorageKeys.BearerToken);
        if (bearerToken && expCheck) {
            const convertedExp: string = this.getClaimValueByKey(AuthClaim.Exp) + '000'; // convert to 13-digit DateTime
            const exp: Date = new Date(parseInt(convertedExp));

            if (new Date() > exp) {
                return undefined;
            }
        }
        return bearerToken;
    }

    private storeCredentials(credentials: AuthenticationCredentials) {
        this.sessionStorageService.setObject<AuthenticationCredentials>(SessionStorageKeys.Credentials, credentials);
    }

    private getCredentials(expCheck: boolean = false): AuthenticationCredentials | undefined {
        const credentials = this.sessionStorageService.getObject<AuthenticationCredentials>(
            SessionStorageKeys.Credentials
        );
        if (credentials && expCheck) {
            const tokenExpiration = new Date(credentials.exp);
            if (new Date() > tokenExpiration) {
                return undefined;
            }
        }
        return credentials;
    }

    /**
     * Gets the user's encoded JWT string from strorage. If a token does not exist, return null; otherwise,
     * decode the JWT string into stringified JSON and returned a parsed JSON object.
     * @returns AuthToken | null Returns the JWT as an object if it exists, otherwise returns null
     */
    private parseJwt(): AuthToken | null {
        const token = this.getBearerToken();
        if (!token) return null;

        const base64Url = token.split('.')[1];
        if (!base64Url) return null;

        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonSting = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonSting);
    }

    /**
     * Gets the value of a claim from the JWT by a given key. If the JWT or claim does not exist,
     * return an empty string.
     * @param key the key of the claim to retrieve
     * @returns the value of the claim
     * @example this.getClaimValueByKey(AuthClaim.IsSuper)
     */
    private getClaimValueByKey(key: string): string {
        const jwt: AuthToken | null = this.parseJwt();
        let value = '';

        if (jwt?.hasOwnProperty(key)) {
            value = get(jwt, [key]);
        }

        return value;
    }
}
