<h1 mat-dialog-title>Create {{ getNarrativeTitle(data.narrativeType) }} Narrative</h1>
<form
    class="form-container"
    [formGroup]="formGroup">
    <mat-form-field
        appearance="fill"
        class="full"
        *ngIf="displayPatients$ | async">
        <mat-label>Select a Patient</mat-label>
        <input
            matInput
            swftSearchGrid
            [dataSource]="PatientDataSource"
            [columns]="patientColumns"
            gridTitle="a Patient"
            (rowSelected)="selectPatient($event)"
            formControlName="patient"
            [enabled]="patientSearchEnabled" />
    </mat-form-field>

    <mat-form-field
        appearance="fill"
        class="full"
        *ngIf="displayHCP$ | async">
        <mat-label>Select an HCP</mat-label>
        <input
            matInput
            swftSearchGrid
            [dataSource]="HCPDataSource"
            [columns]="hcpColumns"
            gridTitle="a HCP"
            (rowSelected)="selectHcp($event)"
            [enabled]="patientSearchEnabled"
            formControlName="hcp" />
    </mat-form-field>

    <mat-form-field
        id="form-input"
        appearance="fill"
        class="full"
        [class.hide]="(displayForms$ | async) === false">
        <mat-label>Select a Form</mat-label>
        <input
            matInput
            swftSearchGrid
            [dataSource]="FormDataSource"
            [columns]="formColumns"
            gridTitle="a Form"
            (rowSelected)="selectForm($event)"
            [readonly]="true"
            formControlName="form" />
    </mat-form-field>

    <mat-form-field
        id="order-input"
        appearance="fill"
        class="full"
        *ngIf="displayOrders$ | async">
        <mat-label>Select an Order</mat-label>
        <input
            matInput
            swftSearchGrid
            [dataSource]="OrderDataSource"
            [columns]="orderColumns"
            (showDiscontinuedChange)="updateDiscontinued($event)"
            gridTitle="an Order"
            [multiSelect]="true"
            [showDiscontinuedToggle]="true"
            (rowSelected)="selectOrder($event)"
            [readonly]="true"
            formControlName="order" />
    </mat-form-field>
</form>
<swft-form-errors
    [floatable]="false"
    [formGroup]="formGroup"></swft-form-errors>
<section class="btn-container">
    <button
        mat-button
        class="deny"
        (click)="cancel()">
        Cancel
    </button>
    <button
        mat-raised-button
        color="accent"
        class="save-btn"
        [disabled]="formGroup.invalid"
        (click)="save(true)">
        Save and Start Conversation
        <mat-icon
            class="menu-trigger"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="menu">
            arrow_drop_down
        </mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <button
            (click)="save()"
            mat-menu-item>
            Save
        </button>
    </mat-menu>
</section>
