import { Component, OnInit } from '@angular/core';
import { map, take } from 'rxjs';
import { HipaaRoutes } from 'src/app/enums/hipaa.enum';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';

export enum FailedCallAction {
    Continue = 'Continue',
    SaveAndClose = 'SaveAndClose',
    Retry = 'Retry',
    AddPhoneNumber = 'AddPhoneNumber',
}

@Component({
    selector: 'swft-hipaa-call-failed',
    templateUrl: './hipaa-call-failed.component.html',
    styleUrls: ['./hipaa-call-failed.component.scss'],
})
export class HipaaCallFailedComponent implements OnInit {
    FailedCallAction = FailedCallAction;
    contact$ = this.hipaaService.dataChanges.pipe(map(hipaaData => hipaaData.contact));
    hasPhoneNumbers = false;

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hasPhoneNumbers = this.hipaaService.phoneNumbers.length > 0;
    }

    /**
     * Navigate the user to the nexc step in the HIPAA process.
     * @param action The action that was selected by the user
     */
    navigate(action: FailedCallAction | null): void {
        this.hipaaService.dataChanges.pipe(take(1)).subscribe(hipaaData => {
            if (action === FailedCallAction.Continue) this.continue();
            if (action === FailedCallAction.SaveAndClose) this.saveAndClose(hipaaData.attestations.ignoredNumbers);
            if (action === FailedCallAction.Retry) this.hipaaService.navigate(HipaaRoutes.SelectContactType);
            if (action === FailedCallAction.AddPhoneNumber)
                this.hipaaService.navigate(HipaaRoutes.AddPhoneNumber, false);
        });
    }

    /**
     * Navigate the user the the conversation via "Documentation" attestation
     */
    private continue(): void {
        this.hipaaService.documentationAttestation = true;
        this.hipaaService.navigate(HipaaRoutes.Attestation);
    }

    /**
     * Navigate the user the the conversation status page
     * If the user ignored numbers, navigate via the "Ignored Numbers" attestation
     */
    private saveAndClose(userIgnoredNumbers: boolean): void {
        if (userIgnoredNumbers) {
            this.hipaaService.documentationAttestation = false;
            this.hipaaService.navigate(HipaaRoutes.Attestation);
            return;
        }
        alert('Go to Conversation Status Page - Not Yet Implemented');
    }
}
