import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, catchError, of, Subject, take, takeUntil, tap } from 'rxjs';
import { PermissionName, PermissionNames, PermissionType } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { SpecialIdService } from './special-id.service';

@Component({
    selector: 'swft-special-id',
    templateUrl: './special-id.component.html',
    styleUrls: ['./special-id.component.scss'],
})
export class SpecialIdComponent implements OnInit {
    onDestroy$: Subject<void> = new Subject<void>();
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    formGroup: FormGroup = new FormGroup({
        specialId: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
    });

    patientId: number = 0;
    specialId: string = '';
    hasSavedValue: boolean = false;

    specialIdPermissionName = PermissionNames.PatientSpecialIdentifier as PermissionName;
    deletePermissionType = PermissionType.Delete;

    /**
     * Busines rule: Manually generated (i.e. user entered) special id
     * does not need to be unique (and can be any string)
     */
    manuallyGenerated: boolean = true;

    private unsavedUpdate: boolean = false;

    constructor(
        private specialIdService: SpecialIdService,
        private snackbar: SWFTSnackBarService,
        public dialogRef: MatDialogRef<SpecialIdComponent>,
        public authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: { patientId: number; specialId: string; manuallyGenerated: boolean }
    ) {}

    ngOnInit(): void {
        this.patientId = this.data.patientId;
        this.specialId = this.data.specialId;
        if (this.specialId) this.hasSavedValue = true;
        this.manuallyGenerated = this.data.manuallyGenerated;
        this.setFormValue(this.specialId);

        this.formGroup
            .get('specialId')
            ?.valueChanges.pipe(takeUntil(this.onDestroy$))
            .subscribe(specialId => {
                this.specialId = specialId;
            });
    }

    /**
     * Requests a new (unique) 4 digit code from the middle-tier
     */
    getNewSystemGeneratedIdentifier(): void {
        if (this.loading$.value) return;
        this.loading$.next(true);
        this.specialIdService
            .getGeneratedSpecialId()
            .pipe(
                take(1),
                tap(specialId => {
                    this.manuallyGenerated = false;
                    this.specialId = specialId.toString();
                    this.setFormValue(this.specialId);
                    this.formGroup.markAsDirty();
                    this.unsavedUpdate = true;
                    this.loading$.next(false);
                }),
                catchError(err => {
                    this.snackbar.error('Error generating id ' + err.message);
                    this.loading$.next(false);
                    return of(true);
                })
            )
            .subscribe();
    }

    /**
     * Fires when the special id text changes, sets the manually generated flag to true
     */
    specialIdChanged(): void {
        this.manuallyGenerated = true;
    }

    /**
     * Sends the new special id assignment to the middle-tier
     */
    saveSpecialIdAssignment(): void {
        this.specialIdService
            .assignPatientSpecialId(this.patientId, this.specialId, this.manuallyGenerated)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.snackbar.success('Special id assignment updated successfully');
                    this.close();
                },
                error: () => this.snackbar.error('Error saving special id assignment'),
            });
    }

    /**
     * Removes an existing special id assignment
     */
    deleteSpecialId(): void {
        this.specialId = '';
        this.manuallyGenerated = true;
        this.saveSpecialIdAssignment();
    }

    /**
     * Closes this dialog component and returns the special id and manually generated flag
     */
    close(): void {
        this.dialogRef.close({ specialId: this.specialId, manuallyGenerated: this.manuallyGenerated });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    private setFormValue(value: string): void {
        this.formGroup.patchValue({
            specialId: value,
        });
    }
}
