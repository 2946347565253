import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'swft-generic-form-page',
    templateUrl: './generic-form-page.component.html',
    styleUrls: ['./generic-form-page.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class GenericFormPageComponent implements OnInit {
    @Input() title: string = '';
    @Input() subtitle: string = '';
    @Input() expanded: boolean = true;
    @Input() expandable: boolean = true;
    @Input() icon: string = '';

    isExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() {}

    ngOnInit(): void {
        this.isExpanded.next(this.expanded);
    }

    toggleExpanded() {
        this.isExpanded.next(!this.isExpanded.value);
    }
}
