import { Injectable } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { SwftNote } from 'src/app/models/patient.model';
import { SwftPopUpNoteComponent } from './swft-popup-note.component';

@Injectable({ providedIn: 'root' })
export class SWFTPopUpNoteService {
    private _maximized: boolean = false;
    private position: DialogPosition = {
        bottom: '10px',
        right: '24px',
    };

    constructor(private dialog: MatDialog) {}

    open(note: SwftNote) {
        this.dialog.closeAll();
        const dialogRef = this.dialog.open(SwftPopUpNoteComponent, {
            panelClass: 'swft-popup-note',
            hasBackdrop: false,
            position: this.position,
        });
        dialogRef.componentInstance.note = note;
    }

    get maximized() {
        return this._maximized;
    }

    set maximized(maximized: boolean) {
        this._maximized = maximized;
    }
}
