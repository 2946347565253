import { Injectable } from '@angular/core';
import { NarrativeSearch } from 'src/app/models/narrative.model';
@Injectable({
    providedIn: 'root',
})
export class NarrativeAdapter {
    adapt(item: any): NarrativeSearch {
        const narrative: NarrativeSearch = {
            id: item.id,
            patientId: item.patientId,
            mrn: item.mrn,
            formId: item.formId,
            formName: item.formName,
            formBasisName: item.formBasisName,
            formTypeName: item.formTypeName,
            hcpId: item.hcpId,
            hcpFirstName: item.hcpFirstName,
            hcpLastName: item.hcpLastName,
            firstName: item.firstName,
            lastName: item.lastName,
            medicationName: item.medicationName,
            otId: item.otId,
            status: item.status,
            nextActionDate: item.nextActionDate !== null ? new Date(item.nextActionDate).toLocaleDateString() : '',
            lastActionDate: item.lastActionDate !== null ? new Date(item.lastActionDate).toLocaleDateString() : '',
            nextActionName: item.nextActionName,
            lastActionName: item.lastActionName,
        };

        return narrative;
    }
}
