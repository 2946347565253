import { Component } from '@angular/core';

@Component({
    selector: 'swft-question-logic',
    templateUrl: './logic.component.html',
    styleUrls: ['./logic.component.scss'],
})
export class QuestionLogicComponent {
    constructor() {}
}
