import { Endpoint, IEndpoint } from './endpoint';

export class FormBuilderEndpoints {
    form: FormEndpoints = new FormEndpoints();
    formType: FormTypeEndpoints = new FormTypeEndpoints();
    formBasis: FormBasisEndpoints = new FormBasisEndpoints();
    informationItem: InformationItemEndpoints = new InformationItemEndpoints();
    informationItemSearch: InformationItemSearchEndpoints = new InformationItemSearchEndpoints();
}

export class FormEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/form');
    update: IEndpoint = new Endpoint('put', 'v1/form');
    read: IEndpoint = new Endpoint('get', 'v1/form/{id}');
    validateTitle: IEndpoint = new Endpoint('get', 'v1/form/validate/{query}');
    search: IEndpoint = new Endpoint('post', 'v1/form/search');
    searchByFormType: IEndpoint = new Endpoint('post', 'v1/form/search/basis');
}

export class FormTypeEndpoints {
    lookup: IEndpoint = new Endpoint('get', 'v1/formType');
}

export class FormBasisEndpoints {
    lookup: IEndpoint = new Endpoint('get', 'v1/formbasis');
}

export class InformationItemEndpoints {
    validateTitle: IEndpoint = new Endpoint('get', 'v1/informationitem/validate/{query}');
    create: IEndpoint = new Endpoint('post', 'v1/informationitem');
    update: IEndpoint = new Endpoint('put', 'v1/informationitem');
    read: IEndpoint = new Endpoint('get', 'v1/informationitem/{id}');
    validate: IEndpoint = new Endpoint('get', 'v1/informationitem/validate/{title}');
    delete: IEndpoint = new Endpoint('delete', 'v1/informationitem/{id}');
}

export class InformationItemSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/informationitem/search');
    searchWithFormBasis: IEndpoint = new Endpoint('post', 'v1/informationitem/search/{formBasis}');
}
