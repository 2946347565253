import { NoteTemplate } from 'src/environments/endpoints/cpr.endpoints';
import { Narrative } from './narrative.model';
import { ProfileMode } from './profile/profile-mode.model';

export interface Conversation {
    id: number;
    startDate: Date;
    endDate?: Date;
    conversationNarratives?: ConversationNarrative[];
    narratives: Narrative[];
    conversationFormData: [];
}

export enum ConversationAction {
    None = 'None',
    SaveNarrative = 'SaveNarrative',
    CompleteNarrative = 'CompleteNarrative',
    CancelNarrative = 'CancelNarrative',
    SaveConversation = 'SaveConversation',
    CompleteConversation = 'CompleteConversation',
    CancelConversation = 'CancelConversation',
    Complete = 'Complete',
    Cancel = 'Cancel',
    Save = 'Save',
}

export enum ConversationActionText {
    SaveNarrative = 'Save Narrative',
    CompleteNarrative = 'Save and Complete Narrative',
    CancelNarrative = 'Finalize Narrative Cancellation',
    SaveConversation = 'Save Conversation',
    CompleteConversation = 'Save and Complete Conversation',
    CancelConversation = 'Finalize Conversation Cancellation',
}

export interface ConversationHeader {
    firstName: string;
    lastName: string;
    specialId: string;
    mode: ProfileMode;
}
export interface ConversationStatus {
    id: number;
    conversationFormData: [];
    narratives: Narrative[];
    comments?: string;
    commentTemplate?: NoteTemplate;
    subject?: string;
}

export interface ConversationNarrative {
    conversationId: number;
    narrativeId: number;
    conversation?: Conversation;
    narrative?: Narrative;
    medicationName?: string;
}

export interface ConversationNote {
    id?: number;
    conversation: Conversation | undefined;
    conversationId: number;
    note: string | undefined;
    subject: string;
    createdBy?: number;
    createdDate?: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    deleted?: boolean;
}

export interface ProgressNote {
    mrn?: string;
    body: string;
    subject: string;
}

export interface MedicationLookup {
    narrativeId?: number;
    medicationName?: string;
    conversationId?: number;
}

export interface StartPointLookup {
    narrativeId?: number;
    startPoint?: string;
    conversationId?: number;
}
