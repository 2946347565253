import { Component } from '@angular/core';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { SwftGridColumn } from 'src/app/models/grid.models';

@Component({
    selector: 'swft-narratives-order',
    templateUrl: './narratives-order.component.html',
    styleUrls: ['./narratives-order.component.scss'],
})
export class OrderNarrativesComponent {
    narrativeType: NarrativeType = NarrativeType.Order;

    displayColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Id', pixelWidth: 30 },
        { dataPath: 'mrn', name: 'MRN', pixelWidth: 60 },
        { dataPath: 'firstName', name: 'First Name', pixelWidth: 70 },
        { dataPath: 'lastName', name: 'Last Name', pixelWidth: 70 },
        { dataPath: 'otId', name: 'Order #', pixelWidth: 60 },
        { dataPath: 'medicationName', name: 'Medication Name', pixelWidth: 150 },
        { dataPath: 'formTypeName', name: 'Form Type', pixelWidth: 80 },
        { dataPath: 'formName', name: 'Form Name', pixelWidth: 120 },
        { dataPath: 'formBasisName', name: 'Form Basis' },
        { dataPath: 'status', name: 'Status' },
        { dataPath: 'lastActionName', name: 'Last Action' },
        { dataPath: 'lastActionDate', name: 'Last Action Date', formatter: 'date' },
        { dataPath: 'nextActionName', name: 'Next Action' },
        { dataPath: 'nextActionDate', name: 'Next Action Date', formatter: 'date' },
    ];

    constructor() {}
}
