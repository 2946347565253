export {};

declare global {
    interface Date {
        addDays: (days: number) => Date;
    }
}

Date.prototype.addDays = function (days: number): Date {
    let date: Date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
