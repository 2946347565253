<div class="page-container">
    <swft-generic-form-page
        [title]="heading"
        [expandable]="false"
        icon="report_problem">
        <div
            class="acknowledge-container"
            [class.acknowledged]="userHasAcknowledged$ | async">
            <div>
                <ng-content></ng-content>
            </div>
        </div>
    </swft-generic-form-page>
    <ng-container *ngIf="(userHasAcknowledged$ | async) !== true">
        <button
            class="acknowledge-btn"
            (click)="onAcknowledgeClicked()"
            mat-raised-button
            color="primary">
            Acknowledge
        </button>
    </ng-container>
</div>
