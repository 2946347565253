import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { OutboundCall, OutboundCallResult, PhoneNumber } from 'src/app/models/call.models';
import { Contact } from 'src/app/models/profile/contact.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { HipaaPhoneNumberSelectorComponent } from './hipaa-phone-number-selector/hipaa-phone-number-selector.component';

@Component({
    selector: 'swft-hipaa-outbound-call',
    templateUrl: './hipaa-outbound-call.component.html',
    styleUrls: ['./hipaa-outbound-call.component.scss'],
})
export class HipaaOutboundCallComponent implements OnInit, OnDestroy {
    @ViewChild('phoneNumberSelector') patientPhoneNumberSelectorComponent!: HipaaPhoneNumberSelectorComponent;
    private onDestroy: Subject<void> = new Subject<void>();
    private overlayPane = document.querySelector('.hipaa-dialog.cdk-overlay-pane');

    selectedResult: OutboundCallResult | null = null;
    selectedContact: Contact | null = null;
    outboundCallResult = OutboundCallResult;
    activePhoneNumber: PhoneNumber | null = null;
    allCallsFailed: boolean = false;
    processingResult = true;

    constructor(private hipaaService: HipaaService, private snackbar: SWFTSnackBarService) {}

    ngOnInit(): void {
        this.overlayPane?.classList.add('wide');
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy)).subscribe(hipaaData => {
            if (!hipaaData.contact && hipaaData.verificationType !== HipaaVerificationType.Patient) {
                this.snackbar.error('Failed to load contact information');
                return;
            }
            const isAnsweredByOther = hipaaData.callLog.find(
                call => call.callResult === OutboundCallResult.AnsweredByOther
            );
            if (isAnsweredByOther) {
                this.ngOnDestroy();
                this.hipaaService.addCallToLog({
                    contact: this.selectedContact!,
                    callDate: new Date(),
                    callResult: OutboundCallResult.Answered,
                    phoneNumber: isAnsweredByOther.phoneNumber,
                });
                this.hipaaService.navigate(HipaaRoutes.Verify, true);
            }
            if (hipaaData.contact) this.selectedContact = hipaaData.contact;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.overlayPane?.classList.remove('wide');
    }

    /**
     * Add the call results to the call log, and navigate to verify if answered
     * @param result The outcome of the attempted call
     */
    callResultSelected(result: OutboundCallResult): void {
        this.processingResult = true;
        if (!this.activePhoneNumber) return;
        this.selectedResult = result;
        this.addCallLog(result, this.activePhoneNumber);
        if (result === OutboundCallResult.Answered) this.hipaaService.navigate(HipaaRoutes.Verify);
        if (result === OutboundCallResult.AnsweredByOther) this.hipaaService.navigate(HipaaRoutes.SelectContactType);
    }

    /**
     * Set the active phone number
     * @param phoneNumber The phone number that the user is trying to contact
     */
    phoneNumberSelected(phoneNumber: PhoneNumber | null): void {
        if (phoneNumber === null) {
            this.allCallsFailed = true;
            this.overlayPane?.classList.remove('wide');
            return;
        }
        this.activePhoneNumber = phoneNumber;
        this.processingResult = false;
    }

    /**
     * Navigates to the hipaa add phone number screen
     */
    addPhoneNumber(): void {
        this.hipaaService.navigate(HipaaRoutes.AddPhoneNumber, false);
    }

    /**
     * Update the HIPAA Data call log with a new call result
     * @param result The outcome of the attempted call
     * @param phoneNumber The phone number that the user attempted to contact
     */
    private addCallLog(result: OutboundCallResult, phoneNumber: PhoneNumber): void {
        const call: OutboundCall = {
            contact: this.selectedContact!,
            callDate: new Date(),
            callResult: result,
            phoneNumber: phoneNumber,
        };
        this.hipaaService.addCallToLog(call);
    }
}
