import { Injectable } from '@angular/core';
import { PhoneNumberType } from 'src/app/models/call.models';
import { Patient } from 'src/app/models/patient.model';
import { PatientContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { PatientPayor } from 'src/app/models/profile/payor.model';
import { PatientPhysician } from 'src/app/models/profile/physician.model';

@Injectable({
    providedIn: 'root',
})
export class PatientAdapter {
    adapt(item: any): Patient {
        const patient: Patient = {
            id: item.id,
            mrn: item.mrn,
            deIdentifiedId: item.deIdentifiedId,
            specialId: item.specialId,
            specialIdManuallyGenerated: item.specialIdManuallyGenerated,
            firstName: item.firstName,
            lastName: item.lastName,
            dob: item.dob,
        };
        return patient;
    }

    adaptDemographic(item: any): PatientDemographic {
        const patientDemographic: PatientDemographic = {
            mrn: item.mrn,
            firstName: item.firstName,
            lastName: item.lastName,
            address: item.address,
            state: item.state,
            city: item.city,
            zip: item.zip,
            phone: item.phone,
            workPhone: item.workPhone,
            cellPhone: item.cellPhone,
            language: item.language,
            email: item.email,
            company: item.company,
            siteName: item.siteName,
            patientStatus: item.patientStatus,
            dob: item.dob,
            sex: item.sex,
            id: item.patientId,
            ssn: item.ssn,
            deidentifiedId: item.deidentifiedId,
            specialId: item.specialId,
            hubId: item.hubId,
        };
        return patientDemographic;
    }

    adaptPayor(item: any): PatientPayor {
        const patientPayor: PatientPayor = {
            mrn: item.mrn,
            rank: item.rank,
            pbm: item.pbm,
            segment: item.segment,
            coverageType: item.coverageType,
            memberID: item.memberID,
            binNumber: item.binNumber,
            pcn: item.pcn,
            groupNumber: item.groupNumber,
            status: item.status,
            effectiveDate: item.effectiveDate,
            terminationDate: item.terminationDate,
            billingNPI: item.billingNPI,
        };
        return patientPayor;
    }

    adaptContact(item: any): PatientContact {
        const patientContact: PatientContact = {
            mrn: item.mrn,
            firstName: item.firstName,
            lastName: item.lastName,
            address: item.address,
            state: item.state,
            city: item.city,
            zip: item.zip,
            email: item.email,
            homePhone: item.homePhone,
            workPhone: item.workPhone,
            cellPhone: item.cellPhone,
            title: item.title,
            phoneNumbers: [
                { number: item.cellPhone, numberType: PhoneNumberType.Cell },
                { number: item.homePhone, numberType: PhoneNumberType.Home },
                { number: item.workPhone, numberType: PhoneNumberType.Work },
            ],
        };

        return patientContact;
    }

    adaptPhysician(item: any): PatientPhysician {
        const patientDemographic: PatientPhysician = {
            mrn: item.mrn,
            physicianName: item.physicianName,
            remsId: item.remsId,
            supervisingPhysician: item.supervisingPhysician,
            specialty: item.specialty,
            organization: item.organization,
            address: item.address,
            city: item.city,
            state: item.state,
            zip: item.zip,
            phone: item.phone,
            phoneExtension: item.phoneExtension,
            fax: item.fax,
            email: item.email,
            npi: item.npi,
            license: item.license,
        };
        return patientDemographic;
    }
}
