<h1 mat-dialog-title>Special Identifier</h1>

<form
    id="specialIdForm"
    class="form-container"
    [formGroup]="formGroup">
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Special Id</mat-label>
        <mat-hint>Must be between 4 and 20 characters</mat-hint>
        <mat-error>Special Id must be between 4 and 20 characters</mat-error>
        <input
            matInput
            (change)="specialIdChanged()"
            formControlName="specialId" />

        <mat-icon
            *ngIf="(loading$ | async) === false"
            class="generate-button"
            (click)="getNewSystemGeneratedIdentifier()"
            matSuffix
            matTooltip="Generate"
            matTooltipPosition="left">
            vpn_key
        </mat-icon>
    </mat-form-field>
    <a
        *ngIf="authService.hasValidPermission(specialIdPermissionName, deletePermissionType) && hasSavedValue"
        class="alt-link"
        swftConfirm="Are you sure you want to delete this special id?"
        (click)="deleteSpecialId()">
        Delete assigned special id (set no special id)
    </a>
    <br />
    <swft-confirm
        *ngIf="(loading$ | async) === false"
        (deny)="cancel()"
        (confirm)="saveSpecialIdAssignment()"
        confirmText="Save"
        denyWarning="You have unsaved changes, are you sure?"
        [doubleConfirm]="!formGroup.pristine"
        [disabled]="formGroup.invalid || !this.specialId"></swft-confirm>
</form>

<swft-spinner
    coverId="specialIdForm"
    [inDialog]="true"
    [show]="loading$ | async"></swft-spinner>
