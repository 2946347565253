<ng-container *ngIf="callLog$ | async; let log">
    <ul
        id="HipaaCallLog"
        [class.hidden]="log.length === 0">
        <li *ngFor="let call of log">
            <ng-container *ngIf="call.contact">
                <header>
                    <p>{{ call.contact.firstName }} {{ call.contact.lastName }}</p>
                    <small>{{ call.contact.title }}</small>
                </header>
                <aside>
                    <p>{{ call.callResult }}</p>
                    <small>{{ call.phoneNumber.numberType }}</small>
                </aside>
            </ng-container>
        </li>
    </ul>
</ng-container>
