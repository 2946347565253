<h1 mat-dialog-title>HIPAA Verification</h1>
<div class="hipaa-container">
    <button
        *ngIf="showBackButton"
        class="back-btn"
        (click)="onBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <router-outlet name="hipaa"></router-outlet>
    <button
        mat-button
        (click)="onCancel()"
        class="cancel">
        Cancel
    </button>
</div>
