import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, take, takeUntil } from 'rxjs';
import { SwftGridSearchComponent } from '../components/swft/swft-grid-search/swft-grid-search.component';
import { PaginationDataSource } from '../models/api/datasource.models';
import { SwftGridColumn } from '../models/grid.models';

@Directive({
    selector: '[swftSearchGrid]',
})
export class SwftSearchGridDirective implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject<void>();

    @Input() columns: SwftGridColumn[] = [];
    @Input() dataSource!: PaginationDataSource<any, any>;
    @Input() gridTitle!: string;
    @Input() showDiscontinuedToggle: boolean = false;
    @Input() multiSelect: boolean = false;
    @Input() enabled: boolean = true;
    @Output() rowSelected = new EventEmitter<any>();
    @Output() showDiscontinuedChange = new EventEmitter<boolean>();

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private dialog: MatDialog,
        @Optional() private control?: NgControl
    ) {}

    ngOnInit(): void {
        /**
         * For a better click hitbox, attempt to select the entire form field. If a
         * parent form-field does NOT exist, select the native element.
         */
        const element: HTMLElement =
            this.elementRef.nativeElement.closest('mat-form-field') || this.elementRef.nativeElement;
        element.onclick = () => {
            // Control must be enabled as well as the NgControl
            if (this.enabled && !this.control?.disabled) {
                this.openSearchGrid();
            }
        };
        element.classList.add('swft-search-grid-input');

        /**
         * Remove auto-focus. When focused, the field's visual styles indicate to the user
         * that the field can be typed in to. This is not the case, so we need the field
         * to always be blurred.
         * */
        window.setTimeout(() => {
            this.elementRef.nativeElement.blur();
            this.control?.control?.markAsUntouched();
        }, 200);
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private openSearchGrid() {
        const searchDiaglog = this.dialog.open(SwftGridSearchComponent, {
            disableClose: true,
            panelClass: 'x-large-auto',
            data: {
                dataSource: this.dataSource,
                columns: this.columns,
                title: this.gridTitle,
                showDiscontinuedToggle: this.showDiscontinuedToggle,
                multiSelect: this.multiSelect,
            },
        });
        searchDiaglog.componentInstance.showDiscontinuedChange
            .pipe(takeUntil(searchDiaglog.afterClosed()))
            .subscribe(showDiscontinued => {
                this.showDiscontinuedChange.emit(showDiscontinued);
            });

        searchDiaglog
            .afterClosed()
            .pipe(take(1))
            .subscribe(row => {
                this.rowSelected.emit(row);
                const control = this.control?.control;
                if (!control) return;
                control.markAsTouched();
                control.updateValueAndValidity();
            });
    }
}
