<h1 mat-dialog-title>
    Warm Transfer
    <mat-icon>phone_forwarded</mat-icon>
</h1>

<div class="header-information">
    <h2>{{ title }}</h2>
    <div>{{ subtitle }}</div>
</div>

<div class="select-container">
    <h2>Choose a SWFT associate</h2>
    <mat-form-field
        appearance="fill"
        class="solid full">
        <mat-label>User</mat-label>
        <input
            matInput
            swftSelectSearch
            searchPlaceholder="Search Users"
            searchOrderBy="displayName"
            searchWidth="400px"
            searchValueFormat="{displayName} · {username}"
            [(ngModel)]="selectedUserGivenName"
            [fetch]="fetchMethod"
            (selectionChanged)="selectionChanged($event)" />
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <swft-confirm
        [disabled]="!selectedUserGivenName"
        confirmText="Complete Transfer"
        (confirm)="continue()"
        denyText="Cancel"
        (deny)="cancel()"></swft-confirm>
</div>
