<div
    [class.show]="showToolbar"
    class="swft-toolbar">
    <span>{{ title }}</span>
    <button
        mat-icon-button
        matTooltip="Create"
        *ngIf="showButton(create, permissionType.Create)"
        (click)="create.next(null)">
        <mat-icon>add_circle</mat-icon>
    </button>

    <button
        mat-icon-button
        matTooltip="Edit"
        *ngIf="showButton(edit, permissionType.Update)"
        (click)="edit.next(null)">
        <mat-icon>edit</mat-icon>
    </button>

    <button
        class="rotate90"
        mat-icon-button
        matTooltip="Assign Next and Last Actions"
        *ngIf="showButton(assignActions, permissionType.Read)"
        (click)="assignActions.next(null)">
        <mat-icon>alt_route</mat-icon>
    </button>

    <button
        mat-icon-button
        matTooltip="Assign Off-Ramp"
        *ngIf="showOffRampButton(offRamp, permissionType.Create)"
        (click)="offRamp.next(null)">
        <mat-icon>fork_right</mat-icon>
    </button>

    <mat-menu #startNarrativeMenu="matMenu">
        <button
            *ngIf="showButton(orderNarrative, permissionType.Read)"
            mat-menu-item
            class="toolbar-button"
            (click)="orderNarrative.next(null)">
            Order Narrative
        </button>
        <button
            *ngIf="showButton(patientNarrative, permissionType.Read)"
            mat-menu-item
            class="toolbar-button"
            (click)="patientNarrative.next(null)">
            Patient Narrative
        </button>
        <button
            *ngIf="showButton(hcpNarrative, permissionType.Read)"
            mat-menu-item
            class="toolbar-button"
            (click)="hcpNarrative.next(null)">
            HCP Narrative
        </button>
    </mat-menu>

    <button
        mat-icon-button
        matTooltip="Special Id"
        *ngIf="showButton(specialIdentifier, permissionType.Create, specialIdPermissionName)"
        (click)="specialIdentifier.next(null)">
        <mat-icon>vpn_key</mat-icon>
    </button>
</div>
