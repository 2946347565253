import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DuoRedirectParams } from 'src/app/models/auth/authorization.models';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'swft-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit, OnDestroy {
    onDestroy = new Subject<void>();
    constructor(private route: ActivatedRoute, private authService: AuthService) {}

    ngOnInit(): void {
        this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            const duo: DuoRedirectParams = new DuoRedirectParams(params.get('code') || '', params.get('state') || '');

            if (duo.invalid) return;

            // If the callback component is being loaded within an iFrame, do not continue the
            // authentication process. Instead, pass the response to the parent window to continue
            // the authentication process.
            if (window.location !== window.parent.location) {
                window.parent.postMessage(duo);
                return;
            }
            this.authService
                .callback(duo)
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: resp => {
                        if (resp.credentials.authResult.result === 'allow') {
                            this.authService.isLoggedIn.next(true);
                        }
                    },
                });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
