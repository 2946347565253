import { BehaviorSubject } from 'rxjs';
import { SearchType, SwftSort } from '../table.models';

export interface SearchOptions {
    value?: string;
    ascending?: boolean;
    orderBy?: string;
    page: number;
    pageLength: number;
}

export class ApiSearch {
    pageNumber: number = 0;
    pageSize: number = 10;
    sort: SwftSort<any> = { order: SortOrder.Ascending, property: 'id' };
    searchTerms?: BehaviorSubject<ApiSearchTerm[]> = new BehaviorSubject<ApiSearchTerm[]>([]);
    value?: string;
    dateFilter?: Date;
}

export interface ApiSearchTerm {
    searchField: string;
    value: string | number | boolean | Date;
    searchType?: SearchType;
    strictSearch?: boolean;
    subObjectSearch?: boolean;
    collectionSearch?: boolean;
}

export enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

export interface SelectSearchValues {
    name: string;
    values: string[];
}
