<section id="PatientInformation">
    <mat-list
        role="list"
        class="mat-elevation-z2"
        id="PatientProfileTabs">
        <ng-container *ngFor="let link of profileLinks">
            <mat-list-item
                (click)="showContent(link)"
                [routerLink]="link.url"
                routerLinkActive="active"
                #rla="routerLinkActive"
                role="listitem">
                {{ link.title }}
            </mat-list-item>
            <ng-container *ngIf="rla.isActive && canViewLink(link.permission)">
                <ng-container *ngFor="let child of link.children">
                    <mat-list-item
                        *ngIf="canViewLink(child.permission)"
                        class="child"
                        (click)="showChild()"
                        [routerLink]="child.url"
                        routerLinkActive="active"
                        role="listitem">
                        {{ child.title }}
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-list>
    <div
        id="PatientProfileContent"
        class="mat-elevation-z2 show">
        <router-outlet></router-outlet>
    </div>
</section>
