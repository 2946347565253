<aside>
    <label for="patient">
        {{ verificationType !== SWFTHipaaVerificationType.Patient ? 'Patient' : 'Patient / Contact' }}
    </label>
    <output name="patient">{{ patientDisplayValue }}</output>
    <ng-container *ngIf="verificationType !== SWFTHipaaVerificationType.Patient">
        <label for="contact">Contact</label>
        <output name="contact">{{ contactDisplayValue }}</output>
    </ng-container>
</aside>
<section>
    <form
        class="form-container verify-form"
        [formGroup]="formGroup">
        <h2>{{ verificationText }}</h2>

        <mat-form-field
            *ngIf="patient?.dob"
            appearance="fill"
            [class.success]="isValid('dob')"
            class="full solid">
            <mat-label>Date of Birth</mat-label>
            <input
                matInput
                [matDatepicker]="picker"
                formControlName="dob" />
            <mat-datepicker-toggle
                *ngIf="!isValid('dob')"
                matSuffix
                [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-icon
                class="success"
                *ngIf="isValid('dob')"
                matSuffix>
                done
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="patient?.ssn && patient?.ssn !== 'xxx-xx-xxxx'"
            appearance="fill"
            [class.success]="isValid('ssn')"
            class="full solid">
            <mat-label>SSN (Last 4 Digits)</mat-label>
            <input
                matInput
                [type]="hideZipValue ? 'password' : 'text'"
                autocomplete="off"
                formControlName="ssn" />
            <mat-icon
                *ngIf="!isValid('ssn')"
                matSuffix
                (click)="hideZipValue = !hideZipValue">
                {{ hideZipValue ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <mat-icon
                class="success"
                *ngIf="isValid('ssn')"
                matSuffix>
                done
            </mat-icon>
        </mat-form-field>

        <div
            class="verify-address"
            *ngIf="patient?.zip">
            <small>
                <mat-icon class="icon">warning</mat-icon>
                You must verify the entire address!
            </small>
            <label>Address</label>
            <p>
                {{ patient?.address }}
                <br />
                {{ patient?.city }}, {{ patient?.state }}
            </p>
            <mat-form-field
                appearance="fill"
                [class.success]="isValid('zipcode')"
                class="half solid">
                <mat-label>Zip Code</mat-label>
                <input
                    matInput
                    formControlName="zipcode" />
                <mat-icon
                    class="success"
                    *ngIf="isValid('zipcode')"
                    matSuffix>
                    done
                </mat-icon>
            </mat-form-field>
        </div>
    </form>
</section>
<div class="button-container">
    <swft-form-errors
        [formGroup]="formGroup"
        [floatable]="false"></swft-form-errors>
    <button
        [disabled]="(validForm | async) === false"
        color="primary"
        class="center"
        mat-raised-button
        (click)="nextStep()">
        Continue
    </button>
</div>
