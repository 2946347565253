import { Endpoint, IEndpoint } from './endpoint';

export class BusinessEndpoints {
    consent = new ConsentEndpoints();
    consentSearch = new ConsentSearchEndpoints();
    lob: LobEndpoints = new LobEndpoints();
    drugInfo: DrugInfoEndpoints = new DrugInfoEndpoints();
    drugInfoSearch: DrugInfoSearchEndpoints = new DrugInfoSearchEndpoints();
    rxTypeSearch: RxTypeSearchEndpoints = new RxTypeSearchEndpoints();
    partsSearch: PartsSearchEndpoints = new PartsSearchEndpoints();
    fedex: FedExEndpoints = new FedExEndpoints();
    customDataEndpoints: CustomDataAttributeEndpoints = new CustomDataAttributeEndpoints();
    addressCrudEndpoints: AddressCrudEndpoints = new AddressCrudEndpoints();
    customDataValueEndpoints: CustomDataAttributeValueEnpoints = new CustomDataAttributeValueEnpoints();
    layoutBuilderEndpoints: LayoutBuilderEndpoints = new LayoutBuilderEndpoints();
    layoutModuleEndpoints: LayoutModuleEndpoints = new LayoutModuleEndpoints();
}

export class LobEndpoints {
    fetchAll: IEndpoint = new Endpoint('get', 'v1/BusinessLine');
    create: IEndpoint = new Endpoint('post', 'v1/BusinessLine');
    read: IEndpoint = new Endpoint('get', 'v1/BusinessLine/{id}');
    edit: IEndpoint = new Endpoint('put', 'v1/BusinessLine');
    delete: IEndpoint = new Endpoint('delete', 'v1/BusinessLine/{id}');
    search: IEndpoint = new Endpoint('post', 'v1/BusinessLine/search');
    validate: IEndpoint = new Endpoint('get', 'v1/BusinessLine/validate/{query}');
    getByPatientId: IEndpoint = new Endpoint('get', 'v1/BusinessLine/patient/{patientId}');
    deleteByPatient: IEndpoint = new Endpoint('delete', 'v1/businessLine/{businessLineId}/patient/{patientId}');
}
export class DrugInfoEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/DrugInfo');
    update: IEndpoint = new Endpoint('put', 'v1/DrugInfo');
    read: IEndpoint = new Endpoint('get', 'v1/DrugInfo/{id}');
    delete: IEndpoint = new Endpoint('delete', 'v1/DrugInfo/{id}');
    validateNameUnique: IEndpoint = new Endpoint('get', 'v1/DrugInfo/validate/{query}');
    search: IEndpoint = new Endpoint('post', 'v1/DrugInfo/search');
}

export class AddressCrudEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/Address');
    update: IEndpoint = new Endpoint('put', 'v1/Address');
    delete: IEndpoint = new Endpoint('delete', 'v1/Address/{id}');
}
export class CustomDataAttributeEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/CustomDataAttribute/search');
    create: IEndpoint = new Endpoint('post', 'v1/CustomDataAttribute');
    read: IEndpoint = new Endpoint('get', 'v1/CustomDataAttribute/{id}');
    edit: IEndpoint = new Endpoint('put', 'v1/CustomDataAttribute');
    delete: IEndpoint = new Endpoint('delete', 'v1/CustomDataAttribute/{id}');
}

export class CustomDataAttributeValueEnpoints {
    create: IEndpoint = new Endpoint('post', 'v1/CustomDataAttributeValue');
    read: IEndpoint = new Endpoint('get', 'v1/CustomDataAttributeValue/{id}');
    edit: IEndpoint = new Endpoint('put', 'v1/CustomDataAttributeValue');
    delete: IEndpoint = new Endpoint('delete', 'v1/CustomDataAttributeValue/{id}');
}

export class DrugInfoSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/DrugInfoSearch/search');
}

export class RxTypeSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/RxTypeSearch/search');
}

export class PartsSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/PartsSearch/search');
}

export class FedExEndpoints {
    validateAddress: IEndpoint = new Endpoint('post', 'v1/ValidateAddress');
}

export class LayoutBuilderEndpoints {
    layoutModule: LayoutModuleEndpoints = new LayoutModuleEndpoints();
}

export class LayoutModuleEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/layoutbuilder/search');
    create: IEndpoint = new Endpoint('post', 'v1/layoutbuilder/create');
    read: IEndpoint = new Endpoint('get', 'v1/layoutbuilder/read/{id}');
    update: IEndpoint = new Endpoint('put', 'v1/layoutbuilder');
    delete: IEndpoint = new Endpoint('delete', 'v1/layoutbuilder/{id}');
    validate: IEndpoint = new Endpoint('get', 'v1/layoutbuilder/validate/{title}');
    fetchLayoutBuilderDataSources: IEndpoint = new Endpoint('get', 'v1/layoutbuilder/datasources');
}

export class ConsentSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/consentsearch/search');
}

export class ConsentEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/consentsearch/search');
    read: IEndpoint = new Endpoint('post', 'v1/consentsearch/search');
    update: IEndpoint = new Endpoint('post', 'v1/consentsearch/search');
    delete: IEndpoint = new Endpoint('post', 'v1/consentsearch/search');
}
