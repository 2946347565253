<swft-toolbar
    *ngIf="!searchId"
    title="{{ narrativeType }} Narrative Tools"
    (create)="openCreateNarrativeDialog()"></swft-toolbar>

<swft-grid
    #grid
    [gridConfiguration]="gridConfiguration"
    (bulkAction)="bulkAction($event)"
    (rowAction)="rowAction($event)"></swft-grid>

<swft-spinner
    [coverPage]="true"
    [show]="isStartingConversation"></swft-spinner>
