import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject, take } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { CallType, OutboundCall, OutboundCallResult, PhoneNumber, PhoneNumberType } from 'src/app/models/call.models';
import { HipaaContact, HipaaData, HipaaValidateContact, SWFTHipaaAttestations } from 'src/app/models/hipaa.models';
import { Contact, CoveredEntityContact, HcpContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { PatientPhysician } from 'src/app/models/profile/physician.model';
import { PhoneNumberRegex } from 'src/app/utils/constants';
import { HipaaComponent } from '../../components/hipaa/hipaa.component';

@Injectable({
    providedIn: 'root',
})
export class HipaaService implements OnDestroy {
    private hipaaData$: BehaviorSubject<HipaaData> = new BehaviorSubject<HipaaData>(new HipaaData());
    private onDestroy$: Subject<void> = new Subject<void>();
    private history: string[] = [];

    showBackButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    contactValidator$: BehaviorSubject<HipaaValidateContact> = new BehaviorSubject<HipaaValidateContact>({
        contact: null,
        valid: false,
    });

    /**
     * When changes are made to the hipaa data, check the validity of the selected contact
     */
    constructor(private router: Router, private dialog: MatDialog) {}

    get attestations(): SWFTHipaaAttestations {
        return this.hipaaData$.value.attestations;
    }

    get callLog$(): Observable<OutboundCall[]> {
        return this.dataChanges.pipe(map(data => data.callLog));
    }

    get dataChanges(): BehaviorSubject<HipaaData> {
        return this.hipaaData$;
    }

    get patient$(): Observable<PatientDemographic> {
        return this.dataChanges.pipe(map(data => data.patient));
    }

    get patientData(): PatientDemographic {
        return this.hipaaData$.value.patient;
    }

    get patientId(): number {
        return this.patientData.id;
    }

    get patientDisplayName(): string {
        return `${this.patientData.mrn} - ${this.patientData.firstName} ${this.patientData.lastName}`;
    }

    get phoneNumbers(): PhoneNumber[] {
        let phoneNumbers: PhoneNumber[] = [];
        if (this.hipaaData$.value.contact?.workPhone) {
            phoneNumbers.push({
                number: this.hipaaData$.value.contact?.workPhone,
                numberType: PhoneNumberType.Work,
            });
        }
        if (this.hipaaData$.value.contact?.cellPhone) {
            phoneNumbers.push({
                number: this.hipaaData$.value.contact?.cellPhone,
                numberType: PhoneNumberType.Cell,
            });
        }
        if (this.hipaaData$.value.contact?.homePhone) {
            phoneNumbers.push({
                number: this.hipaaData$.value.contact?.homePhone,
                numberType: PhoneNumberType.Home,
            });
        }
        this.hipaaData$.value.contact?.phoneNumbers?.forEach(phoneNumber => {
            phoneNumbers.push(phoneNumber);
        });

        //Filter out duplicate phone numbers
        phoneNumbers = phoneNumbers.filter((number, index, numberArray) => {
            return numberArray.findIndex(active => active.number === number.number) === index;
        });

        return phoneNumbers;
    }

    get verificationType(): HipaaVerificationType {
        return this.hipaaData$.value.verificationType ?? null;
    }

    get selectedHcp(): PatientPhysician | null {
        return this.hipaaData$.value.hcpContact ?? null;
    }

    set callType(callType: CallType) {
        const update = this.hipaaData$.value;
        if (callType === CallType.DocumentOnly) {
            update.attestations.documentation = true;
        }
        update.callType = callType;
        this.hipaaData$.next(update);
    }

    set documentationAttestation(active: boolean) {
        const update: SWFTHipaaAttestations = this.hipaaData$.value.attestations;
        update.documentation = active;
        this.hipaaData$.next({ ...this.hipaaData$.value, attestations: update });
    }

    set attestationInitials(userInitials: string) {
        const update: SWFTHipaaAttestations = this.hipaaData$.value.attestations;
        update.userInitials = userInitials;
        this.hipaaData$.next({ ...this.hipaaData$.value, attestations: update });
    }

    set verificationType(verificationType: HipaaVerificationType) {
        const update: HipaaData = this.hipaaData$.value;
        update.verificationType = verificationType;
        this.hipaaData$.next(update);
    }

    set patient(patient: PatientDemographic) {
        const update: HipaaData = this.hipaaData$.value;
        update.patient = patient;
        if (update.verificationType === HipaaVerificationType.Patient) {
            const contact = this.getPatientDataAsContact();
            update.contact = contact;
            this.validateContact(contact, HipaaVerificationType.Patient);
        }
        this.hipaaData$.next(update);
    }

    set contact(contact: HipaaContact) {
        const update: HipaaData = this.hipaaData$.value;
        update.contact = contact;
        this.hipaaData$.next(update);
    }

    set hcpContact(hcpContact: PatientPhysician) {
        const update: HipaaData = this.hipaaData$.value;
        update.hcpContact = hcpContact;
        this.hipaaData$.next(update);
    }

    hasNewPhoneNumber: boolean = false;

    /**
     * When the service is destroyed, unsubscribe from all subscriptions
     * and reset the hipaa data
     */
    ngOnDestroy(): void {
        this.clearData();
        this.hipaaData$.complete();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Add a phone number to the contact phone number
     */
    addPhoneNumber(phoneNumber: PhoneNumber): void {
        const update: HipaaData = this.hipaaData$.value;
        const phoneNumbers = update.contact?.phoneNumbers ?? [];
        if (update.contact) {
            update.contact.phoneNumbers = [...phoneNumbers, phoneNumber];
        }
        this.hipaaData$.next(update);
    }

    /**
     * Add a call attempt to the call log
     * @param call Accepts an OutboundCall object and adds it to the call log
     */
    addCallToLog(call: OutboundCall): void {
        const update: HipaaData = this.hipaaData$.value;
        if (call.callResult === OutboundCallResult.Ignored) {
            update.attestations.ignoredNumbers = true;
        }
        update.callLog.push(call);
        this.hipaaData$.next(update);
    }

    /**
     * Update the history and navigate the user to the previous url.
     * If a user navigates back from the verify screen of an outbound call, remove the last callog entry.
     */
    back(): void {
        let navigateTo = this.history.pop();

        if (this.router.url.includes('verify') && this.hipaaData$.value.callType === CallType.Outbound) {
            const update: HipaaData = this.hipaaData$.value;
            update.callLog.pop();
            this.hipaaData$.next(update);
            //another temp line for brando so back works while number selection is being worked on
            //TODO remove after number selection is finished
            if (
                this.hipaaData$.value.verificationType === HipaaVerificationType.Payor ||
                this.hipaaData$.value.verificationType === HipaaVerificationType.CoveredEntity
            ) {
                navigateTo = this.history.pop();
            }
        }

        this.showBackButton$.next(!!this.history.length);
        if (navigateTo) {
            this.router.navigateByUrl(navigateTo, {
                skipLocationChange: true,
            });
        }
    }

    /**
     * Resets the hipaa data to the default values
     */
    clearData(): void {
        this.hipaaData$.next(new HipaaData());
    }

    /**
     * Handles navigation within the hipaa auxilary route
     * @param route A specific Hipaa route to navigate to
     * @param redirect If true, navigation acts like a redirect (history will not be updated).
     */
    navigate(route: HipaaRoutes, redirect: boolean = false): void {
        const preNavigationUrl: string = this.router.url;
        const urlSegments: string[] = [route];
        if (route === HipaaRoutes.SelectContact) urlSegments.push(this.hipaaData$.value.verificationType);
        this.router
            .navigate(['', { outlets: { hipaa: urlSegments } }], {
                skipLocationChange: true,
            })
            ?.then(() => {
                this.updateHistory(urlSegments, redirect, preNavigationUrl);
            })
            .catch(() => {
                // THIS SHOULD BE REMOVED OR UPDATED, once functionality for Covered Entity is implemented
                alert('Functionality not yet implemented.');
            });
    }

    /**
     * Opens the hipaa dialog. On close, hipaa data is reset.
     */
    openHipaa(): MatDialogRef<HipaaComponent, any> | null {
        const isOpen = !!this.dialog.getDialogById('hipaa');
        if (isOpen) return null;

        const dialogRef = this.dialog.open(HipaaComponent, {
            id: 'hipaa',
            panelClass: 'hipaa-dialog',
            disableClose: true,
        });

        dialogRef
            ?.afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                this.history = ['intro'];
                this.showBackButton$.next(false);
                if (!res) {
                    this.router.navigate([this.router.url]);
                }
            });

        return dialogRef;
    }

    validateContact(contact: HipaaContact, type: HipaaVerificationType): void {
        const validate: HipaaValidateContact = {
            contact: contact,
            valid: false,
        };
        this.checkContactValidity(validate);
        this.verificationType = type;
    }

    /**
     * Reset the contact validator by setting its valid property to false.
     * Contact data is retained for sake of reference.
     */
    resetContactValidator() {
        this.contactValidator$.next({ contact: this.contactValidator$.value.contact, valid: false });
    }

    /**
     * If a patient is selected as the point of contact, extract
     * the contact information from the patient data
     * @returns The patient data as a contact
     */
    private getPatientDataAsContact(): Contact {
        const patient = this.patientData;
        return {
            firstName: patient.firstName,
            lastName: patient.lastName,
            address: patient.address,
            cellPhone: patient.cellPhone,
            homePhone: patient.phone,
            workPhone: patient.workPhone,
            city: patient.city,
            state: patient.state,
            email: patient.email,
            title: 'Patient',
            zip: patient.zip,
            phoneNumbers: [
                { number: patient.cellPhone, numberType: PhoneNumberType.Cell },
                { number: patient.phone, numberType: PhoneNumberType.Home },
                { number: patient.workPhone, numberType: PhoneNumberType.Work },
            ],
        };
    }

    /**
     * Updates the navigation history array
     * @param urlSegments The array of url segments that the user navigated to
     * @param redirect If true, navigation acts like a redirect (history will not be updated).
     * @param url The url that the user navigated from
     */
    private updateHistory(urlSegments: string[], redirect: boolean, url: string): void {
        if (urlSegments.includes(HipaaRoutes.Intro)) {
            this.history = [];
            this.showBackButton$.next(false);
            return;
        }

        if (!redirect) this.history.push(url);
        this.showBackButton$.next(!!this.history.length);
    }

    /**
     * Checks that the current contact data is valid for the selected verification type
     */
    private checkContactValidity(validate: HipaaValidateContact): void {
        const hipaaData = this.hipaaData$.value;
        const contact = validate.contact;

        if (!contact) {
            this.contactValidator$.next(validate);
            return;
        }

        // If the contact is a patient...
        if (hipaaData.verificationType === HipaaVerificationType.Patient && hipaaData.patient.id) {
            this.contactValidator$.next({ ...validate, valid: true });
            return;
        }

        // If the contact is an authorized representitive...
        if (hipaaData.verificationType === HipaaVerificationType.AuthRep && validate.contact?.firstName) {
            this.contactValidator$.next({ ...validate, valid: true });
            return;
        }

        // If the contact is a payor...
        if (
            hipaaData.verificationType === HipaaVerificationType.Payor &&
            contact.firstName &&
            contact.lastName &&
            contact.title
        ) {
            this.contactValidator$.next({ ...validate, valid: true });
            return;
        }

        // If the contact is a HCP...
        if (
            hipaaData.verificationType === HipaaVerificationType.HCP &&
            contact.firstName &&
            contact.lastName &&
            contact.title &&
            new RegExp(PhoneNumberRegex).test(contact.workPhone) &&
            (contact as HcpContact).organization
        ) {
            this.contactValidator$.next({ ...validate, valid: true });
            return;
        }

        // If the contact is a Covered Entity...
        if (
            hipaaData.verificationType === HipaaVerificationType.CoveredEntity &&
            contact.firstName &&
            contact.lastName &&
            contact.title &&
            new RegExp(PhoneNumberRegex).test(contact.workPhone) &&
            (contact as CoveredEntityContact).organization
        ) {
            this.contactValidator$.next({ ...validate, valid: true });
            return;
        }

        // If the contact or verification type is not found or invalid...
        this.contactValidator$.next({ ...validate, valid: false });
    }
}
