import { BusinessLine } from '../businessline.model';

export class User {
    id: number;
    displayName: string;
    phone: string;
    username: string;
    nickname: string;
    givenName: string;
    lastLoginDate: Date;
    pnNameId?: number;
    businessLines: Array<BusinessLine> = [];
    groups: Array<string> = [];

    constructor(
        id: number,
        displayName: string,
        phone: string,
        email: string,
        nickname: string,
        givenName: string,
        lastLoginDate: Date,
        pnNameId?: number
    ) {
        this.id = id;
        this.displayName = displayName;
        this.phone = phone;
        this.username = email;
        this.nickname = nickname;
        this.givenName = givenName;
        this.lastLoginDate = lastLoginDate;
        this.pnNameId = pnNameId;
    }
}

export interface UserViewModel {
    id: number;
    username: string;
    displayName: string;
    phone: string;
}
