<section class="title">
    <h1 class="swft-title">
        {{ profileType }} Profile
        <mat-icon>chevron_right</mat-icon>
        <span>
            {{ lastName }}, {{ firstName }}
            <small>
                <mat-icon>chevron_right</mat-icon>
                {{ profileString }}: {{ id }}
            </small>
        </span>
    </h1>
</section>
