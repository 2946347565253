import { DatePipe, DecimalPipe } from '@angular/common';
import { Injector, Pipe, PipeTransform } from '@angular/core';
import { DateFormat, DateTimeFormat } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';
import { StripHtmlTagsPipe } from '../strip-html-tags/strip-html-tags.pipe';
import { YesNoPipe } from '../yes-no/yes-no.pipe';

@Pipe({
    name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(value: any, pipeToken: any): any {
        try {
            if (pipeToken) {
                switch (pipeToken) {
                    case 'date':
                        var pipe = this.injector.get(DatePipe);
                        return pipe.transform(value, DateFormat);
                    case 'dateTime':
                        var pipe = this.injector.get(DatePipe);
                        return pipe.transform(value, DateTimeFormat);
                    case 'decimal':
                        var decPipe = this.injector.get(DecimalPipe);
                        return decPipe.transform(value, '1.2');
                    case 'boolean':
                        var yesNoPipe = this.injector.get(YesNoPipe);
                        return yesNoPipe.transform(value);
                    case 'stripHTMLTags':
                        var stripHtmlTagsPipe = this.injector.get(StripHtmlTagsPipe);
                        return stripHtmlTagsPipe.transform(value);
                    default:
                        // If we have a function, use the function to parse the
                        if (typeof pipeToken === 'function') return pipeToken(value);
                        else return value;
                }
            } else {
                return value;
            }
        } catch (err) {
            if (!environment.production) console.log(err);
            return value;
        }
    }
}
