import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Subscription, take } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/swft/swft-confirmation-dialog/confirmation-dialog.component';

@Directive({
    selector: '[swftConfirm]',
})
export class ConfirmationDialogDirective implements OnInit, OnDestroy {
    private onClick: Subscription = new Subscription();
    private element = this.elRef.nativeElement;
    private confirmed: boolean = false;
    @Input('swftConfirm') confirm: string = 'Are you sure?';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('swftDescription') description: string = '';

    constructor(private elRef: ElementRef, private dialog: MatDialog) {}

    ngOnInit() {
        /**
         * Hijack the click event and open a confirmation dialog.
         * Upon user confirmation, the original click event is re-emitted and the dialog is bypassed.
         * Upon user denial, the dialog is closed and the original click event is cancelled.
         */
        this.onClick = fromEvent(this.element, 'click', {
            capture: true,
        }).subscribe((event: any) => {
            if (this.confirmed) {
                this.confirmed = false;
                return;
            }

            event.stopPropagation();

            this.dialog
                .open(ConfirmationDialogComponent, {
                    data: {
                        message: this.confirm,
                        description: this.description,
                    },
                    panelClass: 'small-auto',
                })
                .afterClosed()
                .pipe(take(1))
                .subscribe(confirmed => {
                    if (confirmed) {
                        this.confirmed = true;
                        this.element.dispatchEvent(new PointerEvent('click', this.element));
                        return;
                    }
                });
        });
    }

    ngOnDestroy() {
        this.onClick.unsubscribe();
    }
}
