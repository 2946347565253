import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicPipe } from 'src/app/pipes/dynamic/dynamic.pipe';
import { HtmlPipe } from 'src/app/pipes/html/html.pipe';
import { PipeDelimiterPipe } from 'src/app/pipes/pipe-delimiter/pipe-delimiter.pipe';
import { SearchResponseValuesPipe } from 'src/app/pipes/search-response-values/searchResponseValues.pipe';
import { StripHtmlTagsPipe } from 'src/app/pipes/strip-html-tags/strip-html-tags.pipe';
import { TruncatePipe } from 'src/app/pipes/truncate/truncate.pipe';
import { IconService } from 'src/app/services/icon/icon.service';

@NgModule({
    declarations: [HtmlPipe, PipeDelimiterPipe, DynamicPipe, SearchResponseValuesPipe, TruncatePipe, StripHtmlTagsPipe],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        HtmlPipe,
        PipeDelimiterPipe,
        DynamicPipe,
        SearchResponseValuesPipe,
        TruncatePipe,
        StripHtmlTagsPipe,
    ],
})
export class MaterialModule {
    constructor(private iconService: IconService) {
        this.iconService.registerIcons();
    }
}
