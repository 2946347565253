import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import {
    DataDictionary,
    DataDictionarySearch,
    DataDictionarySearchOptions,
} from 'src/app/models/data-dictionary.model';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { DataDictionaryApiService } from 'src/app/services/api/data-dictionary.api.service';
import { SwftBasis } from 'src/app/utils/constants';
import { mapFromSearchResult } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DataDictionaryService {
    constructor(private dataDictionaryApi: DataDictionaryApiService) {}

    pageDataDictionary(
        request: PageRequest<DataDictionarySearch>,
        query: SingleSearchTermQuery,
        dbType: string = '',
        logicUseOnly: boolean = false
    ): Observable<Page<DataDictionarySearch>> {
        const params: DataDictionarySearchOptions = {
            value: query.search,
            ascending: request.sort?.order! == SortOrder.Ascending,
            orderBy: request.sort?.property!,
            page: request.page,
            pageLength: request.size,
            dbType,
            logicUseOnly,
        };

        const response = this.dataDictionaryApi.getObservableEndpoint(
            environment.apiQuestionBuilder.endpoints.dataDictionary.search
        )(params);

        return response.pipe(mapFromSearchResult);
    }

    searchDataDictionary(
        options: SearchOptions,
        query: SingleSearchTermQuery,
        dbType: string = '',
        logicUseOnly: boolean = false
    ) {
        const pageRequest: PageRequest<DataDictionarySearch> = {
            page: options.page,
            size: options.pageLength,
            sort: {
                order: options.ascending ? SortOrder.Ascending : SortOrder.Descending,
                property: options.orderBy as keyof DataDictionarySearch,
            },
        };
        return this.pageDataDictionary(pageRequest, query, dbType, logicUseOnly);
    }

    searchDataDictionaryDynamicTagsWithBasis(
        options: SearchOptions,
        query: SingleSearchTermQuery,
        basis: SwftBasis[] | undefined
    ) {
        const pageRequest: PageRequest<DataDictionarySearch> = {
            page: options.page,
            size: options.pageLength,
            sort: {
                order: options.ascending ? SortOrder.Ascending : SortOrder.Descending,
                property: options.orderBy as keyof DataDictionarySearch,
            },
        };

        const params = {
            value: query.search,
            ascending: pageRequest.sort?.order! == SortOrder.Ascending,
            orderBy: pageRequest.sort?.property!,
            page: pageRequest.page,
            pageLength: pageRequest.size,
            formbasis: basis,
        };

        const response = this.dataDictionaryApi.getObservableEndpoint(
            environment.apiQuestionBuilder.endpoints.dataDictionary.searchWithBasis
        )(params);

        return response.pipe(mapFromSearchResult);
    }

    update(dataDictionary: DataDictionary): Observable<DataDictionary> {
        return this.dataDictionaryApi.getObservableEndpoint(
            environment.apiQuestionBuilder.endpoints.dataDictionary.update
        )(dataDictionary);
    }

    read(id: number): Observable<DataDictionary> {
        return this.dataDictionaryApi.getObservableEndpoint(
            environment.apiQuestionBuilder.endpoints.dataDictionary.read
        )(null, { id });
    }
}
