import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { HipaaAttestationComponent } from './hipaa-attestation/hipaa-attestation.component';
import { HipaaIntroComponent } from './hipaa-intro/hipaa-intro.component';
import { HipaaAddPhoneNumberComponent } from './hipaa-outbound-call/hipaa-add-phone-number/hipaa-add-phone-number.component';
import { HipaaOutboundCallComponent } from './hipaa-outbound-call/hipaa-outbound-call.component';
import { HipaaSelectContactTypeComponent } from './hipaa-select-contact-type/hipaa-select-contact-type.component';
import { HipaaAuthorizedRepComponent } from './hipaa-select-contact/hipaa-authorized-rep/hipaa-authorized-rep.component';
import { HipaaCoveredEntityComponent } from './hipaa-select-contact/hipaa-covered-entity/hipaa-covered-entity.component';
import { HipaaHCPComponent } from './hipaa-select-contact/hipaa-hcp/hipaa-hcp.component';
import { HipaaPayorComponent } from './hipaa-select-contact/hipaa-payor/hipaa-payor.component';
import { HipaaSelectContactComponent } from './hipaa-select-contact/hipaa-select-contact.component';
import { HipaaVerificationComponent } from './hipaa-verification/hipaa-verification.component';

const routes: Routes = [
    {
        path: '',
        component: HipaaIntroComponent,
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.Intro,
        redirectTo: '',
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.Attestation,
        component: HipaaAttestationComponent,
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.OutboundCall,
        component: HipaaOutboundCallComponent,
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.SelectContact,
        component: HipaaSelectContactComponent,
        outlet: 'hipaa',
        children: [
            {
                path: HipaaVerificationType.Patient,
            },
            {
                path: HipaaVerificationType.AuthRep,
                component: HipaaAuthorizedRepComponent,
            },
            {
                path: HipaaVerificationType.HCP,
                component: HipaaHCPComponent,
            },
            {
                path: HipaaVerificationType.Payor,
                component: HipaaPayorComponent,
            },
            {
                path: HipaaVerificationType.CoveredEntity,
                component: HipaaCoveredEntityComponent,
            },
        ],
    },
    {
        path: HipaaRoutes.SelectContactType,
        component: HipaaSelectContactTypeComponent,
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.Verify,
        component: HipaaVerificationComponent,
        outlet: 'hipaa',
    },
    {
        path: HipaaRoutes.AddPhoneNumber,
        component: HipaaAddPhoneNumberComponent,
        outlet: 'hipaa',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HipaaRoutingModule {}
