import { SwftBasis } from '../utils/constants';
import { Answer } from './answer.model';
import { Conversation, ConversationNarrative } from './conversation.model';
import { Doctor } from './doctor.model';
import { SwftForm } from './form.models';
import { Order } from './order.model';
import { Patient } from './patient.model';

export interface Narrative {
    lastActionDate?: Date;
    id: number;
    formName: string;
    status: NarrativeStatus;
    patientId?: number;
    formId?: number;
    otId?: number;
    formBasisName?: SwftBasis;
    formTypeName?: string;
    hcpId?: number;
    patient?: Patient;
    form?: SwftForm;
    doctor?: Doctor;
    order?: Order;
    conversationNarrative?: ConversationNarrative[];
    conversations?: Conversation[];
    nextActionDate?: Date;
    nextActionId?: number;
    lastActionId?: number;
    daysUntilNextAction?: number;
    completionDate?: Date;
    answers?: Answer[];
    medicationName?: string;
    modifiedDate: Date;
    createdDate: Date;
}

export interface NarrativeSearch {
    id: number;
    patientId: number | undefined;
    mrn: number | undefined;
    otId?: number;
    formId: number;
    formName: string;
    formTypeName: string;
    formBasisName: string;
    firstName: string;
    lastName: string;
    medicationName?: string;
    hcpId?: number;
    hcpFirstName?: string;
    hcpLastName?: string;
    status: NarrativeStatus;
    nextActionDate?: string;
    lastActionDate?: string;
    nextActionName?: string;
    lastActionName?: string;
}
export enum NarrativeStatus {
    Open = 'Open',
    Cancelled = 'Cancelled',
    //Brando wants 'Complete' do not update to 'Completed'
    Complete = 'Complete',
    All = 'Complete|Cancelled',
}
