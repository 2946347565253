import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, Observable, throwError } from 'rxjs';
import {
    AsyncEndpoint,
    ObservableEndpoint,
    PromiseEndpoint,
    ResponseTypes,
} from 'src/app/models/api/api-config.models';
import { environment } from 'src/environments/config';
import { IEndpoint } from 'src/environments/endpoints/endpoint';
import { ApplicationInsightsService } from '../app-insights/app-insights.service';

@Injectable({
    providedIn: 'root',
})
export class BaseApiService {
    protected baseUrl = '';

    constructor(private http: HttpClient, private appInsights: ApplicationInsightsService) {}

    getPromiseEndpoint(endpoint: IEndpoint): PromiseEndpoint {
        return this.getEndpoint(endpoint, true) as PromiseEndpoint;
    }

    getObservableEndpoint(endpoint: IEndpoint): ObservableEndpoint {
        return this.getEndpoint(endpoint, false) as ObservableEndpoint;
    }

    private getEndpoint(endpoint: IEndpoint, asPromise: boolean = false): AsyncEndpoint {
        return (payload?: any, urlParams?: any, responseType?: ResponseTypes): Observable<any> | Promise<any> => {
            if (urlParams) {
                endpoint.interpolate(urlParams);
            }

            const responseTypeToSend = responseType ? responseType : 'json';

            return this.request(endpoint, payload, responseTypeToSend, asPromise);
        };
    }

    private request(
        endpoint: IEndpoint,
        payload: any,
        responseType: ResponseTypes,
        asPromise: boolean
    ): Observable<any> | Promise<any> {
        const options = Object.assign({
            body: payload,
            responseType: responseType,
        });

        const $request = this.http.request(endpoint.verb, `${this.baseUrl}${endpoint.path}`, options).pipe(
            map(response => this.defaultResponseHandler(response)),
            catchError(response => this.defaultErrorHandler(response))
        );

        return asPromise ? lastValueFrom($request) : $request;
    }

    private defaultResponseHandler(response: any) {
        this.appInsights.logEvent('API RESPONSE: ', response);
        return response;
    }

    private defaultErrorHandler(error: any) {
        if (!environment.production) {
            console.error('HTTP REQUEST ERROR: ', error);
        }

        error.message = 'API ERROR: ' + error.message;
        this.appInsights.logError('BaseApiService', 'defaultErrorHandler', error);

        return throwError(() => error);
    }
}
