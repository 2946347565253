import { PermissionType } from 'src/app/models/auth/authorization.models';
import { ProfileLink } from 'src/app/models/profile/profile-link.model';

export const PatientLinks: ProfileLink[] = [
    {
        title: 'Demographics',
        url: 'demographics',
        active: false,
    },
    {
        title: 'Contacts',
        url: 'contacts',
        active: false,
    },
    {
        title: 'Notes',
        url: 'notes',
        active: false,
        children: [
            {
                title: 'Progress',
                url: 'notes/progress',
                active: false,
            },
            {
                title: 'Conversation',
                url: 'notes/conversation',
                active: false,
                permission: {
                    name: 'conversationNotes',
                    type: PermissionType.Search,
                },
            },
            {
                title: 'Billing',
                url: 'notes/billing',
                active: false,
            },
        ],
    },
    {
        title: 'Orders',
        url: 'orders',
        active: false,
    },
    {
        title: 'Physicians',
        url: 'physicians',
        active: false,
    },
    {
        title: 'Payors',
        url: 'payors',
        active: false,
    },
    {
        title: 'Medical History',
        url: 'medical-history',
        active: false,
    },
    {
        title: 'Dispenses',
        url: 'dispenses',
        active: false,
    },
    {
        title: 'Delivery Tickets',
        url: 'delivery-tickets',
        children: [
            { title: 'Working', url: 'delivery-tickets/working', active: false },
            { title: 'Confirmed', url: 'delivery-tickets/confirmed', active: false },
        ],
        active: false,
    },
    {
        title: 'Discharges',
        url: 'discharges',
        active: false,
    },
    {
        title: 'User Defined',
        url: 'user-defined',
        active: false,
    },
    {
        title: 'Answer History',
        url: 'answer-history',
        active: false,
    },
    {
        title: 'Consent',
        url: 'consent',
        active: false,
    },
    {
        title: 'Addresses',
        url: 'shipping-addresses',
        active: false,
    },
];
