<span
    class="no-bulk-actions"
    *ngIf="!showActions || displayedActions.length === 0">
    Actions
</span>

<span
    class="select-column"
    *ngIf="showButton(selections, permissionType.Read, GridAction.Select)">
    <mat-checkbox
        [(ngModel)]="checked"
        (change)="onCheckBoxClicked($event)"
        [disabled]="disabled"
        aria-label="Select"
        [matTooltip]="disabled ? '' : 'Select'"
        class="checkbox"></mat-checkbox>
</span>

<mat-select
    *ngIf="showActions || (displayedActions.length > 0 && gridAction.observed)"
    class="action-header"
    id="bkActions"
    placeholder="Bulk Actions"
    [disabled]="disabled"
    [formControl]="dropDownListControl"
    (selectionChange)="onBulkActionSelected($event.value)">
    <mat-option
        *ngIf="showButton(view, permissionType.Read, GridAction.View)"
        [value]="GridAction.View">
        View
    </mat-option>
    <mat-option
        *ngIf="showButton(conversation, null, GridAction.Conversation)"
        [value]="GridAction.Conversation">
        Conversation
    </mat-option>
    <mat-option
        *ngIf="showButton(edit, permissionType.Update, GridAction.Edit)"
        [value]="GridAction.Edit">
        Edit
    </mat-option>
    <mat-option
        *ngIf="showButton(duplicate, permissionType.Create, GridAction.Copy)"
        [value]="GridAction.Copy">
        Duplicate
    </mat-option>
    <mat-option
        *ngIf="showButton(remove, permissionType.Delete, GridAction.Remove)"
        [value]="GridAction.Remove">
        Remove
    </mat-option>
</mat-select>
