import { Component, Input } from '@angular/core';

@Component({
    selector: 'swft-profile-header',
    templateUrl: './profile-header.component.html',
    styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent {
    @Input() profileType: string = '';
    @Input() firstName: string = '';
    @Input() lastName: string = '';
    @Input() profileString: string = '';
    @Input() id: number = 0;

    constructor() {}
}
