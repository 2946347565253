<mat-card [class.collapsed]="(isExpanded | async) === false">
    <header>
        {{ subtitle ? title + ': ' : title }}
        <small>{{ subtitle }}</small>
        <mat-icon
            *ngIf="!!icon"
            class="icon">
            {{ icon }}
        </mat-icon>
        <mat-icon
            *ngIf="expandable"
            class="expandable icon"
            (click)="toggleExpanded()">
            {{ (isExpanded | async) ? 'expand_less' : 'expand_more' }}
        </mat-icon>
    </header>
    <mat-card-content [@detailExpand]="(isExpanded | async) ? 'expanded' : 'collapsed'">
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>
