<mat-form-field
    *ngIf="resource?.expressionEngineType === ExpressionEngineTypes.DateTime"
    floatLabel="always"
    appearance="fill"
    [class.solid]="operatorSelected"
    class="datepicker">
    <mat-label>{{ valueLabel }}</mat-label>
    <input
        [disabled]="!operatorSelected"
        type="text"
        matInput
        placeholder="MM/DD/YYYY"
        [matDatepicker]="picker"
        [(ngModel)]="dateTime.date"
        (dateChange)="valueChanged()"
        (input)="valueChanged()" />
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<div
    class="time-controls"
    [class.disabled]="!operatorSelected"
    *ngIf="resource?.expressionEngineType === ExpressionEngineTypes.DateTime">
    <mat-form-field
        floatLabel="always"
        appearance="fill"
        class="time"
        [class.solid]="operatorSelected">
        <mat-label>Hour</mat-label>
        <input
            [disabled]="!operatorSelected"
            type="number"
            matInput
            min="0"
            max="12"
            placeholder="12"
            [(ngModel)]="dateTime.hour"
            (input)="valueChanged()" />
    </mat-form-field>
    <span
        class="time"
        [ngClass]="groupType">
        :
    </span>
    <mat-form-field
        floatLabel="always"
        appearance="fill"
        class="time"
        [class.solid]="operatorSelected">
        <mat-label>Minute</mat-label>
        <input
            [disabled]="!operatorSelected"
            type="number"
            matInput
            min="0"
            max="59"
            placeholder="00"
            [(ngModel)]="dateTime.minute"
            (input)="valueChanged()" />
    </mat-form-field>
    <div class="time-buttons">
        <button
            [disabled]="!operatorSelected"
            (click)="updateTimeOfDay(TimeOfDay.AM)"
            [ngClass]="groupType"
            [class.active]="dateTime.timeOfDay === TimeOfDay.AM">
            AM
        </button>
        <button
            [disabled]="!operatorSelected"
            (click)="updateTimeOfDay(TimeOfDay.PM)"
            [ngClass]="groupType"
            [class.active]="dateTime.timeOfDay === TimeOfDay.PM">
            PM
        </button>
    </div>
</div>
