<form
    [formGroup]="formGroup"
    class="form-container">
    <mat-form-field
        appearance="fill"
        class="full solid">
        <mat-label>Phone Number</mat-label>
        <input
            matInput
            placeholder="555-555-5555"
            formControlName="number"
            [swftHyphentatePhoneNumber]="true"
            type="tel" />
    </mat-form-field>

    <div class="phonenumber-button-container">
        <mat-button-toggle-group
            formControlName="numberType"
            class="phonenumber-type-toggle-group">
            <mat-button-toggle [value]="PhoneNumberType.Cell">{{ PhoneNumberType.Cell }}</mat-button-toggle>
            <mat-button-toggle [value]="PhoneNumberType.Home">{{ PhoneNumberType.Home }}</mat-button-toggle>
            <mat-button-toggle [value]="PhoneNumberType.Work">{{ PhoneNumberType.Work }}</mat-button-toggle>
            <mat-button-toggle [value]="PhoneNumberType.Other">{{ PhoneNumberType.Other }}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <swft-form-errors [formGroup]="formGroup"></swft-form-errors>
</form>

<div class="button-container">
    <button
        class="center"
        color="primary"
        mat-raised-button
        [disabled]="formGroup.invalid"
        (click)="onContinueClicked()">
        Continue
    </button>
</div>
