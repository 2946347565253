export {};

Number.prototype.MaxInt = maxInt;
Number.prototype.MinInt = minInt;

declare global {
    interface Number {
        MaxInt: typeof maxInt;
        MinInt: typeof minInt;
    }
}

function maxInt(): number {
    return 2147483647;
}

function minInt(): number {
    return -2147483648;
}
