<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <p>{{ message }}</p>
</div>
<div
    mat-dialog-actions
    align="end">
    <button
        mat-button
        (click)="onAbandon()">
        Abandon
    </button>
    <button
        color="accent"
        mat-button
        [disabled]="!suppressible"
        (click)="onProceed()"
        cdkFocusInitial>
        Proceed
    </button>
</div>
