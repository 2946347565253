import { TimeOfDay } from '../enums/datetime.enums';
import { DataDictionarySearch } from './data-dictionary.model';

export interface LogicCondition {
    resource?: DataDictionarySearch;
    operator?: LogicOperator;
    value?: any;
    groupType: LogicModifier;
}

export interface LogicRange {
    start?: string | number;
    end?: string | number;
}

export enum LogicModifier {
    And = 'and',
    Or = 'or',
    Empty = '',
}

export interface DateTimeConditionValue {
    date?: Date;
    hour?: number;
    minute?: number;
    timeOfDay: TimeOfDay;
}

export enum LogicCommandTypes {
    Between = 'IsBetween',
    Blank = 'IsBlank',
    Contains = 'Contains',
    EndsWith = 'EndsWith',
    StartsWith = 'StartsWith',
    Not = 'Not',
    In = 'In',
    Date = 'Date',
    TimeInSeconds = 'TimeInSeconds',
}

export interface LogicOperators {
    string: LogicOperator[];
    date: LogicOperator[];
    time: LogicOperator[];
    datetime: LogicOperator[];
    numeric: LogicOperator[];
    boolean: LogicOperator[];
}

export interface LogicOperator {
    TimeType: boolean;
    booleanType: boolean;
    allowMultipleValues: boolean;
    command: string;
    createdBy: number;
    createdDate: string;
    dateType: boolean;
    datetimeType: boolean;
    deleted: boolean;
    id: number;
    isNot: boolean;
    modifiedBy: number;
    modifiedDate: string;
    name: string;
    numericType: boolean;
    priority: number;
    stringType: boolean;
    requireValue: boolean;
}
type StatementOrStatements = ConditionalStatements | ConditionalStatement;
export interface Operator {
    name: LogicCommandTypes;
    isNot: boolean;
}
export interface ConditionalStatements {
    compare: string;
    operator: Operator;
    compareTo: string;
}
export interface ConditionalStatement {
    logicModifier: LogicModifier;
    conditionalStatements: StatementOrStatements[];
}
export interface LogicPayload {
    name: string;
    conditionalStatement: ConditionalStatement;
}
