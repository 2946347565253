import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, Subject, take, takeUntil } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { CallType, OutboundCallResult } from 'src/app/models/call.models';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';

@Component({
    selector: 'swft-hipaa-select-contact-type',
    templateUrl: './hipaa-select-contact-type.component.html',
    styleUrls: ['./hipaa-select-contact-type.component.scss'],
})
export class HipaaSelectContactTypeComponent implements OnInit, OnDestroy {
    SWFTHipaaVerificationType = HipaaVerificationType;
    hidePatient$: Observable<boolean> = this.hipaaService.dataChanges.pipe(
        map(hipaaData => {
            const isAnsweredByOther = hipaaData.callLog.some(
                call => call.callResult === OutboundCallResult.AnsweredByOther
            );
            return !!(isAnsweredByOther && hipaaData.verificationType === HipaaVerificationType.Patient);
        })
    );
    private callType?: CallType;
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.callType = data.callType;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Set the verification type and navigate the the next step.
     * If a patient is not selected for an inbound call, navigate to the step where the user can select a specific point of contact.
     * If a patient is selected for an inbound call, navigate to the step where the user can verify the patient's data.
     * If a patient is selected for an outbound call, navigate to the step where the user can call the patient.
     * @param verificationType The HIPAA verification (reference for the remainder of the HIPAA workflow)
     */
    setType(verificationType: HipaaVerificationType): void {
        this.hipaaService.verificationType = verificationType;
        this.hipaaService.dataChanges.pipe(take(1)).subscribe(() => {
            const isPatientVerification = verificationType === HipaaVerificationType.Patient;
            if (!isPatientVerification) return this.hipaaService.navigate(HipaaRoutes.SelectContact);
            if (this.callType === CallType.Inbound) return this.hipaaService.navigate(HipaaRoutes.Verify);
            if (this.callType === CallType.Outbound) return this.hipaaService.navigate(HipaaRoutes.OutboundCall);
        });
    }

    onAuth() {
        window.alert('Feature not yet implemented.');
    }
}
