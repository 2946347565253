import { createReducer, on } from '@ngrx/store';
import { LeftNavLink, LeftNavList } from 'src/app/models/left-nav.model';
import * as navAction from './nav.actions';

export interface NavState {
    nav: LeftNavList;
    isExpanded: boolean;
}

export const navInitialState: NavState = {
    nav: {
        patients: [],
        conversations: [],
        questionModules: [],
        hcpProfiles: [],
        linesOfBusiness: [],
        questions: [],
        formBuilder: [],
        layoutBuilder: [],
    },
    isExpanded: false,
};

/**
 * Reducer function for use when a link is intended to be added to the navBar
 */
const addLink = on(navAction.addLink, (state: NavState, args): NavState => {
    if (!args.link.type) {
        throw 'LINK TYPE IS REQUIRED';
    }

    return {
        ...state,
        nav: {
            ...state.nav,
            [args.link.type]: [...updateLinks(args.link, state.nav[args.link.type], args.comparer)],
        },
    };
});

/**
 * Reducer function for use when a link is intended to be removed via the index in the navList for that
 * `linkType`
 */
const removeLinkByIndex = on(navAction.removeLinkByIndex, (state: NavState, args): NavState => {
    state.nav[args.linkType].splice(args.index, 1);

    return {
        ...state,
        nav: {
            ...state.nav,
            [args.linkType]: [...state.nav[args.linkType]],
        },
    };
});

const toggleNavbar = on(navAction.toggleNavbar, (state: NavState, args: { isExpanded: boolean }): NavState => {
    return {
        ...state,
        isExpanded: args.isExpanded,
    };
});

/**
 * Reducer function that removes the link by the route
 */
const removeLinkByRoute = on(navAction.removeLinkByRoute, (state: NavState, args): NavState => {
    const linkIndex = state.nav[args.linkType].findIndex(link => link.url === args.route);

    if (linkIndex > -1) {
        state.nav[args.linkType].splice(linkIndex, 1);
    }

    return {
        ...state,
        nav: {
            ...state.nav,
            [args.linkType]: [...state.nav[args.linkType]],
        },
    };
});

export const navReducer = createReducer(navInitialState, addLink, removeLinkByIndex, removeLinkByRoute, toggleNavbar);

function updateLinks(
    link: LeftNavLink,
    navLinks: LeftNavLink[],
    comparer?: (link: LeftNavLink, link2: LeftNavLink) => boolean
): LeftNavLink[] {
    let index = -1;
    if (comparer) index = navLinks.findIndex(navLink => comparer(navLink, link));
    else index = navLinks.findIndex(navLink => navLink.url === link.url);

    if (index > -1) {
        navLinks[index] = link;
    } else {
        navLinks.push(link);
    }

    return navLinks;
}
