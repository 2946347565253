import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientLineOfBusiness } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';
import { BusinessApiService } from '../api/business-api.service';

@Injectable({
    providedIn: 'root',
})
export class PatientLobService {
    constructor(private api: BusinessApiService) {}

    getLinesOfBusinessByPatientId(patientId: number): Observable<PatientLineOfBusiness[]> {
        return this.api.getObservableEndpoint(environment.apiBusiness.endpoints.lob.getByPatientId)(null, {
            patientId,
        });
    }

    delete(patientId: number, businessLineId: number): Observable<void> {
        return this.api.getObservableEndpoint(environment.apiBusiness.endpoints.lob.deleteByPatient)(null, {
            patientId,
            businessLineId,
        });
    }
}
