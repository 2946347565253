import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/auth/user.model';
import { ProfileMode } from 'src/app/models/profile/profile-mode.model';
import { UserService } from '../../admin/users/user.service';

@Component({
    selector: 'swft-warm-transfer',
    templateUrl: './warm-transfer.component.html',
    styleUrls: ['./warm-transfer.component.scss'],
})
export class WarmTransferComponent {
    @Input() title: string = '';
    @Input() subtitle: string = '';

    selectedUser = new BehaviorSubject<User | undefined>(undefined);
    profileMode = ProfileMode;

    selectedUserGivenName: string = '';

    fetchMethod = this.userService.getUsersWithoutCurrentUser.bind(this.userService);

    constructor(private userService: UserService, private dialogRef: MatDialogRef<WarmTransferComponent>) {}

    selectionChanged(user: User) {
        if (!!user) {
            this.selectedUserGivenName = user.displayName;
            this.selectedUser.next(user);
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    continue() {
        this.dialogRef.close(this.selectedUser.value);
    }
}
