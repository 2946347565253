import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
    ActionCombination,
    ActionCombinationNote,
    ActionType,
    defaultActionCombinationNote,
} from 'src/app/models/action.models';
import { Answer } from 'src/app/models/answer.model';
import { MedicationLookup, ProgressNote } from 'src/app/models/conversation.model';
import { SwftFormElement } from 'src/app/models/form.models';
import { InformationItem } from 'src/app/models/information-item.model';
import { Narrative } from 'src/app/models/narrative.model';
import { Question } from 'src/app/models/question.model';
import { environment } from 'src/environments/environment';
import { CprApiService } from '../api/cpr-api.service';

@Injectable({
    providedIn: 'root',
})
export class NotesService {
    //this is built by data from state everytime a conversation changes
    progressNote!: ProgressNote;

    constructor(private api: CprApiService) {}

    create(progressNote: ProgressNote): Observable<void> {
        return this.api.getObservableEndpoint(environment.apiCpr.endpoints.progressNote.create)(progressNote);
    }

    get getProgressNote(): ProgressNote {
        return this.progressNote;
    }

    setProgressNote(progressNote: ProgressNote) {
        this.progressNote = progressNote;
    }

    /**
     * takes a conversation status form group
     * and turns it into an action combination note to be used in progress/convo notes
     * @param narrativeId
     * @param formGroup
     * @returns ActionCombinationNote populated from formGroup
     */
    buildActionNoteFromFormGroup(narrativeId: number, formGroup: FormGroup): ActionCombinationNote {
        const conversationData = formGroup.controls['conversationFormData'] as FormGroup;
        if (!conversationData) return defaultActionCombinationNote;

        const narrativeData = conversationData.controls[narrativeId] as FormGroup;
        if (!narrativeData) return defaultActionCombinationNote;

        const lastAction = narrativeData.controls['lastAction'].value as ActionCombination;
        if (!lastAction) return defaultActionCombinationNote;

        const lastActionName = lastAction.actions?.find(action => action.type === ActionType.LastAction);
        const nextAction = narrativeData.controls['nextAction'].value;
        const nextActionDate = narrativeData.controls['date'].value;

        if (!lastActionName || !nextAction || !nextActionDate) return defaultActionCombinationNote;
        return {
            lastActionName: lastActionName?.name,
            nextActionName: nextAction.name,
            nextActionDate: nextActionDate,
            narrativeId: narrativeId,
        } as ActionCombinationNote;
    }

    buildFormElementsList(narrative: Narrative): SwftFormElement[] {
        const elements: SwftFormElement[] = [];
        narrative.form?.formPages?.forEach(page => {
            page.formElements?.forEach(formElement => {
                if (formElement.question !== undefined || formElement.informationItem !== undefined)
                    elements.push(formElement);
            });
        });
        return elements;
    }

    getAnswerForQuestion(questionId: number, narrativeId: number, answers: Answer[]): Answer | undefined {
        return answers.find(answer => answer.questionId == questionId && answer.narrativeId == narrativeId);
    }

    /**
     * will gather answers and generate summary text for the answers
     * NOTE: progress notes do not display HTML, conversation notes do
     * @param answer
     * @param question
     * @param showHtml
     * @returns
     */
    getAnswerSummaryText(answer: Answer | undefined, question: Question, progressNote: boolean): string {
        let note = '\n--------------------------------------------\n';
        note += `Question ID: ${question.id}\n`;
        note += `Question Title: ${question.title}\n`;
        note += `Short Prompt: ${
            progressNote ? this.stripHtmlFromPrompt(question.shortPrompt)?.trim() : question.shortPrompt?.trim()
        }\n`;
        if (!progressNote) {
            note += `Full Prompt: ${question.longPrompt?.trim()}\n`;
        }
        const responseOptionText = this.getQuestionResponseOptionsText(question).trim();
        if (responseOptionText.length != 0) note += `Response Options: ${responseOptionText}\n\n`;

        const answerText = answer !== undefined && answer?.value?.length > 0 ? answer?.value : 'Not yet recorded';
        note += `Answer: ${answerText}`;

        return note;
    }

    stripHtmlFromPrompt(prompt: string | undefined): string | undefined {
        return prompt?.replace(/<.*?>/g, '');
    }

    getQuestionResponseOptionsText(question: Question): string {
        const responseOptions: string[] = [];
        question.responseOption?.responseValues?.forEach(responseOptionValue => {
            responseOptions.push(`${responseOptionValue.name}`);
        });
        return responseOptions.join(', ');
    }

    /**
     * generates the note text for information items
     * NOTE: progress notes do not display HTML, conversation notes do
     * @param item - the information item for which we want to format text
     * @param progressNote - whether or not we are in the process of generating a progress note
     * @returns - formatted information item text to add to the progress/conversation note
     */
    getInformationItemText(item: InformationItem, progressNote: boolean): string {
        let note = '\n--------------------------------------------\n';
        note += `Information Item ID: ${item.id}\n`;
        note += `Information Item Title: ${item.title}\n`;
        note += `Prompt: ${progressNote ? this.stripHtmlFromPrompt(item.prompt)?.trim() : item.prompt?.trim()}`;

        return note;
    }

    buildNote(
        narratives: Narrative[],
        answers: Answer[],
        medications: MedicationLookup[],
        formGroup: FormGroup,
        progressNote: boolean
    ): string {
        if (narratives.length === 0) return '';

        let note = `Comments: [comments] \n\n`;
        narratives.forEach(conversationNarrative => {
            const narrative = conversationNarrative;

            if (conversationNarrative === undefined || !narrative) return;
            const medicationName = medications.find(med => med.narrativeId === narrative.id)?.medicationName;

            note += `Narrative: ${narrative?.formName} (${narrative?.id})\n`;
            note += `Conversation Outcome: ${conversationNarrative.status}\n`;

            note += `Medication Name: ${medicationName ? medicationName : 'no medication found'}\n`;

            const actionNote = this.buildActionNoteFromFormGroup(narrative.id, formGroup);

            note += `Last Action: ${actionNote?.lastActionName}\n`;
            note += `Next Action: ${actionNote?.nextActionName}\n`;
            note += `Next Action Date: ${actionNote?.nextActionDate}\n`;

            note += `Call Type: [Not yet implemented]\n`;
            note += `HIPAA Verification: [Not yet implemented]\n`;
            const narrativeFormElements = this.buildFormElementsList(narrative);
            narrativeFormElements.forEach(formElement => {
                // if the form element contains a question section, we process it as a Question. Otherwise, we process as an Information Item
                if (formElement.question) {
                    const question: Question = formElement.question;
                    if (question.id === undefined) return;
                    const answer = this.getAnswerForQuestion(question.id, conversationNarrative.id, answers);
                    note += this.getAnswerSummaryText(answer, question, progressNote) + '\n';
                } else if (formElement.informationItem) {
                    const informationItem: InformationItem = formElement.informationItem;
                    if (informationItem.id === undefined) return;
                    note += this.getInformationItemText(informationItem, progressNote) + '\n';
                }
            });
        });
        return note;
    }
}
