import { Component, OnInit } from '@angular/core';
import { map, Observable, Subject, take, takeUntil } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { PaginationDataSource } from 'src/app/models/api/datasource.models';
import { CallType } from 'src/app/models/call.models';
import { SwftGridColumn } from 'src/app/models/grid.models';
import { HipaaValidateContact } from 'src/app/models/hipaa.models';
import { Patient } from 'src/app/models/patient.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { SingleSearchTermQuery } from 'src/app/models/table.models';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { EmptyQuery, InitalSortPatients, PageSizeTen } from 'src/app/utils/constants';

@Component({
    selector: 'swft-hipaa-select-contact',
    templateUrl: './hipaa-select-contact.component.html',
    styleUrls: ['./hipaa-select-contact.component.scss'],
})
export class HipaaSelectContactComponent implements OnInit {
    PatientDataSource = new PaginationDataSource<Patient, SingleSearchTermQuery>(
        (request, query) => this.patientService.page(request, query),
        InitalSortPatients,
        EmptyQuery,
        PageSizeTen
    );

    patientColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Patient Id' },
        { dataPath: 'mrn', name: 'MRN' },
        { dataPath: 'firstName', name: 'First Name' },
        { dataPath: 'lastName', name: 'Last Name' },
        { dataPath: 'dob', name: 'Date of Birth', formatter: 'date' },
    ];

    patient$ = this.hipaaService.patient$;
    patientDisplayValue$: Observable<string> = this.patient$.pipe(map(() => this.hipaaService.patientDisplayName));
    contactValidator: Observable<HipaaValidateContact> = this.hipaaService.contactValidator$;

    displayValue = '';
    patient?: PatientDemographic;
    SWFTHipaaVerificationType = HipaaVerificationType;
    verificationType = HipaaVerificationType.Empty;
    callType?: CallType;

    private onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private hipaaService: HipaaService,
        private patientService: PatientService,
        private snackbar: SWFTSnackBarService
    ) {}

    /**
     * Determine based off the service
     */
    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            if (data.patient.id) this.patient = data.patient;
            this.verificationType = data.verificationType;
            this.callType = data.callType;
        });
        this.hipaaService.resetContactValidator();
    }

    /**
     * Continue to the next step.
     * If the call type is Inbound, proceed to the verify screen
     * If the call type is Outbound, proceed to the call attempt screen
     * If the verification type is Patient, the user was redirected here in result of an error
     * and should be redirected back onto the correct path.
     */
    continue(): void {
        if (!this.patient?.id) return;
        let route: HipaaRoutes = this.callType === CallType.Outbound ? HipaaRoutes.OutboundCall : HipaaRoutes.Verify;
        if (this.callType === CallType.Outbound && !!this.hipaaService.selectedHcp) route = HipaaRoutes.Verify;

        const redirect: boolean = this.verificationType === HipaaVerificationType.Patient;
        const validateContact: HipaaValidateContact = this.hipaaService.contactValidator$.value;
        if (!validateContact.contact || !validateContact.valid) {
            this.snackbar.error('Invalid Contact, please try again.');
            return;
        }
        this.hipaaService.contact = validateContact.contact;
        this.hipaaService.navigate(route, redirect);
    }

    /**
     * Updates the HIPAA service by acquiring the patient's demographic by patient ID
     * @param patient The patient that is to be referenced for the rest of the HIPAA process
     */
    updatePatient(patient: Patient): void {
        if (!patient) return;
        this.patientService
            .getPatientDemographic(patient.id)
            .pipe(take(1))
            .subscribe(patient => {
                this.displayValue = patient.firstName + ' ' + patient.lastName + ' (' + patient.mrn + ')';
                this.hipaaService.patient = patient;
                if (this.verificationType !== HipaaVerificationType.Patient) return;
                const route: HipaaRoutes =
                    this.callType === CallType.Outbound ? HipaaRoutes.OutboundCall : HipaaRoutes.Verify;
                this.hipaaService.navigate(route, true);
            });
    }
}
