export class FormError {
    controlName: string = '';
    message: string = '';
}

export class FormErrorMessage {
    id: string = '';
    message: string = '';
}

export class FormErrorMessages {
    messages: FormErrorMessage[] = [
        {
            id: 'required',
            message: 'is required.',
        },
        {
            id: 'isNotUnique',
            message: 'already exists and must be unique.',
        },
        {
            id: 'uniqueCheckFailed',
            message: 'could not be checked for uniqueness, call failed.',
        },
        {
            id: 'min',
            message: 'does not meet the minimum required value.',
        },
        {
            id: 'max',
            message: 'exceeds the maximum required value',
        },
        {
            id: 'minlength',
            message: 'does not meet the minimum number of characters.',
        },
        {
            id: 'maxlength',
            message: 'exceeds the maximum number of characters.',
        },
        {
            id: 'hasDuplicates',
            message: 'contains duplicate values.',
        },
        {
            id: 'hasFieldDuplicate',
            message: 'must be unique.',
        },
        {
            id: 'questionSequenceNotUnique',
            message: 'Questions and their sequences must be unique',
        },
        {
            id: 'actionCominationNotUnique',
            message: 'Next Action and Last Action pair must be unique',
        },
        {
            id: 'allowableNextActionNotUnique',
            message: 'Duplicate Allowable Next Actions not allowed',
        },
        {
            id: 'allowableActionDefaultActionNotUnique',
            message: 'Default Next Action and Allowable Next Action Action cannot match',
        },
        {
            id: 'questionSequenceUniqueCheckFailed',
            message: 'Question Sequence uniqueness check failed',
        },
        {
            id: 'email',
            message: 'Must be a valid email address',
        },
        {
            id: 'missingDate',
            message: 'The date must be entered once a time has been entered',
        },
        {
            id: 'missingTime',
            message: 'The time must be fully entered once a date has been entered',
        },
        {
            id: 'maxTimeExceeded',
            message: 'The time is too late in the day',
        },
        {
            id: 'minTimeExceeded',
            message: 'The time is too early in the day',
        },
        {
            id: 'invalidTimeOfDay',
            message: 'Time of Day must be AM or PM',
        },
        {
            id: 'invalidHour',
            message: 'Hour must be between 0 and 24',
        },
        {
            id: 'invalidMinutes',
            message: 'Minute must be between 0 and 59',
        },
        {
            id: 'invalidTimezone',
            message: 'Timezone is not recognized',
        },
        {
            id: 'invalidDate',
            message: 'The date entered is not in a recognized format',
        },
        {
            id: 'fullYearRequired',
            message: 'Year must contain 4 digits',
        },
        {
            id: 'cannotVerify',
            message: 'Failed to verify, internal error',
        },
        {
            id: 'doesNotMatch',
            message: 'Does not match',
        },
        {
            id: 'verifyMoreFields',
            message: 'Additional fields must be verified',
        },
        {
            id: 'pattern',
            message: 'is invalid',
        },
        {
            id: 'invalidDynamicTag',
            message: 'Dynamic Tag is Invalid',
        },

        {
            id: 'invalidDynamicTagMissingEndBracket',
            message: 'Dynamic Tag is Invalid missing closing brackets',
        },
        {
            id: 'invalidDynamicTagMissingOpenBracket',
            message: 'Dynamic Tag is Invalid missing opening brackets',
        },
        {
            id: 'nameAndTypeIsNotUnique',
            message: 'Name and Type combination already exists and must be unique.',
        },
    ];
}
