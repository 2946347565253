import { createAction, props } from '@ngrx/store';
import { LeftNavLink, LeftNavType } from 'src/app/models/left-nav.model';

export const addLink = createAction(
    '[Nav] Add Link',
    props<{ link: LeftNavLink; comparer?: (link: LeftNavLink, link2: LeftNavLink) => boolean }>()
);

export const removeLinkByIndex = createAction(
    '[Nav] Remove Link By Index',
    props<{ linkType: LeftNavType; index: number }>()
);

export const removeLinkByRoute = createAction(
    '[Nav] Remove Link By Route',
    props<{ linkType: LeftNavType; route: string }>()
);

export const toggleNavbar = createAction('[Nav] Toggle Navbar', props<{ isExpanded: boolean }>());
