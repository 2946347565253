<form
    class="form-container"
    [formGroup]="formGroup">
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>First Name</mat-label>

        <input
            matInput
            placeholder="Riley"
            formControlName="firstName" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Last Name/Initial</mat-label>
        <input
            matInput
            placeholder="Jamie / J"
            formControlName="lastNameOrInitial" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Organization</mat-label>

        <input
            matInput
            placeholder="PantherX Rare"
            formControlName="organization" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Title</mat-label>
        <input
            matInput
            placeholder="Manager"
            formControlName="title" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Phone Number</mat-label>
        <input
            matInput
            placeholder="412-555-1234"
            [swftHyphentatePhoneNumber]="true"
            formControlName="phone" />
    </mat-form-field>
</form>

<swft-form-errors
    [formGroup]="formGroup"
    [floatable]="false"></swft-form-errors>
