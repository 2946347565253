import { Injectable } from '@angular/core';
import { environment } from 'src/environments/config';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class HcpApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiHealthCareProvider.protocol}://${environment.apiHealthCareProvider.host}:${environment.apiHealthCareProvider.port}/${environment.apiHealthCareProvider.path}`;
}
