import { SearchOptions } from './api/api-search.models';

export enum ExpressionEngineTypes {
    String = 'String',
    Integer = 'Integer',
    Number = 'Number',
    DateTime = 'DateTime',
    Object = 'Object',
    Boolean = 'Boolean',
    Null = 'Null',
    Array = 'Array',
}

export enum DbTypes {
    Bit = 'bit',
    Date = 'date',
    DateTime = 'datetime',
    Int = 'int',
    Numeric = 'numeric',
    Varchar = 'varchar',
}

export interface DataDictionary {
    id?: number;
    groupName: string;
    property: string;
    alias: string;
    description: string;
    isAvailable: boolean;
    expression: string;
    expressionEngineType: ExpressionEngineTypes;
    dbTable: string;
    dbField: string;
    dbType: string;
    dbLength: number;
    autoPopulated: boolean;
    area: string;
    basis: string;
    dynamicTagUse: boolean;
    layoutUse: boolean;
    logicUse: boolean;
    createdBy: string;
    createdDate?: Date;
    modifiedBy: number;
    modifiedDate?: Date;
    deleted: boolean;
}

export interface DataDictionarySearch {
    id?: number;
    groupName: string;
    property: string;
    alias: string;
    description: string;
    isAvailable: boolean;
    expression: string;
    expressionEngineType: ExpressionEngineTypes;
    dbTable: string;
    dbField: string;
    dbType: string;
    dbLength: number;
    autoPopulated: boolean;
    area: string;
    basis: string;
    dynamicTagUse: boolean;
    layoutUse: boolean;
    logicUse: boolean;
    createdBy: string;
    createdDate?: Date;
    modifiedBy: string;
    modifiedDate?: Date;
    deleted: boolean;
}

export interface DataDictionarySearchOptions extends SearchOptions {
    dbType?: string;
    logicUseOnly?: boolean;
}
