import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchOptions } from 'src/app/models/api/api-search.models';
import { NoteTemplate } from 'src/app/models/note-template.model';
import { Page } from 'src/app/models/table.models';
import { mapFromSearchResult } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { CprApiService } from '../api/cpr-api.service';

@Injectable({
    providedIn: 'root',
})
export class NoteTemplateService {
    constructor(private api: CprApiService) {}

    /**
     * Returns all note templates
     */
    GetNoteTemplates(): Observable<NoteTemplate[]> {
        return this.api.getObservableEndpoint(environment.apiCpr.endpoints.noteTemplate.lookup)();
    }

    searchNoteTemplates(request: SearchOptions): Observable<Page<NoteTemplate>> {
        return this.api
            .getObservableEndpoint(environment.apiCpr.endpoints.noteTemplate.search)(request)
            .pipe(mapFromSearchResult);
    }
}
