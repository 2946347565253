import { Component } from '@angular/core';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { SwftGridColumn } from 'src/app/models/grid.models';

@Component({
    selector: 'swft-narratives-hcp',
    templateUrl: './narratives-hcp.component.html',
    styleUrls: ['./narratives-hcp.component.scss'],
})
export class HcpNarrativesComponent {
    narrativeType: NarrativeType = NarrativeType.Hcp;

    displayColumns: SwftGridColumn[] = [
        { dataPath: 'id', name: 'Id', pixelWidth: 40 },
        { dataPath: 'hcpId', name: 'HCP Id' },
        { dataPath: 'hcpFirstName', name: 'HCP First Name' },
        { dataPath: 'hcpLastName', name: 'HCP Last Name' },
        { dataPath: 'formName', name: 'Form Name' },
        { dataPath: 'formTypeName', name: 'Form Type' },
        { dataPath: 'formBasisName', name: 'Form Basis' },
        { dataPath: 'status', name: 'Status' },
        { dataPath: 'lastActionName', name: 'Last Action' },
        { dataPath: 'lastActionDate', name: 'Last Action Date', formatter: 'date' },
        { dataPath: 'nextActionName', name: 'Next Action' },
        { dataPath: 'nextActionDate', name: 'Next Action Date', formatter: 'date' },
    ];

    constructor() {}
}
