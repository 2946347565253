import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LeftNavType } from 'src/app/models/left-nav.model';
import { LeftNavSection } from 'src/app/services/left-nav/left-nav.models';

interface LinkRemovedEvent {
    linkType: LeftNavType;
    index: number;
}

@Component({
    selector: 'swft-left-nav-section',
    templateUrl: './left-nav-section.component.html',
    styleUrls: ['./left-nav-section.component.scss'],
})
export class LeftNavSectionComponent {
    @Input() section!: LeftNavSection;
    @Output() removed: EventEmitter<LinkRemovedEvent> = new EventEmitter<LinkRemovedEvent>();

    constructor() {}

    toggleExpandedSection() {
        this.section.expanded = !this.section.expanded;
    }

    /**
     * Stops default event propogation and emits a removed event for the removed nav link
     * @param event
     * @param linkType
     * @param index
     */
    removeLinkByIndex(event: Event, linkType: LeftNavType, index: number) {
        // Stop the event so navigation does not occur here
        event.stopImmediatePropagation();
        event.stopPropagation();

        this.removed.emit({ linkType, index });
    }
}
