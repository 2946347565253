import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, forkJoin, Observable, of, switchMap, takeUntil, tap } from 'rxjs';
import { BusinessLine } from 'src/app/models/businessline.model';
import { EditorMode } from 'src/app/models/editor.models';
import { LeftNavLink, LeftNavType, PatientProfileLink } from 'src/app/models/left-nav.model';
import { Patient, PatientAlerts } from 'src/app/models/patient.model';
import { ProfileLink } from 'src/app/models/profile/profile-link.model';
import { PatientLobService } from 'src/app/services/patient/patient-lob.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { StateBase } from 'src/app/services/state/component-store';
import { SwftComponentStoreBase } from 'src/app/services/state/component-store-base';
import { selectPatientLinks } from 'src/app/state/nav/nav.selector';
import { PatientLinks } from '../patient-links';

export const defaultPatient: Patient = {
    id: 0,
    deIdentifiedId: 0,
    dob: new Date(),
    firstName: '',
    lastName: '',
    mrn: 0,
    specialId: '',
    specialIdManuallyGenerated: false,
};

export interface PatientProfileState extends StateBase<Patient> {
    patientAlerts?: PatientAlerts;
    patientAlertAcknowledged: boolean;
    linesOfBusiness: BusinessLine[];
    // We want to store these into state so we don't have to fetch the LOB specific tabs every time
    patientProfileLinks: ProfileLink[];
}

export function patientProfileLinkComparer<T extends LeftNavLink>(link1: T, link2: T): boolean {
    // Regular expression for parsing the ID from the URL. Since the format is /patients/{id}/selectedRoute
    // We want to only replace the patient the user was on and that requires parsing out the ID to ensure that they are the same
    const idRegex = new RegExp(/patients\/\d+/);

    // If we are running this we are on patient, guaranteed match
    const id1 = link1.url?.match(idRegex)![0];
    const id2 = link2.url?.match(idRegex)![0];

    const linkIsEqual = id1 === id2;

    return linkIsEqual;
}

export const defaultPatientProfileState: PatientProfileState = {
    canAddLink: true,
    editorMode: EditorMode.View,
    item: defaultPatient,
    initialItem: undefined,
    patientAlertAcknowledged: false,
    loading: true,
    linesOfBusiness: [],
    // Default links before we load any of the LOBs
    patientProfileLinks: PatientLinks,
};

@Injectable({
    providedIn: 'root',
})
export class PatientProfileStateManagerService extends SwftComponentStoreBase<
    Patient,
    PatientProfileState,
    PatientProfileLink
> {
    /**
     * There is no creating/editing in profiles
     */
    override editorMode$: Observable<EditorMode> = of(EditorMode.View);

    id$ = this.select(state => state.item.id).pipe(distinctUntilChanged());
    patientAlerts$ = this.select(state => state.patientAlerts);
    patientAlertsAcknowledged$ = this.select(state => state.patientAlertAcknowledged);
    patientLinks$ = this.select(state => state.patientProfileLinks);
    patientLobs$ = this.select(state => state.linesOfBusiness);

    navType: LeftNavType = LeftNavType.Patient;

    constructor(
        private patientService: PatientService,
        private patientLobService: PatientLobService,
        router: Router,
        store: Store
    ) {
        super(
            patientService,
            router,
            store,
            selectPatientLinks,
            defaultPatient,
            defaultPatientProfileState,
            patientProfileLinkComparer
        );
    }

    /**
     * Override the base implementation since this checks for view modes and we don't have additional view modes here
     */
    override initializeNavigationListener(): void {
        this.router.events
            .pipe(
                tap(event => {
                    if (!(event instanceof NavigationEnd)) this.updateCanAddLink(false);
                    else this.updateCanAddLink(true);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    override readonly load = this.effect((id: Observable<number>) => {
        return id.pipe(
            // Make requests for patient, patient alerts and patient LOBs
            // We use this information to hydrate the patient profile UI
            switchMap(id =>
                forkJoin([
                    this.patientService.read(id),
                    this.patientService.readPatientAlerts(id),
                    this.patientLobService.getLinesOfBusinessByPatientId(id),
                ])
            ),
            tap(([patient, alerts, patientLobs]) => {
                const businessLines = patientLobs.map(patientLob => patientLob.businessLine);

                this.setPatient(patient);
                this.loadPatientLobs(businessLines);
                this.updatePatientAlerts(alerts);
                this.updatePatientAlertAcknowledged(!alerts.hasAlert);
            }),
            tap(() => this.updateLoading(false))
        );
    });

    readonly setPatient = this.updater((state, item: Partial<Patient>): PatientProfileState => {
        return {
            ...state,
            item: {
                ...state.item,
                ...item,
                specialId: item.specialId ?? '',
                specialIdManuallyGenerated: item.specialIdManuallyGenerated ?? false,
            },
        };
    });

    /**
     * Sets the state's Lines of Business for a patient
     */
    readonly setPatientLobs = this.updater((state, lobs: BusinessLine[]): PatientProfileState => {
        return {
            ...state,
            linesOfBusiness: [...lobs],
        };
    });

    /**
     * We set this as an effect since there are multiple things we expect to do with these LOBs when we process them via the UI
     *
     * Builds the patient profile links based on the patient LOBs and sets this value in state
     * Set the patient LOBs in state
     */
    readonly loadPatientLobs = this.effect((patientLobs$: Observable<BusinessLine[]>) => {
        return patientLobs$.pipe(
            tap(patientLobs => {
                const patientLinks = this.mapLobToPatientProfileLinks(patientLobs);

                this.setPatientLobs(patientLobs);
                this.patchState({ patientProfileLinks: patientLinks });
            })
        );
    });

    /**
     * This will fire the API request to remove a patient LOB
     * If the request is successful, this will remove the LOB from state
     */
    readonly removePatientLob = this.effect((lobToRemove$: Observable<BusinessLine>) => {
        return lobToRemove$.pipe(
            // Remove the LOB
            tap(lobToRemove => {
                this.patientLobService
                    .delete(
                        this.get(state => state.item.id),
                        lobToRemove.id
                    )
                    .subscribe(() => {
                        const lobs = [...this.get(state => state.linesOfBusiness)];
                        const lobIndex = lobs.findIndex(lob => lob.name === lobToRemove.name);

                        lobs.splice(lobIndex, 1);
                        this.loadPatientLobs(lobs);
                    });
            })
        );
    });

    readonly updatePatientAlerts = this.updater((state: PatientProfileState, patientAlerts: PatientAlerts) => {
        return {
            ...state,
            patientAlerts: patientAlerts,
        };
    });

    readonly updatePatientAlertAcknowledged = this.updater((state: PatientProfileState, acknowledged: boolean) => {
        return {
            ...state,
            patientAlertAcknowledged: acknowledged,
        };
    });

    private mapLobToPatientProfileLinks(patientLobs: BusinessLine[]): ProfileLink[] {
        const lobLinks = patientLobs.map<ProfileLink>(lobs => ({
            active: false,
            title: lobs.name,
            url: `line-of-business/${lobs.name}`,
        }));
        return [...PatientLinks, ...lobLinks];
    }
}
