import {
    BusinessApiConfig,
    CprApiConfig,
    FormBuilderApiConfig,
    HcpApiConfig,
    IdentityApiConfig,
    LogicAppApiConfig,
    NarrativeApiConfig,
    PatientApiConfig,
    QuestionBuilderApiConfig,
} from 'src/app/models/api/api-config.models';
import { GUID, guid } from 'src/app/models/guid.model';
import { BaseEnvironment } from './base.environment';
import { BusinessEndpoints } from './endpoints/business.endpoints';
import { CprEndpoints } from './endpoints/cpr.endpoints';
import { FormBuilderEndpoints } from './endpoints/form-builder.endpoints';
import { HcpEndpoints } from './endpoints/hcp.endpoints';
import { IdentityEndpoints } from './endpoints/identity.endpoints';
import { LogicAppEndpoints } from './endpoints/logic-app.endpoints';
import { NarrativeEndpoints } from './endpoints/narrative.endpoints';
import { PatientEndpoints } from './endpoints/patient.endpoints';
import { QuestionBuilderEndpoints } from './endpoints/question-builder.endpoints';

class ApiConfigs {
    identity: IdentityApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8686,
        path: 'api/',
        endpoints: new IdentityEndpoints(),
    };
    business: BusinessApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8268,
        path: 'api/',
        endpoints: new BusinessEndpoints(),
    };
    patient: PatientApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8970,
        path: 'api/',
        endpoints: new PatientEndpoints(),
    };
    formBuilder: FormBuilderApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8747,
        path: 'api/',
        endpoints: new FormBuilderEndpoints(),
    };
    questionBuilder: QuestionBuilderApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8596,
        path: 'api/',
        endpoints: new QuestionBuilderEndpoints(),
    };
    narrative: NarrativeApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8995,
        path: 'api/',
        endpoints: new NarrativeEndpoints(),
    };
    hcp: HcpApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8255,
        path: 'api/',
        endpoints: new HcpEndpoints(),
    };
    cpr: CprApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8568,
        path: 'api/',
        endpoints: new CprEndpoints(),
    };
    logicApp: LogicAppApiConfig = {
        protocol: 'https',
        host: 'swft-dev-api.pantherxrare.com',
        port: 8868,
        path: 'api/',
        endpoints: new LogicAppEndpoints(),
    };
}

// App Insights
const instrumentationKey: GUID = guid('91564f9d-767b-4ae3-bc00-567cb2362df1');

export const environment = new BaseEnvironment(new ApiConfigs(), instrumentationKey, true);

//The amount of time, in seconds, the user must remain inactive before displaying the idle dialog.
export const IdleDuration = 86400;

//The amount of time, in seconds, AFTER THE IDLEDURATION the user must remain inactive before forced logout.
export const TimeoutDuration = 86400;
