import { DatePipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Subscription, take } from 'rxjs';
import { SwftDatepickerComponent } from '../components/swft/swft-datepicker/swft-datepicker.component';
import { DateFormat } from '../utils/constants';

@Directive({
    selector: '[swftDatepicker]',
})
export class DatePickerDirective implements OnInit, OnDestroy {
    private onClick: Subscription = new Subscription();
    private element = this.elRef.nativeElement;
    private dialogRef!: MatDialogRef<SwftDatepickerComponent>;

    @Input() formControl: FormControl | undefined = undefined;
    @Output() dateSelected: EventEmitter<string> = new EventEmitter<string>();

    constructor(private elRef: ElementRef, private dialog: MatDialog, private datePipe: DatePipe) {}

    ngOnInit() {
        /**
         * Hijack the click event and open a confirmation dialog.
         * Upon user confirmation, the original click event is re-emitted and the dialog is bypassed.
         * Upon user denial, the dialog is closed and the original click event is cancelled.
         */
        this.onClick = fromEvent(this.element, 'click', {
            capture: true,
        }).subscribe((event: any) => {
            event.stopPropagation();
            this.dialogRef = this.dialog.open(SwftDatepickerComponent, {
                panelClass: ['small-auto'],
                minHeight: '630px',
                data: {
                    date: this.element.value ? new Date(this.element.value) : new Date(),
                },
            });

            this.dialogRef
                .backdropClick()
                .pipe(take(1))
                .subscribe(() => {
                    this.dialogRef.componentInstance.save();
                });

            this.dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe(date => {
                    if (date) {
                        const dateString = this.datePipe.transform(date, DateFormat);
                        if (this.formControl) this.formControl.setValue(dateString);
                        if (dateString) this.dateSelected.emit(dateString);
                        return;
                    }
                });
        });
    }

    ngOnDestroy() {
        this.onClick.unsubscribe();
    }
}
