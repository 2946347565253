<section>
    <h2>Select a Payor</h2>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Payor</mat-label>
        <mat-select (selectionChange)="onContactSelected($event)">
            <mat-option
                *ngFor="let payor of payors | async"
                [value]="payor">
                {{ payor.pbm }} ({{ payor.coverageType }})
            </mat-option>
        </mat-select>
    </mat-form-field>
</section>

<form
    *ngIf="!!payor"
    class="form-container"
    [formGroup]="formGroup">
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>First Name</mat-label>

        <input
            matInput
            formControlName="firstName" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Last Name Or Initial</mat-label>
        <input
            matInput
            formControlName="lastNameOrInitial" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Title</mat-label>
        <input
            matInput
            formControlName="title" />
    </mat-form-field>
</form>

<swft-form-errors
    [formGroup]="formGroup"
    [floatable]="false"></swft-form-errors>
