import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/models/patient.model';
import { PatientApiService } from 'src/app/services/api/patient-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SpecialIdService {
    constructor(private patientApi: PatientApiService) {}

    /**
     * Performs a lookup of a patient by the special identifier
     * @param specialId the special id to use
     * @returns List of patients with special id matching the one supplied (exact match only)
     */
    getPatientsBySpecialId(specialId: string): Observable<Patient[]> {
        return this.patientApi.getObservableEndpoint(environment.apiPatient.endpoints.getPatientsBySpecialId)(
            specialId
        );
    }

    /**
     * Assigns a special id to a patient.  If the id was not manually generated (user entered), it
     * will be chcked for uniquness (again) when the association is saved (this call)
     * @param patientId Patient Id
     * @param specialId Special identifier
     * @param isManuallyGenerated Flag indicating whether this was user enterred or manually generated
     * @returns success indicator (>-1)
     */
    assignPatientSpecialId(patientId: number, specialId: string, isManuallyGenerated: boolean): Observable<number> {
        const data = {
            patientId: patientId,
            specialId: specialId,
            manuallyGenerated: isManuallyGenerated,
        };

        return this.patientApi.getObservableEndpoint(environment.apiPatient.endpoints.assignPatientSpecialId)(data);
    }

    /**
     * Requests a new special identifier from the middle tier.  Special identifiers are guaranteed unique when
     * issued.  They are also re-validated for uniqueness when the association is saved.
     * @returns
     */
    getGeneratedSpecialId(): Observable<string> {
        return this.patientApi.getObservableEndpoint(environment.apiPatient.endpoints.getNewSpecialId)();
    }
}
