import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forOwn } from 'lodash-es';
import { catchError, Observable, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { AuthTokenRefreshService } from 'src/app/workers/auth-token-refresh.service';
import { LoginEndpoints } from 'src/environments/endpoints/identity.endpoints';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private authTokenRefreshService: AuthTokenRefreshService,
        private snackbar: SWFTSnackBarService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = this.addAuthHeader(request);

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.handleResponseError(err, request, next);
            })
        );
    }

    private handleResponseError(
        error: HttpErrorResponse,
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<any> {
        let errorMessage;

        // Business error
        if (error.status === 400) errorMessage = 'Request failed. Request not recognized';

        /**
         * Invalid token error
         * If the user is not in the process of logging in and recieves a 401 error, prompt the user
         * with the refresh token dialog and return an observable of the token's refresh status.
         * If the token refresh is successful, retry the request, otherwise log the user out.
         */
        if (error.status === 401) {
            if (!!this.isLoginRequest(request.url)) {
                this.authTokenRefreshService.openRefreshTokenDialog();
            }
            return this.authTokenRefreshService.tokenRefreshed.pipe(
                switchMap(successful => {
                    if (successful) {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }
                    throw new HttpErrorResponse({
                        error: 'Token refresh failed',
                        status: 401,
                        statusText: 'Unauthorized',
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status !== 401) {
                        return this.handleResponseError(err, request, next);
                    } else {
                        this.snackbar.error('Request failed. Not authorized', 5000);
                        this.authService.logout();
                        throw error;
                    }
                })
            );
        }

        // Access denied error
        if (error.status === 403) errorMessage = 'Request failed. Insufficient privileges';

        // Server error
        if (error.status === 500) errorMessage = 'Request failed. Server did not respond';

        // Maintenance error
        if (error.status === 503) errorMessage = 'Request failed. Server in maintenance';

        if (errorMessage) {
            this.snackbar.error(errorMessage, 5000);
        }

        throw error;
    }

    private addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
        const token = this.authService.bearerToken.value;

        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
    }

    private isLoginRequest(url: string): boolean {
        const loginEndpoints = new LoginEndpoints();
        let match = 0;
        forOwn(loginEndpoints, value => {
            const endpointPath = value.path.split('?')[0];
            if (url.includes(endpointPath)) {
                match++;
            }
        });

        return !!match;
    }
}
