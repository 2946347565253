import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs';
import { PageRequestFunction } from 'src/app/directives/select-search-modal.directive';
import { SearchOptions, SelectSearchValues } from 'src/app/models/api/api-search.models';
import { SwftBasis } from 'src/app/utils/constants';

export interface SelectSearchData {
    fetch: PageRequestFunction<any> | undefined;
    searchPlaceholder: string;
    searchOrderBy: string;
    searchValueFormat: string;
    searchDropdownLabel: string;
    searchDropdownPlaceholder: string;
    searchDropdownValues: SelectSearchValues;
    searchPageLength?: number;
    basis?: SwftBasis[];
}
@Component({
    selector: 'swft-select-search',
    templateUrl: './swft-select-search.component.html',
    styleUrls: ['./swft-select-search.component.scss'],
})
export class SwftSelectSearchComponent implements OnInit {
    @Output() searchModifierChanged = new EventEmitter<string>();

    fetch: PageRequestFunction<any> | undefined = undefined;
    placeholder = '';
    orderBy = '';
    pageLength = 0;
    searchModifier = '';
    searchTerm = '';
    format = '';
    dropdownValues: SelectSearchValues = { name: '', values: [] };
    dropdownLabel = '';
    dropdownPlaceholder = '';
    formattedItems: string[] = [];
    items: any[] = [];
    basis?: SwftBasis[];

    constructor(
        public dialogRef: MatDialogRef<SwftSelectSearchComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: SelectSearchData
    ) {}

    ngOnInit(): void {
        this.fetch = this.data.fetch;
        this.placeholder = this.data.searchPlaceholder;
        this.orderBy = this.data.searchOrderBy;
        this.pageLength = this.data.searchPageLength ?? 25;
        this.format = this.data.searchValueFormat;
        this.dropdownValues = this.data.searchDropdownValues;
        this.dropdownLabel = this.data.searchDropdownLabel;
        this.dropdownPlaceholder = this.data.searchDropdownPlaceholder;
        this.basis = this.data.basis;
        this.search('');
    }

    search(searchTerm: string = this.searchTerm) {
        if (!this.fetch) return;
        this.searchTerm = searchTerm;
        const params: SearchOptions = {
            value: this.searchTerm,
            ascending: true,
            orderBy: this.orderBy,
            page: 0,
            pageLength: this.pageLength,
        };

        this.fetch(params, { search: this.searchTerm }, this.basis)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.items = [];
                this.formattedItems = [];
                const results = response.results ?? response.content;
                if (results.length > 0) {
                    this.items = results;
                    this.formatItems(results);
                    return;
                }
                this.formattedItems.push('<span>No results found</span>');
            });
    }

    updateSearchModifier(modifier: string) {
        this.searchModifierChanged.emit(modifier);
        this.search(this.searchTerm);
    }

    private formatItems(results: any) {
        results.forEach((result: any) => {
            let value: string = this.format;
            Object.keys(result).forEach((key: string) => {
                const needle = `{${key}}`;
                if (value.includes(needle)) {
                    value = value.replace(needle, `<span>${result[key]}</span>`);
                }
            });
            this.formattedItems.push(value);
        });
    }

    onRowClick(index: number) {
        this.dialogRef.close(this.items[index]);
    }
}
