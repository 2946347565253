import {
    BusinessApiConfig,
    CprApiConfig,
    FormBuilderApiConfig,
    HcpApiConfig,
    IdentityApiConfig,
    LogicAppApiConfig,
    NarrativeApiConfig,
    PatientApiConfig,
    QuestionBuilderApiConfig,
} from 'src/app/models/api/api-config.models';
import { GUID } from 'src/app/models/guid.model';
import { iEnvironment } from './iEnvironment';

export class BaseEnvironment implements iEnvironment {
    production: boolean;
    apiIdentity: IdentityApiConfig;
    apiBusiness: BusinessApiConfig;
    apiPatient: PatientApiConfig;
    apiNarrative: NarrativeApiConfig;
    apiQuestionBuilder: QuestionBuilderApiConfig;
    apiFormBuilder: FormBuilderApiConfig;
    apiHealthCareProvider: HcpApiConfig;
    apiCpr: CprApiConfig;
    apiLogicApp: LogicAppApiConfig;
    instrumentationKey: GUID;

    constructor(apiConfigs: any, instrumentationKey: GUID, enableProdMode: boolean) {
        this.apiIdentity = apiConfigs.identity;
        this.apiBusiness = apiConfigs.business;
        this.apiPatient = apiConfigs.patient;
        this.apiQuestionBuilder = apiConfigs.questionBuilder;
        this.apiFormBuilder = apiConfigs.formBuilder;
        this.apiNarrative = apiConfigs.narrative;
        this.apiHealthCareProvider = apiConfigs.hcp;
        this.apiCpr = apiConfigs.cpr;
        this.apiLogicApp = apiConfigs.logicApp;
        this.production = enableProdMode;
        this.instrumentationKey = instrumentationKey;
    }
}
