<ng-container *ngIf="(acknowledged$ | async) || !(hcp$ | async)?.notes">
    <swft-toolbar
        title="HCP Profile Tools"
        (create)="startNarrative()"></swft-toolbar>
</ng-container>
<ng-container *ngIf="id$ | async; let id">
    <ng-container *ngIf="hcp$ | async; let hcp">
        <swft-profile-header
            profileType="HCP"
            [firstName]="hcp.phFirst"
            [lastName]="hcp.phLast"
            [id]="id"
            profileString="Id"></swft-profile-header>
        <swft-profile-acknowledge
            heading="Hcp Notes Alert"
            [userHasAcknowledged$]="acknowledged$"
            *ngIf="hcp?.notes"
            (acknowledge)="onAcknowledge()">
            {{ hcp?.notes }}
        </swft-profile-acknowledge>
        <ng-container *ngIf="(acknowledged$ | async) || !hcp?.notes">
            <swft-narratives
                #narrativesComponent
                [displayColumns]="displayColumns"
                [resultPageSize]="10"
                [narrativeType]="narrativeType"
                [searchId]="(id$ | async) ?? 0"></swft-narratives>
            <swft-profile
                [profileLinks]="profileLinks"
                [showHcp]="true"
                [id]="id"></swft-profile>
        </ng-container>
    </ng-container>
</ng-container>
