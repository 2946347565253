<label>Select a contact type</label>
<div class="button-container">
    <button
        color="primary"
        mat-raised-button
        (click)="navigate(callType.Inbound)">
        <mat-icon matPrefix>call_received</mat-icon>
        Inbound Call
    </button>
    <button
        color="primary"
        mat-raised-button
        (click)="navigate(callType.Outbound)">
        <mat-icon matPrefix>call_made</mat-icon>
        Outbound Call
    </button>
    <a
        class="alt-link"
        (click)="navigate(null)">
        Documentation Only
    </a>
</div>
