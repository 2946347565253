import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Constructor } from '@angular/cdk/table';
import {
    AfterContentChecked,
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
    Self,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState, _AbstractConstructor } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, Subscription } from 'rxjs';
import { EditorMode } from '../models/editor.models';

export enum ModuleTypes {
    NoMedia = 'noMedia',
    Minimal = 'minimal',
    Full = 'full',
}

export class EditorConfigs {
    noMedia = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link'],
        ],
    };
    minimal = {
        toolbar: [['bold', 'italic', 'underline', 'strike']],
    };
    full = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video'],
        ],
    };
}

/**
 * Custom error state matcher. Matches Quill editor input with Material error handling.
 */
export class CustomErrorStateBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {}
    stateChanges: Subject<void> = new Subject();
}

/**
 * Custom error state mixin, for applying material error handling logic to quill editor form fields
 */
declare type CanUpdateErrorStateCtor = Constructor<CanUpdateErrorState> & _AbstractConstructor<CanUpdateErrorState>;
export const CustomErrorStateMixin: CanUpdateErrorStateCtor & typeof CustomErrorStateBase =
    mixinErrorState(CustomErrorStateBase);

/**
 * Directive for inserting a Quill editor into a form field and handling error states via the
 * Material error handling logic.
 */
@Directive({
    selector: '[swftTextEditor]',
    providers: [{ provide: MatFormFieldControl, useExisting: QuillFormFieldDirective }],
})
export class QuillFormFieldDirective
    extends CustomErrorStateMixin
    implements
        OnInit,
        OnDestroy,
        DoCheck,
        AfterViewInit,
        AfterContentChecked,
        MatFormFieldControl<QuillFormFieldDirective>
{
    _quillInstance: any;
    _quillSubscription: Subscription = new Subscription();
    private _value: any;
    private _placeholder: string = '';
    private _required = false;
    controlType = 'quill-wrapper-directive';
    static nextId = 0;
    override stateChanges: Subject<void> = new Subject();
    override errorState: boolean = false;
    autofilled?: boolean | undefined;
    userAriaDescribedBy?: string | undefined;
    focused: boolean = false;

    @Input() editorStyle: string = 'noMedia';
    @Input() editorMode?: EditorMode;

    constructor(
        public override _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public override _parentForm: NgForm,
        @Optional() public override _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public override ngControl: NgControl,
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef<HTMLElement>,
        private quillEditor: QuillEditorComponent,
        private renderer: Renderer2
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    }

    ngOnInit(): void {
        let module: ModuleTypes;

        /**
         * Set quill editor module based on editorStyle input
         **/
        switch (this.editorStyle.toLowerCase()) {
            case ModuleTypes.Full.toLowerCase():
                module = ModuleTypes.Full;
                break;
            case ModuleTypes.Minimal.toLowerCase():
                module = ModuleTypes.Minimal;
                break;
            default:
                module = ModuleTypes.NoMedia;
        }

        this.quillEditor.modules = new EditorConfigs()[module];

        this._quillSubscription = this.quillEditor.onEditorCreated.subscribe(event => this.quillEditorCreated(event));

        this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe(origin => {
            const quillToolTipIsHidden: boolean =
                !!this.elementRef.nativeElement.querySelector('.ql-tooltip.ql-hidden');
            if (!origin && !quillToolTipIsHidden) return;
            this.focused = !!origin;
            this.stateChanges.next();
            this.hideShow();
        });

        if (this.editorMode === EditorMode.View) {
            this.ngControl.control?.disable();
            this.addViewOnlyExpandButton();
        }
    }

    ngOnDestroy() {
        this.stateChanges.pipe();
        this._quillSubscription.unsubscribe();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    }

    ngAfterViewInit(): void {
        this.addMarkerIfRequired();
    }

    ngAfterContentChecked(): void {
        if (!this.editorMode) this.inferEditorMode();
    }

    ngDoCheck() {
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions).
            this['updateErrorState']();
        }
    }

    quillEditorCreated($event: any) {
        this._quillInstance = $event;
    }

    /**
     * Hide or show the Quill editor if the input is focused
     */
    hideShow(): void {
        const classList: DOMTokenList = this.elementRef.nativeElement.classList;

        classList.remove('show');

        if (this.focused) {
            classList.add('show');
        }
    }

    set value(newValue: QuillFormFieldDirective | null) {
        this._value = newValue;
        this.stateChanges.next();
    }

    @HostBinding() id = `${this.controlType}-id-${QuillFormFieldDirective.nextId++}`;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    get empty() {
        let isEmpty = true;
        if (this._quillInstance) {
            // since quill always apply new line '/n' at the end of file, length === 1 means there is no text inside
            isEmpty = this._quillInstance.getLength() === 1;
        }
        return isEmpty;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _disabled = false;

    @HostBinding('attr.aria-describedby') describedBy = '';
    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {}

    /**
     * Adds a material 'required' marker after this input's
     * closest <mat-label> if a 'required' validator attached
     */
    private addMarkerIfRequired(): void {
        if (!this.ngControl.control?.errors?.hasOwnProperty('required')) return;

        const controlLabelElement: Element | undefined =
            this.elementRef.nativeElement.nextElementSibling?.getElementsByTagName('mat-label')[0];

        if (controlLabelElement) {
            const requiredSpan: HTMLSpanElement = document.createElement('span');
            requiredSpan.setAttribute('aria-hidden', 'true');
            requiredSpan.classList.add('mat-placeholder-required', 'mat-form-field-required-marker');
            requiredSpan.textContent = ' *';
            controlLabelElement.after(requiredSpan);
        }
    }

    /**
     * Attempt ot infer the editor mode by analysing other elements on the page
     * @returns the inferred editor mode
     */
    private inferEditorMode(): void {
        const pageHasViewOnlyElements: boolean = document.querySelectorAll('.view-only').length > 0;
        this.editorMode = pageHasViewOnlyElements ? EditorMode.View : EditorMode.Create;
        if (this.editorMode === EditorMode.View) this.addViewOnlyExpandButton();
    }

    private addViewOnlyExpandButton(): void {
        if (this.elementRef.nativeElement.querySelector('span.quill-expand')) return;
        const span: HTMLSpanElement = this.renderer.createElement('span');
        const iconElement = this.renderer.createElement('mat-icon');
        const icon = this.renderer.createText('expand_more');

        this.renderer.addClass(this.elementRef.nativeElement, 'view-only');

        this.renderer.appendChild(iconElement, icon);
        this.renderer.appendChild(span, iconElement);
        this.renderer.listen(span, 'click', () => {
            const expanded = this.elementRef.nativeElement.classList.contains('expanded');
            if (expanded) {
                this.renderer.removeClass(this.elementRef.nativeElement, 'expanded');
                return;
            }
            this.renderer.addClass(this.elementRef.nativeElement, 'expanded');
        });
        this.renderer.addClass(iconElement, 'mat-icon');
        this.renderer.addClass(iconElement, 'material-icons');
        this.renderer.addClass(span, 'quill-expand');
        this.renderer.appendChild(this.elementRef.nativeElement, span);
    }
}
