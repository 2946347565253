import { Injectable } from '@angular/core';
import { HealthCareProvider } from 'src/app/models/hcp.model';

@Injectable({
    providedIn: 'root',
})
export class HcpAdapter {
    adapt(item: any): HealthCareProvider {
        let hcp: HealthCareProvider = {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            npi: item.npi,
            professionalDesignation: item.professionalDesignation,
            remsId: item.remsId,
            address: item.address,
            city: item.city,
            state: item.state,
            zipCode: item.zipCode,
            phoneNumber: item.phoneNumber,
            faxNumber: item.faxNumber,
            organization: item.organization,
        };
        return hcp;
    }
}
