import { Injectable } from '@angular/core';
import { environment } from 'src/environments/config';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class LogicAppApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiLogicApp.protocol}://${environment.apiLogicApp.host}:${environment.apiLogicApp.port}/${environment.apiLogicApp.path}`;
}
