import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SortOrder } from 'src/app/models/api/api-search.models';
import { PaginationDataSource } from 'src/app/models/api/datasource.models';
import { GridAction, GridRowActionEvent, SwftGridColumn, SwftGridConfiguration } from 'src/app/models/grid.models';
import { SingleSearchTermQuery } from 'src/app/models/table.models';
import { EmptySearchGridText } from 'src/app/utils/constants';

@Component({
    selector: 'swft-grid-search',
    templateUrl: './swft-grid-search.component.html',
    styleUrls: ['./swft-grid-search.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SwftGridSearchComponent implements AfterViewInit {
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @Output() showDiscontinuedChange = new EventEmitter<boolean>();

    tableColumns: SwftGridColumn[] = [];
    expandedColumn: any = {};
    expandedRow: any;
    dataSource!: PaginationDataSource<any, any>;
    subscriptions: Subscription[] = [];
    title = '';
    showDiscontinuedToggle = false;
    showingDiscontinued = false;
    multiSelect = false;
    rows: any[] = [];

    viewColumns: string[] = [];
    initialQuery: SingleSearchTermQuery = { search: '' };
    query = new BehaviorSubject<SingleSearchTermQuery>(this.initialQuery);

    emptyDataText = EmptySearchGridText.NoResult;

    gridConfiguration!: SwftGridConfiguration;

    constructor(
        public dialogRef: MatDialogRef<SwftGridSearchComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            dataSource: PaginationDataSource<any, any>;
            columns: SwftGridColumn[];
            title: string;
            showDiscontinuedToggle: boolean;
            multiSelect: boolean;
        }
    ) {
        this.tableColumns = this.data.columns;
        this.dataSource = this.data.dataSource;
        this.title = this.data.title;
        this.multiSelect = this.data.multiSelect;
        this.showDiscontinuedToggle = this.data.showDiscontinuedToggle;
        this.rows = [];
        this.gridConfiguration = new SwftGridConfiguration({
            pageSize: 10,
            columnDefinitions: this.tableColumns,
            searchEnabled: false,
            initialSort: { order: SortOrder.Ascending, property: 'id' },
            searchTextPlaceholder: this.title,
            searchTextTermFilter: { searchField: 'value', value: '' },
            rowSelectEnabled: true,
            rowActions: [GridAction.Select],
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.queryBy({ search: '' });
    }

    search(query: string): void {
        this.dataSource.queryBy({ search: query });
    }

    refreshTable(): void {
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    rowAction(event: GridRowActionEvent): void {
        if (event.action === GridAction.Select || event.action === GridAction.Deselect) this.select(event.row);
    }

    select(row: any): void {
        if (!this.multiSelect) {
            this.subscriptions.forEach(s => s.unsubscribe());
            this.dialogRef.close(row);
            return;
        }

        var index = this.rows.findIndex(item => isEqual(item, row));
        if (index === -1) this.rows.push(row);
        else this.rows = this.rows.filter(item => !isEqual(item, row));
    }

    close(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.dialogRef.close();
    }

    cancel(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.dialogRef.close();
    }

    save(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.dialogRef.close(this.rows);
        this.rows.forEach(x => {
            x.selected = false;
        });
        this.rows = [];
    }
    /*
     * will trigger
     */
    showdeleted(): void {
        this.showingDiscontinued = !this.showingDiscontinued;
        this.showDiscontinuedChange.emit(this.showingDiscontinued);
    }
}
