import { Endpoint } from './endpoint';

export class CprEndpoints {
    noteTemplate: NoteTemplate = new NoteTemplate();
    progressNote: ProNotes = new ProNotes();
}

export class NoteTemplate {
    lookup = new Endpoint('get', 'v1/notetemplate');
    search = new Endpoint('post', 'v1/notetemplate/search');
}

export class ProNotes {
    create = new Endpoint('post', 'v1/progressnote');
}
