<h3 mat-dialog-title>
    Add Progress Note: {{ currentPatient?.lastName }}, {{ currentPatient?.firstName }} {{ currentPatient?.mrn }}
</h3>

<form
    class="form-container"
    [formGroup]="form">
    <h2>Choose Subject</h2>
    <mat-form-field
        class="full subject-field"
        appearance="fill">
        <mat-label>Subject</mat-label>
        <input
            matInput
            swftSelectSearch
            searchOrderBy="name"
            [searchPageLength]="500"
            searchValueFormat="<span></span>{name}"
            [ngModel]="(selectedTemplate$ | async)?.name"
            [fetch]="fetchMethod"
            (selectionChanged)="selectionChanged($event)"
            formControlName="subject" />
    </mat-form-field>

    <h2>Note Template</h2>
    <mat-form-field
        class="note-form-field"
        appearance="fill">
        <mat-label>Body</mat-label>
        <textarea
            matInput
            [ngModel]="noteBody$.value"
            (ngModelChange)="updateBody($event)"
            swftTextareaAutoresize
            formControlName="body"></textarea>
    </mat-form-field>
</form>

<mat-dialog-actions>
    <swft-confirm
        (deny)="close()"
        (confirm)="save()"
        confirmText="save"
        [disabled]="form.invalid"></swft-confirm>
</mat-dialog-actions>
