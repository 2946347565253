import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionName, PermissionType } from 'src/app/models/auth/authorization.models';
import { GridAction } from 'src/app/models/grid.models';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'swft-actions-column',
    templateUrl: './swft-actions-column.component.html',
    styleUrls: ['./swft-actions-column.component.scss'],
})
export class SwftActionsColumnComponent implements AfterViewInit, OnDestroy {
    @Input() permissionName: PermissionName | undefined;
    @Input() checked: boolean = false;

    @Input() editText: string = 'Edit';
    @Input() viewText: string = 'View';
    @Input() discontinueText: string = 'Discontinue';
    @Input() duplicateText: string = 'Duplicate';
    @Input() addText: string = 'Add';
    @Input() removeText: string = 'Remove';
    @Input() conversationText: string = 'Start Conversation';
    @Input() reorderText: string = 'Reorder Row';
    @Input() reorderable: boolean = false;
    @Input() displayedActions: GridAction[] | undefined = undefined;

    @Output() edit = new EventEmitter();
    @Output() view = new EventEmitter();
    @Output() duplicate = new EventEmitter();
    @Output() discontinue = new EventEmitter();
    @Output() add = new EventEmitter();
    @Output() remove = new EventEmitter();
    @Output() conversation = new EventEmitter();
    @Output() selection = new EventEmitter();
    @Output() gridAction = new EventEmitter();

    tooltip: string = '';
    tooltipWarn: string = '';

    permissionType = PermissionType;
    GridAction = GridAction;

    showActions: boolean = false;
    visibleButtons: EventEmitter<any>[] = [];
    unsubscribe: Subject<void> = new Subject();

    constructor(private auth: AuthService, private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.parentElement.classList.add('swft-actions-column');
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * Checks if the user has permission to perform the action associated with the action column button.
     * If the user does not have permission, return false.
     * If the user has permission, check to see if the button has an attached observer (i.e. there is
     * something listening to the button's click event) and if so, return true; otherwise, return false.
     * If any button is shown, the actions column will be shown AfterViewInit.
     * @param outputEmitter The event emitter associated with the action column button.
     * @param permission The permission associated with the action column button.
     * @returns boolean
     */
    showButton(outputEmitter: EventEmitter<null>, permission: PermissionType | null = null, type: GridAction): boolean {
        const hasPermision: boolean =
            this.permissionName && permission ? this.auth.hasValidPermission(this.permissionName, permission) : true;

        if (hasPermision && (outputEmitter.observed || this.displayedActions?.includes(type))) {
            this.visibleButtons.push(outputEmitter);
            this.showActions = true;
            return true;
        }

        return false;
    }

    onlyActionIsSelection(): boolean {
        return this.displayedActions?.length === 1 && this.displayedActions[0] === GridAction.Select;
    }

    selectionChanged(checked: boolean): void {
        const action: GridAction = checked ? GridAction.Select : GridAction.Deselect;
        this.gridAction.emit(action);
    }
}
