import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root',
})
export class DataDictionaryApiService extends BaseApiService {
    protected override baseUrl: string = `${environment.apiQuestionBuilder.protocol}://${environment.apiQuestionBuilder.host}:${environment.apiQuestionBuilder.port}/${environment.apiQuestionBuilder.path}`;
}
