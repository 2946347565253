import { template, templateSettings } from 'lodash-es';

export interface IEndpoint {
    path: string;
    verb: string;
    interpolate(data: object): void;
}

export class Endpoint implements IEndpoint {
    public path: string;

    /**
     * @param verb HTTP Verb
     * @param _path Un-interpolated path
     */
    constructor(public verb: string, public _path: string) {
        this.path = this._path;
    }

    interpolate(data: object): void {
        // Url encode individual properties before interpolating
        for (var prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                (data as any)[prop] = encodeURIComponent((data as any)[prop]);
            }
        }

        templateSettings.interpolate = /\$?{([\s\S]+?)}/g;
        this.path = template(this._path)(data);
    }
}
