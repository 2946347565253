import { Endpoint, IEndpoint } from './endpoint';

export class PatientEndpoints {
    getById: IEndpoint = new Endpoint('get', 'v1/patient/{id}');
    search: IEndpoint = new Endpoint('post', 'v1/patient/search');
    searchDemographics: IEndpoint = new Endpoint('post', 'v1/patient/searchDemographics');
    addresses: IEndpoint = new Endpoint('post', 'v1/patient/addresses');
    readPatientAlerts: IEndpoint = new Endpoint('get', 'v1/patientalert/{patientId}/alerts');
    patientProfile = new PatientProfileEndpoints();
    patientOrder = new PatientOrderEndpoints();
    getNewSpecialId: IEndpoint = new Endpoint('get', 'v1/patient/generateSpecialPatientId');
    getPatientsBySpecialId: IEndpoint = new Endpoint('get', 'v1/patient/specialPatientId/{specialId}');
    assignPatientSpecialId: IEndpoint = new Endpoint('put', 'v1/patient/saveSpecialId');
}

export class PatientProfileEndpoints {
    progressNotes: IEndpoint = new Endpoint('post', 'v1/patientprofile/{id}/progressnotes');
    billingNotes: IEndpoint = new Endpoint('post', 'v1/patientprofile/{id}/billingnotes');
    userDefined: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/userdefined');
    orders: IEndpoint = new Endpoint(
        'get',
        'v1/patientprofile/{patientId}/orders?showDiscontinuedOrders={showDiscontinuedOrders}'
    );
    dispenses: IEndpoint = new Endpoint('post', 'v1/patientprofile/{patientId}/dispenses/{showVoided}');
    discharges: IEndpoint = new Endpoint('post', 'v1/patientprofile/{patientId}/discharges');
    getDemographic: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/demographic');
    getContacts: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/contacts');
    getPayors: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/payors');
    getHtWtHistory: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/heightweighthistory');

    getAllergies: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/allergies');
    getDiagnosis: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/diagnosis');
    getNonPantherMedication: IEndpoint = new Endpoint(
        'get',
        'v1/patientprofile/{patientId}/nonpanthermedications/{showDiscontinuedOrders}'
    );
    searchNonPantherMedication: IEndpoint = new Endpoint(
        'post',
        'v1/patientprofile/{patientId}/searchnonpanthermedications/{showDiscontinuedOrders}'
    );
    getMedicalHistory: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/medicalhistory/');

    getPhysicians: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/physicians');
    getOrders: IEndpoint = new Endpoint('post', 'v1/patientprofile/{patientId}/orders');
    getWorkingDeliveryTickets: IEndpoint = new Endpoint('get', 'v1/patientprofile/{patientId}/deliveries/working');
    getConfirmedDeliveryTickets: IEndpoint = new Endpoint('post', 'v1/patientprofile/{patientId}/deliveries/confirmed');
}

export class PatientOrderEndpoints {
    getById: IEndpoint = new Endpoint('post', 'v1/patientOrder/{patientId}/getOrdersById');
}
