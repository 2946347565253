export enum TokenTimerMessageTypes {
    INIT = 'init',
    CLEAR = 'clear',
    END = 'end',
}

export class TokenTimerMessage {
    type: TokenTimerMessageTypes;
    time?: number;
    constructor(type: TokenTimerMessageTypes, time?: number) {
        this.type = type;
        this.time = time;
    }
}
