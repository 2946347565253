import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, filter, map, pairwise, Subject, take, takeUntil, tap } from 'rxjs';
import { ProgressNote } from 'src/app/models/conversation.model';
import { NoteTemplate } from 'src/app/models/note-template.model';
import { Patient } from 'src/app/models/patient.model';
import { NotesService } from 'src/app/services/conversation/notes.service';
import { NoteTemplateService } from 'src/app/services/note-template/note-template.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { PatientProfileStateManagerService } from '../../profile/patient-profile/patient-profile-state-manager.service';
import { ConfirmationDialogComponent } from '../../swft/swft-confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'swft-template-progress-notes',
    templateUrl: './progress-notes-editor.component.html',
    styleUrls: ['./progress-notes-editor.component.scss'],
})
export class ProgressNotesEditorComponent implements OnInit, OnDestroy {
    private selectedTemplateBody: string = '';
    private onDestroy$: Subject<void> = new Subject<void>();

    selectedTemplateName: string = '';

    form: FormGroup = new FormGroup({
        subject: new FormControl(null, [Validators.required]),
        body: new FormControl(null, [Validators.required, Validators.maxLength(8000)]),
    });

    selectedTemplate$: BehaviorSubject<NoteTemplate | null> = new BehaviorSubject<NoteTemplate | null>(null);
    noteBody$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    currentPatient: Patient | undefined;

    fetchMethod = this.noteTemplateService.searchNoteTemplates.bind(this.noteTemplateService);

    constructor(
        private noteTemplateService: NoteTemplateService,
        private notesService: NotesService,
        private patientProfileStateManager: PatientProfileStateManagerService,
        private dialogRef: MatDialogRef<ProgressNotesEditorComponent>,
        private dialog: MatDialog,
        private snackBar: SWFTSnackBarService
    ) {}

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnInit(): void {
        this.patientProfileStateManager.item$
            .pipe(
                take(1),
                tap(patient => {
                    this.currentPatient = patient;
                })
            )
            .subscribe();

        this.selectedTemplate$
            .pipe(
                pairwise(),
                filter(
                    ([noteTemplateOld, noteTemplateNew]) =>
                        noteTemplateOld !== noteTemplateNew || noteTemplateNew !== null
                ),
                map(([noteTemplateOld, noteTemplateNew]) => noteTemplateNew),
                tap(noteTemplate => {
                    if (noteTemplate) {
                        this.selectedTemplateName = noteTemplate.name;

                        // format the note to strip out formatting characters so the note is displayed properly to the user
                        const formattedBody = this.formatNote(noteTemplate.note);
                        this.noteBody$.next(formattedBody);
                        this.selectedTemplateBody = formattedBody;
                    }
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe();
    }

    selectionChanged(noteTemplate: NoteTemplate) {
        if (!noteTemplate) return;

        if (!this.selectedTemplate$.value) {
            this.selectedTemplate$.next(noteTemplate);
            return;
        }

        if (this.selectedTemplateBody == this.noteBody$.value) {
            this.selectedTemplate$.next(noteTemplate);
        } else {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                panelClass: 'small-auto',
                disableClose: true,
                data: {
                    description: 'Changing the note template will erase existing notes associated with the template.',
                    message: 'Are you sure you would like to change the note template?',
                },
            });

            dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.selectedTemplate$.next(noteTemplate);
                }
            });
        }
    }

    updateBody($event: string) {
        this.noteBody$.next($event);
    }

    close(success?: boolean) {
        this.dialogRef.close(success);
    }

    save() {
        const finalNote: ProgressNote = {
            mrn: this.currentPatient?.mrn.toString(),
            subject: this.selectedTemplateName,
            body: this.noteBody$.value,
        };

        this.notesService
            .create(finalNote)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                error: () => {
                    this.snackBar.error('Creating progress note failed');
                },
                complete: () => {
                    this.close(true);
                    this.snackBar.success('Progress note saved successfully');
                },
            });
    }

    /**
     * Note templates from CPR+ contain characters for carriage returns, newlines, and tabs
     * This function converts those characters so they can be visually displayed to the user
     * @param note - a string containing 0 or more sets of /r/n or /t
     */
    private formatNote(note: string): string {
        return note.replaceAll('/r/n', '\n').replaceAll('/t', '\t');
    }
}
