import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LeftNavLink } from '../models/left-nav.model';
import { LeftNavService } from '../services/left-nav/left-nav.service';

@Injectable({ providedIn: 'root' })
export class LeftNavResolver implements Resolve<LeftNavLink> {
    constructor(private service: LeftNavService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<LeftNavLink> | Promise<LeftNavLink> | LeftNavLink {
        let addedLink: Observable<LeftNavLink> = of({});

        const hcpId: number = parseInt(route.params['hcpId']);

        if (!isNaN(hcpId)) {
            const url = `/hcp/${hcpId}`;
            this.service.addHcpServiceLink(hcpId, url);
        }

        return addedLink;
    }
}
