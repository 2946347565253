<label>
    Outbound call unsuccessful
    <small *ngIf="!hasPhoneNumbers">Phone number not found</small>
</label>
<div class="button-container">
    <button
        color="primary"
        mat-raised-button
        (click)="navigate(FailedCallAction.Continue)">
        <mat-icon matPrefix>forum</mat-icon>
        <span>Open Conversation</span>
        <small>Documentation Only</small>
    </button>
    <button
        color="primary"
        mat-raised-button
        (click)="navigate(FailedCallAction.SaveAndClose)">
        <mat-icon matPrefix>published_with_changes</mat-icon>
        Save and Close Conversation
    </button>
    <button
        color="gray"
        mat-raised-button
        (click)="navigate(FailedCallAction.Retry)">
        <mat-icon matPrefix>switch_account</mat-icon>
        Attempt Another Contact
    </button>
    <a
        *ngIf="contact$ | async; let contact"
        class="alt-link"
        (click)="navigate(FailedCallAction.AddPhoneNumber)">
        I have a number for {{ contact.firstName }} {{ contact.lastName }}
    </a>
</div>
