import { get, set } from 'lodash-es';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { TimeOfDay } from '../enums/datetime.enums';
import { Page, SingleSearchTermQuery } from '../models/table.models';
import { DateTimeFormat } from './constants';

// This is the shape of the page from our search model in the API
export interface SearchResult {
    page: number;
    pageLength: number;
    results: any[];
    total: number;
}
// Maps from the API search result to the Page model we use within the app
export const mapFromSearchResult = (obs: Observable<SearchResult>) =>
    obs.pipe(
        map<SearchResult, Page<any>>((result: SearchResult) => ({
            content: result.results,
            totalElements: result.total,
            size: result.pageLength,
            number: result.page,
        }))
    );

/** Checks if a search term is in a date format, and if so provides the offset to utc
 *
 * @param value
 * @returns
 */
export function parseSearchOffset(query: SingleSearchTermQuery): string {
    let value = query.search.toLowerCase().trim();
    const timeOfDay = getTimeOfDay(query.search);

    if (value.endsWith(':')) value = value.slice(0, -1);

    for (let i = DateTimeSearchFormats.length - 1; i >= 0; --i) {
        const format = DateTimeSearchFormats[i];
        value = value.toLowerCase().replace(TimeOfDay.AM, '').replace(TimeOfDay.PM, '').trim();
        let date = DateTime.fromFormat(value, format);

        if (date.isValid) {
            const isLocalDST = DateTime.local().isInDST;
            if (date.isInDST && !isLocalDST) date = date.plus({ hours: 1 });
            if (!date.isInDST && isLocalDST) date = date.minus({ hours: 1 });
            if (timeOfDay && timeOfDay === TimeOfDay.PM) date = date.plus({ hours: 12 });
            value = date.toUTC().toFormat(format);
            break;
        }
    }

    return value;
}

const DateTimeSearchFormats: string[] = [
    'hh:m',
    'h:mm',
    'hh:mm',
    'hh:mm:s',
    'hh:mm:ss',
    'MM/dd/yyyy',
    'MM/dd/yyyy hh',
    'MM/dd/yyyy hh:m',
    'MM/dd/yyyy hh:mm',
    'MM/dd/yyyy hh:mm:s',
    'MM/dd/yyyy hh:mm:ss',
    'MM/dd/yyyy hh:mm:ss a',
];

// converts a utc string to local time
export function utcStringToLocalString(utc: string): string {
    if (!utc.endsWith('Z')) utc = `${utc}Z`;
    let utcDate = DateTime.fromISO(utc);
    const isLocalDST = DateTime.local().isInDST;
    if (utcDate.isInDST && !isLocalDST) utcDate = utcDate.minus({ hours: 1 });
    if (!utcDate.isInDST && isLocalDST) utcDate = utcDate.plus({ hours: 1 });
    return utcDate.toFormat(DateTimeFormat);
}

/**
 * Converts the date values of a search results page to local time
 * @param page the page to convert
 * @param search the search term
 * @param properties the properties to convert
 * @returns the converted page
 */
export function convertDateValuesToLocalTime<T extends object>(
    page: Page<T>,
    search: string,
    properties: string[]
): Page<T> {
    const timeOfDay = getTimeOfDay(search);
    const content: any[] = [];
    for (let i = 0; i < page.content.length; i++) {
        let hasCorrectTimeOfDay = false;
        for (let j = 0; j < properties.length; j++) {
            const propertyValue = get(page.content[i], properties[j]);
            const localDateString = utcStringToLocalString(propertyValue);
            if (timeOfDay && localDateString.toLowerCase().includes(timeOfDay)) {
                hasCorrectTimeOfDay = true;
            }
            set(page.content[i], properties[j], localDateString);
        }
        if (!timeOfDay || hasCorrectTimeOfDay) {
            content.push(page.content[i]);
        }
    }
    page.content = content;
    return page;
}

/**
 * Removes search results that do not match the search term
 * @param page the page to clean
 * @param search the search term
 * @returns the cleaned page
 */
export function cleanSearchResults<T extends object>(page: Page<T>, search: string): Page<T> {
    const searchTermParts = search.split(' ');
    const content: any[] = [];
    for (let i = 0; i < page.content.length; i++) {
        const stringifiedContentItem = JSON.stringify(page.content[i]).toLowerCase();
        const includesSearchTerm = searchTermParts.every(term => stringifiedContentItem.includes(term.toLowerCase()));
        if (includesSearchTerm) {
            content.push(page.content[i]);
        }
    }
    page.content = content;
    page.size = content.length;
    page.totalElements = content.length;
    return page;
}

/**
 * Gets the time of day from a date string
 * @param dateString the date string
 * @returns the time of day or undefined if not found
 */
export function getTimeOfDay(dateString: string): string | undefined {
    return dateString
        .toLowerCase()
        .split(' ')
        .find(term => term.includes(TimeOfDay.AM) || term.includes(TimeOfDay.PM));
}
