import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * To add an icon, place an SVG file for the icon in the `assets/icons` folder, then add the
 * file name to the 'icons' array.
 */
@Injectable({ providedIn: 'root' })
export class IconService {
    private icons: string[] = ['doctor', 'event_upcoming', 'select_search'];

    constructor(private registry: MatIconRegistry, private sanitizer: DomSanitizer) {}

    registerIcons() {
        this.icons.forEach(icon => {
            this.registry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon}.svg`));
        });
    }
}
