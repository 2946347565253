import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/swft/modules/material.module';
import { SwftModule } from '../../swft/swft.module';
import { HipaaAddPhoneNumberComponent } from './hipaa-add-phone-number/hipaa-add-phone-number.component';
import { HipaaCallFailedComponent } from './hipaa-call-failed/hipaa-call-failed.component';
import { HipaaCallLogComponent } from './hipaa-call-log/hipaa-call-log.component';
import { HipaaOutboundCallComponent } from './hipaa-outbound-call.component';
import { HipaaPhoneNumberSelectorComponent } from './hipaa-phone-number-selector/hipaa-phone-number-selector.component';

@NgModule({
    declarations: [
        HipaaCallFailedComponent,
        HipaaCallLogComponent,
        HipaaOutboundCallComponent,
        HipaaPhoneNumberSelectorComponent,
        HipaaAddPhoneNumberComponent,
    ],
    imports: [CommonModule, MaterialModule, SwftModule],
    exports: [HipaaCallFailedComponent, HipaaCallLogComponent, HipaaPhoneNumberSelectorComponent],
})
export class HipaaOutboundCallModule {}
