import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    constructor() {}

    /**
     * Fetches items from session storage from a given key and maps to the passed in type
     * @param key the key for the item in session storage
     * @param mapper function for mapping the item to the generic type: ex `(item: string) => { return Number(item); }`
     */
    getObject<T>(key: string, mapper?: (item: string) => T): T | undefined {
        var item = this.getItem(key);

        if (item) {
            if (mapper) {
                return mapper(item);
            } else {
                var result: T = JSON.parse(item);
                return result;
            }
        }

        return undefined;
    }

    /**
     *
     * @param key the key for the item in session storage.
     * This method should be used to query for strings in session storage.
     * @returns @type string | undefined
     */
    getItem(key: string): string | undefined {
        return sessionStorage.getItem(key) || undefined;
    }

    /**
     * Stores items in session storage under the provided key.
     * @param key the key for the item in session storage
     * @param item the object you intend to store in session storage.
     */
    setObject<T>(key: string, item: T) {
        sessionStorage.setItem(key, JSON.stringify(item));
    }

    /**
     * Stores a string in session storage under the provided key
     * @param key the key for the item in session storage
     * @param item the string value that you intend to store in session storage
     */
    setItem(key: string, item: string) {
        sessionStorage.setItem(key, item);
    }

    /**
     * Removes and item from the user's session storage
     * @param key the key for the item in session storage
     */
    removeItem(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
     * Clears all session storage items
     */
    clear() {
        sessionStorage.clear();
    }
}
