import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { AnswerHistorySearch } from 'src/app/models/answer-history.model';
import { SearchOptions, SortOrder } from 'src/app/models/api/api-search.models';
import {
    Conversation,
    ConversationNarrative,
    MedicationLookup,
    StartPointLookup,
} from 'src/app/models/conversation.model';
import { Narrative, NarrativeSearch } from 'src/app/models/narrative.model';
import { PatientOrder } from 'src/app/models/profile/order.model';
import { Page, PageRequest, SingleSearchTermQuery } from 'src/app/models/table.models';
import { NarrativeApiService } from 'src/app/services/api/narrative-api.service';
import { ConversationService } from 'src/app/services/conversation/conversation.service';
import { LeftNavService } from 'src/app/services/left-nav/left-nav.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { SwftBasis } from 'src/app/utils/constants';
import { mapFromSearchResult, parseSearchOffset } from 'src/app/utils/search.utils';
import { environment } from 'src/environments/environment';
import { OrderAdapter } from '../../services/order/order.adapter';
import { ConversationFormService } from './conversations/state/conversation-form.service';
import { CreateNarrativeComponent } from './create/create-narrative.component';
import { NarrativeAdapter } from './narrative.adapter';
export interface OrderSearchOptions extends SearchOptions {
    showDiscontinued: boolean;
    patientId: number;
}
export interface NarrativeSearchOptions extends SearchOptions {
    formBasis: string;
    searchId: string;
    status: string;
    nextActionDate: Date | undefined;
}
@Injectable({
    providedIn: 'root',
})
export class NarrativeService {
    constructor(
        private narrativeAdapter: NarrativeAdapter,
        private api: NarrativeApiService,
        private orderAdapter: OrderAdapter,
        private dialog: MatDialog,
        private leftNavService: LeftNavService,
        private conversationService: ConversationService,
        private conversationFormService: ConversationFormService,
        private router: Router,
        private snackBar: SWFTSnackBarService
    ) {}

    searchOrdersByPatientId(
        request: PageRequest<PatientOrder>,
        query: SingleSearchTermQuery,
        patientId: number,
        showDiscontinued?: boolean
    ): Observable<Page<PatientOrder>> {
        const params: OrderSearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: query.search,
            showDiscontinued: !!showDiscontinued,
            patientId: patientId,
        };
        return this.api
            .getObservableEndpoint(environment.apiNarrative.endpoints.searchOrdersByMrn)(params)
            .pipe(mapFromSearchResult);
    }

    getNarrative(id: number): Observable<NarrativeSearch> {
        const params = { id };
        return this.api
            .getObservableEndpoint(environment.apiNarrative.endpoints.getById)(null, params)
            .pipe(map(data => this.narrativeAdapter.adapt(data)));
    }

    getAnswerHistory(
        patientId: number,
        request: PageRequest<AnswerHistorySearch>,
        query: SingleSearchTermQuery
    ): Observable<Page<any>> {
        const payload: SearchOptions = {
            page: request.page,
            pageLength: request.size,
            ascending: request.sort?.order === SortOrder.Ascending,
            orderBy: request.sort?.property!,
            value: parseSearchOffset(query),
        };

        return this.api
            .getObservableEndpoint(environment.apiNarrative.endpoints.answer.getAnswerHistory)(payload, {
                patientId,
            })
            .pipe(mapFromSearchResult);
    }

    update(narrative: Narrative): Observable<Narrative> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.update)(narrative);
    }

    create(narrative: Narrative): Observable<Narrative> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.create)(narrative);
    }

    createBulk(narrative: Narrative[]): Observable<Narrative[]> {
        return this.api.getObservableEndpoint(environment.apiNarrative.endpoints.bulkCreate)(narrative);
    }

    startNarrative(profileId: number): Observable<void> {
        return this.dialog
            .open(CreateNarrativeComponent, {
                panelClass: 'medium',
                data: { patientId: profileId },
            })
            .afterClosed();
    }

    startHcpNarrative(narrativeType: NarrativeType, hcpId: number): Observable<void> {
        return this.dialog
            .open(CreateNarrativeComponent, {
                panelClass: 'medium',
                data: { narrativeType, hcpId },
            })
            .afterClosed();
    }

    startMultiNarrativeConversation(narratives: Narrative[]): void {
        const link = this.leftNavService.getConversationLinkByNarrative(
            narratives.filter(narr => !!narr).map(narr => narr.id)
        );

        if (link && link.url) {
            this.router.navigateByUrl(link.url);
            return;
        }

        const conversation: Conversation = {
            id: 0,
            startDate: new Date(),
            conversationNarratives: narratives.map<ConversationNarrative>(narrative => ({
                conversationId: 0,
                narrativeId: narrative.id,
                medicationName: narrative.medicationName,
            })),
            narratives: [],
            conversationFormData: [],
        };
        this.conversationService
            .create(conversation)
            .pipe(take(1))
            .subscribe({
                next: result => {
                    let narrative = narratives[0];
                    let formBasis = narrative.formBasisName;

                    let medicationNames: MedicationLookup[] = [];
                    let startPoints: StartPointLookup[] = [];

                    conversation.conversationNarratives?.forEach(convo => {
                        let medName: MedicationLookup = {
                            narrativeId: convo.narrativeId,
                            medicationName: convo.medicationName,
                            conversationId: result.id,
                        };
                        medicationNames.push(medName);
                    });
                    this.conversationFormService.setMedicationNames(medicationNames);

                    conversation.conversationNarratives?.forEach(convo => {
                        let startPoint: StartPointLookup = {
                            narrativeId: convo.narrativeId,
                            startPoint: this.router.url,
                            conversationId: result.id,
                        };
                        startPoints.push(startPoint);
                    });

                    switch (formBasis) {
                        case SwftBasis.Order:
                        case SwftBasis.Patient:
                            this.router.navigate(['/', 'patients', narrative.patientId, 'conversations', result.id]);
                            break;
                        case SwftBasis.HCP:
                            this.router.navigate(['/', 'hcp', narrative.hcpId, 'conversations', result.id]);
                            break;
                    }
                },
                error: () => {
                    this.snackBar.error('Conversation was unable to be started, call failed.');
                },
            });
    }
}
