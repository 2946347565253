import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterContentChecked,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import _ from 'lodash';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ApiSearch, ApiSearchTerm, SortOrder } from 'src/app/models/api/api-search.models';
import { PaginationDataSource } from 'src/app/models/api/datasource.models';
import {
    GridAction,
    GridBulkActionEvent,
    GridRowActionEvent,
    PayloadSortOrderType,
    SwftGridColumn,
    SwftGridConfiguration,
} from 'src/app/models/grid.models';
import { Page, PageRequest, SingleSearchTermQuery, SwftSort } from 'src/app/models/table.models';
import { EmptyQuery, EmptyTableCell } from 'src/app/utils/constants';
import { mapFromSearchResult } from 'src/app/utils/search.utils';
@Component({
    selector: 'swft-grid',
    templateUrl: './swft-grid.component.html',
    styleUrls: ['./swft-grid.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SwftGridComponent implements OnInit, OnDestroy, AfterContentChecked {
    @ViewChild('table', { read: ElementRef }) table!: ElementRef<HTMLTableElement>;
    @Input() gridConfiguration: SwftGridConfiguration = new SwftGridConfiguration({});
    /** If this is defined, skip setting the datasource in the initDataSource function */
    @Input() dataSource: PaginationDataSource<any, SingleSearchTermQuery> | undefined;
    @Output() rowAction: EventEmitter<GridRowActionEvent> = new EventEmitter<GridRowActionEvent>();
    @Output() bulkAction: EventEmitter<GridBulkActionEvent> = new EventEmitter<GridBulkActionEvent>();

    columnList: string[] = [];
    sort: SwftSort<any> = { order: SortOrder.Ascending, property: 'id' };
    onDestroy$: Subject<void> = new Subject<void>();
    GridAction = GridAction;
    pageRows: object[] = [];
    expandedRow: object | undefined;
    selectedRows: object[] = [];
    lastSelectedRowIndex: number | undefined;
    shiftKeyDown: boolean = false;
    detailRowWidth: number = 0;
    searchText: string = '';

    constructor(private renderer: Renderer2) {}

    @HostListener('window:keyup', ['$event'])
    @HostListener('window:keydown', ['$event'])
    updateShiftKeyStatus(event: KeyboardEvent) {
        this.shiftKeyDown = event.shiftKey;
    }

    ngOnInit(): void {
        this.sort = this.gridConfiguration.initialSort;
        this.setColumnListFromColumnDefinitions();
        this.initDataSource();

        this.gridConfiguration.searchParameters.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.refresh());

        this.dataSource?.page$.pipe(takeUntil(this.onDestroy$)).subscribe(page => {
            this.pageRows = page.content;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngAfterContentChecked(): void {
        if (!this.table) return;
        this.detailRowWidth = this.table.nativeElement.offsetWidth;
    }

    /**
     * Processes a row "select"/"deselect" action event when a modifier key is depressed
     * and emits the event to the parent component
     * @param event The event that was emitted from the grid row
     */
    multiSelect(event: GridRowActionEvent): void {
        if (this.lastSelectedRowIndex === undefined) return;
        const currentRowIndex = this.pageRows.indexOf(event.row);
        const start = Math.min(this.lastSelectedRowIndex, currentRowIndex);
        const end = Math.max(this.lastSelectedRowIndex, currentRowIndex);
        const rows = this.pageRows.slice(start, end + 1);
        if (event.action === GridAction.Select) {
            this.selectedRows = _.union(this.selectedRows, rows);
            this.bulkAction.emit({ action: GridAction.Select, rows: this.selectedRows });
        }
        if (event.action === GridAction.Deselect) {
            this.selectedRows = _.difference(this.selectedRows, rows);
            this.bulkAction.emit({ action: GridAction.Deselect, rows: this.selectedRows });
        }
        this.lastSelectedRowIndex = currentRowIndex;
    }

    /**
     * Processes a bulk action event and emits the event to the parent component
     * @param action The action to be performed on the selected rows
     */
    emitBulkAction(action: GridAction): void {
        if (action === GridAction.Select) this.selectedRows = this.pageRows;
        if (action === GridAction.Deselect) this.selectedRows = [];
        if (this.selectedRows.length === 0) return;
        this.bulkAction.emit({ action, rows: this.selectedRows });
    }

    /**
     * Processes a row action event and emits the event to the parent component
     * @param event The event that was emitted from the grid row
     */
    emitRowAction(event: GridRowActionEvent): void {
        if (this.shiftKeyDown) {
            this.multiSelect(event);
            return;
        }
        this.lastSelectedRowIndex = this.pageRows.indexOf(event.row);
        const selected: boolean = this.selectedRows.includes(event.row);
        if (selected && event.action === GridAction.Deselect) {
            this.selectedRows = this.selectedRows.filter(row => row !== event.row);
        }
        if (!selected && event.action === GridAction.Select) {
            this.selectedRows.push(event.row);
        }
        this.rowAction.emit(event);
    }

    /**
     * Updated the expanded row to the row that was last clicked or undefined if the row was already expanded
     * @param $event A mouse event to stop propagation of the click event to the row click handler
     * @param row The row to expand
     */
    expandRow($event: MouseEvent, row: object): void {
        $event.stopPropagation();
        if (this.expandedRow === row) {
            this.expandedRow = undefined;
            return;
        }
        this.expandedRow = row;
    }

    /**
     * Refreshes the data grid, here so that consuming components
     * can force a data refresh (i.e. when a new record is added)
     */
    refresh(): void {
        this.dataSource?.fetch(this.gridConfiguration.apiSearchConfig.pageNumber);
    }

    /**
     * Updates the search text and fetches the data using the new filter
     * @param searchText The search text
     */
    search(searchText: string): void {
        this.gridConfiguration.updateFilter(this.gridConfiguration.searchTextTermFilter.searchField, searchText);
        this.searchText = searchText;
        this.refresh();
    }

    /**
     * Fires when the date is changed in the search component
     * @param date The new date
     */
    searchDateChanged(date: Date): void {
        this.gridConfiguration.apiSearchConfig.dateFilter = date;
        this.refresh();
    }

    /**
     *  Changes the applied sort (fetches new data)
     * @param sort The sort to apply
     */
    applySort(sort: Sort) {
        this.sort = {
            order: sort.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
            property: sort.active,
        };
        this.dataSource?.sortBy(this.sort);
    }

    /**
     * Fires when the primary toggle filter is toggled
     * @param enabled true if the toggle is on
     */
    primaryToggleChanged(enabled: boolean): void {
        if (!this.gridConfiguration.primaryToggleFilter) return;

        if (enabled) this.addFilter(this.gridConfiguration.primaryToggleFilter);
        else this.removeFilter(this.gridConfiguration.primaryToggleFilter);

        this.refresh();
    }

    /**
     * Fires when the secondary toggle filter is toggled
     * @param enabled true if the toggle is on
     */
    secondaryToggleChanged(enabled: boolean): void {
        if (!this.gridConfiguration.secondaryToggleFilter) return;

        if (enabled) this.addFilter(this.gridConfiguration.secondaryToggleFilter);
        else this.removeFilter(this.gridConfiguration.secondaryToggleFilter);

        this.refresh();
    }

    /**
     * Gets the value of the column.  This is here so that nested portions of objects can be dispayed in the grid
     * by supplying the full path (i.e. data.subitem.name).  If there is no path (no period) in the column name definition
     * the value is loaded normally. If there is a path (period) in the column name definition lodash is used to get the value (for non-collections).
     *
     * If the column is marked as a collection, the values are loaded into a single text block using
     * the delimiter specified on the column definition (or the default, ',' - if none specified).
     *
     * @param row
     * @param gridColumn
     * @returns
     */
    getColumnValue(row: any, gridColumn: SwftGridColumn): any {
        const objectPath: string = gridColumn.dataPath;

        if (!objectPath) {
            return EmptyTableCell;
        }

        if (!gridColumn.collectionDelimiter) {
            // single value
            const value = objectPath.indexOf('.') > -1 ? _.get(row, objectPath) : row[objectPath];
            const hasValue = value !== undefined && value !== null && value !== '';
            return hasValue ? value : EmptyTableCell;
        }

        // otherwise, assume it is a string or number collection
        if (objectPath.indexOf('.') <= -1) {
            const objects: any[] = _.get(row, objectPath);
            return objects.join(gridColumn.collectionDelimiter) || EmptyTableCell;
        }

        // if there are multiple json tree levels, assume the collection is either the last node or the 2nd to
        // last node (i.e. "ResponseValueStrings", "ResponseOption.ResponseValuesStrings" or "ResponseOption.ResponseValues.Name")
        let displayPropertyName = objectPath.substring(objectPath.lastIndexOf('.'));
        const objects: any[] = _.get(row, objectPath.replace(displayPropertyName, ''));
        displayPropertyName = displayPropertyName.substring(1, displayPropertyName.length); // remove the period
        const objectDisplayValues = objects.map(o => o[displayPropertyName]);
        return objectDisplayValues.join(gridColumn.collectionDelimiter) || EmptyTableCell; // uses default ',' if not specified/falsy
    }

    getColumnStyles(width: number | undefined, removeBorder: boolean): string {
        let style = '';
        if (width) style += `min-width: ${width}px; max-width: ${width}px`;
        if (removeBorder) style += '; border: none';
        return style;
    }

    /**
     * Cleans the item description string of characters not valid for the table id field
     */
    cleanStringForTableId(id: string): string {
        return id.replace(/\W+/g, ' ');
    }

    /**
     * Applies a tooltip element to the cell if the text is too long to fit in the cell
     * @param event a mouseover event
     * @returns void
     */
    cellHovered(event: Event): void {
        const element = event.target as HTMLElement;
        const parent = element.parentElement;

        if (element.scrollWidth <= element.clientWidth || !parent) return;

        if (parent.querySelector('span.tooltip')) {
            parent?.removeEventListener('mouseenter', this.cellHovered);
            return;
        }

        const width = Math.min(element.scrollWidth + 5, 280);
        const tooltip = this.renderer.createElement('span');
        const tooltopText = this.renderer.createText(element.innerText);
        this.renderer.addClass(tooltip, 'tooltip');
        this.renderer.setStyle(tooltip, 'width', `${width}px`);
        this.renderer.appendChild(tooltip, tooltopText);
        this.renderer.appendChild(parent, tooltip);
    }

    /**
     * Sets the column list used by the mat table's row render definition from the
     * full column list in the grid configuration.
     */
    private setColumnListFromColumnDefinitions(): void {
        this.gridConfiguration.columnDefinitions.forEach(columnDef => {
            this.columnList.push(columnDef.dataPath);
        });

        if (this.gridConfiguration.rowActions.length > 0) {
            this.columnList.push('actions');
        }

        if (this.gridConfiguration.detailRow) this.columnList = ['expand', ...this.columnList];
    }

    /**
     * Initial data source instantiation
     */
    private initDataSource(): void {
        // Skips setting the datasource if one is already configured, this allows us to set up
        // tables that are NOT tied to an API
        if (this.dataSource) {
            return;
        }

        this.dataSource = new PaginationDataSource<any, SingleSearchTermQuery>(
            request => this.getPage(request),
            this.sort,
            EmptyQuery,
            this.gridConfiguration.pageSize
        );
    }

    /**
     * Loads a page from the data source
     */
    private getPage(request: PageRequest<any>): Observable<Page<any>> {
        if (!this.gridConfiguration.searchEndpoint || !this.gridConfiguration.apiService)
            return new Observable<Page<any>>();

        this.gridConfiguration.apiSearchConfig.pageSize = request.size;
        this.gridConfiguration.apiSearchConfig.pageNumber = request.page;
        this.gridConfiguration.apiSearchConfig.sort = request.sort ?? this.sort;

        return this.gridConfiguration.apiService
            .getObservableEndpoint(this.gridConfiguration.searchEndpoint)(
                this.getPayloadFromApiSearchConfig(this.gridConfiguration.apiSearchConfig)
            )
            .pipe(takeUntil(this.onDestroy$), mapFromSearchResult);
    }

    /**
     * Adds a filter to the query
     *
     * Fires when initially setting up the grid for any 'static' filters
     * defined for the grid query as well as when toggle option filters are applied
     *
     * @param filter The filter to be added
     */
    private addFilter(filter: ApiSearchTerm): void {
        if (this.gridConfiguration.apiSearchConfig?.searchTerms?.value.indexOf(filter) ?? -1 < 0) {
            const currentValue = this.gridConfiguration.apiSearchConfig?.searchTerms?.value;
            const updatedValue = [...currentValue!, filter];
            this.gridConfiguration.apiSearchConfig.searchTerms?.next(updatedValue);
        }
    }

    /**
     * Removed a filter from the query
     *
     * Fires when a toggle is turned off to remove the filter
     *
     * @param filter The filter to remove
     */
    private removeFilter(filter: ApiSearchTerm): void {
        if (this.gridConfiguration.apiSearchConfig?.searchTerms) {
            this.gridConfiguration.apiSearchConfig.searchTerms.next(
                this.gridConfiguration.apiSearchConfig.searchTerms.value.filter(term => term !== filter)
            );
        }
    }

    /**
     * Gets the payload for the api search endpoint call
     * @param searchConfig configuration information for the api search endpoint's payload format
     * @returns the payload for the api search endpoint call
     */
    private getPayloadFromApiSearchConfig(searchConfig: ApiSearch): any {
        const payload: any = {};
        payload[this.gridConfiguration.payloadPageNumberTag] = searchConfig.pageNumber;
        payload[this.gridConfiguration.payloadPageSizeTag] = searchConfig.pageSize;
        payload[this.gridConfiguration.payloadOrderByTag] = searchConfig.sort.property;
        payload[this.gridConfiguration.payloadDateTag] = searchConfig.dateFilter;

        switch (this.gridConfiguration.payloadSortOrderType) {
            case PayloadSortOrderType.AscDscText:
                payload[this.gridConfiguration.payloadSortOrderTag] = searchConfig.sort.order;
                break;
            case PayloadSortOrderType.AscendingBoolean:
                payload[this.gridConfiguration.payloadSortOrderTag] = searchConfig.sort.order === 'asc';
                break;
        }

        searchConfig.searchTerms?.value.forEach(searchTerm => {
            if (!payload[searchTerm.searchField]) payload[searchTerm.searchField] = searchTerm.value;
            else payload[searchTerm.searchField] += '|' + searchTerm.value;
        });

        return payload;
    }
}
