import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { RefreshTokenComponent } from '../components/swft/refresh-token/refresh-token.component';
import { TokenTimerMessage, TokenTimerMessageTypes } from '../enums/auth-token-timer.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthTokenRefreshService implements OnDestroy {
    logout: EventEmitter<void> = new EventEmitter();
    tokenRefreshed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private authTokenRefreshWorker: Worker;
    private onDestroy: Subject<void> = new Subject();

    constructor(private dialog: MatDialog) {
        this.authTokenRefreshWorker = new Worker(new URL('./auth-token-refresh.worker', import.meta.url));
        this.authTokenRefreshWorker.onmessage = ({ data }) => {
            if (data === TokenTimerMessageTypes.END) {
                this.openRefreshTokenDialog();
            }
        };
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    /**
     * Initialize the auth token refresh web worker
     * @param expirationClaim Expiration Claim that indicates when a user should log out
     * @returns
     */
    initTokenTimer(expiresUTCSeconds: number) {
        const buffer = 5;
        const nowUTCSeconds = Math.round(new Date().getTime() / 1000);
        const secondsUntilExpiration = expiresUTCSeconds - nowUTCSeconds - buffer;

        if (secondsUntilExpiration <= 0) {
            this.logout.emit();
            return;
        }

        this.authTokenRefreshWorker.postMessage(
            new TokenTimerMessage(TokenTimerMessageTypes.INIT, secondsUntilExpiration)
        );
    }

    clearTimer() {
        if (!this.authTokenRefreshWorker) return;

        this.authTokenRefreshWorker.postMessage(new TokenTimerMessage(TokenTimerMessageTypes.CLEAR));
        this.authTokenRefreshWorker.terminate();
    }

    /**
     * Wrapped in a try catch since the dialog may be fired after the test Injector has been discarded.
     * This is a scenario that only happens in our tests.
     */
    openRefreshTokenDialog() {
        const refreshTokenDialogId = 'RefreshTokenDialog';
        try {
            if (!this.dialog.getDialogById(refreshTokenDialogId)) {
                this.dialog
                    .open(RefreshTokenComponent, {
                        id: refreshTokenDialogId,
                        panelClass: 'small-auto',
                        disableClose: true,
                    })
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe((success: boolean) => {
                        this.tokenRefreshed.next(success);
                    });
            }
        } catch (ex) {
            console.error('Unable to open dialog! Injector may have been discarded.');
        }
    }
}
