import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty, isEqual } from 'lodash-es';
import { Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { LeftNavList, LeftNavType } from 'src/app/models/left-nav.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LeftNavService } from 'src/app/services/left-nav/left-nav.service';
import { NavState } from './../../../state/nav/nav.reducer';

@Component({
    selector: 'swft-left-nav',
    templateUrl: './swft-left-nav.component.html',
    styleUrls: ['./swft-left-nav.component.scss'],
})
export class SwftLeftNavComponent implements OnInit, OnDestroy {
    navTypes = LeftNavType;
    navState$: Observable<NavState>;
    navIsEmpty$: Observable<boolean>;
    links?: LeftNavList;
    expanded = false;
    locked = false;
    isLoggedIn: Observable<boolean> = of(false);
    flash = false;

    // Defines the individual sections of links
    sections = this.service.sections;

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(public service: LeftNavService, private router: Router, private authService: AuthService) {
        this.navState$ = this.service.navState$.pipe(
            tap(navState => {
                this.links = navState.nav;
            })
        );

        this.navIsEmpty$ = this.service.isNavEmpty$.pipe(
            tap(isEmpty => {
                if (isEmpty) {
                    this.locked = false;
                    this.toggleExpandNav(false);
                }
            }),
            takeUntil(this.onDestroy$)
        );

        this.isLoggedIn = this.authService.isLoggedIn;
    }

    ngOnInit() {
        // collapse the left nav when the user navigates between pages.
        // 'this.expanded' is always 'true' if the nav is in a 'locked' state
        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(e => {
            this.expanded = this.locked;
            this.toggleExpandNav(this.expanded);
        });

        this.service.isNavExpanded$
            .pipe(
                tap(isExpanded => {
                    this.expanded = isExpanded;
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe();

        // get the links from the service, if there are no links reset the left nav to default and hide it.
        // if the links change, apply the 'flash' css class to the tab for 1 second and update the links.
        this.service.links$.pipe(takeUntil(this.onDestroy$)).subscribe(links => {
            if (isEmpty(links)) {
                this.links = undefined;
                this.expanded = false;
                this.locked = false;
                return;
            }

            if (this.navListsAreDifferent(this.links, links)) {
                this.flash = true;
                setTimeout(() => {
                    this.flash = false;
                }, 1500);
            }
            this.links = links;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    toggleExpandNav(state: boolean) {
        this.service.toggleNavbar(state);
    }

    removeLinkByIndex(linkType: LeftNavType, index: number) {
        this.service.removeLinkByIndex(linkType, index);
    }

    /**
     * Maps the links to their IDs and types and then does an object equality comparison
     * @param previous previous LeftNavList
     * @param current current LeftNavList
     * @returns boolean
     */
    private navListsAreDifferent(previous: LeftNavList | undefined, current: LeftNavList): boolean {
        const previousMappedLinks = this.mapLinksToIdAndType(previous);
        const currentMappedLinks = this.mapLinksToIdAndType(current);

        return !isEqual(previousMappedLinks, currentMappedLinks);
    }

    /**
     * Uses the object keys to map the links to their IDs and types and returns an array
     * @param links A mapped version of the links that only contains the ID and the type
     * @returns
     */
    private mapLinksToIdAndType(links: LeftNavList | undefined): {
        id: any;
        type: LeftNavType | keyof LeftNavList | undefined;
    }[] {
        if (!links) {
            return [];
        }
        const keys = Object.keys(links);

        // Map the links to their ID and type and sort them by ID
        const mappedLinks = keys
            .flatMap(key => links[key as keyof LeftNavList].map(link => ({ id: link.data.id, type: link.type })))
            .sort((a, b) => a.id - b.id);

        return mappedLinks;
    }
}
