import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { BehaviorSubject, of, Subject, takeUntil } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { ValidationObs } from 'src/app/validators/unique.validator';

interface HipaaVerificationForm {
    dob: FormControl<string | null>;
    ssn: FormControl<string | null>;
    zipcode: FormControl<string | null>;
}

@Component({
    selector: 'swft-hipaa-verification',
    templateUrl: './hipaa-verification.component.html',
    styleUrls: ['./hipaa-verification.component.scss'],
})
export class HipaaVerificationComponent implements OnInit, OnDestroy, OnChanges {
    contactDisplayValue = '';
    formFieldsRequiredCount = 2;
    formGroup = new FormGroup<HipaaVerificationForm>(
        {
            dob: new FormControl(null, [], this.validateDob()),
            ssn: new FormControl(null, [], this.validateSsn()),
            zipcode: new FormControl(null, [], this.validateZip()),
        },
        this.validateForm()
    );
    hideZipValue = true;
    patient?: PatientDemographic;
    patientDisplayValue = '';
    validForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    verificationText = '';
    verificationType?: HipaaVerificationType;

    SWFTHipaaVerificationType = HipaaVerificationType;

    private onDestroy: Subject<void> = new Subject();

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges?.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            if (data.patient?.id === 0) {
                this.ngOnDestroy();
                this.hipaaService.verificationType = HipaaVerificationType.Patient;
                this.hipaaService.navigate(HipaaRoutes.SelectContact, true);
                return;
            }
            if (!data.contact) {
                this.hipaaService.navigate(HipaaRoutes.SelectContactType, true);
                return;
            }
            this.patient = data.patient;
            this.patientDisplayValue = this.hipaaService.patientDisplayName;
            this.updateFormByVerificationType(data.verificationType);
            this.contactDisplayValue = `${data.contact.firstName} ${data.contact.lastName}`;
            this.verificationType = data.verificationType;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    ngOnChanges() {
        this.formGroup.updateValueAndValidity();
    }

    nextStep() {
        alert('Feature not yet implemented.');
    }

    /**
     * Checks the value and validity of a form control
     * @param controlName the name of the form control
     * @returns a boolean indicating if the form control is valid where true if valid.
     */
    isValid(controlName: string): boolean {
        const control = this.formGroup.get(controlName);
        if (!control) return false;
        return control.value && control.valid;
    }

    get controlNames(): string[] {
        return Object.keys(this.formGroup.controls);
    }

    get patientLastFourSsn(): string {
        if (!this.patient || !this.patient.ssn) return '';
        return this.patient.ssn.slice(this.patient.ssn.length - 4, this.patient.ssn.length);
    }

    /**
     * Confirms that the form has the required number of valid fields
     * @returns ValidatorFn | null
     */
    private validateForm(): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!this.formGroup) return null;
            let validControlCount = 0;
            this.controlNames.forEach(controlName => {
                const control = this.formGroup.get(controlName);
                if (!control) return;
                if (control.valid && control.value) validControlCount++;
            });
            if (validControlCount === 0) return null;
            const isValid = validControlCount >= this.formFieldsRequiredCount;
            this.validForm.next(isValid);
            this.formGroup.markAllAsTouched();
            return isValid ? null : { verifyMoreFields: true };
        };
    }

    /**
     * Validate the date of birth
     * @returns An async ValidatorFn that checks the user's input date of birth against the patient's actual date of birth
     */
    private validateDob(): AsyncValidatorFn {
        return (control: AbstractControl): ValidationObs => {
            if (!control.value) return of(null);
            if (!this.patient?.dob) return of({ cannotVerify: true });
            const dob = new Date(control.value);
            const input = new Date(this.patient.dob);
            return dob.toDateString() !== input.toDateString() ? of({ doesNotMatch: true }) : of(null);
        };
    }

    /**
     * Validate the social security number
     * @returns An async ValidatorFn that checks the user's input ssn against the patient's actual ssn
     */
    private validateSsn(): AsyncValidatorFn {
        return (control: AbstractControl): ValidationObs => {
            if (!control.value) return of(null);
            if (!this.patient?.ssn) return of({ cannotVerify: true });
            return control.value !== this.patientLastFourSsn ? of({ doesNotMatch: true }) : of(null);
        };
    }

    /**
     * Validate the zip code
     * @returns An async ValidatorFn that checks the user's input zip code against the patient's actual zip code
     */
    private validateZip(): AsyncValidatorFn {
        return (control: AbstractControl): ValidationObs => {
            if (!control.value) return of(null);
            if (!this.patient?.zip) return of({ cannotVerifyZip: true });
            return control.value !== this.patient.zip ? of({ doesNotMatch: true }) : of(null);
        };
    }

    /**
     * Update the form's requirements based on the verification type
     * @param verificationType The VerificationType in which to base the form's requirements on
     */
    private updateFormByVerificationType(verificationType: HipaaVerificationType): void {
        const oneFieldRequiredTypes: HipaaVerificationType[] = [HipaaVerificationType.Payor, HipaaVerificationType.HCP];
        if (oneFieldRequiredTypes.includes(verificationType)) {
            this.formFieldsRequiredCount = 1;
            this.verificationText = 'Verify at least one field';
            return;
        }
        this.formFieldsRequiredCount = 2;
        this.verificationText = 'Verify at least two fields';
    }
}
