import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { FormError, FormErrorMessage, FormErrorMessages } from './swft-form-errors.models';

@Component({
    selector: 'swft-form-errors',
    templateUrl: './swft-form-errors.component.html',
    styleUrls: ['./swft-form-errors.component.scss'],
})
export class SwftFormErrorsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('errorContainer') errorContainer!: ElementRef;
    @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
    @Input() floatable: boolean = true;
    formSub: Subscription = new Subscription();
    formGroupErrors: FormError[] = [];
    formErrors: FormError[] = [];
    messages: FormErrorMessage[] = new FormErrorMessages().messages;
    show = false;
    float = false;
    containerObserver: IntersectionObserver = new IntersectionObserver(
        e => {
            this.float = !e[0].isIntersecting;
        },
        {
            root: document.querySelector('body'),
        }
    );

    constructor() {}

    ngOnInit(): void {
        this.formSub = combineLatest([this.formGroup.valueChanges, this.formGroup.statusChanges])
            .pipe()
            .subscribe(_ => {
                this.markInvalidControlsAsTouched();
                this.updateFormErrors();
            });
    }

    ngAfterViewInit(): void {
        this.containerObserver.observe(this.errorContainer.nativeElement);
        this.addFormEventListeners();
    }

    markInvalidControlsAsTouched() {
        if (this.formGroup && this.formGroup.invalid) {
            for (let key in this.formGroup.controls) {
                let ctrl = this.formGroup.controls[key];
                if (ctrl.invalid && ctrl.value && ctrl.value.length !== 0) {
                    ctrl.markAsTouched();
                }
            }
            return;
        }
    }

    ngOnDestroy(): void {
        this.formSub.unsubscribe();
        this.containerObserver.disconnect();
    }

    goToControl(target: EventTarget | null, controlName: string): void {
        if (!target) return;

        const ngContentAttribute: string = this.getNgContentAttribute(target);
        const controlSelector: string = `[${ngContentAttribute}][formcontrolname="${controlName.replace(' ', '')}"]`;
        const controlElement: HTMLElement | null | undefined = document
            .querySelector(controlSelector)
            ?.closest('mat-form-field');

        if (controlElement) {
            const scrollParent: HTMLElement | null = this.getScrollParent(controlElement);
            if (scrollParent) {
                const y = controlElement.getBoundingClientRect().top + scrollParent.offsetTop + 20;
                scrollParent.scrollTo({ top: y, behavior: 'smooth' });
            }
            controlElement.classList.remove('bounce');
            setTimeout(() => {
                controlElement.classList.add('bounce');
            }, 100);
        }
    }

    addErrorMessages(messages: FormErrorMessage[]): void {
        this.messages.push(...messages);
    }

    get hasErrors(): boolean {
        return this.formGroupErrors.length > 0 || this.formErrors.length > 0;
    }

    private getNgContentAttribute(target: EventTarget): string {
        const attributes: NamedNodeMap | undefined = (target as HTMLTextAreaElement).closest(
            'swft-form-errors'
        )?.attributes;

        if (!attributes) return '';

        for (let i = 0; i < attributes.length; i++) {
            const attributeName = attributes[i].name;
            if (attributeName.includes('_ngcontent')) {
                return attributeName;
            }
        }

        return '';
    }

    private addFormEventListeners(): void {
        const formElement: HTMLFormElement | null = document.querySelector('form');
        if (formElement) {
            formElement.addEventListener('click', () => {
                this.updateFormErrors();
            });
            formElement.addEventListener('mouseenter', () => {
                this.updateFormErrors();
            });
        }
    }

    private updateFormErrors(): void {
        if (!this.formGroup) return;

        this.formGroupErrors = [];
        if (this.formGroup.errors) {
            Object.keys(this.formGroup.errors).forEach(err => {
                const control: AbstractControl = this.formGroup;

                if (!control.errors || control.untouched) return;

                const cleanedErrors: ValidationErrors = this.clearIgnoredErrors(control.errors);

                Object.keys(cleanedErrors).forEach((errorId: string) => {
                    const message: string | undefined = this.messages.find(msg => msg.id == errorId)?.message;

                    this.formGroupErrors.push({
                        controlName: '',
                        message: message || errorId,
                    });
                });
            });
        } else {
            this.updateFormControlErrors();
        }
    }

    updateFormControlErrors(): void {
        if (!this.formGroup) return;

        this.formErrors = [];

        Object.keys(this.formGroup.controls).forEach((controlName: string) => {
            const control: AbstractControl = this.formGroup.controls[controlName];

            if (!control.errors || control.untouched) return;

            const cleanedErrors: ValidationErrors = this.clearIgnoredErrors(control.errors);
            Object.keys(cleanedErrors).forEach((errorId: string) => {
                const message: string | undefined = this.messages.find(msg => msg.id == errorId)?.message;
                //will add space to formControls name like 'formBasis' or 'formType'
                if (!controlName.includes(' ')) controlName = controlName.replace(/([A-Z])/g, ' $1'); // add spaces to camelCase
                this.formErrors.push({
                    controlName: controlName,
                    message: message || errorId,
                });
            });
        });
    }

    private clearIgnoredErrors(errors: ValidationErrors): ValidationErrors {
        const ignoredErrorIds: string[] = ['closed', 'observers', 'isStopped', 'hasError', 'thrownError', '_value'];
        const cleanedErrors: ValidationErrors = {};

        Object.keys(errors).forEach((errorId: string) => {
            if (!ignoredErrorIds.includes(errorId)) {
                cleanedErrors[errorId] = errors[errorId];
            }
        });

        return cleanedErrors;
    }

    getScrollParent(element: HTMLElement): HTMLElement | null {
        if (element == null) return null;

        if (element.scrollHeight > element.clientHeight) {
            return element;
        }

        if (element.parentElement) {
            return this.getScrollParent(element.parentElement);
        }

        return null;
    }
}
