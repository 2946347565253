import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { CallbackComponent } from './components/login/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { HcpNarrativesComponent } from './components/narratives/narratives-hcp/narratives-hcp.component';
import { OrderNarrativesComponent } from './components/narratives/narratives-order/narratives-order.component';
import { PatientNarrativesComponent } from './components/narratives/narratives-patient/narratives-patient.component';
import { ProfileGridComponent } from './components/profile-grid/profile-grid.component';
import { HcpProfileComponent } from './components/profile/hcp-profile/hcp-profile.component';
import { PatientProfileComponent } from './components/profile/patient-profile/patient-profile.component';
import { IsLoggedInGuard } from './guards/is-logged-in/is-logged-in.guard';
import { ProfileMode } from './models/profile/profile-mode.model';
import { AppRouterParams } from './models/router-params.models';
import { LeftNavResolver } from './resolvers/left-nav.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [IsLoggedInGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./components/admin/admin.module').then(mod => {
                        return mod.AdminModule;
                    }),
            },
            {
                path: 'patients',
                data: {
                    profileMode: ProfileMode.Patient,
                },
                children: [
                    { path: '', component: ProfileGridComponent },
                    {
                        path: `:${AppRouterParams.PatientId}`,
                        component: PatientProfileComponent,
                        resolve: {
                            leftNavLink: LeftNavResolver,
                        },
                        loadChildren: () =>
                            import('./components/profile/patient-profile/patient-profile.module').then(mod => {
                                return mod.PatientProfileModule;
                            }),
                    },
                    {
                        path: `:${AppRouterParams.PatientId}`,
                        children: [
                            {
                                path: 'conversations',
                                loadChildren: () =>
                                    import('./components/narratives/conversations/patient-conversation.module').then(
                                        mod => {
                                            return mod.PatientConversationModule;
                                        }
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'hcp',
                data: {
                    profileMode: ProfileMode.Hcp,
                },
                children: [
                    { path: '', component: ProfileGridComponent },
                    {
                        path: `:${AppRouterParams.HcpId}`,
                        component: HcpProfileComponent,
                        resolve: {
                            leftNavLink: LeftNavResolver,
                        },
                        loadChildren: () =>
                            import('./components/profile/hcp-profile/hcp-profile.module').then(mod => {
                                return mod.HcpProfileModule;
                            }),
                    },
                    {
                        path: `:${AppRouterParams.HcpId}`,
                        children: [
                            {
                                path: 'conversations',

                                loadChildren: () =>
                                    import('./components/narratives/conversations/hcp-conversation.module').then(
                                        mod => {
                                            return mod.HcpConversationModule;
                                        }
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'narratives',
                children: [
                    { path: 'order', component: OrderNarrativesComponent },
                    { path: 'patient', component: PatientNarrativesComponent },
                    { path: 'hcp', component: HcpNarrativesComponent },
                    {
                        path: `:${AppRouterParams.NarrativeId}`,
                        children: [
                            {
                                path: 'conversation',
                                loadChildren: () =>
                                    import('./components/narratives/conversations/conversation.module').then(mod => {
                                        return mod.ConversationModule;
                                    }),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'login',
        canActivate: [IsLoggedInGuard],
        component: LoginComponent,
    },
    {
        path: 'login/callback',
        component: CallbackComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
