<ng-container [class.show]="showActions">
    <span
        class="select-column"
        [class.select-column-only]="onlyActionIsSelection()"
        *ngIf="showButton(selection, permissionType.Read, GridAction.Select)">
        <mat-checkbox
            [(ngModel)]="checked"
            (change)="selection.next(null); selectionChanged($event.checked)"
            aria-label="Select"
            matTooltip="Select"
            class="checkbox"></mat-checkbox>
    </span>

    <!-- View -->
    <mat-icon
        *ngIf="showButton(view, permissionType.Read, GridAction.View)"
        (click)="view.next(null); gridAction.next(GridAction.View)"
        [attr.aria-label]="viewText"
        matTooltipPosition="below"
        [matTooltip]="viewText">
        visibility
    </mat-icon>

    <!-- Edit -->
    <mat-icon
        *ngIf="showButton(edit, permissionType.Update, GridAction.Edit)"
        (click)="edit.next(null); gridAction.next(GridAction.Edit)"
        [attr.aria-label]="editText"
        [matTooltip]="editText">
        edit
    </mat-icon>

    <!-- Copy (Duplicate) -->
    <mat-icon
        *ngIf="showButton(duplicate, permissionType.Create, GridAction.Copy)"
        (click)="duplicate.next(null); gridAction.next(GridAction.Copy)"
        [attr.aria-label]="duplicateText"
        [matTooltip]="duplicateText">
        file_copy
    </mat-icon>

    <!-- Open in Conversation -->
    <mat-icon
        *ngIf="showButton(conversation, null, GridAction.Conversation)"
        (click)="conversation.next(null); gridAction.next(GridAction.Conversation)"
        [attr.aria-label]="conversationText"
        [matTooltip]="conversationText">
        forum
    </mat-icon>

    <!-- Add -->
    <mat-icon
        *ngIf="showButton(add, null, GridAction.Create)"
        (click)="add.next(null); gridAction.next(GridAction.Create)"
        [attr.aria-label]="addText"
        [matTooltip]="addText">
        add_circle
    </mat-icon>

    <!-- Remove -->
    <mat-icon
        *ngIf="showButton(remove, permissionType.Delete, GridAction.Remove)"
        (click)="remove.next(null); gridAction.next(GridAction.Remove)"
        class="warn"
        [attr.aria-label]="removeText"
        [matTooltip]="removeText">
        cancel
    </mat-icon>

    <!-- Discontinue -->
    <mat-icon
        *ngIf="showButton(discontinue, permissionType.Update, GridAction.Discontinue)"
        (click)="discontinue.next(null); gridAction.next(GridAction.Discontinue)"
        class="warn"
        [attr.aria-label]="discontinueText"
        [matTooltip]="discontinueText">
        do_not_disturb_on
    </mat-icon>

    <!-- Reorder, This should always be the last icon -->
    <mat-icon
        *ngIf="reorderable"
        class="reorder"
        [attr.aria-label]="reorderText"
        [matTooltip]="reorderText">
        drag_indicator
    </mat-icon>
</ng-container>
