import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

export enum AppInsightsSeverityLevel {
    Critical = 4,
    Error = 3,
    Warning = 2,
    Information = 1,
    Verbose = 0,
    None = -1,
}

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights | undefined;
    private key = environment.instrumentationKey;
    private invalidKey: boolean = this.key.replaceAll('0', '').length <= 4; // Check if GUID is blank

    constructor() {
        if (this.invalidKey) return;
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.key,
                enableAutoRouteTracking: true, // option to log all route changes
            },
        });
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string) {
        if (!this.appInsights) return;
        // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (!this.appInsights) return;
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (!this.appInsights) return;
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (!this.appInsights) return;
        this.appInsights.trackTrace({ message: message }, properties);
    }

    logCritical(exception: Error) {
        this.logException(exception, AppInsightsSeverityLevel.Critical);
    }

    logError(ComponentOrServiceName: string, FunctionOrMethodName: string, exception: Error) {
        const error = new Error(
            `${ComponentOrServiceName}::${FunctionOrMethodName} - ${exception.message || JSON.stringify(exception)}`
        );
        this.logException(error, AppInsightsSeverityLevel.Error);
    }

    logInformation(exception: Error) {
        this.logException(exception, AppInsightsSeverityLevel.Information);
    }

    logVerbose(exception: Error) {
        this.logException(exception, AppInsightsSeverityLevel.Verbose);
    }

    logWarning(exception: Error) {
        this.logException(exception, AppInsightsSeverityLevel.Warning);
    }

    private logException(exception: Error, severityLevel?: number) {
        if (!this.appInsights) return;
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
}
