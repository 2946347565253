<!-- Search bar component -->
<swft-searchbar
    *ngIf="dataSource && gridConfiguration.searchEnabled"
    [dateSearch]="gridConfiguration.dateSearchEnabled"
    [showPrimaryToggle]="!!gridConfiguration.primaryToggleFilter"
    [primaryToggleText]="gridConfiguration.primaryToggleText"
    [showSecondaryToggle]="!!gridConfiguration.secondaryToggleFilter"
    [secondaryToggleText]="gridConfiguration.secondaryToggleText"
    [placeholder]="gridConfiguration.searchTextPlaceholder"
    (dateChanged)="searchDateChanged($event)"
    (primaryToggle)="primaryToggleChanged($event)"
    (secondaryToggle)="secondaryToggleChanged($event)"
    (search)="search($event)"></swft-searchbar>

<!-- Table definition -->
<div class="table-container">
    <table
        [id]="gridConfiguration.tableId"
        #table
        class="data-grid"
        *ngIf="dataSource"
        mat-table
        matSort
        multiTemplateDataRows
        [class.no-data]="pageRows.length === 0 && (dataSource.loading$ | async) === false"
        [dataSource]="dataSource"
        [matSortActive]="sort.property.toString()"
        [matSortDirection]="sort.order"
        (matSortChange)="applySort($event)"
        matSortDisableClear>
        <!-- Expand Column -->
        <ng-container
            matColumnDef="expand"
            *ngIf="gridConfiguration.detailRow">
            <th
                mat-header-cell
                *matHeaderCellDef
                [style]="getColumnStyles(14, false)"
                aria-label="row actions"></th>
            <td
                mat-cell
                [style]="getColumnStyles(14, true)"
                class="expand-icon"
                *matCellDef="let row">
                <mat-icon (click)="expandRow($event, row)">
                    {{ expandedRow === row ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </td>
        </ng-container>

        <!-- Header / cell definition for table columns -->
        <ng-container *ngFor="let column of gridConfiguration.columnDefinitions">
            <ng-container [matColumnDef]="column.dataPath">
                <!-- Table header definition. Sort header is applied by default and only is disabled if column.sortable is false -->
                <th
                    mat-header-cell
                    mat-sort-header
                    [disabled]="column.sortable === false || pageRows.length === 0"
                    *matHeaderCellDef
                    [style]="getColumnStyles(column.pixelWidth, false)">
                    {{ column.name }}
                </th>
                <ng-container
                    mat-cell
                    *matCellDef="let row">
                    <!-- If this function is provided, display this column as an icon -->
                    <ng-container *ngIf="column.displayAsIcon">
                        <ng-container *ngIf="column.displayAsIcon(row); let icon">
                            <td>
                                <mat-icon [style.color]="icon.color">{{ icon.icon }}</mat-icon>
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!column.displayAsIcon">
                        <td [style]="getColumnStyles(column.pixelWidth, false)">
                            <div
                                (mouseenter)="cellHovered($event)"
                                [innerHtml]="getColumnValue(row, column) | dynamicPipe: column.formatter | html"></div>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th
                mat-header-cell
                *matHeaderCellDef="let row">
                <swft-actions-header
                    [permissionName]="gridConfiguration.permissionName"
                    [displayedActions]="gridConfiguration.bulkActions"
                    [disabled]="pageRows.length < 2"
                    [checked]="selectedRows.length > 0 && selectedRows.length === pageRows.length"
                    (gridAction)="emitBulkAction($event)"></swft-actions-header>
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <swft-actions-column
                    [permissionName]="gridConfiguration.permissionName"
                    [displayedActions]="gridConfiguration.rowActions"
                    [checked]="selectedRows.includes(row)"
                    (gridAction)="emitRowAction({ action: $event, row: row })"></swft-actions-column>
            </td>
        </ng-container>

        <!-- Column definition for the expanded row -->
        <ng-container matColumnDef="expandedRow">
            <td
                mat-cell
                *matCellDef="let row"
                [attr.colspan]="columnList.length + 1">
                <div
                    class="detail-row"
                    [style.width]="detailRowWidth + 'px'"
                    [@detailExpand]="row === expandedRow ? 'expanded' : 'collapsed'">
                    <div
                        class="detail-row-container"
                        [innerHTML]="
                            (gridConfiguration.detailRow ? row[gridConfiguration.detailRow] : '---') | html
                        "></div>
                </div>
            </td>
        </ng-container>

        <!-- Header row definition -->
        <tr
            mat-header-row
            *matHeaderRowDef="columnList"></tr>

        <!-- Row definition -->
        <tr
            mat-row
            *matRowDef="let row; columns: columnList"
            [class.no-detail-row]="!gridConfiguration.detailRow"
            [class.expanded-row]="expandedRow === row"
            [class.selected-row]="selectedRows.includes(row)"></tr>

        <!-- Detail row defintion -->
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedRow']"
            class="detail-row"></tr>

        <!-- Footer row defintion -->
        <tr
            *matNoDataRow
            class="no-data">
            <td [attr.colspan]="columnList.length">
                <span>
                    {{
                        searchText === ''
                            ? gridConfiguration.emptySearchGridText
                            : 'No results found for "' + searchText + '"'
                    }}
                </span>
            </td>
        </tr>
    </table>
</div>

<!-- Pagination component -->
<mat-paginator
    class="data-grid"
    *ngIf="pageRows.length > 0 && gridConfiguration.pagingEnabled && dataSource && (dataSource.page$ | async) as page"
    [length]="page.totalElements"
    [pageSize]="page.size"
    [pageIndex]="page.number"
    [hidePageSize]="page.totalElements === 0"
    (page)="dataSource.fetch($event.pageIndex)"></mat-paginator>

<!-- Loading animation component -->
<swft-spinner
    [coverId]="gridConfiguration.tableId"
    [show]="!dataSource || (dataSource.loading$ | async)"></swft-spinner>
