import { Injector, Pipe, PipeTransform } from '@angular/core';
import { ResponseValue } from 'src/app/models/response-options.models';

@Pipe({
    name: 'searchResponseValues',
})
export class SearchResponseValuesPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(items: ResponseValue[] | null = null, searchTxt: string) {
        if (!items || !items.length) return items;
        if (!searchTxt || !searchTxt.length) return items;
        return items.filter(item => {
            return item.name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1;
        });
    }
}
