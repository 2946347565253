import { Component } from '@angular/core';
import { HipaaRoutes } from 'src/app/enums/hipaa.enum';
import { CallType } from 'src/app/models/call.models';
import { HipaaService } from '../../../services/hipaa/hipaa.service';

@Component({
    selector: 'swft-hipaa-intro',
    templateUrl: './hipaa-intro.component.html',
    styleUrls: ['./hipaa-intro.component.scss'],
})
export class HipaaIntroComponent {
    callType = CallType;

    constructor(private hipaaService: HipaaService) {}

    /**
     * Update the call type and navigate to the next page.
     * @param callType The call type selected by the user. Typically either 'inbound', 'outbound' or 'documentation-only'.
     */
    navigate(callType: CallType | null) {
        const route: HipaaRoutes = callType ? HipaaRoutes.SelectContactType : HipaaRoutes.Attestation;
        this.hipaaService.callType = callType ? callType : CallType.DocumentOnly;
        this.hipaaService.navigate(route);
    }
}
