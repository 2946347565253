import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuestionLogicComponent } from 'src/app/components/admin/builders/question/editor/logic/logic.component';
import { LogicModule } from 'src/app/components/logic/logic.module';
import { CreateNarrativeComponent } from 'src/app/components/narratives/create/create-narrative.component';
import { HcpNarrativesComponent } from 'src/app/components/narratives/narratives-hcp/narratives-hcp.component';
import { OrderNarrativesComponent } from 'src/app/components/narratives/narratives-order/narratives-order.component';
import { PatientNarrativesComponent } from 'src/app/components/narratives/narratives-patient/narratives-patient.component';
import { NarrativesComponent } from 'src/app/components/narratives/narratives.component';
import { SwftModule } from 'src/app/components/swft/swft.module';
import { QuillFormFieldDirective } from 'src/app/directives/rich-text-editor.directive';
import { MaterialModule } from './material.module';

@NgModule({
    declarations: [
        QuillFormFieldDirective,
        NarrativesComponent,
        QuestionLogicComponent,
        PatientNarrativesComponent,
        HcpNarrativesComponent,
        OrderNarrativesComponent,
        CreateNarrativeComponent,
    ],
    exports: [NarrativesComponent, QuillFormFieldDirective, SwftModule, QuestionLogicComponent],
    imports: [CommonModule, MaterialModule, SwftModule, LogicModule],
})
export class SharedModule {}
