<ng-container>
    <label>Who are you speaking with?</label>
    <div class="button-container">
        <button
            *ngIf="(hidePatient$ | async) === false"
            color="primary"
            mat-raised-button
            (click)="setType(SWFTHipaaVerificationType.Patient)">
            <mat-icon>personal_injury</mat-icon>
            Patient
        </button>
        <button
            color="primary"
            mat-raised-button
            (click)="setType(SWFTHipaaVerificationType.AuthRep)">
            <mat-icon>verified_user</mat-icon>
            Authorized Representitive
        </button>
        <button
            color="primary"
            mat-raised-button
            (click)="setType(SWFTHipaaVerificationType.HCP)">
            <mat-icon>local_hospital</mat-icon>
            Health Care Provider
        </button>
        <button
            color="primary"
            mat-raised-button
            (click)="setType(SWFTHipaaVerificationType.Payor)">
            <mat-icon>paid</mat-icon>
            Payor
        </button>
        <button
            color="primary"
            mat-raised-button
            (click)="setType(SWFTHipaaVerificationType.CoveredEntity)">
            <mat-icon>domain_add</mat-icon>
            Covered Entity
        </button>
    </div>
</ng-container>
