import { CdkDrag, CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SwftNote } from 'src/app/models/patient.model';
import { DateTimeFormat } from 'src/app/utils/constants';
import { SWFTPopUpNoteService } from './swft-popup-note.service';

@Component({
    selector: 'swft-popup-note',
    templateUrl: './swft-popup-note.component.html',
    styleUrls: ['./swft-popup-note.component.scss'],
})
export class SwftPopUpNoteComponent {
    @Input() note!: SwftNote;
    @Output() dateChanged = new EventEmitter<Date>();
    maximized: boolean = this.service.maximized;
    dragSource: CdkDrag<any> | null = null;
    dateTimeFormat: string = DateTimeFormat;

    constructor(private service: SWFTPopUpNoteService, private dialogRef: MatDialogRef<SwftPopUpNoteComponent>) {}

    close() {
        this.dialogRef.close();
    }

    maximize(maximize: boolean) {
        this.maximized = maximize;
        this.service.maximized = maximize;
        if (maximize) this.checkPosition();
    }

    // When the user drags the dialog, keep a record of the event source
    // so that we can reset the dialog when checking its position.
    dragEnded(event: CdkDragEnd): void {
        this.dragSource = event.source;
    }

    // If the user drags the dialog to the top of the screen, then expands it, there is a chance that the dialog controls will be
    // off the screen. This method will reset the dialog to its original position if the top of the dialog is off the screen.
    private checkPosition() {
        setTimeout(() => {
            const ele = document.querySelector('.cdk-overlay-pane.swft-popup-note') as HTMLElement;
            const rect = ele?.getBoundingClientRect();
            if (!rect || !ele) return;
            if (rect.y < 0) {
                this.dragSource?.reset();
            }
        }, 500);
    }
}
