import { AfterContentChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { distinct, filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import 'src/app/swft/prototypes/date-prototypes';
import 'src/app/swft/prototypes/number-prototypes';
import 'src/app/swft/prototypes/string-prototypes';
import { IdleDuration, TimeoutDuration } from 'src/environments/environment';
import { IdleComponent } from './components/swft/idle/idle.component';
import { PermissionName, PermissionType } from './models/auth/authorization.models';
import { AppService } from './services/app/app.service';
import { AuthService } from './services/auth/auth.service';
import { HipaaService } from './services/hipaa/hipaa.service';
import { selectIsNavExpanded } from './state/nav/nav.selector';

export interface Builder {
    name: string;
    component: any;
}

@Component({
    selector: 'swft-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
    @ViewChild('RouterOutlet', { read: ElementRef }) routerOutlet!: ElementRef<HTMLDivElement>;
    hasBuilderNav$ = this.service._hasBuilderNav.asObservable();
    leftNavIsExpanded$: Observable<boolean>;

    title = 'SWFT-UI';
    isLoggedIn: Observable<boolean>;
    isAdmin = false;
    isBottom = false;
    isScrollable = false;
    hasVisibleBuilderLinks = false;
    isSuperAdmin = false;
    canSearchUsers = false;

    private onDestroy = new Subject<void>();
    private appObserver: MutationObserver | undefined;

    constructor(
        private authService: AuthService,
        public dialog: MatDialog,
        private store: Store,
        public service: AppService,
        private idle: Idle,
        private hipaaService: HipaaService,
        private router: Router
    ) {
        this.leftNavIsExpanded$ = this.store.select(selectIsNavExpanded);
        this.isLoggedIn = this.authService.isLoggedIn.pipe(distinct());

        this.router.events
            .pipe(
                takeUntil(this.onDestroy),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: any) => {
                this.service.previousUrl = this.service.currentUrl;
                this.service.currentUrl = event.url;
            });

        this.service.isScrollable.pipe(takeUntil(this.onDestroy)).subscribe(isScrollable => {
            this.isScrollable = isScrollable;
        });

        this.service.isBottom.pipe(takeUntil(this.onDestroy)).subscribe(isBottom => {
            this.isBottom = isBottom;
        });
    }

    ngOnInit(): void {
        this.isAdmin = this.authService.isAdmin;
        this.isSuperAdmin = this.authService.isSuperAdmin;
        this.canSearchUsers = this.authService.hasValidPermission('userManager', PermissionType.Search);

        this.leftNavIsExpanded$
            .pipe(
                tap(isExpanded => {
                    if (isExpanded) {
                        this.service.resizeTablesOnNavExpand();
                    }
                }),
                takeUntil(this.onDestroy)
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        this.registerIdle();
        this.service.appRouterOutlet = this.routerOutlet.nativeElement;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.appObserver?.disconnect();
    }

    ngAfterContentChecked(): void {
        this.service.updateAppView();
    }

    resetIdle(): void {
        this.idle.watch();
    }

    logout(): void {
        this.authService.logout();
    }

    isActive(param: string): boolean {
        return window.location.pathname.split('/')[1] === param;
    }

    /**
     * Determines whether a link in the builder menu should be displayed.
     * If any links are displayed, the menu will be displayed.
     * @param requiredPermission The required permission for the link
     * @returns boolean
     */
    displayBuilderLink(requiredPermission: PermissionName): boolean {
        const hasPermission: boolean = this.authService.hasValidPermission(requiredPermission, PermissionType.Search);
        if (hasPermission) {
            this.hasVisibleBuilderLinks = true;
        }
        return hasPermission;
    }

    /**
     * Inactive user timeout dialog.
     * When the user's "isloggedIn" status has changes to true, configure and start an idle timer.
     * @see https://www.npmjs.com/package/@ng-idle/core
     */
    private registerIdle(): void {
        this.isLoggedIn
            .pipe(
                distinct(),
                filter(isloggedIn => isloggedIn),
                tap(_ => {
                    const idleUnsubscribe: Subject<void> = new Subject<void>();
                    this.idle.setIdle(IdleDuration);
                    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                    this.idle.onIdleStart.pipe(takeUntil(idleUnsubscribe)).subscribe(() => {
                        const idleDialog = this.dialog.open(IdleComponent, {
                            panelClass: 'small-auto',
                        });
                        idleDialog.componentInstance.countDown = TimeoutDuration;
                        idleDialog.afterClosed().subscribe((stayLoggedIn: boolean) => {
                            if (!stayLoggedIn) {
                                idleUnsubscribe.next();
                                idleUnsubscribe.complete();
                                this.logout();
                            }
                            this.resetIdle();
                        });
                    });

                    this.resetIdle();
                })
            )
            .subscribe();
    }

    openHipaa() {
        this.hipaaService
            .openHipaa()
            ?.afterClosed()
            .pipe(take(1))
            .subscribe(() => this.router.navigateByUrl(this.router.url.split('(')[0]));
    }
}
