import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';

interface HipaaAttestationForm {
    initials: FormControl<string | null>;
}

@Component({
    selector: 'swft-hipaa-attestation',
    templateUrl: './hipaa-attestation.component.html',
    styleUrls: ['./hipaa-attestation.component.scss'],
})
export class HipaaAttestationComponent implements OnInit {
    formGroup: FormGroup<HipaaAttestationForm> = new FormGroup<HipaaAttestationForm>({
        initials: new FormControl(null, [Validators.required, Validators.minLength(1)]),
    });
    /**
     * Get the attestations for the current user.
     */
    attestations = this.hipaaService.dataChanges.pipe(map(data => data.attestations));

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe().subscribe(data => {
            if (!data.attestations.ignoredNumbers) return;
        });
    }

    onAgree() {
        this.attestations.pipe(take(1)).subscribe(attestations => {
            this.hipaaService.attestationInitials = this.formGroup.value.initials || '';
            if (!attestations.documentation) {
                alert('Open Conversation Status Screen - Feature not yet implemented.');
                return;
            }
            alert('Open Conversation - Feature not yet implemented.');
        });
    }
}
