import { MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'swft-conflict-dialog',
    templateUrl: './conflict-dialog.component.html',
    styleUrls: ['./conflict-dialog.component.scss'],
})
export class ConflictDialogComponent {
    @Input() title: string = '';
    @Input() message: string = '';
    @Input() suppressible: boolean = false;

    @Output() proceed = new EventEmitter<void>();
    @Output() abandon = new EventEmitter<void>();

    constructor(private dialogRef: MatDialogRef<ConflictDialogComponent>) {}

    onProceed() {
        this.proceed.emit();
        this.dialogRef.close();
    }

    onAbandon() {
        this.abandon.emit();
        this.dialogRef.close();
    }
}
