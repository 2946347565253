<section
    *ngIf="!viewOnly"
    class="btn-container">
    <button
        *ngIf="!doubleConfirm"
        mat-button
        class="cancal-btn"
        (click)="deny.emit(true)">
        {{ denyText }}
    </button>
    <button
        *ngIf="doubleConfirm"
        mat-button
        [swftConfirm]="denyWarning"
        [swftDescription]="denyDescription"
        class="cancal-btn"
        (click)="deny.emit(true)">
        {{ denyText }}
    </button>
    <ng-content></ng-content>
    <button
        mat-raised-button
        color="accent"
        [disabled]="disableSave"
        (click)="confirm.emit(true)">
        {{ confirmText }}
    </button>
</section>

<section
    *ngIf="viewOnly"
    class="btn-container view-only">
    <button
        mat-button
        class="cancel"
        (click)="deny.emit(true)">
        {{ closeText }}
    </button>
</section>
