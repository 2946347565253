import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HipaaRoutes, HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { OutboundCall, OutboundCallResult, PhoneNumber } from 'src/app/models/call.models';
import { HipaaContact } from 'src/app/models/hipaa.models';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';

@Component({
    selector: 'swft-hipaa-phone-number-selector',
    templateUrl: './hipaa-phone-number-selector.component.html',
    styleUrls: ['./hipaa-phone-number-selector.component.scss'],
})
export class HipaaPhoneNumberSelectorComponent implements OnInit, OnDestroy {
    @Output() phoneNumberSelected = new EventEmitter<PhoneNumber | null>();

    currentContact?: HipaaContact;
    callLog?: OutboundCall[];
    phoneNumbers: PhoneNumber[] = [];
    currentIndex = 0;
    activePhoneNumber: PhoneNumber | null = null;
    hideDetails = false;
    onDestroy$: Subject<void> = new Subject<void>();

    constructor(private hipaaService: HipaaService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy$)).subscribe(hipaaData => {
            const isAnsweredByOther = hipaaData.callLog.some(
                call => call.callResult === OutboundCallResult.AnsweredByOther
            );
            if (isAnsweredByOther) return;
            if (!hipaaData.patient?.id) {
                this.hipaaService.navigate(HipaaRoutes.SelectContact, true);
                return;
            }
            this.currentContact = hipaaData.contact;
            this.callLog = hipaaData.callLog;
            this.buildPhoneNumberList(this.currentContact!);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Selects the next or previous phone number in the stack of phone numbers and sets it as the active number
     * A Timeout is used to animate the phone number list via the hideDetails property
     * @param next TRUE if the next number should be selected, FALSE if the previous number should be selected
     */
    selectNumber(next: boolean): void {
        // if next
        if (next && this.currentIndex === this.phoneNumbers.length - 1) return;
        if (next && this.currentIndex < this.phoneNumbers.length - 1) this.currentIndex++;

        // if previous
        if (!next && this.currentIndex === 0) return;
        if (!next && this.currentIndex > 0) this.currentIndex--;

        this.hideDetails = true;
        setTimeout(() => {
            this.setActivePhoneNumber(this.phoneNumbers[this.currentIndex]);
            this.hideDetails = false;
        }, 300);
    }

    showAdditionalDetails(): void {
        alert('not yet implemented');
    }

    /**
     * Determines whether to show the default (first) phone number or if
     * one was just added, returns the new one as the active one
     * @returns Active phone number or null if no phone numbers available
     */
    private determineActivePhoneNumber(): PhoneNumber | null {
        if (this.phoneNumbers.length) {
            const activePhoneNumberIndex = this.hipaaService.hasNewPhoneNumber
                ? this.phoneNumbers.length - 1
                : this.currentIndex;
            this.hipaaService.hasNewPhoneNumber = false;
            this.currentIndex = activePhoneNumberIndex;
            return this.phoneNumbers[activePhoneNumberIndex];
        }
        return null;
    }

    /**
     * Set the currently displayed phone number that is currently being viewed by the user
     */
    private setActivePhoneNumber(phone: PhoneNumber): void {
        this.activePhoneNumber = phone;
        this.phoneNumberSelected.emit(this.activePhoneNumber);
    }

    /**
     * Builds the phone number list for the given contact and sets the first number returned as the active number.
     * A Timeout is used to animate the phone number list via the hideDetails property
     * @param contact The contact to build the phone number list for
     */
    private buildPhoneNumberList(contact: HipaaContact): void {
        this.hideDetails = true;
        const numbers = this.getContactPhoneNumbers(contact);
        if (!numbers.length) {
            //temporary route to PHI verification per brando
            if (
                this.hipaaService.verificationType === HipaaVerificationType.Payor ||
                this.hipaaService.verificationType === HipaaVerificationType.CoveredEntity
            )
                this.hipaaService.navigate(HipaaRoutes.Verify);
            return;
        }
        setTimeout(() => {
            this.phoneNumbers = numbers;
            this.currentIndex = 0;
            const activePhoneNumber = this.determineActivePhoneNumber();
            if (activePhoneNumber) this.setActivePhoneNumber(activePhoneNumber);
            this.hideDetails = false;
        }, 300);
    }

    /**
     * Returns an array of phone numbers for the given contact
     * @param contact The contact to get phone numbers for
     * @returns An array of phone numbers with type (Cell/Work/Home)
     */
    private getContactPhoneNumbers(contact: HipaaContact): PhoneNumber[] {
        if (!contact) return [];

        const phoneNumbers: PhoneNumber[] = [];
        const numbers: PhoneNumber[] = this.hipaaService.phoneNumbers;
        const hasNumber: boolean = numbers.some(number => !!number.number);
        if (!hasNumber) {
            this.phoneNumberSelected.emit(null);
            return [];
        }

        numbers.forEach(number => {
            if (!number.number) return;
            const match = this.callLog?.some(call => call.phoneNumber.number === number.number);
            if (!match) phoneNumbers.push(number);
        });

        const isAnswered: boolean =
            this.callLog?.some(call => call.callResult === OutboundCallResult.Answered) || false;
        if (phoneNumbers.length === 0 && !isAnswered) {
            setTimeout(() => {
                this.phoneNumberSelected.emit(null);
            }, 300);
            return [];
        }

        return phoneNumbers;
    }
}
