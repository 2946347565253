import { BehaviorSubject } from 'rxjs';
import { IEndpoint } from 'src/environments/config';
import { BaseApiService } from '../services/api/base-api.service';
import { EmptySearchGridText } from '../utils/constants';
import { ApiSearch, ApiSearchTerm, SortOrder } from './api/api-search.models';
import { PermissionName } from './auth/authorization.models';
import { SwftSort } from './table.models';

export enum GridAction {
    Conversation = 'conversation',
    Copy = 'copy',
    Create = 'create',
    Remove = 'remove',
    Discontinue = 'discontinue',
    Edit = 'edit',
    Select = 'select',
    Deselect = 'deselect',
    View = 'view',
}
export interface GridRowActionEvent {
    action: GridAction;
    row: any;
}

export interface GridBulkActionEvent {
    action: GridAction;
    rows: any[];
}

export enum PayloadSortOrderType {
    AscDscText,
    AscendingBoolean,
}

interface IconProperties {
    color: string;
    icon: string;
}

export class SwftGridColumn {
    name: string;
    dataPath: string;
    pixelWidth?: number;
    sortable?: boolean;
    formatter?: string | ((value: any) => string);
    /**
     * Function that takes any argument that is expected to return the column as an icon
     *
     * If this function is supplied, this will override any other displaying functions i.e formatter
     * @default undefined
     */
    displayAsIcon?: (row: any) => IconProperties;
    displayProp?: string;
    collectionDelimiter?: string;

    constructor(name: string, dataPath: string) {
        this.name = name;
        this.dataPath = dataPath;
    }
}

/**
 * Configuration for SwftGridComponent
 */
export class SwftGridConfiguration {
    /**
     * The name of the grid, used for the id of the table element
     */
    tableId: string = '';

    /**
     * Enables / disables paging functionality
     *
     * default: true
     */
    pagingEnabled: boolean = true;

    /**
     * Enables / disables sorting functionality
     *
     * default: true
     */
    sortEnabled: boolean = true;

    /**
     * Enables / disables search functionality
     *
     * default: true
     */
    searchEnabled: boolean = true;

    detailRow: string | undefined = undefined;

    /**
     * Enables / disables date functionality
     * default: false
     */
    dateSearchEnabled: boolean = false;

    /**
     * Enables / disables row selection
     * default: false
     */
    rowSelectEnabled: boolean = false;

    /**
     * Assigning a search term to this property will enable the primary toggle switch
     *
     * When the switch is on, this filter will be applied to the query
     *
     * Note, assining the same searchField to the primary and secondary toggle will cause them to combine
     * values with a pipe delimiter if both are selected (i.e. "formBasis: 'HCP | Order").  These are also
     * combined with any other (static) filters (i.e. status: 'Open | Complete | Closed')
     */
    primaryToggleFilter: ApiSearchTerm | undefined;

    /**
     * Text label for primary toggle switch
     */
    primaryToggleText: string = '';

    /**
     * Assigning a search term to this property will enable the secondary toggle switch
     *
     * When the switch is on, this filter will be applied to the query
     *
     */
    secondaryToggleFilter: ApiSearchTerm | undefined;

    /**
     * Text label for secondary toggle switch
     */
    secondaryToggleText: string = '';

    /**
     * Number of records per grid page
     */
    pageSize: number = 10;

    /**
     * The search endpoint
     */
    searchEndpoint: IEndpoint | undefined;

    /**
     * API service
     */
    apiService: BaseApiService | undefined;

    /**
     * Column definitions
     */
    columnDefinitions: SwftGridColumn[] = [];

    /**
     * Initial sort field / order
     */
    initialSort: SwftSort<any> = { order: SortOrder.Ascending, property: 'id' };

    /**
     * Defines the parameter name (and default value if desired) that will be sent
     * to the search endpoint with the text from the search textbox
     *
     */
    searchTextTermFilter: ApiSearchTerm = {
        searchField: 'value',
        value: '',
    };

    /**
     * Sets the default / 'static' values to be sent to the search endpoint
     */
    searchParameters: BehaviorSubject<ApiSearchTerm[]> = new BehaviorSubject<ApiSearchTerm[]>([]);

    /**
     * Search configuration - used by the grid component at runtime and can be used
     */
    apiSearchConfig: ApiSearch = {
        pageNumber: 0,
        pageSize: 10,
        searchTerms: this.searchParameters,
        sort: this.initialSort,
    };

    /**
     * The property name in the payload for the page number
     */
    payloadPageNumberTag: string = 'page';

    /**
     * The property name in the payload for the page size
     */
    payloadPageSizeTag: string = 'pageLength';

    /**
     * The property name in the payload for the field to sort/order by
     */
    payloadOrderByTag: string = 'orderBy';

    /**
     * The property name in the payload for the sort direction
     */
    payloadSortOrderTag: string = 'ascending';

    /**
     * The type of the sort order property in the payload (i.e. expecting 'ASC' or 'DESC' or is acending: true/false)
     */
    payloadSortOrderType: PayloadSortOrderType = PayloadSortOrderType.AscendingBoolean;

    /**
     * The property name in the payload for the selected date value
     */
    payloadDateTag: string = 'nextActionDate';

    /**
     * A list of row actions to be displayed in the grid
     */
    rowActions: GridAction[] = [];

    /**
     * A list of bulk actions to be displayed in the grid
     */
    bulkActions: GridAction[] = [];

    /**
     * The placeholder text for the search
     */
    searchTextPlaceholder: string = 'Search...';

    /**
     * The text to be displayed when the grid is empty
     */
    emptySearchGridText: string = EmptySearchGridText.NoResult;

    /**
     * Permission name
     */
    permissionName: PermissionName | undefined;

    constructor(params: Partial<SwftGridConfiguration> = {}) {
        Object.assign(this, params);
        this.apiSearchConfig.searchTerms = this.searchParameters;
        this.apiSearchConfig.sort = this.initialSort;

        if (this.apiSearchConfig.searchTerms.value) {
            const currentValue = this.apiSearchConfig.searchTerms.value;
            const updatedValue = [...currentValue, this.searchTextTermFilter];
            this.apiSearchConfig.searchTerms.next(updatedValue);
        }
    }

    updateFilter(filterName: string, value: any): void {
        if (this.apiSearchConfig.searchTerms?.value) {
            const currentValue = this.apiSearchConfig.searchTerms.value;
            const index = currentValue.findIndex((searchTerm: ApiSearchTerm) => searchTerm.searchField === filterName);
            currentValue[index].value = value;
            this.apiSearchConfig.searchTerms.next([...currentValue]);
        }
    }
}
