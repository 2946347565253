import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HipaaRoutes } from 'src/app/enums/hipaa.enum';
import { PhoneNumber, PhoneNumberType } from 'src/app/models/call.models';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PhoneNumberRegex } from 'src/app/utils/constants';

@Component({
    selector: 'swft-hipaa-add-phone-number',
    templateUrl: './hipaa-add-phone-number.component.html',
    styleUrls: ['./hipaa-add-phone-number.component.scss'],
})
export class HipaaAddPhoneNumberComponent {
    formGroup: FormGroup = new FormGroup({
        number: new FormControl(undefined, [Validators.required, Validators.pattern(PhoneNumberRegex)]),
        numberType: new FormControl(undefined, [Validators.required]),
    });

    PhoneNumberType = PhoneNumberType;

    constructor(private hipaaService: HipaaService) {}

    onContinueClicked(): void {
        const phoneNumber: PhoneNumber = this.formGroup.value;
        this.hipaaService.addPhoneNumber(phoneNumber);
        this.hipaaService.hasNewPhoneNumber = true;
        this.hipaaService.navigate(HipaaRoutes.OutboundCall);
    }
}
