import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { isEqual } from 'lodash-es';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { HipaaVerificationType } from 'src/app/enums/hipaa.enum';
import { OutboundCallResult } from 'src/app/models/call.models';
import { HipaaContact } from 'src/app/models/hipaa.models';
import { PatientContact } from 'src/app/models/profile/contact.model';
import { PatientDemographic } from 'src/app/models/profile/demographic.model';
import { HipaaService } from 'src/app/services/hipaa/hipaa.service';
import { PatientService } from 'src/app/services/patient/patient.service';

@Component({
    selector: 'swft-hipaa-authorized-rep',
    templateUrl: './hipaa-authorized-rep.component.html',
    styleUrls: ['./hipaa-authorized-rep.component.scss'],
})
export class HipaaAuthorizedRepComponent implements OnInit, OnDestroy {
    patient?: PatientDemographic;
    onDestroy$: Subject<void> = new Subject<void>();
    patientDisplayValue?: string;
    authorizedRepresentatives$?: Observable<PatientContact[]>;
    authorizedRep?: HipaaContact;

    constructor(private hipaaService: HipaaService, private patientService: PatientService) {}

    ngOnInit(): void {
        this.hipaaService.dataChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.patientDisplayValue = this.hipaaService.patientDisplayName;
            if (data.patient.id) {
                this.patient = data.patient;
                this.authorizedRep = data.contact;

                if (!this.authorizedRepresentatives$) {
                    this.authorizedRepresentatives$ = this.patientService.getPatientContacts(data.patient.id).pipe(
                        map(authReps => {
                            const reps = authReps as PatientContact[];
                            const isAnsweredByOther = data.callLog.find(
                                call => call.callResult === OutboundCallResult.AnsweredByOther
                            );
                            // If the call was answered by another person, remove the representitive we are switching from
                            if (isAnsweredByOther) {
                                return reps.filter(rep => !isEqual(rep, isAnsweredByOther.contact));
                            }
                            return reps;
                        })
                    );
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onAddAuthorizedRepClicked() {
        alert('Functionality not yet implemented');
    }

    /**
     * Update the HIPAA data's contact with the selected authorized representative
     * @param selectEvent A selection event from the authorized representative select
     */
    onAuthorizedRepSelected(selectEvent: MatSelectChange): void {
        this.hipaaService.validateContact(selectEvent.value as PatientContact, HipaaVerificationType.AuthRep);
    }
}
