export enum HipaaRoutes {
    AddPhoneNumber = 'add-phone-number',
    Attestation = 'attestation',
    SelectContact = 'select-contact',
    SelectContactType = 'select-contact-type',
    OutboundCall = 'outbound-call',
    Verify = 'verify',
    Intro = 'intro',
}

export enum HipaaVerificationType {
    Empty = '',
    Patient = 'patient',
    AuthRep = 'authrep',
    Payor = 'payor',
    HCP = 'healthcareprovider',
    CoveredEntity = 'coveredentity',
}
