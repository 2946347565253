<section>
    <h2>Select a Health Care Provider</h2>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Health Care Provider</mat-label>
        <mat-select (selectionChange)="selected($event)">
            <mat-option
                *ngFor="let hcp of hcps$ | async"
                [value]="hcp">
                {{ hcp.physicianName }}
                <ng-container *ngIf="hcp.organization">({{ hcp.organization }})</ng-container>
            </mat-option>
            <mat-option [value]="_otherOption">Other</mat-option>
        </mat-select>
    </mat-form-field>
</section>

<form
    *ngIf="selectionMade$ | async"
    class="form-container"
    [formGroup]="formGroup">
    <mat-form-field
        *ngIf="otherSelected$ | async"
        appearance="fill"
        class="full">
        <mat-label>Organization</mat-label>

        <input
            matInput
            formControlName="organization" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>First Name</mat-label>

        <input
            matInput
            formControlName="firstName" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Last Name/Initial</mat-label>
        <input
            matInput
            formControlName="lastNameOrInitial" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Title</mat-label>
        <input
            matInput
            formControlName="title" />
    </mat-form-field>
    <mat-form-field
        appearance="fill"
        class="full">
        <mat-label>Phone</mat-label>
        <input
            matInput
            formControlName="phone"
            [swftHyphentatePhoneNumber]="true" />
    </mat-form-field>
</form>

<swft-form-errors
    [formGroup]="formGroup"
    [floatable]="false"></swft-form-errors>
