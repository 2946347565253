<ng-container *ngIf="section.links$ | async; let links">
    <section
        *ngIf="links.length"
        [class.expanded]="section.expanded">
        <header (click)="toggleExpandedSection()">
            <mat-icon>{{ section.icon }}</mat-icon>
            <span class="section-title">
                {{ section.title }}
            </span>
            <mat-icon
                class="expand"
                [class.expanded]="section.expanded">
                expand_more
            </mat-icon>
        </header>
        <ul>
            <li
                *ngFor="let link of links; let idx = index"
                routerLinkActive="active"
                [routerLink]="link.url">
                {{ section.displayWith(link) }}
                <ng-container *ngIf="!section.confirmMessage; else iconWithConfirm">
                    <mat-icon (click)="removeLinkByIndex($event, section.linkType, idx)">close</mat-icon>
                </ng-container>
                <ng-template #iconWithConfirm>
                    <mat-icon
                        (click)="removeLinkByIndex($event, section.linkType, idx)"
                        [swftConfirm]="section.confirmMessage!">
                        close
                    </mat-icon>
                </ng-template>
            </li>
        </ul>
    </section>
</ng-container>
