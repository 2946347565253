import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwftModule } from 'src/app/components/swft/swft.module';
import { MaterialModule } from 'src/app/swft/modules/material.module';
import { HipaaAttestationComponent } from './hipaa-attestation/hipaa-attestation.component';
import { HipaaIntroComponent } from './hipaa-intro/hipaa-intro.component';
import { HipaaOutboundCallModule } from './hipaa-outbound-call/hipaa-outbound-call.module';
import { HipaaRoutingModule } from './hipaa-routing.module';
import { HipaaSelectContactTypeComponent } from './hipaa-select-contact-type/hipaa-select-contact-type.component';
import { HipaaAuthorizedRepComponent } from './hipaa-select-contact/hipaa-authorized-rep/hipaa-authorized-rep.component';
import { HipaaCoveredEntityComponent } from './hipaa-select-contact/hipaa-covered-entity/hipaa-covered-entity.component';
import { HipaaHCPComponent } from './hipaa-select-contact/hipaa-hcp/hipaa-hcp.component';
import { HipaaPayorComponent } from './hipaa-select-contact/hipaa-payor/hipaa-payor.component';
import { HipaaSelectContactComponent } from './hipaa-select-contact/hipaa-select-contact.component';
import { HipaaVerificationComponent } from './hipaa-verification/hipaa-verification.component';
import { HipaaComponent } from './hipaa.component';

@NgModule({
    declarations: [
        HipaaAuthorizedRepComponent,
        HipaaComponent,
        HipaaAttestationComponent,
        HipaaHCPComponent,
        HipaaIntroComponent,
        HipaaPayorComponent,
        HipaaSelectContactComponent,
        HipaaSelectContactTypeComponent,
        HipaaVerificationComponent,
        HipaaCoveredEntityComponent,
    ],
    imports: [HipaaRoutingModule, CommonModule, MaterialModule, SwftModule, HipaaOutboundCallModule],
    providers: [],
    exports: [],
})
export class HipaaModule {}
