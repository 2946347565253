import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { NarrativeType } from 'src/app/enums/narrative-type.enum';
import { ApiSearchTerm, SortOrder } from 'src/app/models/api/api-search.models';
import { Conversation, ConversationNarrative, MedicationLookup } from 'src/app/models/conversation.model';
import {
    GridAction,
    GridBulkActionEvent,
    GridRowActionEvent,
    PayloadSortOrderType,
    SwftGridColumn,
    SwftGridConfiguration,
} from 'src/app/models/grid.models';
import { Narrative, NarrativeSearch, NarrativeStatus } from 'src/app/models/narrative.model';
import { NarrativeApiService } from 'src/app/services/api/narrative-api.service';
import { ConversationService } from 'src/app/services/conversation/conversation.service';
import { LeftNavService } from 'src/app/services/left-nav/left-nav.service';
import { SWFTSnackBarService } from 'src/app/services/swft-snackbar/swft-snackbar.service';
import { SwftBasis } from 'src/app/utils/constants';
import { environment } from 'src/environments/config';
import { SwftGridComponent } from '../swft/swft-grid/swft-grid.component';
import { ConversationFormService } from './conversations/state/conversation-form.service';
import { CreateNarrativeComponent } from './create/create-narrative.component';
import { NarrativeService } from './narrative.service';

@Component({
    selector: 'swft-narratives',
    templateUrl: './narratives.component.html',
    styleUrls: ['./narratives.component.scss'],
})
export class NarrativesComponent implements OnDestroy, OnInit, OnChanges {
    @ViewChild(SwftGridComponent) grid!: SwftGridComponent;

    @Input() showTitle: boolean = false;
    @Input() searchId: number = 0;
    @Input() narrativeType: string = '';
    @Input() resultPageSize: number = 100;
    @Input() displayColumns: SwftGridColumn[] = [];

    onDestroy$ = new Subject<void>();

    isStartingConversation: boolean = false;

    // Grid properties
    gridConfiguration: SwftGridConfiguration = new SwftGridConfiguration({
        dateSearchEnabled: true,
        primaryToggleFilter: { searchField: 'status', value: NarrativeStatus.Complete },
        primaryToggleText: 'Show Completed',
        secondaryToggleFilter: { searchField: 'status', value: NarrativeStatus.Cancelled },
        secondaryToggleText: 'Show Cancelled',
        pageSize: 10,
        searchEndpoint: environment.apiNarrative.endpoints.search,
        apiService: this.api,
        columnDefinitions: this.displayColumns,
        initialSort: { order: SortOrder.Ascending, property: 'NextActionDate' },
        searchTextTermFilter: { searchField: 'value', value: '' },
        searchParameters: new BehaviorSubject<ApiSearchTerm[]>([
            { searchField: 'formBasis', value: this.narrativeType },
            { searchField: 'status', value: NarrativeStatus.Open },
            { searchField: 'searchId', value: this.searchId > 0 ? this.searchId : '' },
        ]),
        payloadSortOrderTag: 'ascending',
        payloadSortOrderType: PayloadSortOrderType.AscendingBoolean,
        payloadDateTag: 'nextActionDate',
        rowActions: [GridAction.Conversation, GridAction.Edit, GridAction.Remove, GridAction.Select],
        bulkActions: [GridAction.Conversation, GridAction.Edit, GridAction.Remove, GridAction.Select],
        searchTextPlaceholder: 'Search ' + this.narrativeType.split('|').join(' and ') + ' narratives...',
        rowSelectEnabled: true,
        tableId: 'Narratives',
    });

    constructor(
        public api: NarrativeApiService,
        private narrative: NarrativeService,
        private leftNavService: LeftNavService,
        private conversationService: ConversationService,
        private conversationFormService: ConversationFormService,
        private router: Router,
        private snackBar: SWFTSnackBarService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.gridConfiguration.updateFilter('formBasis', this.narrativeType);
        this.gridConfiguration.columnDefinitions = this.displayColumns;
        if (this.searchId > 0) this.gridConfiguration.updateFilter('searchId', this.searchId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['searchId'] && changes['searchId'].currentValue > 0) {
            this.gridConfiguration.updateFilter('searchId', Number(changes['searchId'].currentValue));
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    search(): void {
        this.grid.refresh();
    }

    rowAction(event: GridRowActionEvent): void {
        if (event.action === GridAction.Edit) this.rowEdit(event.row);
        if (event.action === GridAction.Remove) this.rowDelete(event.row);
        if (event.action === GridAction.Conversation) this.startSingleNarrativeConversation(event.row);
    }

    bulkAction(event: GridBulkActionEvent): void {
        if (event.action === GridAction.Edit) this.rowEdit(event.rows);
        if (event.action === GridAction.Remove) this.rowDelete(event.rows);
        if (event.action === GridAction.Conversation) this.startMultiNarrativeConversation(event.rows);
    }

    rowEdit(row: NarrativeSearch | NarrativeSearch[]): void {
        alert('not yet implemented');
    }

    rowDelete(row: NarrativeSearch | NarrativeSearch[]): void {
        alert('not yet implemented');
    }

    openCreateNarrativeDialog() {
        this.dialog
            .open(CreateNarrativeComponent, {
                panelClass: 'medium',
                disableClose: true,
                data: { narrativeType: this.narrativeType },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.grid.applySort({ active: 'id', direction: SortOrder.Descending });
            });
    }

    startSingleNarrativeConversation(narrative: Narrative): void {
        this.isStartingConversation = true;

        const link = this.leftNavService.getConversationLinkByNarrative([narrative.id]);
        if (link && link.url) {
            this.router.navigateByUrl(link.url);
            return;
        }

        this.narrative.startMultiNarrativeConversation([narrative]);
        this.isStartingConversation = false;
    }

    startMultiNarrativeConversation(narratives: Narrative[]): void {
        if (!this.checkNarrativesAreSamePatient(narratives)) {
            const narrativePersona =
                this.narrativeType === NarrativeType.Hcp ? NarrativeType.Hcp : NarrativeType.Patient;
            this.snackBar.error(`Selected narratives must be for the same ${narrativePersona}`, 5000);
            return;
        }

        const link = this.leftNavService.getConversationLinkByNarrative(narratives.map(narr => narr.id));

        if (link && link.url) {
            this.router.navigateByUrl(link.url);
            return;
        }

        const conversation: Conversation = {
            id: 0,
            startDate: new Date(),
            conversationNarratives: narratives.map<ConversationNarrative>(narrative => ({
                conversationId: 0,
                narrativeId: narrative.id,
                medicationName: narrative.medicationName,
            })),
            narratives: [],
            conversationFormData: [],
        };
        this.conversationService
            .create(conversation)
            .pipe(take(1))
            .subscribe({
                next: result => {
                    const narrative = narratives[0];
                    const formBasis = narrative.formBasisName;

                    const medicationNames: MedicationLookup[] = [];
                    conversation.conversationNarratives?.forEach(convo => {
                        let medName: MedicationLookup = {
                            narrativeId: convo.narrativeId,
                            medicationName: convo.medicationName,
                            conversationId: result.id,
                        };
                        medicationNames.push(medName);
                    });
                    this.conversationFormService.setMedicationNames(medicationNames);

                    switch (formBasis) {
                        case SwftBasis.Order:
                        case SwftBasis.Patient:
                            this.router.navigate(['/', 'patients', narrative.patientId, 'conversations', result.id]);
                            break;
                        case SwftBasis.HCP:
                            this.router.navigate(['/', 'hcp', narrative.hcpId, 'conversations', result.id]);
                            break;
                    }
                },
                error: () => {
                    this.isStartingConversation = false;
                    this.snackBar.error('starting conversation failed.');
                },
            });
    }

    private checkNarrativesAreSamePatient(narratives: Narrative[]): boolean {
        if (narratives.length < 2) return true;
        return narratives[0].formBasisName === SwftBasis.HCP
            ? !narratives.find(narrative => narrative.hcpId !== narratives[0].hcpId)
            : !narratives.find(narrative => narrative.patientId !== narratives[0].patientId);
    }
}
