<section>
    <swft-logic-condition
        *ngFor="let condition of conditions | async; let i = index"
        [condition]="condition"
        [firstInGroup]="i === 0"
        [lastInGroup]="(conditions | async)?.length === i + 1"
        (valueChanges)="updateCondition($event, i)"
        (addCondition)="addCondition($event)"
        (removeCondition)="removeCondition(i)"></swft-logic-condition>
</section>
<swft-confirm
    [doubleConfirm]="true"
    [disabled]="!conditions.value[0].value"
    (deny)="cancel()"
    confirmText="Save"
    (confirm)="save()"></swft-confirm>
