import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Answer } from 'src/app/models/answer.model';
import { ConversationAction, MedicationLookup } from 'src/app/models/conversation.model';

@Injectable({
    providedIn: 'root',
})
export class ConversationFormService implements OnDestroy {
    conversationAction$: BehaviorSubject<ConversationAction> = new BehaviorSubject<ConversationAction>(
        ConversationAction.None
    );
    actionCombinationsUpdated$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

    formGroupLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    //need to persist the medication of the narrative because it is not loaded in MT when reading conversation
    medicationNameLookup: MedicationLookup[] = [];
    //need to persist the location user opened the conversation to return them to that location

    answerUpdated$: BehaviorSubject<Answer | undefined> = new BehaviorSubject<Answer | undefined>(undefined);

    private _onDestroy$: Subject<void> = new Subject<void>();

    afterValidityUpdated$: Subject<void> = new Subject<void>();

    constructor() {}

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    //set the medication name after conversation created to put into state
    setMedicationNames(medicationNames: MedicationLookup[]) {
        this.medicationNameLookup = medicationNames;
    }

    //this is not a stable reference point for medication name
    //will be overritten once a new convo is opened, refer to conversationstore for stable medication data
    getMedicationNameForConversationId(conversationId: number): MedicationLookup[] {
        return this.medicationNameLookup?.filter(med => med.conversationId === conversationId);
    }

    answerValidityUpdated() {
        this.afterValidityUpdated$.next();
    }

    getAnswerFormGroupControlName(
        elementId: string | number,
        questionId: string | number,
        narrativeId: string | number
    ) {
        if (!elementId || !questionId || !narrativeId) return '';
        return `${elementId}:${questionId}:${narrativeId}`;
    }
}
