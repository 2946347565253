import { Endpoint, IEndpoint } from './endpoint';

export class QuestionBuilderEndpoints {
    responseOption: ResponseOptionEndpoints = new ResponseOptionEndpoints();
    responseType: ResponseTypeEndpoints = new ResponseTypeEndpoints();
    responseValue: ResponseValueEndpoints = new ResponseValueEndpoints();
    questionModule: QuestionModuleEndpoints = new QuestionModuleEndpoints();
    question: QuestionEndpoints = new QuestionEndpoints();
    questionSearch: QuestionSearchEndpoints = new QuestionSearchEndpoints();
    questionModuleSearch: QuestionModuleSearchEndpoints = new QuestionModuleSearchEndpoints();
    dataDictionary: DataDictionaryEndpoints = new DataDictionaryEndpoints();
    informationItem: InformationItemEndpoints = new InformationItemEndpoints();
    informationItemSearch: InformationItemSearchEndpoints = new InformationItemSearchEndpoints();
    questionLock: QuestionLockEndpoints = new QuestionLockEndpoints();
}

export class ResponseOptionEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/responseoption');
    update: IEndpoint = new Endpoint('put', 'v1/responseoption');
    lookup: IEndpoint = new Endpoint('get', 'v1/responseoption/');
    get: IEndpoint = new Endpoint('get', 'v1/responseoption/{id}');
    delete: IEndpoint = new Endpoint('delete', 'v1/responseoption');
    validate: IEndpoint = new Endpoint('get', 'v1/responseoption/validate/{query}');
    search: IEndpoint = new Endpoint('post', 'v1/responseoption/search');
}

export class ResponseTypeEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/responsetype');
    update: IEndpoint = new Endpoint('put', 'v1/responsetype');
    get: IEndpoint = new Endpoint('get', 'v1/responsetype');
    getById: IEndpoint = new Endpoint('get', 'v1/responsetype/{id}');
    deleteById: IEndpoint = new Endpoint('delete', 'v1/responsetype/{id}');
}

export class ResponseValueEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/responsevalue');
    update: IEndpoint = new Endpoint('put', 'v1/responsevalue');
    getById: IEndpoint = new Endpoint('get', 'v1/responsevalue/{id}');
    deleteById: IEndpoint = new Endpoint('delete', 'v1/responsevalue/{id}');
}

export class QuestionModuleEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/questionmodule');
    read: IEndpoint = new Endpoint('get', 'v1/questionmodule/{id}');
    update: IEndpoint = new Endpoint('put', 'v1/questionmodule');
    delete: IEndpoint = new Endpoint('delete', 'v1/questionmodule/{id}');
    getAll: IEndpoint = new Endpoint('get', 'v1/questionmodule');
    validate: IEndpoint = new Endpoint('get', 'v1/questionmodule/validate/{query}');
    questionsValidate: IEndpoint = new Endpoint('post', 'v1/questionmodule/questions/validate');
    getForms: IEndpoint = new Endpoint('post', 'v1/questionmodule/{id}/forms');
}

export class QuestionModuleSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/questionmodulesearch/search');
    searchByBasis: IEndpoint = new Endpoint('post', 'v1/questionmodulesearch/search/{basis}');
}

export class QuestionEndpoints {
    validateTitle: IEndpoint = new Endpoint('get', 'v1/question/validate/{query}');
    create: IEndpoint = new Endpoint('post', 'v1/question');
    update: IEndpoint = new Endpoint('put', 'v1/question');
    read: IEndpoint = new Endpoint('get', 'v1/question/{id}');
    validate: IEndpoint = new Endpoint('get', 'v1/question/validate/{title}');
    delete: IEndpoint = new Endpoint('delete', 'v1/question/{id}');
}

export class QuestionLockEndpoints {
    create: IEndpoint = new Endpoint('post', 'v1/questionLock');
    update: IEndpoint = new Endpoint('put', 'v1/questionLock');
    read: IEndpoint = new Endpoint('get', 'v1/questionLock/{id}');
    delete: IEndpoint = new Endpoint('delete', 'v1/questionLock/{id}');
    bulkCreate: IEndpoint = new Endpoint('post', 'v1/questionLock/bulkinsert');
}

export class QuestionSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/question/search');
    searchWithFormBasis: IEndpoint = new Endpoint('post', 'v1/question/search/{formBasis}');
}

export class DataDictionaryEndpoints {
    read: IEndpoint = new Endpoint('get', 'v1/datadictionary/{id}');
    update: IEndpoint = new Endpoint('put', 'v1/datadictionary');
    validateAliasUnique: IEndpoint = new Endpoint('get', 'v1/datadictionary/validate/{query}');
    validateAliasUniqueWithBasis: IEndpoint = new Endpoint('post', 'v1/datadictionary/validateWithBasis');

    search: IEndpoint = new Endpoint('post', 'v1/datadictionarySearch/search');
    searchWithBasis: IEndpoint = new Endpoint('post', 'v1/datadictionarySearch/searchwithbasis');
}

export class InformationItemEndpoints {
    validateTitle: IEndpoint = new Endpoint('get', 'v1/informationitem/validate/{query}');
    create: IEndpoint = new Endpoint('post', 'v1/informationitem');
    update: IEndpoint = new Endpoint('put', 'v1/informationitem');
    read: IEndpoint = new Endpoint('get', 'v1/informationitem/{id}');
    validate: IEndpoint = new Endpoint('get', 'v1/informationitem/validate/{title}');
    delete: IEndpoint = new Endpoint('delete', 'v1/informationitem/{id}');
}

export class InformationItemSearchEndpoints {
    search: IEndpoint = new Endpoint('post', 'v1/informationitem/search');
    searchWithFormBasis: IEndpoint = new Endpoint('post', 'v1/informationitem/search/{formBasis}');
}
